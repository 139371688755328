import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Locality }         from "Utils/API";

// Types
import {
    LOCALITY, LOCALITY_LOADING,
    LOCALITY_LIST, LOCALITY_ELEM, LOCALITY_CREATE,
} from "Utils/Types";



/**
 * Fetches the Locality List
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchLocalities(params = {}) {
    return async (dispatch) => {
        dispatch({ type : LOCALITY_LOADING });
        Params.unset(params);
        const data = await Locality.getAll(params);
        data.sort = params;
        dispatch({ type : LOCALITY_LIST, data });
        dispatch(setSection(LOCALITY));
    };
}

/**
 * Fetches a single Locality
 * @param {Number} localityID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchLocality(localityID) {
    return async (dispatch) => {
        const data = await Locality.getOne({ localityID });
        dispatch({ type : LOCALITY_ELEM, data });
    };
}

/**
 * Fetches the Locality Create data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchLocalityCreate() {
    return async (dispatch) => {
        const data = await Locality.getCreateData();
        dispatch({ type : LOCALITY_CREATE, data });
    };
}



/**
 * Edits a Locality
 * @param {Object} data
 * @returns {() => Void}
 */
export function editLocality(data) {
    return () => Locality.edit(data);
}

/**
 * Deletes a Locality
 * @param {Number} localityID
 * @returns {() => Void}
 */
export function deleteLocality(localityID) {
    return () => Locality.delete({ localityID });
}
