import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Assignment }       from "Utils/API";

// Types
import {
    ASSIGNMENT, ASSIGNMENT_LOADING,
    ASSIGNMENT_LIST, ASSIGNMENT_ELEM, ASSIGNMENT_CREATE,
} from "Utils/Types";



/**
 * Fetches the Assignments List
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchAssignments(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : ASSIGNMENT_LOADING });
        }
        Params.unset(params);
        const data = await Assignment.getAll(params);
        data.sort = params;
        dispatch({ type : ASSIGNMENT_LIST, data });
        dispatch(setSection(ASSIGNMENT));
    };
}

/**
 * Fetches a single Assignment
 * @param {Number} assignmentID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchAssignment(assignmentID) {
    return async (dispatch) => {
        const data = await Assignment.getOne({ assignmentID });
        dispatch({ type : ASSIGNMENT_ELEM, data });
    };
}

/**
 * Fetches the Assignment Create data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchAssignmentCreate() {
    return async (dispatch) => {
        const data = await Assignment.getCreateData();
        dispatch({ type : ASSIGNMENT_CREATE, data });
    };
}



/**
 * Edits an Assignment
 * @param {Object} data
 * @returns {() => Void}
 */
export function editAssignment(data) {
    return () => Assignment.edit(data);
}

/**
 * Deletes an Assignment
 * @param {Number} assignmentID
 * @returns {() => Void}
 */
export function deleteAssignment(assignmentID) {
    return () => Assignment.delete({ assignmentID });
}
