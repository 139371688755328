import { setSection }       from "Actions/Core/CoreActions";
import { ErrorLog }         from "Utils/API";

// Types
import {
    ERROR_LOG, ERROR_LOG_LOADING,
    ERROR_LOG_LIST, ERROR_LOG_ELEM,
} from "Utils/Types";



/**
 * Fetches the Error Log
 * @param {Object=}  filter
 * @param {Object=}  sort
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchErrorLog(filter = {}, sort = {}, withLoader = true) {
    return async (dispatch) => {
        try {
            if (withLoader) {
                dispatch({ type : ERROR_LOG_LOADING });
            }
            const data  = await ErrorLog.filter({ ...filter, ...sort });
            data.filter = filter;
            data.sort   = sort;
            dispatch({ type : ERROR_LOG_LIST, data });
            dispatch(setSection(ERROR_LOG));
        } catch (errors) {
            throw errors;
        }
    };
}

/**
 * Fetches a single Error Log
 * @param {Number} logID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchError(logID) {
    return async (dispatch) => {
        const data = await ErrorLog.getOne({ logID });
        dispatch({ type : ERROR_LOG_ELEM, data });
    };
}



/**
 * Resolves an Error
 * @param {Number} logID
 * @returns {() => Void}
 */
export function resolveError(logID) {
    return () => ErrorLog.markResolved({ logID });
}

