import React                from "react";
import PropTypes            from "prop-types";

// Components
import MediaContent         from "Components/Utils/Media/MediaContent";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";



/**
 * The Media Page
 */
class MediaPage extends React.Component {
    // The Refs
    mediaRef = React.createRef();

    /**
     * Handles the Media Select
     * @returns {Void}
     */
    startAction = (action) => {
        this.mediaRef.current.startAction(action);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { route } = this.props;

        return <Main>
            <Header message="MEDIA_NAME" icon="media" route={route}>
                <ActionList data={{ canCreate : true }} onAction={this.startAction} />
            </Header>
            <Content>
                <MediaContent
                    passedRef={this.mediaRef}
                />
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        route : PropTypes.string.isRequired,
    }
}

export default MediaPage;
