import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Guide }            from "Utils/API";

// Types
import {
    GUIDE, GUIDE_LOADING, GUIDE_CHARGING,
    GUIDE_LIST, GUIDE_ELEM, GUIDE_CREATE,
} from "Utils/Types";



/**
 * Fetches the Guide List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch) => Void}
 */
export function fetchGuides(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : GUIDE_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "COURSE":
            params.courseID = elemID;
            data = await Guide.getAllForCourse(params);
            break;
        default:
            data = await Guide.getAll(params);
        }
        data.sort = params;
        dispatch({ type : GUIDE_LIST, data });
        dispatch(setSection(GUIDE));
    };
}

/**
 * Fetches a single Guide
 * @param {Number}   guideID
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchGuide(guideID, withLoader = false) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : GUIDE_CHARGING });
        }
        const data = await Guide.getOne({ guideID });
        dispatch({ type : GUIDE_ELEM, data });
    };
}

/**
 * Fetches the Guide Create data
 * @param {Number} courseID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchGuideCreate(courseID) {
    return async (dispatch) => {
        const data = await Guide.getCreateData({ courseID });
        dispatch({ type : GUIDE_CREATE, data });
    };
}



/**
 * Edits/Creates a Guide
 * @param {Object} data
 * @returns {() => Void}
 */
export function editGuide(data) {
    return () => Guide.edit(data);
}

/**
 * Deletes a Guide
 * @param {Number} guideID
 * @returns {() => Void}
 */
export function deleteGuide(guideID) {
    return () => Guide.delete({ guideID });
}
