import Params                from "dashboard/dist/Core/Params";
import { setSection }        from "Actions/Core/CoreActions";
import { InscriptionReason } from "Utils/API";

// Types
import {
    INSCRIPTION_REASON, INSCRIPTION_REASON_LOADING,
    INSCRIPTION_REASON_LIST, INSCRIPTION_REASON_ELEM, INSCRIPTION_REASON_CREATE,
} from "Utils/Types";



/**
 * Fetches the Inscription Reasons List
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchReasons(params = {}) {
    return async (dispatch) => {
        dispatch({ type : INSCRIPTION_REASON_LOADING });
        Params.unset(params);
        const data = await InscriptionReason.getAll(params);
        data.sort = params;
        dispatch({ type : INSCRIPTION_REASON_LIST, data });
        dispatch(setSection(INSCRIPTION_REASON));
    };
}

/**
 * Fetches a single Inscription Reason
 * @param {Number} reasonID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchReason(reasonID) {
    return async (dispatch) => {
        const data = await InscriptionReason.getOne({ reasonID });
        dispatch({ type : INSCRIPTION_REASON_ELEM, data });
    };
}

/**
 * Fetches the Inscription Reason Create data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchReasonCreate() {
    return async (dispatch) => {
        const data = await InscriptionReason.getCreateData();
        dispatch({ type : INSCRIPTION_REASON_CREATE, data });
    };
}



/**
 * Edits a Inscription Reason
 * @param {Object} data
 * @returns {() => Void}
 */
export function editReason(data) {
    return () => InscriptionReason.edit(data);
}

/**
 * Deletes a Inscription Reason
 * @param {Number} reasonID
 * @returns {() => Void}
 */
export function deleteReason(reasonID) {
    return () => InscriptionReason.delete({ reasonID });
}
