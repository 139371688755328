import React                from "react";
import PropTypes            from "prop-types";

// Sections
import SetupList            from "Components/Admin/Setup/SetupList";
import AdminList            from "Components/Admin/User/Admin/AdminList";
import InterestList         from "Components/Admin/Setup/Interest/InterestList";
import GroupInterestList    from "Components/Admin/Setup/GroupInterest/GroupInterestList";

import StaffList            from "Components/Admin/Setup/Staff/StaffList";
import NewsList             from "Components/Admin/Setup/News/NewsList";
import AdviceList           from "Components/Admin/Setup/Advice/AdviceList";

import PaymentMethodList    from "Components/Admin/Setup/PaymentMethod/PaymentMethodList";
import MatCategoryList      from "Components/Admin/Material/Category/CategoryList";
import InscReasonList       from "Components/Admin/Strech/Reason/ReasonList";

import CoachSpecialityList  from "Components/Admin/Coach/Speciality/SpecialityList";
import CoachCategoryList    from "Components/Admin/Coach/Category/CategoryList";
import CoachMaterialList    from "Components/Admin/Coach/Material/MaterialList";
import CoachQuestionList    from "Components/Admin/Coach/Question/QuestionList";
import CoachProfesionList   from "Components/Admin/Coach/Profesion/ProfesionList";

import HelpConfigPage       from "Components/Admin/Ticket/Help/HelpConfigPage";
import TicketTypeList       from "Components/Admin/Ticket/TicketType/TicketTypeList";
import SupportHelpPage      from "Components/Admin/Ticket/Support/SupportHelpPage";
import SupportTypeList      from "Components/Admin/Ticket/Support/SupportTypeList";

import OriginList           from "Components/Admin/Lead/Origin/OriginList";
import AssignmentList       from "Components/Admin/Lead/Assignment/AssignmentList";
import CloseReasonList      from "Components/Admin/Lead/CloseReason/CloseReasonList";

import CountryList          from "Components/Admin/Setup/Country/CountryList";
import ProvinceList         from "Components/Admin/Setup/Province/ProvinceList";
import LocalityList         from "Components/Admin/Setup/Locality/LocalityList";
import LocationList         from "Components/Admin/Location/Location/LocationList";

import EmailTemplateList    from "Components/Admin/Setup/EmailTemplate/EmailTemplateList";
import EmailQueueList       from "Components/Admin/Setup/EmailQueue/EmailQueueList";
import EmailWhiteList       from "Components/Admin/Setup/EmailWhiteList/EmailWhiteList";

import ErrorLogList         from "Components/Admin/Setup/ErrorLog/ErrorLogList";
import MediaPage            from "Components/Admin/Setup/MediaPage";
import ActionLogList        from "Components/Admin/Setup/ActionLogList";

// Components
import SecondaryNav         from "Components/Utils/Navigation/SecondaryNav";
import AdminRoute           from "Components/Utils/Route/AdminRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";
import SubNavigationList    from "dashboard/dist/Components/SubNavigationList";



/**
 * The Setup Container
 */
class SetupContainer extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { from, match, route } = this.props;
        const { path, url          } = match;

        return <>
            <SecondaryNav
                message="SETTINGS_NAME"
                href={from}
                path={route}
                baseUrl={url}
            >
                <NavigationItem
                    message="GENERAL_START"
                    url="HOME"
                    icon="home"
                />
                <NavigationItem
                    message="SETTINGS_GENERAL"
                    icon="settings"
                    url="SETTINGS_GENERAL"
                    collapseOnSelect
                    isCollapsed
                    usePrefix
                >
                    <SubNavigationList>
                        <NavigationItem message="ADMINS_NAME"    url="ADMINS"    icon="admin"    />
                        <NavigationItem message="INTERESTS_NAME" url="INTERESTS" icon="interest" />
                        <NavigationItem message="GROUPINTERESTS_NAME" url="GROUPINTERESTS" icon="interest" />
                        <NavigationItem message="STAFF_NAME"     url="STAFF"     icon="staff"    />
                        <NavigationItem message="ADVICES_NAME"   url="ADVICES"   icon="advice"   />
                    </SubNavigationList>
                </NavigationItem>

                <NavigationItem
                    message="STRECHES_NAME"
                    icon="strech"
                    url="SETTINGS_STRECHES"
                    collapseOnSelect
                    isCollapsed
                    usePrefix
                >
                    <SubNavigationList>
                        <NavigationItem message="PAYMENT_METHODS_NAME" url="PAYMENT_METHODS"     icon="billing"  />
                        <NavigationItem message="MAT_CATEGORIES_NAME"  url="MATERIAL_CATEGORIES" icon="material" />
                        <NavigationItem message="INSC_REASONS_NAME"    url="CANCEL_REASONS"      icon="cancel"   />
                    </SubNavigationList>
                </NavigationItem>

                <NavigationItem
                    message="COACHES_NAME"
                    icon="coach"
                    url="SETTINGS_COACHES"
                    collapseOnSelect
                    isCollapsed
                    usePrefix
                >
                    <SubNavigationList>
                        <NavigationItem message="SPECIALITIES_NAME"     url="COACHES_SPECIALITIES" icon="speciality" />
                        <NavigationItem message="COACH_CATEGORIES_NAME" url="COACHES_CATEGORIES"   icon="coach"      />
                        <NavigationItem message="COACH_QUESTIONS_NAME"  url="COACHES_QUESTIONS"    icon="question"   />
                        <NavigationItem message="COACH_MATERIALS_NAME"  url="COACHES_MATERIALS"    icon="material"   />
                        <NavigationItem message="COACH_PROFESION_NAME"  url="COACHES_PROFESION"    icon="question"   />
                    </SubNavigationList>
                </NavigationItem>

                <NavigationItem
                    message="TICKETS_NAME"
                    icon="ticket"
                    url="SETTINGS_TICKETS"
                    collapseOnSelect
                    isCollapsed
                    usePrefix
                >
                    <SubNavigationList>
                        <NavigationItem message="HELP_NAME"          url="TICKETS_HELP"  icon="help" />
                        <NavigationItem message="TICKETS_TYPES_NAME" url="TICKETS_TYPES" icon="type" />
                    </SubNavigationList>
                </NavigationItem>

                <NavigationItem
                    message="SUPPORT_NAME"
                    icon="support"
                    url="SETTINGS_SUPPORT"
                    collapseOnSelect
                    isCollapsed
                    usePrefix
                >
                    <SubNavigationList>
                        <NavigationItem message="HELP_NAME"          url="SUPPORT_HELP"  icon="help" />
                        <NavigationItem message="SUPPORT_TYPES_NAME" url="SUPPORT_TYPES" icon="type" />
                    </SubNavigationList>
                </NavigationItem>

                <NavigationItem
                    message="LEADS_NAME"
                    icon="lead"
                    url="SETTINGS_LEADS"
                    collapseOnSelect
                    isCollapsed
                    usePrefix
                >
                    <SubNavigationList>
                        <NavigationItem message="ORIGINS_NAME"       url="LEADS_ORIGINS"     icon="origin"     />
                        <NavigationItem message="ASSIGNMENTS_NAME"   url="LEADS_ASSIGNMENTS" icon="assignment" />
                        <NavigationItem message="CLOSE_REASONS_NAME" url="LEADS_REASONS"     icon="cancel"     />
                    </SubNavigationList>
                </NavigationItem>

                <NavigationItem
                    message="SETTINGS_LOCATION"
                    icon="location"
                    url="SETTINGS_LOCATIONS"
                    collapseOnSelect
                    isCollapsed
                    usePrefix
                >
                    <SubNavigationList>
                        <NavigationItem message="LOCATIONS_NAME"  url="LOCATIONS"  icon="location" />
                        <NavigationItem message="COUNTRIES_NAME"  url="COUNTRIES"  icon="country"  />
                        <NavigationItem message="PROVINCES_NAME"  url="PROVINCES"  icon="province" />
                        <NavigationItem message="LOCALITIES_NAME" url="LOCALITIES" icon="locality" />
                    </SubNavigationList>
                </NavigationItem>

                <NavigationItem
                    message="SETTINGS_EMAILS"
                    icon="email"
                    url="SETTINGS_EMAILS"
                    collapseOnSelect
                    isCollapsed
                    usePrefix
                >
                    <SubNavigationList>
                        <NavigationItem message="EMAIL_TEMPLATES_NAME"  url="EMAIL_TEMPLATES"  icon="email"      />
                        <NavigationItem message="EMAIL_QUEUE_NAME"      url="EMAIL_QUEUE"      icon="queue"      />
                        <NavigationItem message="EMAIL_WHITE_LIST_NAME" url="EMAIL_WHITE_LIST" icon="whiteemail" />
                    </SubNavigationList>
                </NavigationItem>

                <NavigationItem
                    message="SETTINGS_EXTRAS"
                    icon="create"
                    url="SETTINGS_EXTRAS"
                    collapseOnSelect
                    isCollapsed
                    usePrefix
                >
                    <SubNavigationList>
                        <NavigationItem message="MEDIA_NAME"       url="MEDIA"   icon="media"   />
                        <NavigationItem message="ACTIONS_LOG_NAME" url="ACTIONS" icon="actions" />
                        <NavigationItem message="ERRORS_LOG_NAME"  url="ERRORS"  icon="error"   />
                    </SubNavigationList>
                </NavigationItem>
            </SecondaryNav>

            <SwitchRoute type="SETUP" path={path} baseUrl={url}>
                <AdminRoute url="HOME"                 component={SetupList}           exact />
                <AdminRoute url="SETTINGS_GENERAL"     component={SetupList}           exact />
                <AdminRoute url="SETTINGS_STRECHES"    component={SetupList}           exact />
                <AdminRoute url="SETTINGS_COACHES"     component={SetupList}           exact />
                <AdminRoute url="SETTINGS_TICKETS"     component={SetupList}           exact />
                <AdminRoute url="SETTINGS_SUPPORT"     component={SetupList}           exact />
                <AdminRoute url="SETTINGS_LEADS"       component={SetupList}           exact />
                <AdminRoute url="SETTINGS_LOCATIONS"   component={SetupList}           exact />
                <AdminRoute url="SETTINGS_EMAILS"      component={SetupList}           exact />
                <AdminRoute url="SETTINGS_EXTRAS"      component={SetupList}           exact />

                <AdminRoute url="ADMINS"               component={AdminList}           />
                <AdminRoute url="GROUPINTERESTS"       component={GroupInterestList}        />
                <AdminRoute url="INTERESTS"            component={InterestList}        />
                <AdminRoute url="STAFF"                component={StaffList}           />
                <AdminRoute url="NEWS"                 component={NewsList}            />
                <AdminRoute url="ADVICES"              component={AdviceList}          />

                <AdminRoute url="PAYMENT_METHODS"      component={PaymentMethodList}   />
                <AdminRoute url="MATERIAL_CATEGORIES"  component={MatCategoryList}     />
                <AdminRoute url="CANCEL_REASONS"       component={InscReasonList}      />

                <AdminRoute url="COACHES_SPECIALITIES" component={CoachSpecialityList} />
                <AdminRoute url="COACHES_CATEGORIES"   component={CoachCategoryList}   />
                <AdminRoute url="COACHES_QUESTIONS"    component={CoachQuestionList}   />
                <AdminRoute url="COACHES_MATERIALS"    component={CoachMaterialList}   />
                <AdminRoute url="COACHES_PROFESION"    component={CoachProfesionList}   />

                <AdminRoute url="TICKETS_HELP"         component={HelpConfigPage}      />
                <AdminRoute url="TICKETS_TYPES"        component={TicketTypeList}      />

                <AdminRoute url="SUPPORT_HELP"         component={SupportHelpPage}     />
                <AdminRoute url="SUPPORT_TYPES"        component={SupportTypeList}     />

                <AdminRoute url="LEADS_ORIGINS"        component={OriginList}          />
                <AdminRoute url="LEADS_ASSIGNMENTS"    component={AssignmentList}      />
                <AdminRoute url="LEADS_REASONS"        component={CloseReasonList}    />

                <AdminRoute url="COUNTRIES"            component={CountryList}         />
                <AdminRoute url="PROVINCES"            component={ProvinceList}        />
                <AdminRoute url="LOCALITIES"           component={LocalityList}        />
                <AdminRoute url="LOCATIONS"            component={LocationList}        />

                <AdminRoute url="EMAIL_TEMPLATES"      component={EmailTemplateList}   />
                <AdminRoute url="EMAIL_QUEUE"          component={EmailQueueList}      />
                <AdminRoute url="EMAIL_WHITE_LIST"     component={EmailWhiteList}      />

                <AdminRoute url="MEDIA"                component={MediaPage}           />
                <AdminRoute url="ACTIONS"              component={ActionLogList}       />
                <AdminRoute url="ERRORS"               component={ErrorLogList}        />
            </SwitchRoute>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        match : PropTypes.object.isRequired,
        route : PropTypes.string.isRequired,
    }
}

export default SetupContainer;
