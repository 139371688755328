import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchGroupInterest, editGroupInterest
} from "Actions/Admin/Setup/GroupInterestActions";



/**
 * The Interest Edit Dialog
 */
class GroupInterestEdit extends React.Component {
    // The Initial Data
    initialData = {
        groupinterestID  : 0,
        name        : "",
        status      : Status.getID("ACTIVE"),
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, fetchGroupInterest } = this.props;
        let loading = false;
        let data    = null;
        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchGroupInterest(elemID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition && elemID) {
            data = Utils.extend(this.initialData, elem);
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editGroupInterest(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose } = this.props;
        const { data, loading, errors          } = this.state;

        return <EditDialog
            open={open}
            icon="interest"
            title={!!elemID ? "GROUPINTERESTS_EDIT_TITLE" : "GROUPINTERESTS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                name="name"
                label="GROUPINTERESTS_ITS_NAME"
                value={data.name}
                error={errors.name}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                value={data.status}
                error={errors.status}
                onChange={this.handleChange}
                options={Status.getSelect()}
                withNone
                isRequired
                shrinkLabel
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchGroupInterest       : PropTypes.func.isRequired,
        editGroupInterest        : PropTypes.func.isRequired,
        open                : PropTypes.bool.isRequired,
        onClose             : PropTypes.func.isRequired,
        onSubmit            : PropTypes.func.isRequired,
        edition             : PropTypes.number.isRequired,
        elem                : PropTypes.object.isRequired,
        elemID              : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition  : state.groupinterest.edition,
            elem     : state.groupinterest.elem,
        };
    }
}

export default connect(GroupInterestEdit.mapStateToProps, {
    fetchGroupInterest, editGroupInterest, 
})(GroupInterestEdit);
