// Types
import {
    CORE_SECTION, CORE_RESULT,
    CORE_MENU_OPEN, CORE_MENU_CLOSE,
    CORE_DETAILS_OPEN, CORE_DETAILS_CLOSE,
} from "Utils/Types";



/**
 * Sets the Section
 * @param {String} section
 * @returns {{type: String, section: String}}
 */
export function setSection(section) {
    return { type : CORE_SECTION, section };
}



/**
 * Shows an Error
 * @param {String} message
 * @returns {(dispatch: Function) => Void}
 */
export function showError(message) {
    const result = { open : true, variant : "error", message };
    return (dispatch) => dispatch({ type : CORE_RESULT, result });
}

/**
 * Hides the Result
 * @returns {(dispatch: Function) => Void}
 */
export function hideResult() {
    const result = { open : false, variant : "", message : "" };
    return (dispatch) => dispatch({ type : CORE_RESULT, result });
}



/**
 * Opens the Menu
 * @returns {(dispatch: Function) => Void}
 */
export function openMenu() {
    return (dispatch) => dispatch({ type : CORE_MENU_OPEN });
}

/**
 * Closes the Menu
 * @returns {(dispatch: Function) => Void}
 */
export function closeMenu() {
    return (dispatch) => dispatch({ type : CORE_MENU_CLOSE });
}



/**
 * Opens the Details
 * @returns {(dispatch: Function) => Void}
 */
export function openDetails() {
    return (dispatch) => dispatch({ type : CORE_DETAILS_OPEN });
}

/**
 * Closes the Details
 * @returns {(dispatch: Function) => Void}
 */
export function closeDetails() {
    return (dispatch) => dispatch({ type : CORE_DETAILS_CLOSE });
}
