import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchStrechEdit, fetchStrechCreate, editStrech,
} from "Actions/Admin/Strech/StrechActions";



/**
 * The Strech Edit Dialog
 */
class StrechEdit extends React.Component {
    // The Initial Data
    initialData = {
        strechID         : 0,
        programID        : 0,
        name             : "",
        title            : "",
        mode             : 0,
        locationID       : 0,
        roomID           : 0,
        streamLink       : "",
        streamPassword   : "",
        fromTimeDate     : "",
        fromTimeHour     : "",
        toTimeDate       : "",
        toTimeHour       : "",
        inscFromTimeDate : "",
        inscToTimeDate   : "",
        quota            : "",
        conversations    : "",
        meetingAmount    : "",
        meetingPrice     : "",
        featuredInSite   : 0,
        free             : 0,
        isRequired       : 0,
    }

    // The Current State
    state = {
        loading   : false,
        data      : { ...this.initialData },
        errors    : {},
        syncTitle : true,
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const {
            open, elemID, elem, toActivate, courseID, programID, locationID,
            programsData, edition, fetchStrechEdit, fetchStrechCreate,
        } = this.props;

        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchStrechEdit(elemID, courseID);
                loading = true;
            } else {
                fetchStrechCreate(courseID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
                data.isRequired = toActivate || Status.is("ACTIVE", elem.status) ? 1 : 0;
            } else {
                data = { ...this.initialData };
                if (programID) {
                    data.programID = programID;
                    data.title     = Utils.getValue(programsData, "programID", programID, "title");
                } else if (locationID) {
                    data.locationID = locationID;
                }
                data.isRequired = toActivate ? 1 : 0;
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const data      = { ...this.state.data,   [name] : value };
        const errors    = { ...this.state.errors, [name] : ""    };
        let   syncTitle = this.state.syncTitle;

        if (syncTitle && name === "programID") {
            if (value) {
                data.title = Utils.getValue(this.props.programsData, "programID", value, "title");
            } else {
                data.title = "";
            }
        } else if (syncTitle && name === "title") {
            syncTitle = false;
        }
        this.setState({ data, errors, syncTitle });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { elemID, editStrech, onSubmit } = this.props;
        const { data, loading                } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const response = await editStrech(data);
                this.setState({ loading : false });
                onSubmit(response.strechID, !!elemID);
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, loading, errors } = this.state;
        const {
            elemID, programID, open, onClose,
            programs, programsData, locations, rooms,
        } = this.props;

        const program       = Utils.getValue(programsData, "programID", data.programID);
        const isPresential  = Common.isPresentialMode(data.mode);
        const isVirtual     = Common.isVirtualMode(data.mode);
        const showDates     = Boolean(data.programID && !program.hasClasses);
        const capacity2cols = Boolean(!program.hasConversations && !program.hasGroups);
        const isRequired    = Boolean(data.isRequired);

        return <EditDialog
            open={open}
            icon="strech"
            title={!!elemID ? "STRECHES_EDIT_TITLE" : "STRECHES_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                isHidden={!!programID}
                type="select"
                name="programID"
                label="STRECHES_PROGRAM"
                value={data.programID}
                error={errors.programID}
                onChange={this.handleChange}
                options={programs}
                withNone
                isRequired
            />
            <InputField
                name="name"
                label="STRECHES_ITS_NAME"
                value={data.name}
                error={errors.name}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                name="title"
                label="GENERAL_STUDENT_TITLE"
                helperText="GENERAL_STUDENT_TITLE_HELP"
                value={data.title}
                error={errors.title}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="select"
                name="mode"
                label="STRECHES_MODE"
                value={data.mode}
                error={errors.mode}
                onChange={this.handleChange}
                options="MODES"
                isRequired={isRequired}
                withNone
            />
            <Columns>
                <InputField
                    isHidden={!isVirtual}
                    name="streamLink"
                    label="GENERAL_STREAM_LINK"
                    value={data.streamLink}
                    error={errors.streamLink}
                    onChange={this.handleChange}
                    isRequired={isRequired}
                />
                <InputField
                    isHidden={!isVirtual}
                    name="streamPassword"
                    label="GENERAL_STREAM_PASSWORD"
                    value={data.streamPassword}
                    error={errors.streamPassword}
                    onChange={this.handleChange}
                />

                <InputField
                    isHidden={!isPresential}
                    type="select"
                    name="locationID"
                    label="STRECHES_LOCATION"
                    value={data.locationID}
                    error={errors.locationID}
                    onChange={this.handleChange}
                    options={locations}
                    isRequired={isRequired}
                    withNone
                />
                <InputField
                    isHidden={!isPresential}
                    type="select"
                    name="roomID"
                    label="STRECHES_ROOM"
                    value={data.roomID}
                    error={errors.roomID}
                    onChange={this.handleChange}
                    options={data.locationID ? rooms[data.locationID] || [] : []}
                    isRequired={isRequired}
                    withNone
                />

                <InputField
                    isHidden={!showDates}
                    type="date"
                    name="fromTimeDate"
                    label="STRECHES_FROM_DATE"
                    value={data.fromTimeDate}
                    error={errors.fromTimeDate}
                    onChange={this.handleChange}
                    isRequired={isRequired}
                />
                <InputField
                    isHidden={!showDates}
                    type="time"
                    name="fromTimeHour"
                    label="STRECHES_FROM_HOUR"
                    value={data.fromTimeHour}
                    error={errors.fromTimeHour}
                    onChange={this.handleChange}
                    isRequired={isRequired}
                />
                <InputField
                    isHidden={!showDates}
                    type="date"
                    name="toTimeDate"
                    label="STRECHES_TO_DATE"
                    value={data.toTimeDate}
                    error={errors.toTimeDate}
                    onChange={this.handleChange}
                    isRequired={isRequired}
                />
                <InputField
                    isHidden={!showDates}
                    type="time"
                    name="toTimeHour"
                    label="STRECHES_TO_HOUR"
                    value={data.toTimeHour}
                    error={errors.toTimeHour}
                    onChange={this.handleChange}
                    isRequired={isRequired}
                />

                <InputField
                    type="date"
                    name="inscFromTimeDate"
                    label="STRECHES_INSC_FROM_DATE"
                    value={data.inscFromTimeDate}
                    error={errors.inscFromTimeDate}
                    onChange={this.handleChange}
                    isRequired={isRequired}
                />
                <InputField
                    type="date"
                    name="inscToTimeDate"
                    label="STRECHES_INSC_TO_DATE"
                    value={data.inscToTimeDate}
                    error={errors.inscToTimeDate}
                    onChange={this.handleChange}
                    isRequired={isRequired}
                />

                <InputField
                    className={capacity2cols ? "columns-double" : ""}
                    type="number"
                    name="quota"
                    label="GENERAL_QUOTA"
                    value={data.quota}
                    error={errors.quota}
                    onChange={this.handleChange}
                    isRequired={isRequired}
                />
                <InputField
                    isHidden={!program.hasConversations}
                    type="number"
                    name="conversations"
                    label="CONVERSATIONS_NAME"
                    value={data.conversations}
                    error={errors.conversations}
                    onChange={this.handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!program.hasGroups}
                    type="number"
                    name="meetingAmount"
                    label="STRECHES_MEETING_AMOUNT"
                    value={data.meetingAmount}
                    error={errors.meetingAmount}
                    onChange={this.handleChange}
                    isRequired={isRequired}
                />
                <InputField
                    isHidden={!program.hasGroups}
                    type="number"
                    icon="money"
                    name="meetingPrice"
                    label="STRECHES_MEETING_PRICE"
                    value={data.meetingPrice}
                    error={errors.meetingPrice}
                    onChange={this.handleChange}
                    isRequired={isRequired}
                    shrinkLabel
                />

                <InputField
                    type="toggle"
                    name="featuredInSite"
                    label="STRECHES_FEATURED_IN_SITE"
                    value={data.featuredInSite}
                    error={errors.featuredInSite}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="free"
                    label="STRECHES_FREE"
                    value={data.free}
                    error={errors.free}
                    onChange={this.handleChange}
                />
            </Columns>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrechEdit   : PropTypes.func.isRequired,
        fetchStrechCreate : PropTypes.func.isRequired,
        editStrech        : PropTypes.func.isRequired,
        open              : PropTypes.bool.isRequired,
        onClose           : PropTypes.func.isRequired,
        onSubmit          : PropTypes.func.isRequired,
        edition           : PropTypes.number.isRequired,
        programs          : PropTypes.array.isRequired,
        programsData      : PropTypes.array.isRequired,
        locations         : PropTypes.array.isRequired,
        rooms             : PropTypes.object.isRequired,
        elem              : PropTypes.object.isRequired,
        elemID            : PropTypes.number,
        courseID          : PropTypes.number,
        programID         : PropTypes.number,
        locationID        : PropTypes.number,
        toActivate        : PropTypes.bool,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition      : state.strech.edition,
            programs     : state.strech.programs,
            programsData : state.strech.programsData,
            locations    : state.strech.locations,
            rooms        : state.strech.rooms,
            elem         : state.strech.elem,
        };
    }
}

export default connect(StrechEdit.mapStateToProps, {
    fetchStrechEdit, fetchStrechCreate, editStrech,
})(StrechEdit);
