import Access               from "dashboard/dist/Core/Access";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    AUTH_DATA, AUTH_COURSE, AUTH_ASSIGN,
    AUTH_CURRENT_USER, PROFILE_UPLOAD,
} from "Utils/Types";



// The initial State
const initialState = {
    isAuthenticated : false,
    isAdmin         : false,
    isOwner         : false,
    isSupport       : false,
    isCoach         : false,
    isStudent       : false,
    loggedAsUser    : false,
    error           : false,
    credential      : {},
    credentialID    : 0,
    countries       : [],
    provinces       : {},
    idds            : [],
    elem            : {},
    courses         : [],
    completed       : [],
    strech          : {},
    edition         : new Date().getTime(),
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, AUTH_ASSIGN)) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case AUTH_DATA:
        return {
            ...state,
            error        : false,
            countries    : action.data.countries,
            provinces    : action.data.provinces,
            idds         : action.data.idds,
            elem         : action.data.elem,
        };
    case AUTH_COURSE:
        return {
            ...state,
            error        : false,
            credentialID : action.data.credentialID,
            courses      : action.data.courses,
            completed    : action.data.completed,
        };
    case AUTH_ASSIGN:
        return {
            ...state,
            error        : false,
            strech       : action.data.strech,
        };

    case AUTH_CURRENT_USER:
        if (Utils.isEmpty(action.credential)) {
            return initialState;
        }
        const level = action.credential.accessLevel;
        return {
            ...state,
            error           : false,
            isAuthenticated : true,
            credential      : action.credential,
            credentialID    : action.credential.credentialID,
            loggedAsUser    : action.credential.loggedAsUser,
            isAdmin         : Access.inGroup("ADMIN", level),
            isOwner         : Access.is("OWNER",   level),
            isSupport       : Access.is("SUPPORT", level),
            isCoach         : Access.is("COACH",   level),
            isStudent       : Access.is("STUDENT", level),
        };

    case PROFILE_UPLOAD:
        return {
            ...state,
            edition         : state.edition + 1,
        };

    default:
        return state;
    }
};

export default reducer;
