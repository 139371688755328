import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { HelpSection }      from "Utils/API";

// Types
import {
    HELP_SECTION, HELP_SECTION_LOADING,
    HELP_SECTION_LIST, HELP_SECTION_ELEM, HELP_SECTION_CREATE,
} from "Utils/Types";



/**
 * Fetches the Section List
 * @param {Object=}  params
 * @param {Boolean=} forSupport
 * @returns {(dispatch) => Void}
 */
export function fetchSections(params = {}, forSupport = false) {
    return async (dispatch) => {
        dispatch({ type : HELP_SECTION_LOADING });
        Params.unset(params);
        params.forSupport = forSupport;
        const data = await HelpSection.getAll(params);
        data.sort = params;
        dispatch({ type : HELP_SECTION_LIST, data });
        dispatch(setSection(HELP_SECTION));
    };
}

/**
 * Fetches a single Section
 * @param {Number} sectionID
 * @returns {(dispatch) => Void}
 */
export function fetchSection(sectionID) {
    return async (dispatch) => {
        const data = await HelpSection.getOne({ sectionID });
        dispatch({ type : HELP_SECTION_ELEM, data });
    };
}

/**
 * Fetches the Section Create data
 * @param {Boolean=} forSupport
 * @returns {(dispatch: Function) => Void}
 */
export function fetchSectionCreate(forSupport = false) {
    return async (dispatch) => {
        const data = await HelpSection.getCreateData({ forSupport });
        dispatch({ type : HELP_SECTION_CREATE, data });
    };
}



/**
 * Edits/Creates a Section
 * @param {Object} data
 * @returns {() => Void}
 */
export function editSection(data) {
    return () => HelpSection.edit(data);
}

/**
 * Deletes a Section
 * @param {Number} sectionID
 * @returns {() => Void}
 */
export function deleteSection(sectionID) {
    return () => HelpSection.delete({ sectionID });
}
