import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { CoachInvoice }     from "Utils/API";

// Types
import {
    COACH_INVOICE, COACH_INVOICE_LOADING,
    COACH_INVOICE_LIST, COACH_INVOICE_ELEM, COACH_INVOICE_CREATE,
} from "Utils/Types";



/**
 * Fetches the Invoice List
 * @param {String=}  type
 * @param {Number=}  elemID
 * @param {Object=}  params
 * @param {Object=}  filters
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchInvoices(type = "", elemID = 0, params = {}, filters = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : COACH_INVOICE_LOADING });
        }
        Params.unset(params);
        const sendParams = { ...params, ...filters };
        let data = {};

        switch (type) {
        case "STRECH":
            sendParams.strechID = elemID;
            data = await CoachInvoice.getAllForStrech(sendParams);
            break;
        case "COACH":
            sendParams.coachID = elemID;
            data = await CoachInvoice.getAllForCoach(sendParams);
            break;
        case "USER":
            data = await CoachInvoice.getAllAsCoach(sendParams);
            break;
        default:
            data = await CoachInvoice.getAll(sendParams);
        }

        data.filters = filters;
        data.sort    = params;
        dispatch({ type : COACH_INVOICE_LIST, data });
        dispatch(setSection(COACH_INVOICE));
    };
}

/**
 * Generates the export url from the given data
 * @param {String} type
 * @param {Number} elemID
 * @param {Object} filters
 * @returns {String}
 */
export function exportInvoices(type, elemID, filters) {
    const sendParams = { ...filters };
    switch (type) {
    case "STRECH":
        sendParams.strechID = elemID;
        break;
    case "COACH":
        sendParams.coachID = elemID;
        break;
    default:
    }
    return CoachInvoice.export(sendParams);
}



/**
 * Fetches a single Invoice
 * @param {Number} invoiceID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchInvoice(invoiceID) {
    return async (dispatch) => {
        const data = await CoachInvoice.getOne({ invoiceID });
        dispatch({ type : COACH_INVOICE_ELEM, data });
    };
}

/**
 * Fetches the Invoice Create data
 * @param {Number} coachID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchInvoiceCreate(coachID) {
    return async (dispatch) => {
        const data = await CoachInvoice.getCreateData({ coachID });
        dispatch({ type : COACH_INVOICE_CREATE, data });
    };
}




/**
 * Edits an Invoice
 * @param {Object} data
 * @returns {() => Void}
 */
export function createInvoice(data) {
    return () => CoachInvoice.create(data);
}

/**
 * Sends an Invoice
 * @param {Object} data
 * @returns {() => Void}
 */
export function sendInvoice(data) {
    return () => CoachInvoice.send(data);
}

/**
 * Attaches an Invoice
 * @param {Object} data
 * @returns {() => Void}
 */
export function attachInvoice(data) {
    return () => CoachInvoice.attach(data);
}

/**
 * Approves an Invoice
 * @param {Number} invoiceID
 * @returns {() => Void}
 */
export function approveInvoice(invoiceID) {
    return () => CoachInvoice.markApproved({ invoiceID });
}

/**
 * Rejects an Invoice
 * @param {Number} invoiceID
 * @param {String} reason
 * @returns {() => Void}
 */
export function rejectInvoice(invoiceID, reason) {
    return () => CoachInvoice.markRejected({ invoiceID, reason });
}

/**
 * Pays an Invoice
 * @param {Number} invoiceID
 * @returns {() => Void}
 */
export function paidInvoice(invoiceID) {
    return () => CoachInvoice.markPaid({ invoiceID });
}
