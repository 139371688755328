import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { News }             from "Utils/API";

// Types
import {
    NEWS, NEWS_LOADING,
    NEWS_LIST, NEWS_ELEM,
} from "Utils/Types";



/**
 * Fetches the News List
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchNews(params = {}) {
    return async (dispatch) => {
        dispatch({ type : NEWS_LOADING });
        Params.unset(params);
        const data = await News.getAll(params);
        data.sort = params;
        dispatch({ type : NEWS_LIST, data });
        dispatch(setSection(NEWS));
    };
}

/**
 * Fetches a single News
 * @param {Number} newsID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchNew(newsID) {
    return async (dispatch) => {
        const data = await News.getOne({ newsID });
        dispatch({ type : NEWS_ELEM, data });
    };
}



/**
 * Edits a News
 * @param {Object} data
 * @returns {() => Void}
 */
export function editNews(data) {
    return () => News.edit(data);
}

/**
 * Deletes a News
 * @param {Number} newsID
 * @returns {() => Void}
 */
export function deleteNews(newsID) {
    return () => News.delete({ newsID });
}
