import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import InputField           from "dashboard/dist/Components/InputField";
import NoneAvailable        from "dashboard/dist/Components/NoneAvailable";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";
import Html                 from "dashboard/dist/Components/Html";

// Actions
import {
    fetchInvoiceCreate, createInvoice,
} from "Actions/Admin/Coach/InvoiceActions";

// Styles
import "Styles/Components/App/Invoices.css";



/**
 * The Coach Invoice Create Dialog
 */
class InvoiceCreate extends React.Component {
    // The Initial Data
    initialData = {
        file    : "",
        message : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, coachID, fetchInvoiceCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            fetchInvoiceCreate(coachID);
            loading = true;

        // Data Updated
        } else if (prevProps.edition !== edition) {
            data = { ...this.initialData };
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { createInvoice, onSubmit, coachID } = this.props;
        const { data, loading                    } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await createInvoice({ ...data, coachID });
                this.setState({ loading : false });
                onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, canInvoice, conversations, totalPrice } = this.props;
        const { data, loading, errors                                } = this.state;

        return <EditDialog
            open={open}
            icon="invoice"
            title="COACH_INVOICES_CREATE_TITLE"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
            isDisabled={!canInvoice}
        >
            {!canInvoice && <NoneAvailable
                variant="dialog"
                message="COACH_INVOICES_NONE_TO_INVOICE"
            />}
            <DialogMessage isHidden={!canInvoice} className="coach-invoices">
                <h3>{NLS.get("COACH_INVOICES_WITHOUT")}</h3>
                <ul>
                    {conversations.map((elem) => <li key={elem.conversationID}>
                        <Html>{elem.text}</Html>
                        <div><b>${elem.price}</b></div>
                    </li>)}
                </ul>
                <div>${totalPrice}</div>
            </DialogMessage>

            <InputField
                isHidden={!canInvoice}
                type="file"
                name="file"
                label="COACH_INVOICES_FILE"
                error={errors.file}
                onChange={this.handleChange}
                shrinkLabel
            />
            <InputField
                isHidden={!canInvoice}
                type="textarea"
                name="message"
                label="COACH_INVOICES_MESSAGE"
                value={data.message}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchInvoiceCreate : PropTypes.func.isRequired,
        createInvoice      : PropTypes.func.isRequired,
        open               : PropTypes.bool.isRequired,
        onClose            : PropTypes.func.isRequired,
        onSubmit           : PropTypes.func.isRequired,
        edition            : PropTypes.number.isRequired,
        canInvoice         : PropTypes.bool.isRequired,
        totalPrice         : PropTypes.number.isRequired,
        conversations      : PropTypes.array.isRequired,
        coachID            : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition       : state.coachInvoice.edition,
            canInvoice    : state.coachInvoice.canInvoice,
            totalPrice    : state.coachInvoice.totalPrice,
            conversations : state.coachInvoice.conversations,
        };
    }
}

export default connect(InvoiceCreate.mapStateToProps, {
    fetchInvoiceCreate, createInvoice,
})(InvoiceCreate);
