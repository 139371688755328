import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { HelpQuestion }     from "Utils/API";

// Types
import {
    HELP_QUESTION, HELP_QUESTION_LOADING,
    HELP_QUESTION_LIST, HELP_QUESTION_ELEM, HELP_QUESTION_CREATE,
    HELP_QUESTION_DATA,
} from "Utils/Types";



/**
 * Fetches the Question List
 * @param {Object=}  params
 * @param {Boolean=} forSupport
 * @returns {(dispatch) => Void}
 */
export function fetchQuestions(params = {}, forSupport = false) {
    return async (dispatch) => {
        dispatch({ type : HELP_QUESTION_LOADING });
        Params.unset(params);
        params.forSupport = forSupport;
        const data = await HelpQuestion.getAll(params);
        data.sort = params;
        dispatch({ type : HELP_QUESTION_LIST, data });
        dispatch(setSection(HELP_QUESTION));
    };
}

/**
 * Fetches a single Question
 * @param {Number} questionID
 * @returns {(dispatch) => Void}
 */
export function fetchQuestion(questionID) {
    return async (dispatch) => {
        const data = await HelpQuestion.getOne({ questionID });
        dispatch({ type : HELP_QUESTION_ELEM, data });
    };
}

/**
 * Fetches the Question Create data
 * @param {Boolean=} forSupport
 * @returns {(dispatch: Function) => Void}
 */
export function fetchQuestionCreate(forSupport = false) {
    return async (dispatch) => {
        const data = await HelpQuestion.getCreateData({ forSupport });
        dispatch({ type : HELP_QUESTION_CREATE, data });
    };
}

/**
 * Fetches the Questions and Sections
 * @param {Boolean=} forSupport
 * @returns {(dispatch: Function) => Void}
 */
export function fetchHelp(forSupport = false) {
    return async (dispatch) => {
        const data = await HelpQuestion.getHelp({ forSupport });
        dispatch({ type : HELP_QUESTION_DATA, data });
    };
}



/**
 * Edits/Creates a Question
 * @param {Object} data
 * @returns {() => Void}
 */
export function editQuestion(data) {
    return () => HelpQuestion.edit(data);
}

/**
 * Deletes a Question
 * @param {Number} questionID
 * @returns {() => Void}
 */
export function deleteQuestion(questionID) {
    return () => HelpQuestion.delete({ questionID });
}
