import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { LocSchedule }      from "Utils/API";

// Types
import {
    LOC_SCHEDULE, LOC_SCHEDULE_LOADING,
    LOC_SCHEDULE_LIST, LOC_SCHEDULE_ELEM,
} from "Utils/Types";



/**
 * Fetches the Schedule List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch) => Void}
 */
export function fetchSchedules(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : LOC_SCHEDULE_LOADING });
        Params.unset(params);
        let data = null;

        switch (type) {
        case "LOCATION":
            params.locationID = elemID;
            data = await LocSchedule.getAllForLocation(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : LOC_SCHEDULE_LIST, data });
        dispatch(setSection(LOC_SCHEDULE));
    };
}

/**
 * Fetches a single Schedule
 * @param {Number} scheduleID
 * @returns {(dispatch) => Void}
 */
export function fetchSchedule(scheduleID) {
    return async (dispatch) => {
        const data = await LocSchedule.getOne({ scheduleID });
        dispatch({ type : LOC_SCHEDULE_ELEM, data });
    };
}



/**
 * Edits/Creates a Schedule
 * @param {Object} data
 * @returns {() => Void}
 */
export function editSchedule(data) {
    return () => LocSchedule.edit(data);
}

/**
 * Deletes a Schedule
 * @param {Number} scheduleID
 * @returns {() => Void}
 */
export function deleteSchedule(scheduleID) {
    return () => LocSchedule.delete({ scheduleID });
}
