import React                from "react";
import PropTypes            from "prop-types";

// Components
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Name Field
 */
class NameField extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, errors, onChange } = this.props;

        return <Columns amount="2">
            <InputField
                name="firstName"
                label="USERS_FIRST_NAME"
                value={data.firstName}
                error={errors.firstName}
                onChange={onChange}
                isRequired
            />
            <InputField
                name="lastName"
                label="USERS_LAST_NAME"
                value={data.lastName}
                error={errors.lastName}
                onChange={onChange}
                isRequired
            />
        </Columns>;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        data     : PropTypes.object.isRequired,
        errors   : PropTypes.object.isRequired,
        onChange : PropTypes.func.isRequired,
    }
}

export default NameField;
