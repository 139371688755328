import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";
import StatusData           from "Utils/Status";

// Types
import {
    INSCRIPTION_LOADING,
    INSCRIPTION_LIST, INSCRIPTION_ELEM,
    INSCRIPTION_CREATE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading          : false,
    error            : false,
    edition          : 0,
    canCreate        : false,
    canEdit          : false,
    canImport        : false,
    canExport        : false,
    list             : [],
    total            : 0,
    reasons          : [],
    elem             : {},
    prices           : [],
    payments         : [],
    isFree           : false,
    hasChats         : false,
    hasConversations : false,
    totalConv        : 0,
    sort             : {
        filter   : StatusData.ACTIVE,
        orderBy  : "createdTime",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate       = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime   = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.cancelledDate     = DateTime.formatIf(elem.cancelledTime, "dashes");
    elem.statusName        = Status.getFemName(elem.status);
    elem.statusColor       = Status.getColor(elem.status);
    elem.statusClass       = Status.getTextClass(elem.status);
    elem.priceText         = Common.combinePrice(elem);
    elem.conversationsText = Common.parseConvLimit(elem.conversations);
    elem.tipo = '';
    if ( elem.level === 1 ){
        elem.tipo = 'Alumno';
    }else if ( elem.level === 2 ) {
        elem.tipo = 'Coach';
    }
    return elem;
}

/**
 * Parses the Prices
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parsePrices(list) {
    const result = [];
    for (const elem of list) {
        let value = "";
        if (elem.isPromotion) {
            const promotionEnd = DateTime.formatDate(elem.promotionEnd, "dashes");
            value = NLS.pluralize("PRICES_PROMOTION", elem.dues, elem.priceFormat, promotionEnd);
        } else {
            value = NLS.pluralize("PRICES_NAME", elem.dues, elem.priceFormat);
        }
        result.push({ key : elem.strechPriceID, value });
    }
    return result;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, INSCRIPTION_LIST, INSCRIPTION_ELEM, INSCRIPTION_CREATE)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case INSCRIPTION_LOADING:
        return {
            ...state,
            loading          : true,
        };

    case INSCRIPTION_LIST:
        return {
            ...state,
            loading          : false,
            error            : false,
            canCreate        : action.data.canCreate,
            canEdit          : action.data.canEdit,
            canImport        : action.data.canImport,
            canExport        : action.data.canExport,
            list             : parseList(action.data.list),
            total            : action.data.total,
            sort             : action.data.sort,
            reasons          : action.data.reasons,
            isFree           : action.data.isFree,
            hasChats         : action.data.hasChats,
            hasConversations : action.data.hasConversations,
        };

    case INSCRIPTION_ELEM:
        return {
            ...state,
            error            : false,
            edition          : state.edition + 1,
            canEdit          : action.data.canEdit,
            elem             : parseElem(action.data.elem),
            prices           : parsePrices(action.data.prices),
            payments         : action.data.payments,
            isFree           : action.data.isFree,
            hasConversations : action.data.hasConversations,
            totalConv        : action.data.totalConv,
        };

    case INSCRIPTION_CREATE:
        return {
            ...state,
            error            : false,
            edition          : state.edition + 1,
            prices           : parsePrices(action.data.prices),
            payments         : action.data.payments,
            isFree           : action.data.isFree,
            hasConversations : action.data.hasConversations,
            totalConv        : action.data.totalConv,
        };

    default:
        return state;
    }
};

export default reducer;
