import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchCompleted, editCompleted,
} from "Actions/Admin/Course/CompletedActions";



/**
 * The Course Completed Dialog
 */
class CourseCompleted extends React.Component {
    // The Initial Data
    initialData = {
        credentialID : 0,
        courses      : {},
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, courses, completed, fetchCompleted } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchCompleted(elemID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition && elemID) {
            const newCourses = {};
            for (const elem of courses) {
                newCourses[elem.key] = 0;
            }
            for (const elem of completed) {
                if (newCourses[elem.courseID] === 0) {
                    newCourses[elem.courseID] = 1;
                }
            }
            data = { credentialID : elemID, courses : newCourses };
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {Number} courseID
     * @returns {Void}
     */
    handleToggle = (courseID) => {
        const courses = this.state.data.courses;
        courses[courseID] = courses[courseID] ? 0 : 1;
        this.setState({ data : { ...this.state.data, courses }, errors : {} });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const completed = [];
                for (const [ courseID, valid ] of Object.entries(data.courses)) {
                    if (valid) {
                        completed.push(courseID);
                    }
                }
                await this.props.editCompleted({
                    credentialID : data.credentialID,
                    completed    : JSON.stringify(completed),
                });
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, courses, name } = this.props;
        const { data, loading, errors        } = this.state;

        return <EditDialog
            open={open}
            icon="check"
            title="COURSES_COMPLETED_NAME"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <DialogMessage html={NLS.format("COURSES_COMPLETED_HELP", name)} />
            <ul className="spaced-list no-list">
                {courses.map((elem) => <li key={elem.key}>
                    <InputField
                        type="toggle"
                        name={`course-${elem.key}`}
                        label={elem.value}
                        value={!!data.courses[elem.key]}
                        onChange={() => this.handleToggle(elem.key)}
                    />
                </li>)}
            </ul>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCompleted : PropTypes.func.isRequired,
        editCompleted  : PropTypes.func.isRequired,
        open           : PropTypes.bool.isRequired,
        onClose        : PropTypes.func.isRequired,
        onSubmit       : PropTypes.func.isRequired,
        edition        : PropTypes.number.isRequired,
        name           : PropTypes.string.isRequired,
        courses        : PropTypes.array.isRequired,
        completed      : PropTypes.array.isRequired,
        elemID         : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.courseCompleted.edition,
            name      : state.courseCompleted.name,
            courses   : state.courseCompleted.courses,
            completed : state.courseCompleted.completed,
        };
    }
}

export default connect(CourseCompleted.mapStateToProps, {
    fetchCompleted, editCompleted,
})(CourseCompleted);
