import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import NLS                  from "dashboard/dist/Core/NLS";
import Common               from "Utils/Common";

// Sections
import EnrollFilters        from "Components/Student/Enroll/EnrollFilters";
import EnrollList           from "Components/Student/Enroll/EnrollList";
import EnrollChat           from "Components/Student/Enroll/EnrollChat";

// Components
import CircularLoader       from "dashboard/dist/Components/CircularLoader";
import NoneAvailable        from "dashboard/dist/Components/NoneAvailable";
import AlertDialog          from "dashboard/dist/Components/AlertDialog";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";

// Actions
import {
    fetchEnroll, updateEnroll, cancelEnroll,
} from "Actions/Student/EnrollActions";

// Styles
import "Styles/Components/App/Enroll.css";



/**
 * The Enroll Step
 */
class EnrollStep extends React.Component {
    // The Initial Data
    initialFilters = {
        courseID     : 0,
        strechMode   : 0,
        scheduleType : 0,
        strechFor    : 1,
        countryID    : 0,
        provinceID   : 0,
        localityID   : 0,
    }

    // The Current State
    state = {
        filters : { ...this.initialFilters },
        action  : Action.get(),
        elem    : {},
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchEnroll();
        if (this.props.isCoach) {
            this.setState({ filters : { ...this.state.filters, strechFor : 0 } });
        }
    }



    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({ filters : { ...this.state.filters, [name] : value } });
    }

    /**
     * Starts an Action
     * @param {String} key
     * @param {Object} elem
     * @returns {Void}
     */
    startAction = (key, elem) => {
        const action = Action.get(key);
        if (action.isSenroll) {
            this.props.onSubmit({ step : "select", strech : elem });
        } else {
            this.setState({ action, elem });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction("", {});
    }



    /**
     * Handles the Chat Enroll
     * @param {Object} chats
     * @returns {Promise}
     */
    handleChatEnroll = async (chats) => {
        this.endAction();
        this.props.onSubmit({ step : "select", strech : this.state.elem, chats });
    }

    /**
     * Handles the Enroll Cancel
     * @returns {Promise}
     */
    handleCancel = async () => {
        const result = await this.props.cancelEnroll(this.state.elem.strechID);
        this.endAction();
        if (result.success) {
            this.props.updateEnroll();
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, streches, course, strechID } = this.props;
        const { filters, action, elem               } = this.state;

        if (loading) {
            return <section className="auth-container">
                <CircularLoader />
            </section>;
        }

        const list       = Common.filterStreches(streches, filters, course, strechID);
        const isSingle   = Boolean(strechID);
        const showNone   = !streches.length;
        const showFilter = !strechID && streches.length > 0;
        const showEmpty  = !showNone && !list.length;
        const showList   = list.length > 0;

        return <section className={"auth-enroll" + (isSingle ? " auth-enroll-single" : "")}>
            <header className="auth-enroll-header">
                <h2 className="auth-title">{NLS.get("ENROLL_NAME")}</h2>
                {showNone && <NoneAvailable
                    className="auth-enroll-none"
                    message="ENROLL_NONE_AVAILABLE"
                />}
                {showFilter && <EnrollFilters
                    className="auth-enroll-filters"
                    filters={filters}
                    course={course}
                    onChange={this.handleChange}
                    labelInside
                />}
            </header>

            {showEmpty && <NoneAvailable
                className="auth-enroll-none"
                message="ENROLL_NONE_FILTERED"
            />}
            {showList && <EnrollList
                className="auth-enroll-list"
                startAction={this.startAction}
                list={list}
            />}

            <EnrollChat
                open={action.isCenroll}
                elem={elem}
                onSubmit={this.handleChatEnroll}
                onClose={this.endAction}
            />
            <AlertDialog
                open={action.isEnrolled}
                icon="inscription"
                title="ENROLL_INFO_TITLE"
                message={elem.isFree ? "ENROLL_INFO_TEXT_FREE" : "ENROLL_INFO_TEXT"}
                content={elem.title}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isCancel}
                icon="inscription"
                title="ENROLL_CANCEL_TITLE"
                message="ENROLL_CANCEL_TEXT"
                content={elem.title}
                onSubmit={this.handleCancel}
                onClose={this.endAction}
            />
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchEnroll  : PropTypes.func.isRequired,
        updateEnroll : PropTypes.func.isRequired,
        cancelEnroll : PropTypes.func.isRequired,
        loading      : PropTypes.bool.isRequired,
        streches     : PropTypes.array.isRequired,
        isCoach      : PropTypes.bool.isRequired,
        onSubmit     : PropTypes.func.isRequired,
        course       : PropTypes.string,
        strechID     : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loading  : state.enroll.loading,
            streches : state.enroll.streches,
            isCoach  : state.auth.isCoach,
        };
    }
}

export default connect(EnrollStep.mapStateToProps, {
    fetchEnroll, updateEnroll, cancelEnroll,
})(EnrollStep);
