import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { CoachMaterial }    from "Utils/API";

// Types
import {
    COACH_MATERIAL, COACH_MATERIAL_LOADING, COACH_MATERIAL_CHARGING,
    COACH_MATERIAL_LIST, COACH_MATERIAL_ELEM, COACH_MATERIAL_CREATE
} from "Utils/Types";



/**
 * Fetches the Coach Material List
 * @param {Object=} params
 * @returns {(dispatch) => Void}
 */
export function fetchMaterials(type = "", params = {}) {
    return async (dispatch) => {
        dispatch({ type : COACH_MATERIAL_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "USER":
            data = await CoachMaterial.getAllAsCoach();
            break;
        default:
            data = await CoachMaterial.getAll(params);
        }
        data.sort = params;
        dispatch({ type : COACH_MATERIAL_LIST, data });
        dispatch(setSection(COACH_MATERIAL));
    };
}

/**
 * Fetches a single Coach Material
 * @param {Number}   materialID
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchMaterial(materialID, withLoader = false) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : COACH_MATERIAL_CHARGING });
        }
        const data = await CoachMaterial.getOne({ materialID });
        dispatch({ type : COACH_MATERIAL_ELEM, data });
    };
}

/**
 * Fetches the Coach Material Create data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMaterialCreate() {
    return async (dispatch) => {
        const data = await CoachMaterial.getCreateData();
        dispatch({ type : COACH_MATERIAL_CREATE, data });
    };
}



/**
 * Edits/Creates a Coach Material
 * @param {Object} data
 * @returns {() => Void}
 */
export function editMaterial(data) {
    return () => CoachMaterial.edit(data);
}

/**
 * Deletes a Coach Material
 * @param {Number} materialID
 * @returns {() => Void}
 */
export function deleteMaterial(materialID) {
    return () => CoachMaterial.delete({ materialID });
}
