import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { ProgramClass }     from "Utils/API";

// Types
import {
    PROGRAM_CLASS, PROGRAM_CLASS_LOADING,
    PROGRAM_CLASS_LIST, PROGRAM_CLASS_ELEM, PROGRAM_CLASS_CREATE,
} from "Utils/Types";



/**
 * Fetches the Program Class List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchClasses(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : PROGRAM_CLASS_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "PROGRAM":
            params.programID = elemID;
            data = await ProgramClass.getAllForProgram(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : PROGRAM_CLASS_LIST, data });
        dispatch(setSection(PROGRAM_CLASS));
    };
}

/**
 * Fetches a single Program Class
 * @param {Number} classID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchClass(classID) {
    return async (dispatch) => {
        const data = await ProgramClass.getOne({ classID });
        dispatch({ type : PROGRAM_CLASS_ELEM, data });
    };
}

/**
 * Fetches the Program Class Create data
 * @param {Number} programID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchClassCreate(programID) {
    return async (dispatch) => {
        const data = await ProgramClass.getCreateData({ programID });
        dispatch({ type : PROGRAM_CLASS_CREATE, data });
    };
}



/**
 * Edits/Creates a Program Class
 * @param {Object} data
 * @returns {() => Void}
 */
export function editClass(data) {
    return () => ProgramClass.edit(data);
}

/**
 * Deletes a Program Class
 * @param {Number} classID
 * @returns {() => Void}
 */
export function deleteClass(classID) {
    return () => ProgramClass.delete({ classID });
}
