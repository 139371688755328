import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStrech }      from "Actions/Admin/Strech/StrechActions";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";
import Utils                from "dashboard/dist/Utils/Utils";
import StrechStep           from "Utils/Entity/StrechStep";

// Sections
import CoachEdit            from "./CoachEdit";
import CampaignSend         from "Components/Admin/Campaign/CampaignSend";
import StrechBar            from "Components/Admin/Strech/Strech/StrechBar";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchCoaches, fetchCoachesEmails, deleteCoach,
} from "Actions/Admin/Strech/CoachActions";



/**
 * The Strech Coach List
 */
class CoachList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
        users  : [],
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, withLoader) => {
        const { type, elemID, data } = this.props;
        this.props.fetchCoaches(type, elemID, params || data.sort, withLoader);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Promise}
     */
    startAction = async (action, elemID) => {
        const { type, elemID : typeID, data, fetchCoachesEmails } = this.props;
        if (action.isCoach) {
            const coach = Utils.getValue(data.list, "coachID", elemID);
            Href.goto("COACHES", coach.credentialID);
        } else if (data.canEdit) {
            let users = [];
            if (action.isCampaign) {
                users = await fetchCoachesEmails(type, typeID);
            }
            this.setState({ action, elemID, users });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch(null, false);
        this.props.fetchStrech(this.props.elemID);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        await this.props.deleteCoach(this.state.elemID);
        this.editElem();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID, users                                 } = this.state;
        const { data, strech, params, route, withDetails              } = this.props;
        const { canEdit, hasConversations, list, total, sort, loading } = data;

        const elemName = Utils.getValue(list, "coachID", elemID, "credentialName");

        return <Main withDetails={withDetails}>
            <Header message="COACHES_NAME" icon="coach" route={route}>
                <ActionList
                    data={data}
                    onAction={this.startAction}
                    withCampaign={data.canEdit && !strech.isCreating}
                    useAssign
                />
            </Header>
            <Content isLoading={loading}>
                <StrechBar step={StrechStep.COACHES} />
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="COACHES_NONE_AVAILABLE"
                    hasAlert={StrechStep.hasHelp(strech, StrechStep.COACHES)}
                >
                    <TableHead>
                        <TableHeader field="firstName" message="GENERAL_NAME"       hideCircle={!canEdit} />
                        <TableHeader field="type"      message="GENERAL_TYPE"       maxWidth="80"  />
                        <TableHeader field="price"     message="GENERAL_FEE"        maxWidth="100" align="right"  isHidden={!canEdit} />
                        <TableHeader field="canAssign" message="COACHES_HAS_ASSIGN" maxWidth="80"  align="center" isHidden={!canEdit || !hasConversations} />
                        <TableHeader field="position"  message="GENERAL_POSITION"   maxWidth="80"  align="center" isHidden={!canEdit} hideMobile />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.coachID} elemID={elem.coachID}>
                            <TableCell message={elem.credentialName} circle={elem.statusColor} />
                            <TableCell message={elem.typeName}        />
                            <TableCell message={elem.priceString}     />
                            <TableCell message={elem.canAssignString} />
                            <TableCell message={elem.position}        />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="EDIT"   message="COACHES_EDIT_TITLE"   />
                        <TableAction action="DELETE" message="COACHES_DELETE_TITLE" />
                        <TableAction action="COACH"  message="COACHES_VIEW_TITLE"   isHidden={!canEdit} />
                    </TableActionList>
                </Table>
            </Content>

            <CampaignSend
                open={action.isCampaign}
                users={users}
                onClose={this.endAction}
                onSubmit={this.endAction}
            />
            <CoachEdit
                open={action.isVCE}
                elemID={elemID}
                strechID={params.strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="COACHES_DELETE_TITLE"
                message="COACHES_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrech        : PropTypes.func.isRequired,
        fetchCoaches       : PropTypes.func.isRequired,
        fetchCoachesEmails : PropTypes.func.isRequired,
        deleteCoach        : PropTypes.func.isRequired,
        data               : PropTypes.object.isRequired,
        strech             : PropTypes.object.isRequired,
        type               : PropTypes.string.isRequired,
        params             : PropTypes.object.isRequired,
        route              : PropTypes.string.isRequired,
        withDetails        : PropTypes.bool.isRequired,
        elemID             : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data   : state.strechCoach,
            strech : state.strech.elem,
        };
    }
}

export default connect(CoachList.mapStateToProps, {
    fetchStrech, fetchCoaches, fetchCoachesEmails, deleteCoach,
})(CoachList);
