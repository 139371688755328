import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Fields
import NameField            from "Components/Utils/Credential/NameField";
import FiscalField          from "Components/Utils/Credential/FiscalField";
import ContactField         from "Components/Utils/Credential/ContactField";
import AddressField         from "Components/Utils/Credential/AddressField";
import PersonalField        from "Components/Utils/Credential/PersonalField";
import PasswordField        from "Components/Utils/Credential/PasswordField";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchStudent, fetchStudentCreate, editStudent,
} from "Actions/Admin/User/StudentActions";



/**
 * The Student Edit Dialog
 */
class StudentEdit extends React.Component {
    // The Initial Data
    initialData = {
        credentialID    : 0,
        firstName       : "",
        lastName        : "",
        nationality     : 0,
        document        : 1,
        dni             : "",
        cuit            : "",
        taxID           : "",
        countryID       : 0,
        provinceID      : 0,
        address         : "",
        email           : "",
        idd             : 0,
        cellphone       : "",
        birthDate       : "",
        gender          : 0,
        ocupation       : "",
        newPassword     : "",
        confirmPassword : "",
        status          : Status.getID("ACTIVE"),
        observations    : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
        syncIDD : true,
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, status, fetchStudent, fetchStudentCreate } = this.props;
        let loading = false;
        let data    = null;
        let syncIDD = true;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchStudent(elemID);
                loading = true;
            } else {
                fetchStudentCreate();
                if (status) {
                    data.status = status;
                }
            }

        // Data Updated
        } else if (prevProps.edition !== edition && elemID) {
            data    = Utils.extend(this.initialData, elem);
            syncIDD = !elem.countryID && !elem.idd;
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {}, syncIDD });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const newState = Common.handleUserChange(this.state, name, value);
        this.setState(newState);
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const response = await this.props.editStudent(data);
                this.setState({ loading : false });
                this.props.onSubmit(response);
            } catch (errors) {
                this.setState({
                    errors,
                    loading : false,
                    data    : { ...data, "password" : "" },
                });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, idds, countries, provinces } = this.props;
        const { data, loading, errors                             } = this.state;

        const isActive = Status.is("ACTIVE", Number(data.status));

        return <EditDialog
            open={open}
            icon="student"
            title={!!elemID ? "STUDENTS_EDIT_TITLE" : "STUDENTS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <NameField
                data={data}
                errors={errors}
                onChange={this.handleChange}
            />
            <FiscalField
                data={data}
                errors={errors}
                countries={countries}
                onChange={this.handleChange}
                isRequired={isActive}
            />
            <AddressField
                data={data}
                errors={errors}
                countries={countries}
                provinces={provinces}
                onChange={this.handleChange}
                isRequired={isActive}
            />
            <ContactField
                data={data}
                errors={errors}
                idds={idds}
                onChange={this.handleChange}
                isRequired={isActive}
            />
            <PasswordField
                data={data}
                errors={errors}
                isNew={!elemID}
                onChange={this.handleChange}
                isRequired={isActive}
            />
            <PersonalField
                data={data}
                errors={errors}
                onChange={this.handleChange}
                isRequired={isActive}
            />

            <Columns>
                <InputField
                    name="ocupation"
                    label="USERS_OCUPATION"
                    value={data.ocupation}
                    error={errors.ocupation}
                    onChange={this.handleChange}
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    error={errors.status}
                    onChange={this.handleChange}
                    options={Status.getSelect("STUDENT")}
                    withNone
                    isRequired
                    shrinkLabel
                />
            </Columns>

            <InputField
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                value={data.observations}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStudent       : PropTypes.func.isRequired,
        fetchStudentCreate : PropTypes.func.isRequired,
        editStudent        : PropTypes.func.isRequired,
        open               : PropTypes.bool.isRequired,
        onClose            : PropTypes.func.isRequired,
        onSubmit           : PropTypes.func.isRequired,
        edition            : PropTypes.number.isRequired,
        idds               : PropTypes.array.isRequired,
        countries          : PropTypes.array.isRequired,
        provinces          : PropTypes.object.isRequired,
        elem               : PropTypes.object.isRequired,
        elemID             : PropTypes.number,
        status             : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.student.edition,
            idds      : state.student.idds,
            countries : state.student.countries,
            provinces : state.student.provinces,
            elem      : state.student.elem,
        };
    }
}

export default connect(StudentEdit.mapStateToProps, {
    fetchStudent, fetchStudentCreate, editStudent,
})(StudentEdit);
