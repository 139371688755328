import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Advice }           from "Utils/API";

// Types
import {
    ADVICE, ADVICE_LOADING,
    ADVICE_LIST, ADVICE_ELEM,
} from "Utils/Types";



/**
 * Fetches the Advice List
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchAdvices(params = {}) {
    return async (dispatch) => {
        dispatch({ type : ADVICE_LOADING });
        Params.unset(params);
        const data = await Advice.getAll(params);
        data.sort = params;
        dispatch({ type : ADVICE_LIST, data });
        dispatch(setSection(ADVICE));
    };
}

/**
 * Fetches a single Advice
 * @param {Number} adviceID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchAdvice(adviceID) {
    return async (dispatch) => {
        const data = await Advice.getOne({ adviceID });
        dispatch({ type : ADVICE_ELEM, data });
    };
}



/**
 * Edits a Advice
 * @param {Object} data
 * @returns {() => Void}
 */
export function editAdvice(data) {
    return () => Advice.edit(data);
}

/**
 * Deletes a Advice
 * @param {Number} adviceID
 * @returns {() => Void}
 */
export function deleteAdvice(adviceID) {
    return () => Advice.delete({ adviceID });
}
