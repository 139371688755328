import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Types
import {
    STRECH_CLASS_LOADING, STRECH_CLASS_CHARGING,
    STRECH_CLASS_LIST, STRECH_CLASS_ELEM,
    STRECH_CLASS_EDIT, STRECH_CLASS_CREATE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading     : false,
    charging    : false,
    error       : false,
    edition     : 0,
    canCreate   : false,
    canEdit     : false,
    canEditDate : false,
    canFeedback : false,
    canImport   : false,
    canExport   : false,
    list        : [],
    total       : 0,

    elem        : {},
    guides      : [],
    materials   : [],
    schedules   : [],
    coaches     : [],
    dates       : [],

    sort        : {
        orderBy  : "time",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.date            = DateTime.formatDate(elem.timeDate,    "dashes");
    elem.timeDate        = DateTime.formatDate(elem.timeDate,    "dashesReverse");
    elem.statusName      = Status.getFemName(elem.status);
    elem.statusColor     = Status.getColor(elem.status);
    elem.statusClass     = Status.getTextClass(elem.status);
    elem.feedbackShort   = elem.feedback.length > 160 ? `${elem.feedback.substr(0, 160)}...` : elem.feedback;
    elem.scheduleTime    = [ elem.startHour, elem.endHour ].join(" - ");

    const dates = Common.getClassDates(elem);
    elem.timeString      = dates.timeText;
    elem.timeReal        = NLS.format("GENERAL_DATE_TIME", elem.date, elem.startHour, elem.endHour);

    elem.videosSources   = [];
    for (const [ index, video ] of elem.videosParts.entries()) {
        elem.videosSources[index] = { name : elem.name, source : video };
    }
    return elem;
}

/**
 * Parses the Schedules
 * @param {Object} data
 * @returns {Object}
 */
function parseSchedules(data) {
    for (const elem of data) {
        const parts = elem.value.split(" - ");
        parts[0]   = DateTime.dayToName(parts[0]);
        elem.value = parts.join(" - ");
    }
    return data;
}

/**
 * Parses the Dates
 * @param {Object} data
 * @returns {Object}
 */
function parseDates(data) {
    for (const elem of data) {
        const parts = elem.value.split(" - ");
        parts[0]   = DateTime.dayToName(parts[0]);
        elem.value = parts.join(" ");
    }
    return data;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, STRECH_CLASS_LIST, STRECH_CLASS_ELEM, STRECH_CLASS_EDIT, STRECH_CLASS_CREATE)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case STRECH_CLASS_LOADING:
        return {
            ...state,
            loading     : true,
        };
    case STRECH_CLASS_CHARGING:
        return {
            ...state,
            charging    : true,
        };

    case STRECH_CLASS_LIST:
        return {
            ...state,
            loading     : false,
            error       : false,
            canCreate   : action.data.canCreate,
            canEdit     : action.data.canEdit,
            canEditDate : action.data.canEditDate,
            canFeedback : action.data.canFeedback,
            canImport   : action.data.canImport,
            canExport   : action.data.canExport,
            list        : parseList(action.data.list),
            total       : action.data.total,
            sort        : action.data.sort,
            dates       : parseDates(action.data.dates),
        };

    case STRECH_CLASS_ELEM:
        return {
            ...state,
            charging    : false,
            error       : false,
            edition     : state.edition + 1,
            canEdit     : action.data.canEdit,
            elem        : parseElem(action.data.elem),
            materials   : Common.parseMaterials(action.data.materials),
            guides      : action.data.guides,
        };

    case STRECH_CLASS_EDIT:
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            canEdit     : action.data.canEdit,
            elem        : parseElem(action.data.elem),
            schedules   : parseSchedules(action.data.schedules),
            coaches     : action.data.coaches,
        };

    case STRECH_CLASS_CREATE:
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            schedules   : parseSchedules(action.data.schedules),
            coaches     : action.data.coaches,
        };

    default:
        return state;
    }
};

export default reducer;
