import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStrech }      from "Actions/Admin/Strech/StrechActions";
import NLS                  from "dashboard/dist/Core/NLS";
import Href                 from "dashboard/dist/Core/Href";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import Alert                from "dashboard/dist/Components/Alert";
import InputField           from "dashboard/dist/Components/InputField";
import Button               from "dashboard/dist/Components/Button";

// Actions
import {
    fetchQuestions, answerQuestions,
} from "Actions/Admin/Strech/AnswerActions";

// Styles
import "Styles/Components/App/Questions.css";



/**
 * The Strech Answer Create Page
 */
class AnswerCreate extends React.Component {
    // The Current State
    state = {
        data    : {},
        errors  : {},
        loading : false,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const { elemID, fetchQuestions } = this.props;
        fetchQuestions(elemID);
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { elemID, answerQuestions, fetchStrech, strech } = this.props;
        const { loading, data                                } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await answerQuestions(elemID, JSON.stringify(data));
                await fetchStrech(elemID);
                await this.setState({ loading : false });
                if (strech.hasCertificate) {
                    Href.goto("COURSES_MINE", strech.strechID, "CERTIFICATE");
                }
            } catch (errors) {
                this.setState({ loading : false, errors });
                this.container.scrollTo(0, 0);
            }
        }
    }



    /**
     * Returns the Value depending on the Data
     * @param {Number} id
     * @returns {String}
     */
    getValue(id) {
        const { answers } = this.props;
        const { data    } = this.state;

        if (answers[id] !== undefined) {
            return answers[id];
        }
        if (data[id] !== undefined) {
            return data[id];
        }
        return "";
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { route, isLoading, strech, questions, answers, coachName } = this.props;
        const { loading, errors                                         } = this.state;

        const canAnswer = Utils.isEmpty(answers) && questions.length > 0;
        const title     = strech.typeName ? NLS.format("ANSWERS_TITLE", strech.typeName) : "ANSWERS_TITLE_SHORT";

        return <Main>
            <Header message={title} icon="question" route={route} />
            <Content isLoading={isLoading} passedRef={(elem) => this.container = elem }>
                <Alert variant="error" message={errors.form} />
                <section>
                    {questions.map((elem) => <div key={elem.id} className="question">
                        <h3>
                            {elem.question.replace("{coach}", coachName)}
                            {elem.isRequired && <span className="question-required">*</span>}
                        </h3>
                        <div className={`question-input question-${elem.type}`}>
                            <InputField
                                type={elem.type}
                                name={String(elem.id)}
                                placeholder="ANSWERS_MINE"
                                value={this.getValue(elem.id)}
                                error={errors[elem.id] || ""}
                                onChange={this.handleChange}
                                options={elem.select}
                                withCustom={elem.withOther}
                                isDisabled={!canAnswer}
                                withNone
                            />
                        </div>
                    </div>)}
                </section>
                {canAnswer && <footer className="questions-footer">
                    <Button
                        variant="primary"
                        message="ANSWERS_REPLY"
                        onClick={this.handleSubmit}
                        isDisabled={loading}
                    />
                </footer>}
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrech     : PropTypes.func.isRequired,
        fetchQuestions  : PropTypes.func.isRequired,
        answerQuestions : PropTypes.func.isRequired,
        isLoading       : PropTypes.bool.isRequired,
        coachName       : PropTypes.string.isRequired,
        questions       : PropTypes.array.isRequired,
        answers         : PropTypes.object.isRequired,
        strech          : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isLoading  : state.strechAnswer.loading,
            coachName  : state.strechAnswer.coachName,
            questions  : state.strechAnswer.questions,
            answers    : state.strechAnswer.answers,
            strech     : state.strech.elem,
        };
    }
}

export default connect(AnswerCreate.mapStateToProps, {
    fetchStrech, fetchQuestions, answerQuestions,
})(AnswerCreate);
