import NLS                  from "dashboard/dist/Core/NLS";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    STRECH_PRESENTISM_LOADING,
    STRECH_PRESENTISM_LIST, STRECH_PRESENTISM_ELEM,
} from "Utils/Types";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    students  : [],
    sort      : {
        orderBy  : "time",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.date            = DateTime.formatDate(elem.timeDate,    "dashes");
    elem.coachName       = elem.credentialName || NLS.get("GENERAL_NOBODY");
    elem.amount          = NLS.format("GENERAL_X_OF_Y", elem.count, elem.total);
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, STRECH_PRESENTISM_LIST, STRECH_PRESENTISM_ELEM)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case STRECH_PRESENTISM_LOADING:
        return {
            ...state,
            loading    : true,
        };

    case STRECH_PRESENTISM_LIST:
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : parseList(action.data.list),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case STRECH_PRESENTISM_ELEM:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            canEdit   : action.data.canEdit,
            elem      : parseElem(action.data.elem),
            students  : action.data.students,
        };

    default:
        return state;
    }
};

export default reducer;
