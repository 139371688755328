const data = {

    // ID to Section
    SECTIONS : {
        "1"  : "Autenticación",
        "8"  : "Perfil",
        "13" : "Media",

        "56" : "Cursos",
        "54" : "Cursos completados",

        "9"  : "Programa",
        "26" : "Precio de programa",
        "28" : "Charla de programa",
        "11" : "Clase de programa",
        "12" : "Material de programa",
        "14" : "Guía de programa",
        "62" : "Especialidad de programa",
        "42" : "Encuesta de programa",

        "15" : "Tramo",
        "27" : "Precio de tramo",
        "17" : "Horario de tramo",
        "19" : "Coach de tramo",
        "29" : "Charla de tramo",
        "20" : "Clase de tramo",
        "21" : "Material de tramo",
        "22" : "Guía de tramo",
        "63" : "Especialidad de tramo",
        "25" : "Inscripción a tramo",
        "47" : "Inscripción a charla",
        "68" : "Motivo de cancelación",
        "65" : "Asignación en tramo",
        "33" : "Presentismo en tramo",
        "66" : "Grupo en tramo",
        "67" : "Reunión de grupo",
        "43" : "Encuesta de tramo",

        "34" : "Conversación",

        "55" : "Campaña",

        "31" : "Consulta",
        "32" : "Mensaje de consulta",
        "30" : "Tipo de consulta",
        "58" : "Sección de ayuda",
        "59" : "Pregunta de ayuda",

        "18" : "Alumno",
        "6"  : "Coach",
        "4"  : "Administrador",

        "73" : "Lead",
        "71" : "Origen",
        "76" : "Asignación inicial",
        "77" : "Motivo de cancelación",

        "57" : "Material",
        "38" : "Categoría de material",

        "48" : "Guía",
        "49" : "Sección de guía",
        "50" : "Pregunta de guía",

        "16" : "Lugar",
        "23" : "Salón de lugar",
        "24" : "Horario de lugar",

        "64" : "Precios de coach",
        "61" : "Especialidad de coach",
        "52" : "Categoría de coach",
        "36" : "Pregunta de reporte",
        "53" : "Factura de coach",
        "60" : "Material de coach",

        "70" : "Interés",
        "75" : "Quienes somos",
        "40" : "Novedad",
        "41" : "Consejo",
        "69" : "Método de pago",
        "51" : "País",
        "44" : "Provincia",
        "45" : "Localidad",
        "7"  : "Template de email",
        "78" : "Lista blanca de email",
        "39" : "Log de errores",
    },



    // ID to Action
    ACTIONS : {
        "11"  : "Inicio de sesión",
        "12"  : "Inicio como usuario",
        "13"  : "Cierre de sesión",
        "14"  : "Cierre como usuario",
        "15"  : "Registro de usuario",
        "16"  : "Recupero de contraseña",
        "17"  : "Contraseña reseteada",
        "18"  : "Datos guardados",

        "131" : "Directorio creado",
        "132" : "Archivo subido",
        "133" : "Archivo renombrado",
        "135" : "Archivo movido",
        "134" : "Archivo eliminado",

        "81"  : "Cuenta editada",
        "82"  : "Foto cambiada",


        "561" : "Curso creado",
        "562" : "Curso editado",
        "563" : "Curso eliminado",

        "541" : "Curso completados editados",


        "91"  : "Programa creado",
        "92"  : "Programa editado",
        "93"  : "Programa eliminado",

        "261" : "Precio creado",
        "262" : "Precio editado",
        "263" : "Precio eliminado",

        "281" : "Charla creada",
        "282" : "Charla editada",
        "283" : "Charla eliminada",

        "111" : "Clase creada",
        "112" : "Clase editada",
        "113" : "Clase eliminada",

        "121" : "Material asignado",
        "122" : "Material editado",
        "123" : "Material eliminado",

        "141" : "Guía asignada",
        "142" : "Guía editada",
        "143" : "Guía eliminada",

        "621" : "Especialidad asignada",
        "622" : "Especialidad editada",
        "623" : "Especialidad eliminada",

        "421" : "Pregunta creada",
        "422" : "Pregunta editada",
        "423" : "Pregunta eliminada",


        "151" : "Tramo creado",
        "152" : "Tramo editado",
        "154" : "Tramo activado",
        "155" : "Tramo cancelado",
        "153" : "Tramo eliminado",

        "271" : "Precio creado",
        "272" : "Precio editado",
        "273" : "Precio eliminado",
        "274" : "Precios importados",

        "171" : "Horario creado",
        "172" : "Horario editado",
        "173" : "Horario eliminado",

        "191" : "Coach asignado",
        "192" : "Coach editado",
        "193" : "Coach eliminado",

        "291" : "Charla creada",
        "292" : "Charla editada",
        "293" : "Charla eliminada",
        "294" : "Charlas importadas",

        "201" : "Clase creada",
        "202" : "Clase editada",
        "203" : "Clase eliminada",
        "204" : "Clases importadas",
        "205" : "Fecha de clases editada",
        "206" : "Feedback de clases editado",

        "211" : "Material asignado",
        "212" : "Material editado",
        "213" : "Material eliminado",
        "214" : "Materiales importados",

        "221" : "Guía asignada",
        "222" : "Guía editada",
        "223" : "Guía eliminada",
        "224" : "Guías importadas",
        "225" : "Respuesta de guía guardada",
        "226" : "Respuesta de guía enviada",
        "227" : "Feedback de guía guardada",
        "228" : "Feedback de guía enviada",

        "631" : "Especialidad asignada",
        "632" : "Especialidad editada",
        "633" : "Especialidad eliminada",

        "251" : "Inscripción creada",
        "252" : "Inscripción editadd",
        "253" : "Inscripción cancelada",
        "255" : "Inscripción creada por alumno",
        "256" : "Inscripción cancelada por alumno",

        "471" : "Inscripción a charla creada",
        "472" : "Inscripción a charla por alumno",
        "473" : "Inscripción a charla asistida",
        "474" : "Inscripción a charla no asistida",

        "681" : "Motivo de cancelación creado",
        "682" : "Motivo de cancelación editado",
        "683" : "Motivo de cancelación eliminado",

        "651" : "Asignaciones editadas",

        "331" : "Presentismo a clase agregado",
        "332" : "Presentismo a clase quitado",

        "661" : "Grupo creado",
        "662" : "Grupo editado",
        "664" : "Alumnos asignados",
        "665" : "Alumno cambiado de grupo",
        "663" : "Grupo eliminado",

        "671" : "Reunión creada",
        "672" : "Reunión editada",
        "673" : "Reunión reportada",
        "674" : "Reunión cancelada",
        "675" : "Reunión eliminada",

        "431" : "Pregunta creada",
        "432" : "Pregunta editada",
        "433" : "Pregunta eliminada",
        "434" : "Preguntas importadas",
        "435" : "Preguntas respondidas",


        "349" : "Conversación asignada",
        "344" : "Conversación solicitada",
        "345" : "Conversación de-solicitada",
        "341" : "Conversación creada",
        "346" : "Conversación programada",
        "342" : "Conversación editada",
        "347" : "Conversación reportada",
        "348" : "Conversación cancelada",
        "343" : "Conversación eliminada",


        "551" : "Campaña creada",
        "553" : "Campaña editada",
        "552" : "Campaña cancelada",
        "554" : "Campaña eliminada",


        "311" : "Consulta creada",
        "312" : "Consulta editada",
        "313" : "Consulta eliminada",
        "318" : "Consulta asignada",
        "314" : "Consulta abierta",
        "315" : "Consulta cerrada",
        "316" : "Consulta silenciada",
        "317" : "Consulta activada",

        "321" : "Mensaje agregado",
        "322" : "Mensaje editado",
        "323" : "Mensaje eliminado",

        "301" : "Tipo creado",
        "302" : "Tipo editado",
        "303" : "Tipo eliminado",

        "581" : "Sección de ayuda creada",
        "582" : "Sección de ayuda editada",
        "583" : "Sección de ayuda eliminada",

        "591" : "Pregunta de ayuda creada",
        "592" : "Pregunta de ayuda editada",
        "593" : "Pregunta de ayuda eliminada",


        "181" : "Alumno creado",
        "182" : "Alumno editado",
        "183" : "Alumno eliminado",
        "184" : "Nivel de alumno editado",
        "185" : "Contraseña enviada a alumno",

        "61"  : "Coach creado",
        "62"  : "Coach editado",
        "63"  : "Coach eliminado",
        "64"  : "Nivel de coach editado",

        "41"  : "Admin creado",
        "42"  : "Admin editado",
        "43"  : "Admin eliminado",


        "731" : "Lead creado",
        "732" : "Lead editado",
        "737" : "Mensaje de Lead enviado",
        "734" : "Lead asignado",
        "735" : "Lead cerrado",
        "736" : "Lead abierto",
        "733" : "Lead eliminado",

        "711" : "Origen creado",
        "712" : "Origen editado",
        "713" : "Origen eliminado",

        "761" : "Asignación inicial creada",
        "762" : "Asignación inicial editada",
        "763" : "Asignación inicial eliminada",

        "771" : "Motivo de cancelación creado",
        "772" : "Motivo de cancelación editado",
        "773" : "Motivo de cancelación eliminado",


        "571" : "Material creado",
        "572" : "Material editado",
        "573" : "Material eliminado",

        "381" : "Categoría creada",
        "382" : "Categoría editada",
        "383" : "Categoría eliminada",


        "481" : "Guia creada",
        "482" : "Guia editada",
        "483" : "Guia eliminada",

        "491" : "Sección creada",
        "492" : "Sección editada",
        "493" : "Sección eliminada",

        "501" : "Pregunta creada",
        "502" : "Pregunta editada",
        "503" : "Pregunta eliminada",


        "161" : "Lugar creado",
        "162" : "Lugar editado",
        "163" : "Lugar eliminado",

        "231" : "Salón creado",
        "232" : "Salón editado",
        "233" : "Salón eliminado",

        "241" : "Horario creado",
        "242" : "Horario editado",
        "243" : "Horario eliminado",


        "641" : "Precios editados",

        "611" : "Especialidad creada",
        "612" : "Especialidad editada",
        "613" : "Especialidad eliminada",

        "521" : "Categoría creada",
        "522" : "Categoría editada",
        "523" : "Categoría eliminada",

        "361" : "Pregunta creada",
        "362" : "Pregunta editada",
        "363" : "Pregunta eliminada",

        "531" : "Factura creada",
        "535" : "Factura enviada",
        "536" : "Factura adjuntada",
        "534" : "Factura aprobada",
        "533" : "Factura rechazada",
        "532" : "Factura pagada",

        "601" : "Material creado",
        "602" : "Material editado",
        "603" : "Material eliminado",


        "701" : "Interés creado",
        "702" : "Interés editado",
        "703" : "Interés eliminado",

        "751" : "Miembro creado",
        "752" : "Miembro editado",
        "753" : "Miembro eliminado",

        "401" : "Novedad creada",
        "402" : "Novedad editada",
        "403" : "Novedad eliminada",

        "411" : "Consejo creado",
        "412" : "Consejo editado",
        "413" : "Consejo eliminado",

        "691" : "Método de pago creado",
        "692" : "Método de pago editado",
        "693" : "Método de pago eliminado",

        "511" : "País creado",
        "512" : "País editado",
        "513" : "País eliminado",

        "441" : "Provincia creada",
        "442" : "Provincia editada",
        "443" : "Provincia eliminada",

        "451" : "Localidad creada",
        "452" : "Localidad editada",
        "453" : "Localidad eliminada",

        "71"  : "Template de email editado",

        "781" : "Email agregado",
        "782" : "Email editado",
        "783" : "Email quitado",

        "391" : "Error resuelto",
    },
};

export default data;
