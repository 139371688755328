import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { editProfile }      from "Actions/Profile/ProfileActions";
import NLS                  from "dashboard/dist/Core/NLS";
import Href                 from "dashboard/dist/Core/Href";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";
import Action               from "dashboard/dist/Core/Action";


// Fields
import NameField            from "Components/Utils/Credential/NameField";
import FiscalField          from "Components/Utils/Credential/FiscalField";
import ContactField         from "Components/Utils/Credential/ContactField";
import AddressField         from "Components/Utils/Credential/AddressField";
import PersonalField        from "Components/Utils/Credential/PersonalField";
import PasswordField        from "Components/Utils/Credential/PasswordField";

// Components
import Columns              from "dashboard/dist/Components/Columns";
import Button               from "dashboard/dist/Components/Button";
import Form                 from "dashboard/dist/Components/Form";
import InputField           from "dashboard/dist/Components/InputField";

// Dialogs
import CourseCompleted      from "Components/Admin/Course/CourseCompleted";



/**
 * The Settings Form
 */
class SettingsForm extends React.Component {
    // The Initial Data
    initialData = {
        credentialID      : 0,
        firstName         : "",
        lastName          : "",
        nationality       : 0,
        document          : 1,
        dni               : "",
        cuit              : "",
        taxID             : "",
        countryID         : 0,
        provinceID        : 0,
        address           : "",
        email             : "",
        idd               : 0,
        cellphone         : "",
        birthDate         : "",
        gender            : 0,
        ocupation         : "",
        profesion         : 0,
        schedule          : "",
        afipInscription   : 0,
        sendEmailNotis    : 1,
        sendCoachEnrolls  : 1,
        sendWhatsAppNotis : 1,
        canConverse       : 0,
        newPassword       : "",
        confirmPassword   : "",
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : true,
        errors  : {},
        syncIDD : true,
        action : Action.get(),
        elemID : 0
    }

    /**
     * Set the Profile data
     * @returns {Void}
     */
    componentDidMount() {
        const { elem } = this.props;
        let data    = null;
        let syncIDD = true;

        if (!Utils.isEmpty(elem)) {
            data    = Utils.extend(this.initialData, elem);
            syncIDD = !elem.countryID && !elem.idd;
        }
        if (data) {
            this.setState({ data, loading : false, errors : {}, syncIDD });
        }
    }

    /**
     * Reset the State
     * @returns {Void}
     */
    componentWillUnmount() {
        this.setState({ data : { ...this.initialData }, loading : true, errors : {} });
    }



    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const newState = Common.handleUserChange(this.state, name, value);
        this.setState(newState);
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editProfile(data);
                await this.setState({ loading : false });
                Href.goto();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }

    startAction = async (action, elemID) => {
        const { elem } = this.props;
        this.setState({ action, elemID : elem.credentialID });
    }

    endAction = (update) => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isStudent, isCoach, isAdmin, idds, countries, provinces, profesions } = this.props;
        const { action, data, loading, errors, elemID                            } = this.state;

        const showOptions = isStudent || isCoach;

        return <div className="settings-content">
            <Form className="settings-form" onSubmit={this.handleSubmit} noAutoFocus>
                <NameField
                    data={data}
                    errors={errors}
                    onChange={this.handleChange}
                />
                <FiscalField
                    data={data}
                    errors={errors}
                    countries={countries}
                    onChange={this.handleChange}
                    isRequired={isStudent || isCoach}
                />
                <AddressField
                    data={data}
                    errors={errors}
                    countries={countries}
                    provinces={provinces}
                    onChange={this.handleChange}
                    isRequired={isStudent || isCoach}
                />
                <ContactField
                    data={data}
                    errors={errors}
                    idds={idds}
                    onChange={this.handleChange}
                    isRequired={isStudent || isCoach}
                />
                <PersonalField
                    data={data}
                    errors={errors}
                    onChange={this.handleChange}
                    isRequired={isStudent || isCoach}
                />

                <Columns isHidden={!isCoach && !isAdmin}>
                    <InputField
                        type="select"
                        name="profesion"
                        label="USERS_PROFESION"
                        value={data.profesion}
                        onChange={this.handleChange}
                        options={profesions}
                        noneText="GENERAL_NONE"
                        withNone
                        shrinkLabel
                    />
                    <InputField
                        isHidden={!isCoach && !isAdmin}
                        name="ocupation"
                        label="USERS_OCUPATION"
                        value={data.ocupation}
                        error={errors.ocupation}
                        onChange={this.handleChange}
                    />
                    <InputField
                        isHidden={!isCoach && !isAdmin}
                        name="schedule"
                        label="USERS_SCHEDULE"
                        value={data.schedule}
                        error={errors.schedule}
                        onChange={this.handleChange}
                    />

                    <InputField
                        isHidden={!isCoach}
                        type="select"
                        name="afipInscription"
                        label="USERS_AFIP_INSCRIPTION"
                        value={data.afipInscription}
                        error={errors.afipInscription}
                        options="USERS_AFIP_MINE"
                        onChange={this.handleChange}
                        isRequired
                        withNone
                    />
                </Columns>

                {showOptions && <>
                    <h3 className="settings-subtitle">
                        {NLS.get("ACCOUNT_CHANGE_OPTIONS")}
                    </h3>
                    <Columns>
                        <InputField
                            type="toggle"
                            name="sendEmailNotis"
                            label="USERS_SEND_EMAIL_NOTIS"
                            value={data.sendEmailNotis}
                            onChange={this.handleChange}
                        />
                        {/* <InputField
                            type="toggle"
                            name="sendWhatsAppNotis"
                            label="USERS_SEND_WHATSAPP_NOTIS"
                            value={data.sendWhatsAppNotis}
                            onChange={this.handleChange}
                        /> */}
                        <InputField
                            isHidden={!isCoach || !data.canConverse}
                            type="toggle"
                            name="sendCoachEnrolls"
                            label="USERS_SEND_COACH_ENROLLS"
                            value={data.sendCoachEnrolls}
                            onChange={this.handleChange}
                        />
                        <Button
                            isHidden={!isCoach}
                            variant="primary"
                            className="settings-button"
                            message="COURSES_COMPLETED_NAME"
                            onClick={() => this.startAction(Action.get("COMPLETED"), data.credentialID)}
                            
                        />
                    </Columns>
                </>}

                <h3 className="settings-subtitle">
                    {NLS.get("ACCOUNT_CHANGE_PASSWORD")}
                </h3>
                <PasswordField
                    data={data}
                    errors={errors}
                    onChange={this.handleChange}
                />
            </Form>
            <CourseCompleted
                open={action.isCompleted}
                elemID={elemID}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <div className="settings-bottom">
                <Button
                    variant="primary"
                    className="settings-button"
                    message="GENERAL_SAVE"
                    onClick={this.handleSubmit}
                    isDisabled={loading}
                />
            </div>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        editProfile : PropTypes.func.isRequired,
        isStudent   : PropTypes.bool.isRequired,
        isCoach     : PropTypes.bool.isRequired,
        isAdmin     : PropTypes.bool.isRequired,
        idds        : PropTypes.array.isRequired,
        countries   : PropTypes.array.isRequired,
        provinces   : PropTypes.object.isRequired,
        profesions   : PropTypes.array.isRequired,
        elem        : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isStudent : state.auth.isStudent,
            isCoach   : state.auth.isCoach,
            isAdmin   : state.auth.isAdmin,
            edition   : state.profile.edition,
            idds      : state.profile.idds,
            countries : state.profile.countries,
            provinces : state.profile.provinces,
            profesions : state.profile.profesions,
            elem      : state.profile.elem,
        };
    }
}

export default connect(SettingsForm.mapStateToProps, {
    editProfile,
})(SettingsForm);
