import React                from "react";
import PropTypes            from "prop-types";
import Common               from "Utils/Common";

// Components
import AuthForm             from "Components/Auth/AuthForm";



/**
 * The Enroll Error Step
 */
class ErrorStep extends React.Component {
    /**
     * Handles the Link
     * @returns {Void}
     */
    handleLink = () => {
        this.props.onSubmit({ step : "enroll" });
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { strechName, toChat, error } = this.props;

        return <AuthForm
            title="ENROLL_NAME"
            help={Common.parseEnrollError(strechName, toChat, error)}
            link="ENROLL_BACK"
            onClick={this.handleLink}
        />;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        onSubmit   : PropTypes.func.isRequired,
        strechName : PropTypes.string.isRequired,
        toChat     : PropTypes.bool.isRequired,
        error      : PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]).isRequired,
    }
}

export default ErrorStep;
