import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Inscription }      from "Utils/API";

// Types
import {
    INSCRIPTION, INSCRIPTION_LOADING,
    INSCRIPTION_LIST, INSCRIPTION_ELEM,
    INSCRIPTION_CREATE,
} from "Utils/Types";



/**
 * Fetches the Inscription List
 * @param {String=}  type
 * @param {Number=}  elemID
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchInscriptions(type = "", elemID = 0, params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : INSCRIPTION_LOADING });
        }
        Params.unset(params);
        let data = {};

        switch (type) {
        case "STRECH":
            params.strechID = elemID;
            data = await Inscription.getAllForStrech(params);
            break;
        default:
            data = await Inscription.getAllRequests(params);
        }
        data.sort = params;
        dispatch({ type : INSCRIPTION_LIST, data });
        dispatch(setSection(INSCRIPTION));
    };
}

/**
 * Fetches the Inscriptions Emails
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Number=} filter
 * @returns {(dispatch) => Void}
 */
export function fetchInscriptionEmails(type = "", elemID = 0, filter = 0) {
    return async () => {
        const params = { filter };
        if (type === "STRECH") {
            params.strechID = elemID;
        }
        return await Inscription.getEmails(params);
    };
}

/**
 * Fetches a single Inscription
 * @param {Number} inscriptionID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchInscription(inscriptionID) {
    return async (dispatch) => {
        const data = await Inscription.getOne({ inscriptionID });
        dispatch({ type : INSCRIPTION_ELEM, data });
    };
}

/**
 * Fetches the Inscription Create data
 * @param {Number} strechID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchInscriptionCreate(strechID) {
    return async (dispatch) => {
        const data = await Inscription.getCreateData({ strechID });
        dispatch({ type : INSCRIPTION_CREATE, data });
    };
}




/**
 * Edits/Creates a Inscription
 * @param {Object} data
 * @returns {() => Void}
 */
export function editInscription(data) {
    return () => Inscription.edit(data);
}

/**
 * Deletes a Inscription
 * @param {Number} inscriptionID
 * @param {Number} reasonID
 * @returns {() => Void}
 */
export function cancelInscription(inscriptionID, reasonID) {
    return () => Inscription.cancel({ inscriptionID, reasonID });
}
