import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Types
import {
    STRECH_LOADING, STRECH_CHARGING,
    STRECH_LIST, STRECH_ELEM, STRECH_WELCOME,
    STRECH_EDIT, STRECH_CREATE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading       : false,
    charging      : false,
    error         : false,
    edition       : 0,
    canCreate     : false,
    canEdit       : false,
    canImport     : false,
    canExport     : false,
    list          : [],
    total         : 0,

    elem          : {},
    classes       : [],
    materials     : [],
    schedules     : [],
    coaches       : [],
    coachName     : "",
    conversations : 0,

    programs      : [],
    programsData  : [],
    locations     : [],
    rooms         : {},

    sort          : {
        filter    : "active",
        orderBy   : "fromTime",
        orderAsc  : 0,
        page      : 0,
        amount    : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate      = DateTime.formatDate(elem.createdTime,    "dashes");
    elem.createdDateTime  = DateTime.formatDate(elem.createdTime,    "dashesTime");
    elem.fromDate         = DateTime.formatIf(elem.fromTimeDate,     "dashes");
    elem.fromTimeDate     = DateTime.formatIf(elem.fromTimeDate,     "dashesReverse");
    elem.fromTimeHour     = DateTime.formatIf(elem.fromTimeFull,     "time");
    elem.fromTimeDateHour = DateTime.formatIf(elem.fromTimeFull,     "dashesHour");
    elem.toDate           = DateTime.formatIf(elem.toTimeDate,       "dashes");
    elem.toTimeDate       = DateTime.formatIf(elem.toTimeDate,       "dashesReverse");
    elem.toTimeHour       = DateTime.formatIf(elem.toTimeFull,       "time");
    elem.toTimeDateHour   = DateTime.formatIf(elem.toTimeFull,       "dashesHour");
    elem.inscFromDate     = DateTime.formatIf(elem.inscFromTimeDate, "dashes");
    elem.inscFromTimeDate = DateTime.formatIf(elem.inscFromTimeDate, "dashesReverse");
    elem.inscToDate       = DateTime.formatIf(elem.inscToTimeDate,   "dashes");
    elem.inscToTimeDate   = DateTime.formatIf(elem.inscToTimeDate,   "dashesReverse");
    elem.locationText     = elem.isPresential ? elem.locationName : NLS.get("STRECHES_MODE_VIRTUAL");
    elem.link             = elem.inInscription ? NLS.fullUrl("INSC_STRECH_ONLY", elem.strechID) : "";
    elem.typeName         = Common.getTypeName(elem.programType);
    elem.modeName         = Common.getModeName(elem.mode);
    elem.statusName       = Status.getName(elem.status);
    elem.statusColor      = Status.getColor(elem.status);
    elem.statusClass      = Status.getTextClass(elem.status);

    if (!elem.hasClasses) {
        const dates = Common.getStrechDates(elem);
        elem.timeText = dates.timeText;
    }
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, STRECH_LIST, STRECH_ELEM, STRECH_WELCOME, STRECH_EDIT, STRECH_CREATE)) {
        return { ...state, loading : false, charging : false, error : true };
    }

    switch (action.type) {
    case STRECH_LOADING:
        return {
            ...state,
            loading       : true,
        };
    case STRECH_CHARGING:
        return {
            ...state,
            charging      : true,
        };

    case STRECH_LIST:
        return {
            ...state,
            loading       : false,
            error         : false,
            canCreate     : action.data.canCreate,
            canEdit       : action.data.canEdit,
            canImport     : action.data.canImport,
            canExport     : action.data.canExport,
            list          : parseList(action.data.list),
            total         : action.data.total,
            sort          : action.data.sort,
        };

    case STRECH_ELEM:
        return {
            ...state,
            charging      : false,
            error         : false,
            edition       : state.edition + 1,
            canEdit       : action.data.canEdit,
            elem          : parseElem(action.data.elem),
            classes       : action.data.classes,
        };
    case STRECH_WELCOME:
        return {
            ...state,
            charging      : false,
            error         : false,
            edition       : state.edition + 1,
            materials     : Common.parseMaterials(action.data.materials),
            schedules     : Common.parseSchedules(action.data.schedules).list,
            coaches       : action.data.coaches,
            coachName     : action.data.coachName,
            conversations : action.data.conversations,
        };

    case STRECH_EDIT:
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            canEdit       : action.data.canEdit,
            elem          : parseElem(action.data.elem),
            programs      : action.data.programs,
            programsData  : action.data.programsData,
            locations     : action.data.locations,
            rooms         : action.data.rooms,
        };

    case STRECH_CREATE:
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            programs      : action.data.programs,
            programsData  : action.data.programsData,
            locations     : action.data.locations,
            rooms         : action.data.rooms,
        };

    default:
        return state;
    }
};

export default reducer;
