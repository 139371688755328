import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchLocation }    from "Actions/Admin/Location/LocationActions";
import Action               from "dashboard/dist/Core/Action";

// Components
import LocationEdit         from "./LocationEdit";
import Details              from "dashboard/dist/Components/Details";
import DetailList           from "dashboard/dist/Components/DetailList";
import DetailItem           from "dashboard/dist/Components/DetailItem";
import DetailActionList     from "dashboard/dist/Components/DetailActionList";
import DetailAction         from "dashboard/dist/Components/DetailAction";



/**
 * The Location Details
 */
class LocationDetails extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
    }

    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.props.fetchLocation(this.props.locationID, true);
        this.endAction();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { charging, error, canEdit, elem, locationID } = this.props;
        const { action                                     } = this.state;

        return <>
            <Details
                isLoading={charging}
                hasError={error}
                error="LOCATIONS_ERROR_EXISTS"
            >
                <DetailList message="LOCATIONS_DETAILS_TITLE">
                    <DetailItem icon="name"         message={elem.name}            tooltip="LOCATIONS_ITS_NAME"   />
                    <DetailItem icon="country"      message={elem.countryName}     tooltip="LOCATIONS_COUNTRY"    />
                    <DetailItem icon="province"     message={elem.provinceName}    tooltip="LOCATIONS_PROVINCE"   />
                    <DetailItem icon="locality"     message={elem.localityName}    tooltip="LOCATIONS_LOCALITY"   />
                    <DetailItem icon="address"      message={elem.address}         tooltip="LOCATIONS_ADDRESS"    />
                    <DetailItem icon="contact"      message={elem.contact}         tooltip="LOCATIONS_CONTACT"    />
                    <DetailItem icon="phone"        message={elem.phone}           tooltip="LOCATIONS_PHONE"      isPhone />
                    <DetailItem icon="status"       message={elem.statusName}      tooltip="GENERAL_STATUS"       className={elem.statusClass}     />
                    <DetailItem icon="observations" message={elem.observations}    tooltip="GENERAL_OBSERVATIONS" />
                    <DetailItem icon="time"         message={elem.createdDateTime} tooltip="GENERAL_CREATED"      />
                </DetailList>
                <DetailActionList onAction={this.startAction} canEdit={canEdit}>
                    <DetailAction action="EDIT"  message="LOCATIONS_EDIT_TITLE" />
                </DetailActionList>
            </Details>

            <LocationEdit
                open={action.isEdit}
                elemID={locationID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchLocation : PropTypes.func.isRequired,
        charging      : PropTypes.bool.isRequired,
        error         : PropTypes.bool.isRequired,
        canEdit       : PropTypes.bool.isRequired,
        elem          : PropTypes.object.isRequired,
        locationID    : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            charging : state.location.charging,
            error    : state.location.error,
            canEdit  : state.location.canEdit,
            elem     : state.location.elem,
        };
    }
}

export default connect(LocationDetails.mapStateToProps, {
    fetchLocation,
})(LocationDetails);
