import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { logout }           from "Actions/Core/AuthActions";
import { Search }           from "Utils/API";
import Href                 from "dashboard/dist/Core/Href";

// Routers
import AdminRouter          from "Components/Admin/AdminRouter";
import StudentRouter        from "Components/Student/StudentRouter";
import CoachRouter          from "Components/Coach/CoachRouter";

// Components
import NewDrawer            from "Components/Core/NewDrawer";
import Container            from "dashboard/dist/Components/Container";
import SideBar              from "dashboard/dist/Components/SideBar";
import TopBar               from "dashboard/dist/Components/TopBar";
import SearchDrawer         from "dashboard/dist/Components/SearchDrawer";
import BarIcon              from "dashboard/dist/Components/BarIcon";

// Images
import Icon                 from "Styles/Images/Icon.png";
import IconWhite            from "Styles/Images/IconWhite.png";
import LogoWhite            from "Styles/Images/LogoWhite.png";

// Actions
import {
    openMenu, openDetails, closeMenu, closeDetails,
} from "Actions/Core/CoreActions";



/**
 * The App
 */
class App extends React.Component {
    // The Current State
    state = {
        showSearch : false,
        showCreate : false,
    }

    /**
     * Opens the Search Drawer
     * @returns {Void}
     */
    openSearch = () => {
        this.setState({ showSearch : true });
    }

    /**
     * Closes the Search Drawer
     * @returns {Void}
     */
    closeSearch = () => {
        this.setState({ showSearch : false });
    }

    /**
     * Opens the Create Drawer
     * @returns {Void}
     */
    openCreate = () => {
        if (this.props.isStudent) {
            Href.goto("INSCRIPTIONS");
        } else {
            this.setState({ showCreate : true });
        }
    }

    /**
     * Closes the Create Drawer
     * @returns {Void}
     */
    closeCreate = () => {
        this.setState({ showCreate : false });
    }

    /**
     * Goes to the Support
     * @returns {Void}
     */
    gotoSupport = () => {
        Href.goto("SUPPORT");
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            isAdmin, loggedAsUser, credential, edition, logout,
            showMenu, openMenu, closeMenu,
            hasDetails, showDetails, openDetails, closeDetails,
        } = this.props;

        const { showSearch, showCreate } = this.state;

        return <Container
            showMenu={showMenu}
            showDetails={showDetails}
            closeMenu={closeMenu}
            closeDetails={closeDetails}
        >
            <TopBar
                className="topbar"
                logo={LogoWhite}
                logoWidth={0}
                logoHeight={36}
                onMenu={openMenu}
                onDetails={openDetails}
                showDetails={hasDetails}
                avatarUrl="PROFILE"
                avatarEmail={credential.email}
                avatarAvatar={credential.avatar}
                avatarEdition={edition}
            />
            <SideBar
                className="mainbar"
                logo={IconWhite}
                logoWidth={40}
                message={loggedAsUser ? credential.name : ""}
                onSearch={this.openSearch}
                onCreate={this.openCreate}
                onClose={closeMenu}
                onLogout={logout}
                avatarUrl="PROFILE"
                avatarEmail={credential.email}
                avatarAvatar={credential.avatar}
                avatarEdition={edition}
                hasSearch={isAdmin}
                hasCreate={isAdmin}
            >
                <BarIcon
                    isHidden={!isAdmin}
                    variant="dark"
                    icon="support"
                    onClick={this.gotoSupport}
                />
            </SideBar>

            <AdminRouter />
            <CoachRouter />
            <StudentRouter />

            <SearchDrawer
                isHidden={!isAdmin}
                open={showSearch}
                logo={Icon}
                logoWidth={40}
                fetch={Search.search}
                onClose={this.closeSearch}
            />
            <NewDrawer
                isHidden={!isAdmin}
                open={showCreate}
                onClose={this.closeCreate}
            />
        </Container>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        closeMenu    : PropTypes.func.isRequired,
        closeDetails : PropTypes.func.isRequired,
        logout       : PropTypes.func.isRequired,
        showMenu     : PropTypes.bool.isRequired,
        showDetails  : PropTypes.bool.isRequired,
        hasDetails   : PropTypes.bool.isRequired,
        isAdmin      : PropTypes.bool.isRequired,
        isStudent    : PropTypes.bool.isRequired,
        loggedAsUser : PropTypes.bool.isRequired,
        credential   : PropTypes.object.isRequired,
        edition      : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            showMenu     : state.core.showMenu,
            showDetails  : state.core.showDetails,
            hasDetails   : state.core.hasDetails,
            isAdmin      : state.auth.isAdmin,
            isStudent    : state.auth.isStudent,
            loggedAsUser : state.auth.loggedAsUser,
            credential   : state.auth.credential,
            edition      : state.auth.edition,
        };
    }
}

export default connect(App.mapStateToProps, {
    openMenu, openDetails, closeMenu, closeDetails, logout,
})(App);
