import { setSection }        from "Actions/Core/CoreActions";
import { GuideReport }       from "Utils/API";

// Types
import {
    GUIDE_REPORT, GUIDE_REPORT_LOADING,
    GUIDE_REPORT_DATA, GUIDE_REPORT_GRAPH,
} from "Utils/Types";



/**
 * Fetches the Guide Data
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchGuideData(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : GUIDE_REPORT_LOADING });
        }
        const data = await GuideReport.getData(params);
        dispatch({ type : GUIDE_REPORT_DATA, data });
        dispatch(setSection(GUIDE_REPORT));
    };
}

/**
 * Fetches the Guide Graph
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchGuideGraph(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : GUIDE_REPORT_LOADING });
        }
        const data = await GuideReport.getGraph(params);
        dispatch({ type : GUIDE_REPORT_GRAPH, data });
        return data;
    };
}

/**
 * Fetches the Guide Emails
 * @param {Object=} params
 * @returns {(dispatch) => Void}
 */
export function fetchGuideEmails(params = {}) {
    return async () => {
        return await GuideReport.getEmails(params);
    };
}
