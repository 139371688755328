import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { loginAs }          from "Actions/Core/AuthActions";
import Action               from "dashboard/dist/Core/Action";
import NLS                  from "dashboard/dist/Core/NLS";
import Utils                from "dashboard/dist/Utils/Utils";

// Dialogs
import StudentFilter        from "./StudentFilter";
import StudentEdit          from "./StudentEdit";
import CourseCompleted      from "Components/Admin/Course/CourseCompleted";
import CampaignSend         from "Components/Admin/Campaign/CampaignSend";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";
import Downloader           from "dashboard/dist/Components/Downloader";

// Actions
import {
    fetchStudents, fetchStudentsEmails, exportStudents, editStudentLevel, deleteStudent,
} from "Actions/Admin/User/StudentActions";



/**
 * The Student List
 */
class StudentList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
        users  : [],
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Returns true if this is the Signups
     * @returns {Boolean}
     */
    isSignups() {
        return this.props.route === NLS.baseUrl("DASHBOARD_SIGNUPS");
    }

    /**
     * Returns the type depending if this is the Signups
     * @returns {String}
     */
    getType() {
        return this.isSignups() ? "SIGNUP" : this.props.type;
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Object=}  filters
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, filters, withLoader) => {
        const { elemID, data, fetchStudents } = this.props;
        fetchStudents(this.getType(), elemID, params || data.sort, filters || data.filters, withLoader);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Promise}
     */
    startAction = async (action, elemID) => {
        const { data, fetchStudentsEmails, loginAs } = this.props;
        if (action.isLogin) {
            loginAs(elemID);
        } else {
            let users = [];
            if (action.isCampaign) {
                users = await fetchStudentsEmails(this.getType(), data.filters);
            }
            this.setState({ action, elemID, users });
        }
    }

    /**
     * Ends an Action
     * @param {Boolean=} update
     * @returns {Void}
     */
    endAction = (update) => {
        this.startAction(Action.get(), 0);
        if (update) {
            this.fetch(null, null, false);
        }
    }



    /**
     * Handles the Filter Submit
     * @param {Object} newFilters
     * @returns {Promise}
     */
    filterElem = async (newFilters) => {
        const { filters, sort } = this.props.data;
        this.endAction();
        this.fetch({ ...sort, page : 0 }, { ...filters, ...newFilters }, false);
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction(true);
    }

    /**
     * Handles the Level Submit
     * @returns {Promise}
     */
    editLevel = async () => {
        const elemID = this.state.elemID;
        await this.props.editStudentLevel(elemID);
        this.endAction(true);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID = this.state.elemID;
        await this.props.deleteStudent(elemID);
        this.endAction(true);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID, users                        } = this.state;
        const { data, route                                  } = this.props;
        const { canEdit, list, total, sort, filters, loading } = data;

        const isSignups = this.isSignups();
        const message   = isSignups ? "STUDENTS_SIGNUPS"      : "STUDENTS_NAME";
        const none      = isSignups ? "STUDENTS_NONE_SIGNUPS" : "STUDENTS_NONE_AVAILABLE";
        const realRoute = isSignups ? "STUDENTS"              : route;
        const elemName  = Utils.getValue(list, "credentialID", elemID, "credentialName");

        return <Main>
            <Header message={message} icon="student" route={route}>
                <ActionList data={data} onAction={this.startAction} withCampaign />
            </Header>
            <Content isLoading={loading}>
                <Table fetch={this.fetch} sort={sort} none={none}>
                    <TableHead>
                        <TableHeader field="firstName"    message="GENERAL_NAME"         />
                        <TableHeader field="email"        message="GENERAL_EMAIL"        />
                        <TableHeader field="cellphone"    message="USERS_CELLPHONE"      />
                        <TableHeader field="subscription" message="GENERAL_SUBSCRIPTION" maxWidth="110" />
                        <TableHeader field="createdTime"  message="GENERAL_CREATED"      maxWidth="90"  hideMobile />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.credentialID} elemID={elem.credentialID}>
                            <TableCell message={elem.credentialName}   circle={elem.statusColor} />
                            <TableCell message={elem.email}            />
                            <TableCell message={elem.phoneString}      />
                            <TableCell message={elem.subscriptionName} className={elem.subscriptionClass} />
                            <TableCell message={elem.createdDate}      />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"      message="STUDENTS_VIEW_TITLE"    route={realRoute} />
                        <TableAction action="EDIT"      message="STUDENTS_EDIT_TITLE"    />
                        <TableAction action="DELETE"    message="STUDENTS_DELETE_TITLE"  />
                        <TableAction action="COMPLETED" message="COURSES_COMPLETED_NAME" />
                        <TableAction action="ACCESS"    message="STUDENTS_ACCESS_TITLE"  />
                        <TableAction action="LOGIN"     message="STUDENTS_LOGIN_AS"      />
                    </TableActionList>
                </Table>
            </Content>

            <StudentFilter
                open={action.isFilter}
                filters={filters}
                onSubmit={this.filterElem}
                onClose={this.endAction}
            />
            <StudentEdit
                open={action.isVCE}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <CourseCompleted
                open={action.isCompleted}
                elemID={elemID}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isAccess}
                icon="access"
                title="STUDENTS_ACCESS_TITLE"
                message="STUDENTS_ACCESS_TEXT"
                content={elemName}
                onSubmit={this.editLevel}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="STUDENTS_DELETE_TITLE"
                message="STUDENTS_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
            <CampaignSend
                open={action.isCampaign}
                users={users}
                onClose={this.endAction}
                onSubmit={this.endAction}
            />

            <Downloader
                download={action.isExport}
                source={exportStudents(this.getType(), filters)}
                onLoad={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStudents       : PropTypes.func.isRequired,
        fetchStudentsEmails : PropTypes.func.isRequired,
        editStudentLevel    : PropTypes.func.isRequired,
        deleteStudent       : PropTypes.func.isRequired,
        loginAs             : PropTypes.func.isRequired,
        data                : PropTypes.object.isRequired,
        route               : PropTypes.string.isRequired,
        type                : PropTypes.string.isRequired,
        elemID              : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.student,
        };
    }
}

export default connect(StudentList.mapStateToProps, {
    fetchStudents, fetchStudentsEmails, editStudentLevel, deleteStudent,
    loginAs,
})(StudentList);
