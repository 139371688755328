import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";

// Components
import FilterDialog         from "dashboard/dist/Components/FilterDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchCoachesFilter,
} from "Actions/Admin/User/CoachActions";



/**
 * The Coach Filter Dialog
 */
class CoachFilter extends React.Component {
    // The Initial Data
    initialData = {
        courseID        : 0,
        specialityID    : 0,
        coachCategoryID : 0,
        gender          : 0,
        countryID       : 0,
        provinceID      : 0,
        subscription    : "",
        afipInscription : 0,
        status          : 0,
        schoolStaff     : 0,
        canConverse     : 0,
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, filters, edition, fetchCoachesFilter } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = {};
            // Load new data
            fetchCoachesFilter();
            loading = true;

        // Data Updated
        } else if (prevProps.edition !== edition) {
            data = { ...this.initialData, ...filters };
        }

        // Set the State
        if (data) {
            this.setState({ data, loading });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const data = { ...this.state.data, [name] : value };
        this.setState({ data });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onSubmit, onClose, courses, specialities, categories, countries, provinces } = this.props;
        const { loading, data                                                                    } = this.state;

        return <FilterDialog
            open={open}
            title="COACHES_FILTER_TITLE"
            initialData={this.initialData}
            data={data}
            onSubmit={onSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                type="select"
                name="courseID"
                label="COURSES_COMPLETED_SINGULAR"
                value={data.courseID}
                onChange={this.handleChange}
                options={courses}
                noneText="GENERAL_ANY"
                withNone
                shrinkLabel
            />
            <InputField
                type="select"
                name="specialityID"
                label="SPECIALITIES_SHORT"
                value={data.specialityID}
                onChange={this.handleChange}
                options={specialities}
                noneText="GENERAL_ANY"
                withNone
                shrinkLabel
            />
            <InputField
                type="select"
                name="coachCategoryID"
                label="COACH_CATEGORIES_SINGULAR"
                value={data.coachCategoryID}
                onChange={this.handleChange}
                options={categories}
                noneText="GENERAL_ANY"
                withNone
                shrinkLabel
            />
            <InputField
                type="select"
                name="gender"
                label="USERS_GENDER"
                value={data.gender}
                onChange={this.handleChange}
                options="USERS_GENDERS"
                noneText="GENERAL_ALL"
                withNone
                shrinkLabel
            />
            <Columns>
                <InputField
                    type="select"
                    name="countryID"
                    label="USERS_COUNTRY"
                    value={data.countryID}
                    onChange={this.handleChange}
                    options={countries}
                    noneText="GENERAL_ALL"
                    withNone
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="provinceID"
                    label="PROVINCES_SINGULAR"
                    value={data.provinceID}
                    onChange={this.handleChange}
                    options={data.countryID ? provinces[data.countryID] || [] : []}
                    noneText="GENERAL_ALL_FEM"
                    withNone
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="subscription"
                    label="GENERAL_SUBSCRIPTION"
                    value={data.subscription}
                    onChange={this.handleChange}
                    options="SUBSCRIPTIONS"
                    noneText="GENERAL_ALL"
                    withNone
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="afipInscription"
                    label="USERS_AFIP_INSCRIPTION"
                    value={data.afipInscription}
                    onChange={this.handleChange}
                    options="USERS_AFIP_OPTIONS"
                    noneText="GENERAL_ALL_FEM"
                    withNone
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    onChange={this.handleChange}
                    options={Status.getSelect("STUDENT")}
                    noneText="GENERAL_ALL"
                    withNone
                    shrinkLabel
                />
                <InputField
                    type="toggle"
                    name="schoolStaff"
                    label="USERS_SCHOOL_STAFF"
                    value={data.schoolStaff}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="canConverse"
                    label="USERS_CAN_CONVERSE"
                    value={data.canConverse}
                    onChange={this.handleChange}
                />
            </Columns>
        </FilterDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCoachesFilter : PropTypes.func.isRequired,
        open               : PropTypes.bool.isRequired,
        onSubmit           : PropTypes.func.isRequired,
        onClose            : PropTypes.func.isRequired,
        edition            : PropTypes.number.isRequired,
        courses            : PropTypes.array.isRequired,
        specialities       : PropTypes.array.isRequired,
        categories         : PropTypes.array.isRequired,
        countries          : PropTypes.array.isRequired,
        provinces          : PropTypes.object.isRequired,
        filters            : PropTypes.object,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
     static mapStateToProps(state) {
        return {
            edition      : state.coach.edition,
            courses      : state.coach.courses,
            specialities : state.coach.specialities,
            categories   : state.coach.categories,
            countries    : state.coach.countries,
            provinces    : state.coach.provinces,
        };
    }
}

export default connect(CoachFilter.mapStateToProps, {
    fetchCoachesFilter,
})(CoachFilter);
