import Access               from "dashboard/dist/Core/Access";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Types
import {
    CAMPAIGN_USER_LOADING, CAMPAIGN_USER_LIST,
} from "Utils/Types";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    stats     : {},
    sort      : {
        orderBy  : "firstName",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate       = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime   = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.levelName         = Access.getName(elem.level);
    elem.phoneString       = Common.combinePhone(elem, true);
    elem.subscriptionName  = Common.getSubscriptionName(elem.subscription);
    elem.subscriptionClass = Common.getSubscriptionClass(elem.subscription);
    elem.sentString        = Utils.toYesNo(elem.sent);
    elem.openString        = Utils.toYesNo(elem.open);
    elem.clickString       = Utils.toYesNo(elem.click);
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, CAMPAIGN_USER_LIST)) {
        return { ...state, loading : false, charging : false, error : true };
    }

    switch (action.type) {
    case CAMPAIGN_USER_LOADING:
        return {
            ...state,
            loading   : true,
        };

    case CAMPAIGN_USER_LIST:
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : parseList(action.data.list),
            total     : action.data.total,
            sort      : action.data.sort,
            stats     : action.data.stats,
        };

    default:
        return state;
    }
};

export default reducer;
