import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { closeMenu }        from "Actions/Core/CoreActions";

// Components
import Navigation           from "dashboard/dist/Components/Navigation";
import NavigationHeader     from "dashboard/dist/Components/NavigationHeader";
import NavigationBody       from "dashboard/dist/Components/NavigationBody";

// Images
import Title                from "Styles/Images/TitleWhite.png";

// Styles
import "Styles/Components/Utils/Navigation.css";



/**
 * The Primary Navigation
 */
class PrimaryNav extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { path, baseUrl, closeMenu, children } = this.props;

        return <Navigation variant="dark" onClose={closeMenu}>
            <NavigationHeader className="navigation-header" logo={Title} />
            <NavigationBody path={path} baseUrl={baseUrl}>
                {children}
            </NavigationBody>
        </Navigation>;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        closeMenu : PropTypes.func.isRequired,
        path      : PropTypes.string.isRequired,
        baseUrl   : PropTypes.string,
        children  : PropTypes.any,
    }

    /**
     * The Default Properties
     * @type {Object} defaultProps
     */
    static defaultProps = {
        href    : "/",
        baseUrl : "",
    }
}

export default connect(null, {
    closeMenu,
})(PrimaryNav);
