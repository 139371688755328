import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { InscriptionReport }from "Utils/API";
import Action               from "dashboard/dist/Core/Action";
import Status               from "dashboard/dist/Core/Status";
import Graph                from "Utils/Graph";
import GraphType            from "Utils/Entity/GraphType";

// Partials
import ReportFilters        from "../Partials/ReportFilters";
import ReportGraph          from "../Partials/ReportGraph";
import ReportTable          from "../Partials/ReportTable";

// Components
import CampaignSend         from "Components/Admin/Campaign/CampaignSend";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import InputField           from "dashboard/dist/Components/InputField";
import Downloader           from "dashboard/dist/Components/Downloader";

// APIs
import {
    Search,
} from "Utils/API";

// Actions
import {
    fetchInscriptionData, fetchInscriptionGraph, fetchInscriptionEmails,
} from "Actions/Admin/Report/InscriptionActions";

// Styles
import "Styles/Components/App/Report.css";



/**
 * The Inscription Report Page
 */
class InscriptionPage extends React.Component {
    // The Initial Data
    initialFilters = {
        graphType     : GraphType.COURSES,
        dataType      : Graph.DATA_AMOUNT,
        status        : 0,
        programType   : 0,
        strechType    : 0,
        mode          : 0,
        dues          : 0,
        paymentMethod : 0,
        conversations : 0,
        courseID      : 0,
        programID     : 0,
        strechID      : 0,
        credentialID  : 0,
        coacheeID    : 0,
        coacheeName  : "",
        period        : 0,
        fromDate      : "",
        toDate        : "",
    }

    // The Current State
    state = {
        filters : { ...this.initialFilters },
        action  : Action.get(),
        elemID  : 0,
        users   : [],
        graph   : 0,
    }



    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        if (!this.props.data.loaded) {
            this.props.fetchInscriptionData();
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const filters = GraphType.handleFilter(this.state, name, value);
        if (GraphType.shouldFetchFilters(name)) {
            this.props.fetchInscriptionData(filters, false);
        }
        this.setState({ filters });
    }

    /**
     * Handles the Report Filter
     * @returns {Promise}
     */
    handleFilter = async () => {
        const { graph, filters } = this.state;
        await this.props.fetchInscriptionGraph(filters);
        this.setState({ graph : graph + 1 });
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Promise}
     */
    startAction = async (action, elemID) => {
        let users = [];
        if (action.isCampaign) {
            users = await this.props.fetchInscriptionEmails({ ...this.state.filters, elemID });
        }
        this.setState({ action, elemID, users });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { route, data                           } = this.props;
        const { filters, action, elemID, users, graph } = this.state;

        const hideFilter = GraphType.getHideFilters(filters.graphType);

        return <Main>
            <Header message="REPORTS_INSCRIPTIONS" icon="inscription" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content>
                <ReportFilters
                    type="INSCRIPTIONS"
                    data={data}
                    filters={filters}
                    onChange={this.handleChange}
                    onFilter={this.handleFilter}
                >
                    <InputField
                        type="select"
                        name="dataType"
                        label="REPORTS_DATA"
                        value={filters.dataType}
                        onChange={this.handleChange}
                        options="REPORTS_DATA_OPTIONS"
                        noMargin
                        shrinkLabel
                        labelInside
                    />
                    <InputField
                        type="select"
                        name="status"
                        label="REPORTS_INSCRIPTION_STATUS"
                        value={filters.status}
                        onChange={this.handleChange}
                        options={Status.getFemSelect("INSCRIPTION")}
                        noneText="GENERAL_ALL"
                        withNone
                        noMargin
                        shrinkLabel
                        labelInside
                    />
                    <InputField
                        type="select"
                        name="strechType"
                        label="REPORTS_STRECH_TYPE"
                        value={filters.strechType}
                        onChange={this.handleChange}
                        options="REPORTS_STRECH_TYPE_OPTIONS"
                        noneText="GENERAL_ALL"
                        withNone
                        noMargin
                        shrinkLabel
                        labelInside
                    />
                    <InputField
                        isHidden={hideFilter.payments}
                        type="select"
                        name="paymentMethod"
                        label="PAYMENT_METHODS_SINGULAR"
                        value={filters.paymentMethod}
                        onChange={this.handleChange}
                        options={data.paymentMethods}
                        noneText="GENERAL_ALL"
                        withNone
                        noMargin
                        shrinkLabel
                        labelInside
                    />

                    <InputField
                        isHidden={hideFilter.dues}
                        type="select"
                        name="dues"
                        label="REPORTS_DUES"
                        value={filters.dues}
                        onChange={this.handleChange}
                        options={data.dues}
                        noneText="GENERAL_ALL_FEM"
                        withNone
                        noMargin
                        shrinkLabel
                        labelInside
                    />
                    <InputField
                        isHidden={hideFilter.conversations}
                        type="select"
                        name="conversations"
                        label="CONVERSATIONS_NAME"
                        value={filters.conversations}
                        onChange={this.handleChange}
                        options={data.conversations}
                        noneText="GENERAL_ALL"
                        withNone
                        noMargin
                        shrinkLabel
                        labelInside
                    />
                    <InputField
                        isHidden={hideFilter.facilitators}
                        type="select"
                        name="credentialID"
                        label="STRECHES_COACHES_SINGULAR"
                        value={filters.credentialID}
                        onChange={this.handleChange}
                        options={data.facilitators}
                        noneText="GENERAL_ALL"
                        withNone
                        noMargin
                        shrinkLabel
                        labelInside
                    />
                    <InputField
                        isHidden={hideFilter.coachees}
                        name="coacheeName"
                        label="STUDENTS_SINGULAR"
                        suggestID="coacheeID"
                        suggestParams={{ justUsers : true }}
                        suggestFetch={Search.searchUser}
                        suggestNone="STUDENTS_NONE_AVAILABLE"
                        value={filters.coacheeName}
                        onChange={this.handleChange}
                        noMargin
                        shrinkLabel
                        labelInside
                    />
                </ReportFilters>

                <ReportGraph
                    data={data}
                    graph={graph}
                    dataType={filters.dataType}
                />
                <ReportTable
                    data={data}
                    startAction={this.startAction}
                    withPrice
                />
            </Content>

            <Downloader
                download={action.isExport}
                source={InscriptionReport.export(filters)}
                onLoad={this.endAction}
            />
            <Downloader
                download={action.isDownload}
                source={InscriptionReport.download({ ...filters, elemID })}
                onLoad={this.endAction}
            />
            <CampaignSend
                open={action.isCampaign}
                users={users}
                onClose={this.endAction}
                onSubmit={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchInscriptionData   : PropTypes.func.isRequired,
        fetchInscriptionGraph  : PropTypes.func.isRequired,
        fetchInscriptionEmails : PropTypes.func.isRequired,
        data                   : PropTypes.object.isRequired,
        route                  : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.inscriptionReport,
        };
    }
}

export default connect(InscriptionPage.mapStateToProps, {
    fetchInscriptionData, fetchInscriptionGraph, fetchInscriptionEmails,
})(InscriptionPage);
