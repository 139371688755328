import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Ticket }           from "Utils/API";

// Types
import {
    TICKET, TICKET_LOADING,
    TICKET_LIST, TICKET_ELEM,
    TICKET_CREATE, TICKET_ASSIGN,
} from "Utils/Types";



/**
 * Fetches the Ticket List
 * @param {String=}  type
 * @param {Number=}  elemID
 * @param {Object=}  params
 * @param {Number=}  filter
 * @param {Boolean=} forSupport
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTickets(type = "", elemID = 0, params = {}, filter = 0, forSupport = false, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : TICKET_LOADING });
        }
        Params.unset(params);
        params.filter = filter;
        let data = {};

        switch (type) {
        case "STUDENT":
            params.studentID = elemID;
            data = await Ticket.getAllForStudent(params);
            break;
        case "COACH":
            params.coachID = elemID;
            data = await Ticket.getAllForCoach(params);
            break;
        case "USER":
            data = await Ticket.getAllAsUser(params);
            break;
        default:
            params.forSupport = forSupport;
            data = await Ticket.getAll(params);
        }
        data.sort = params;
        dispatch({ type : TICKET_LIST, data });
        dispatch(setSection(TICKET));
    };
}

/**
 * Fetches a single Ticket
 * @param {Number} ticketID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTicket(ticketID) {
    return async (dispatch) => {
        const data = await Ticket.getOne({ ticketID });
        dispatch({ type : TICKET_ELEM, data });
    };
}

/**
 * Fetches the Ticket Create data
 * @param {Boolean=} forSupport
 * @param {Boolean=} use
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTicketCreate(forSupport, use) {
    return async (dispatch) => {
        const data = await Ticket.getCreateData({ forSupport, use });
        dispatch({ type : TICKET_CREATE, data });
    };
}

/**
 * Fetches the Ticket Assign data
 * @param {Number}   ticketID
 * @param {Boolean=} forSupport
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTicketAssign(ticketID, forSupport) {
    return async (dispatch) => {
        const data = await Ticket.getAssignData({ ticketID, forSupport });
        dispatch({ type : TICKET_ASSIGN, data });
    };
}



/**
 * Creates a Ticket
 * @param {Object} data
 * @returns {(dispatch: Function) => Void}
 */
export function createTicket(data) {
    return () => Ticket.create(data);
}

/**
 * Edits a Ticket
 * @param {Object} data
 * @returns {() => Void}
 */
export function editTicket(data) {
    return () => Ticket.edit(data);
}

/**
 * Deletes a Ticket
 * @param {Number}   ticketID
 * @param {Boolean=} forSupport
 * @returns {() => Void}
 */
export function deleteTicket(ticketID, forSupport) {
    return () => Ticket.delete({ ticketID, forSupport });
}



/**
 * Assigns a Ticket
 * @param {Object} data
 * @returns {() => Void}
 */
export function assignTicket(data) {
    return () => Ticket.assign(data);
}

/**
 * Opens a Ticket
 * @param {Number}   ticketID
 * @param {Boolean=} forSupport
 * @returns {() => Void}
 */
export function openTicket(ticketID, forSupport) {
    return () => Ticket.open({ ticketID, forSupport });
}

/**
 * Closes a Ticket
 * @param {Number}   ticketID
 * @param {Boolean=} forSupport
 * @returns {() => Void}
 */
export function closeTicket(ticketID, forSupport) {
    return () => Ticket.close({ ticketID, forSupport });
}

/**
 * Mutes a Ticket
 * @param {Number}   ticketID
 * @param {Boolean=} forSupport
 * @returns {() => Void}
 */
export function muteTicket(ticketID, forSupport) {
    return () => Ticket.mute({ ticketID, forSupport });
}

/**
 * Unmutes a Ticket
 * @param {Number}   ticketID
 * @param {Boolean=} forSupport
 * @returns {() => Void}
 */
export function unmuteTicket(ticketID, forSupport) {
    return () => Ticket.unmute({ ticketID, forSupport });
}
