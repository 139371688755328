import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchProfile }     from "Actions/Profile/ProfileActions";

// Components
import SettingsForm         from "./SettingsForm";
import SettingsAvatar       from "./SettingsAvatar";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";

// Styles
import "Styles/Components/App/Settings.css";



/**
 * The Settings Page
 */
class SettingsPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchProfile();
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { route, loading } = this.props;

        return <Main>
            <Header message="SETTINGS_NAME" icon="settings" route={route} />
            <Content isLoading={loading}>
                <div className="settings-container">
                    <SettingsForm />
                    <SettingsAvatar />
                </div>
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        route   : PropTypes.string.isRequired,
        loading : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
     static mapStateToProps(state) {
        return {
            loading : state.profile.loading,
        };
    }
}

export default connect(SettingsPage.mapStateToProps, {
    fetchProfile,
})(SettingsPage);
