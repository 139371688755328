import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchCampaign }    from "Actions/Admin/Campaign/CampaignActions";

// Sections
import UserList             from "./UserList";
import ContentPage          from "./ContentPage";

// Components
import CampaignDetails      from "./CampaignDetails";
import SecondaryNav         from "Components/Utils/Navigation/SecondaryNav";
import AdminRoute           from "Components/Utils/Route/AdminRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Campaign Page
 */
class CampaignPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchCampaign(this.props.params.campaignID, true);
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.params.campaignID !== prevProps.params.campaignID) {
            this.props.fetchCampaign(this.props.params.campaignID, true);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, from, params, match, route } = this.props;
        const { path, url                        } = match;

        return <>
            <SecondaryNav
                message={elem.subject}
                fallback="CAMPAIGNS_SINGULAR"
                href={from}
                path={route}
                baseUrl={url}
            >
                <NavigationItem message="USERS_NAME"        url="USERS"   icon="user"  />
                <NavigationItem message="CAMPAIGNS_CONTENT" url="CONTENT" icon="media" />
            </SecondaryNav>

            <SwitchRoute type="CAMPAIGN" path={path} baseUrl={url} withDetails>
                <AdminRoute url="USERS"   component={UserList}    />
                <AdminRoute url="CONTENT" component={ContentPage} />
            </SwitchRoute>

            <CampaignDetails campaignID={params.campaignID} />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCampaign : PropTypes.func.isRequired,
        elem          : PropTypes.object.isRequired,
        from          : PropTypes.string.isRequired,
        params        : PropTypes.object.isRequired,
        match         : PropTypes.object.isRequired,
        route         : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem : state.campaign.elem,
        };
    }
}

export default connect(CampaignPage.mapStateToProps, {
    fetchCampaign,
})(CampaignPage);
