import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";

// Dialogs
import ConversationEdit     from "./ConversationEdit";
import ConversationProgram  from "./ConversationProgram";
import ConversationReport   from "./ConversationReport";
import ConversationCancel   from "./ConversationCancel";

// Components
import Dialog               from "dashboard/dist/Components/Dialog";
import DialogHeader         from "dashboard/dist/Components/DialogHeader";
import DialogBody           from "dashboard/dist/Components/DialogBody";
import DialogFooter         from "dashboard/dist/Components/DialogFooter";
import DialogAction         from "dashboard/dist/Components/DialogAction";
import AccordionList        from "dashboard/dist/Components/AccordionList";
import AccordionItem        from "dashboard/dist/Components/AccordionItem";
import Columns              from "dashboard/dist/Components/Columns";
import ViewField            from "dashboard/dist/Components/ViewField";

// Actions
import {
    fetchConversation,
} from "Actions/Admin/Conversation/ConversationActions";



/**
 * The Conversation View Dialog
 */
class ConversationView extends React.Component {
    // The Current State
    state = {
        action  : Action.get(),
        loading : false,
        update  : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchConversation } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchConversation(elemID);
            this.setState({ loading : true, update : false });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false, update : false });
        }
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @param {Boolean} update
     * @returns {Promise}
     */
    endAction = async (update) => {
        if (update !== undefined) {
            await this.props.fetchConversation(this.props.elemID);
            this.setState({ action : Action.get(), update });
        } else {
            this.setState({ action : Action.get() });
        }
    }

    /**
     * Edits the Conversation
     * @returns {Void}
     */
    editElem = () => {
        this.endAction(true);
    }

    /**
     * Handles de Dialog Closing
     * @returns {Void}
     */
    handleClose = () => {
        this.props.onClose(this.state.update);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, questions, open, strechID } = this.props;
        const { action, loading                 } = this.state;

        return <>
            <Dialog open={open} onClose={this.handleClose} isLoading={loading}>
                <DialogHeader message="CONVERSATIONS_VIEW_TITLE" icon="conversation" />
                <DialogBody withSpacing>
                    <Columns>
                        <ViewField label="STUDENTS_SINGULAR"     value={elem.studentName} />
                        <ViewField label="CONVERSATIONS_COACHEE" value={elem.coacheeName} />
                        <ViewField label="COACHES_SINGULAR"      value={elem.coachString} />

                        <ViewField
                            isHidden={!!strechID || !elem.strechID}
                            className="columns-double"
                            label="CONVERSATIONS_STRECH"
                            value={elem.strechName}
                        />
                        <ViewField
                            className={!elem.mode ? "columns-double" : ""}
                            label="SPECIALITIES_SHORT"
                            value={elem.specialityName}
                        />
                        <ViewField
                            label="GENERAL_MODE"
                            value={elem.modeName}
                        />
                    </Columns>

                    <ViewField
                        isHidden={!elem.isPresential}
                        label="LOCATIONS_SINGULAR"
                        value={elem.locationString}
                    />
                    <ViewField
                        isHidden={!elem.hasStream}
                        label="GENERAL_STREAM_LINK"
                        value={elem.streamLink}
                    />
                    <ViewField
                        isHidden={!elem.hasStream || !elem.streamPassword}
                        label="GENERAL_STREAM_PASSWORD"
                        value={elem.streamPassword}
                    />

                    <ViewField
                        label="GENERAL_DATE"
                        value={elem.timeString}
                    />
                    <ViewField
                        isHidden={!elem.message || (!elem.isRequested && !elem.isAssigned)}
                        label="CONVERSATIONS_MESSAGE"
                        value={elem.message}
                    />
                    <ViewField
                        isHidden={!elem.observations || !elem.canView}
                        label="GENERAL_OBSERVATIONS"
                        value={elem.observations}
                    />
                    <ViewField
                        isHidden={!elem.isCancelled}
                        label="GENERAL_STATUS"
                        value={elem.cancelledString}
                    />
                    <ViewField
                        isHidden={!elem.canViewReport}
                        label="GENERAL_STATUS"
                        value={elem.reportString}
                    />

                    {elem.canViewReport && <AccordionList>
                        {questions.map((question, index) => <AccordionItem
                            key={index}
                            icon="checkedbox"
                            number={index + 1}
                            message={question.question}
                        >
                            <ViewField label="ANSWERS_SINGULAR" value={question.answer} />
                        </AccordionItem>)}
                    </AccordionList>}
                </DialogBody>
                <DialogFooter onAction={this.startAction} cancel="GENERAL_CLOSE">
                    <DialogAction action="EDIT"    isHidden={!elem.canEdit}    />
                    <DialogAction action="PROGRAM" isHidden={!elem.canProgram} />
                    <DialogAction action="REPORT"  isHidden={!elem.canReport}  />
                    <DialogAction action="CANCEL"  isHidden={!elem.canCancel}  />
                </DialogFooter>
            </Dialog>

            <ConversationEdit
                open={action.isCE}
                elemID={elem.conversationID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <ConversationProgram
                open={action.isProgram}
                elemID={elem.conversationID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <ConversationReport
                open={action.isReport}
                elemID={elem.conversationID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <ConversationCancel
                open={action.isCancel}
                elemID={elem.conversationID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchConversation : PropTypes.func.isRequired,
        open              : PropTypes.bool.isRequired,
        onClose           : PropTypes.func.isRequired,
        edition           : PropTypes.number.isRequired,
        elem              : PropTypes.object.isRequired,
        questions         : PropTypes.array.isRequired,
        elemID            : PropTypes.number,
        strechID          : PropTypes.number,
        locationID        : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.conversation.edition,
            elem      : state.conversation.elem,
            questions : state.conversation.questions,
        };
    }
}

export default connect(ConversationView.mapStateToProps, {
    fetchConversation,
})(ConversationView);
