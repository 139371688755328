import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { TicketType }       from "Utils/API";

// Types
import {
    TICKET_TYPE, TICKET_TYPE_LOADING,
    TICKET_TYPE_LIST, TICKET_TYPE_ELEM, TICKET_TYPE_CREATE,
} from "Utils/Types";



/**
 * Fetches the Ticket Type List
 * @param {Object=}  params
 * @param {Boolean=} forSupport
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTicketTypes(params = {}, forSupport = false) {
    return async (dispatch) => {
        dispatch({ type : TICKET_TYPE_LOADING });
        Params.unset(params);
        params.forSupport = forSupport;
        const data = await TicketType.getAll(params);
        data.sort = params;
        dispatch({ type : TICKET_TYPE_LIST, data });
        dispatch(setSection(TICKET_TYPE));
    };
}

/**
 * Fetches a single Ticket Type
 * @param {Number}   ticketTypeID
 * @param {Boolean=} forSupport
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTicketType(ticketTypeID, forSupport = false) {
    return async (dispatch) => {
        const data = await TicketType.getOne({ ticketTypeID, forSupport });
        dispatch({ type : TICKET_TYPE_ELEM, data });
    };
}

/**
 * Fetches the Ticket Type Create data
 * @param {Boolean=} forSupport
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTicketTypeCreate(forSupport = false) {
    return async (dispatch) => {
        const data = await TicketType.getCreateData({ forSupport });
        dispatch({ type : TICKET_TYPE_CREATE, data });
    };
}



/**
 * Edits a Ticket Type
 * @param {Object} data
 * @returns {() => Void}
 */
export function editTicketType(data) {
    return () => TicketType.edit(data);
}

/**
 * Deletes a Ticket Type
 * @param {Number}   ticketTypeID
 * @param {Boolean=} forSupport
 * @returns {() => Void}
 */
export function deleteTicketType(ticketTypeID, forSupport) {
    return () => TicketType.delete({ ticketTypeID, forSupport });
}
