import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";
import InputField           from "dashboard/dist/Components/InputField";
import ViewField            from "dashboard/dist/Components/ViewField";
import Columns              from "dashboard/dist/Components/Columns";

// Actions
import {
    fetchPrices, editPrices,
} from "Actions/Admin/Coach/PriceActions";



/**
 * The Coach Prices Dialog
 */
class CoachPrices extends React.Component {
    // The Initial Data
    initialData = {
        credentialID : 0,
        prices       : {},
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, specialities, prices, fetchPrices } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchPrices(elemID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition && elemID) {
            const newPrices = {};
            for (const elem of specialities) {
                newPrices[elem.key] = "";
            }
            for (const elem of prices) {
                if (newPrices[elem.specialityID] === "") {
                    newPrices[elem.specialityID] = elem.price;
                }
            }
            data = { credentialID : elemID, prices : newPrices };
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {Number} specialityID
     * @param {Number} price
     * @returns {Void}
     */
    handleChange = (specialityID, price) => {
        const prices = this.state.data.prices;
        prices[specialityID] = price;
        this.setState({ data : { ...this.state.data, prices }, errors : {} });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editPrices({
                    credentialID : data.credentialID,
                    prices       : JSON.stringify(data.prices),
                });
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, specialities, name } = this.props;
        const { data, loading, errors             } = this.state;

        return <EditDialog
            open={open}
            icon="price"
            title="COACH_PRICES_NAME"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <DialogMessage html={NLS.format("COACH_PRICES_HELP", name)} />
            <Columns>
                {specialities.map((elem) => <React.Fragment key={elem.key}>
                    <ViewField value={elem.value} />
                    <InputField
                        type="number"
                        icon="money"
                        name={`coach-${elem.key}`}
                        label="GENERAL_PRICE"
                        value={data.prices[elem.key]}
                        onChange={(name, value) => this.handleChange(elem.key, value)}
                    />
                </React.Fragment>)}
            </Columns>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchPrices  : PropTypes.func.isRequired,
        editPrices   : PropTypes.func.isRequired,
        open         : PropTypes.bool.isRequired,
        onClose      : PropTypes.func.isRequired,
        onSubmit     : PropTypes.func.isRequired,
        edition      : PropTypes.number.isRequired,
        name         : PropTypes.string.isRequired,
        specialities : PropTypes.array.isRequired,
        prices       : PropTypes.array.isRequired,
        elemID       : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition      : state.coachPrices.edition,
            name         : state.coachPrices.name,
            specialities : state.coachPrices.specialities,
            prices       : state.coachPrices.prices,
        };
    }
}

export default connect(CoachPrices.mapStateToProps, {
    fetchPrices, editPrices,
})(CoachPrices);
