import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchAssignment, fetchAssignmentCreate, editAssignment,
} from "Actions/Admin/Lead/AssignmentActions";



/**
 * The Assignment Edit Dialog
 */
class AssignmentEdit extends React.Component {
    // The Initial Data
    initialData = {
        assignmentID : 0,
        interestID   : 0,
        credentialID : 0,
        status       : Status.getID("ACTIVE"),
        observations : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, fetchAssignment, fetchAssignmentCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchAssignment(elemID);
                loading = true;
            // Load edit data
            } else {
                fetchAssignmentCreate();
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editAssignment(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, interests, credentials } = this.props;
        const { data, loading, errors                         } = this.state;

        return <EditDialog
            open={open}
            icon="assignment"
            title={!!elemID ? "ASSIGNMENTS_EDIT_TITLE" : "ASSIGNMENTS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                type="select"
                name="interestID"
                label="ASSIGNMENTS_INTEREST"
                value={data.interestID}
                error={errors.interestID}
                onChange={this.handleChange}
                options={interests}
                withNone
                isRequired
            />
            <InputField
                type="select"
                name="credentialID"
                label="ASSIGNMENTS_CREDENTIAL"
                value={data.credentialID}
                error={errors.credentialID}
                onChange={this.handleChange}
                options={credentials}
                withNone
                isRequired
            />
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                value={data.status}
                error={errors.status}
                onChange={this.handleChange}
                options={Status.getFemSelect()}
                withNone
                isRequired
                shrinkLabel
            />
            <InputField
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                value={data.observations}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchAssignment       : PropTypes.func.isRequired,
        fetchAssignmentCreate : PropTypes.func.isRequired,
        editAssignment        : PropTypes.func.isRequired,
        open                  : PropTypes.bool.isRequired,
        onClose               : PropTypes.func.isRequired,
        onSubmit              : PropTypes.func.isRequired,
        edition               : PropTypes.number.isRequired,
        interests             : PropTypes.array.isRequired,
        credentials           : PropTypes.array.isRequired,
        elem                  : PropTypes.object.isRequired,
        elemID                : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition     : state.assignment.edition,
            interests   : state.assignment.interests,
            credentials : state.assignment.credentials,
            elem        : state.assignment.elem,
        };
    }
}

export default connect(AssignmentEdit.mapStateToProps, {
    fetchAssignment, fetchAssignmentCreate, editAssignment,
})(AssignmentEdit);
