import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { PaymentMethod }    from "Utils/API";

// Types
import {
    PAYMENT_METHOD, PAYMENT_METHOD_LOADING,
    PAYMENT_METHOD_LIST, PAYMENT_METHOD_ELEM, PAYMENT_METHOD_CREATE,
} from "Utils/Types";



/**
 * Fetches the Payment Methods List
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPaymentMethods(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : PAYMENT_METHOD_LOADING });
        }
        Params.unset(params);
        const data = await PaymentMethod.getAll(params);
        data.sort = params;
        dispatch({ type : PAYMENT_METHOD_LIST, data });
        dispatch(setSection(PAYMENT_METHOD));
    };
}

/**
 * Fetches a single Payment Method
 * @param {Number} paymentMethodID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPaymentMethod(paymentMethodID) {
    return async (dispatch) => {
        const data = await PaymentMethod.getOne({ paymentMethodID });
        dispatch({ type : PAYMENT_METHOD_ELEM, data });
    };
}

/**
 * Fetches the Payment Method Create data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPaymentMethodCreate() {
    return async (dispatch) => {
        const data = await PaymentMethod.getCreateData();
        dispatch({ type : PAYMENT_METHOD_CREATE, data });
    };
}



/**
 * Edits a Payment Method
 * @param {Object} data
 * @returns {() => Void}
 */
export function editPaymentMethod(data) {
    return () => PaymentMethod.edit(data);
}

/**
 * Deletes a Payment Method
 * @param {Number} paymentMethodID
 * @returns {() => Void}
 */
export function deletePaymentMethod(paymentMethodID) {
    return () => PaymentMethod.delete({ paymentMethodID });
}
