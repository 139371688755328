import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { CoachSpeciality }  from "Utils/API";

// Types
import {
    COACH_SPECIALITY, COACH_SPECIALITY_LOADING,
    COACH_SPECIALITY_LIST, COACH_SPECIALITY_ELEM, COACH_SPECIALITY_CREATE,
} from "Utils/Types";



/**
 * Fetches the Coach Speciality List
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchSpecialities(params = {}) {
    return async (dispatch) => {
        dispatch({ type : COACH_SPECIALITY_LOADING });
        Params.unset(params);
        const data = await CoachSpeciality.getAll(params);
        data.sort = params;
        dispatch({ type : COACH_SPECIALITY_LIST, data });
        dispatch(setSection(COACH_SPECIALITY));
    };
}

/**
 * Fetches a single Coach Speciality
 * @param {Number} specialityID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchSpeciality(specialityID) {
    return async (dispatch) => {
        const data = await CoachSpeciality.getOne({ specialityID });
        dispatch({ type : COACH_SPECIALITY_ELEM, data });
    };
}

/**
 * Fetches the Coach Speciality Create data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchSpecialityCreate() {
    return async (dispatch) => {
        const data = await CoachSpeciality.getCreateData();
        dispatch({ type : COACH_SPECIALITY_CREATE, data });
    };
}



/**
 * Edits a Coach Speciality
 * @param {Object} data
 * @returns {() => Void}
 */
export function editSpeciality(data) {
    return () => CoachSpeciality.edit(data);
}

/**
 * Deletes a Coach Speciality
 * @param {Number} specialityID
 * @returns {() => Void}
 */
export function deleteSpeciality(specialityID) {
    return () => CoachSpeciality.delete({ specialityID });
}
