import NLS                  from "dashboard/dist/Core/NLS";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Types
import {
    HOME_LOADING,
    HOME_LIST, HOME_WELCOME,
} from "Utils/Types";



// The initial State
const initialState = {
    loading     : false,
    error       : false,
    edition     : 0,
    canCreate   : false,
    canEdit     : false,
    canImport   : false,
    canExport   : false,

    advice      : null,
    activities  : [],
    notAnswered : [],
};



/**
 * Parses the Activities
 * @param {Object} data
 * @returns {Object}
 */
function parseActivities(data) {
    const result      = [];
    const addActivity = (type, id, date, text, data) => {
        const key = `${type}-${id}`;
        result.push({ key, type, id, date, text, data });
    };

    for (const elem of data.streches) {
        const dates     = Common.getStrechDates(elem);
        elem.timeString = dates.timeText;
        addActivity("strech", elem.strechID, dates.date, dates.homeText, elem);
    }

    for (const elem of data.classes) {
        const dates     = Common.getClassDates(elem);
        elem.timeString = dates.timeText;
        addActivity("class", elem.strechClassID, dates.date, dates.homeText, elem);
    }

    for (const elem of data.chats) {
        const dates     = Common.getChatDates(elem);
        elem.timeString = dates.timeText;
        addActivity("chat", elem.strechChatID, dates.date, dates.homeText, elem);
    }

    for (const elem of data.meetings) {
        const date = DateTime.formatDate(elem.time, "dashes");
        const day  = DateTime.formatString(elem.time);
        const text = NLS.format("HOME_MEETING", elem.groupName, day);

        elem.timeString     = DateTime.formatString(elem.time);
        elem.locationString = Common.combineLocation(elem);
        addActivity("meeting", elem.meetingID, date, text, elem);
    }

    for (const elem of data.conversations) {
        const date    = DateTime.formatDate(elem.time, "dashes");
        const day     = DateTime.formatString(elem.time);
        const coachee = elem.asCoach ? elem.userName : elem.coachName;
        const text    = NLS.format("HOME_CONVERSATION", coachee, day);

        elem.timeString     = DateTime.formatString(elem.time);
        elem.locationString = Common.combineLocation(elem);
        addActivity("conversation", elem.conversationID, date, text, elem);
    }

    result.sort((a, b) => a.data.time - b.data.time);
    return result.slice(0, 15);
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, HOME_LIST, HOME_WELCOME)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case HOME_LOADING:
        return {
            ...state,
            loading : true,
        };

    case HOME_LIST:
        return {
            ...state,
            loading     : false,
            error       : false,
            activities  : parseActivities(action.data),
            advice      : action.data.advice,
            notAnswered : action.data.notAnswered,
        };

    case HOME_WELCOME:
        return {
            ...state,
            loading     : false,
            error       : false,
            advice      : action.data.advice,
        };

    default:
        return state;
    }
};

export default reducer;
