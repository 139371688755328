import { setSection }       from "Actions/Core/CoreActions";
import { InvoiceReport }    from "Utils/API";

// Types
import {
    INVOICE_REPORT, INVOICE_REPORT_LOADING,
    INVOICE_REPORT_DATA, INVOICE_REPORT_GRAPH,
} from "Utils/Types";



/**
 * Fetches the Invoice Data
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchInvoiceData(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : INVOICE_REPORT_LOADING });
        }
        const data = await InvoiceReport.getData(params);
        dispatch({ type : INVOICE_REPORT_DATA, data });
        dispatch(setSection(INVOICE_REPORT));
    };
}

/**
 * Fetches the Invoice Graph
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchInvoiceGraph(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : INVOICE_REPORT_LOADING });
        }
        const data = await InvoiceReport.getGraph(params);
        dispatch({ type : INVOICE_REPORT_GRAPH, data });
        return data;
    };
}

/**
 * Fetches the Invoice Emails
 * @param {Object=} params
 * @returns {(dispatch) => Void}
 */
export function fetchInvoiceEmails(params = {}) {
    return async () => {
        return await InvoiceReport.getEmails(params);
    };
}
