import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Coach }            from "Utils/API";

// Types
import {
    COACH, COACH_LOADING, COACH_CHARGING,
    COACH_LIST, COACH_FILTER,
    COACH_ELEM, COACH_CREATE,
} from "Utils/Types";



/**
 * Fetches the Coach List
 * @param {Object=}  params
 * @param {Object=}  filters
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCoaches(params = {}, filters = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : COACH_LOADING });
        }
        Params.unset(params);
        const data = await Coach.getAll({ ...params, ...filters });

        data.filters = filters;
        data.sort    = params;
        dispatch({ type : COACH_LIST, data });
        dispatch(setSection(COACH));
    };
}

/**
 * Fetches the Coaches Filter data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCoachesFilter() {
    return async (dispatch) => {
        const data = await Coach.getFilterData();
        dispatch({ type : COACH_FILTER, data });
    };
}

/**
 * Fetches the Coaches Emails
 * @param {Object=} filters
 * @returns {(dispatch) => Void}
 */
export function fetchCoachesEmails(filters = {}) {
    return async () => {
        return await Coach.getEmails(filters);
    };
}

/**
 * Generates the export url from the given data
 * @param {Object} filters
 * @returns {String}
 */
export function exportCoaches(filters) {
    return Coach.export({ ...filters });
}



/**
 * Fetches a single Coach
 * @param {Number}   credentialID
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCoach(credentialID, withLoader = false) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : COACH_CHARGING });
        }
        const data = await Coach.getOne({ credentialID });
        dispatch({ type : COACH_ELEM, data });
    };
}

/**
 * Fetches the Coach Create data
 * @returns {(dispatch) => Void}
 */
export function fetchCoachCreate() {
    return async (dispatch) => {
        const data = await Coach.getCreateData();
        dispatch({ type : COACH_CREATE, data });
    };
}



/**
 * Edits/Creates a Coach
 * @param {Object} data
 * @returns {() => Void}
 */
export function editCoach(data) {
    return () => Coach.edit(data);
}

/**
 * Edits a Coach Level
 * @param {Number} credentialID
 * @returns {() => Void}
 */
export function editCoachLevel(credentialID) {
    return () => Coach.editLevel({ credentialID });
}

/**
 * Deletes a Coach
 * @param {Number} credentialID
 * @returns {() => Void}
 */
export function deleteCoach(credentialID) {
    return () => Coach.delete({ credentialID });
}
