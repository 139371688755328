import { setSection }       from "Actions/Core/CoreActions";
import { Home }             from "Utils/API";

// Types
import {
    CALENDAR, CALENDAR_LOADING,
    CALENDAR_LIST, CALENDAR_DAY,
} from "Utils/Types";



/**
 * Fetches the Calendar
 * @param {String} fromTime
 * @param {Number} toTime
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCalendar(fromTime, toTime) {
    return async (dispatch) => {
        dispatch({ type : CALENDAR_LOADING });
        const data = await Home.getCalendar({ fromTime, toTime });
        dispatch({ type : CALENDAR_LIST, data });
        dispatch(setSection(CALENDAR));
    };
}

/**
 * Fetches the Calendar
 * @param {Object} currentDay
 * @returns {(dispatch: Function) => Void}
 */
export function setCalendarDay(currentDay) {
    return (dispatch) => dispatch({ type : CALENDAR_DAY, currentDay });
}
