import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { closeMenu }        from "Actions/Core/CoreActions";

// Components
import Navigation           from "dashboard/dist/Components/Navigation";
import NavigationTitle      from "dashboard/dist/Components/NavigationTitle";
import NavigationBody       from "dashboard/dist/Components/NavigationBody";



/**
 * The Secondary Navigation
 */
class SecondaryNav extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { message, fallback, href, path, baseUrl, closeMenu, children } = this.props;

        return <Navigation variant="light" onClose={closeMenu}>
            <NavigationTitle
                message={message}
                fallback={fallback}
                href={href || "/"}
            />
            <NavigationBody path={path} baseUrl={baseUrl}>
                {children}
            </NavigationBody>
        </Navigation>;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        closeMenu : PropTypes.func.isRequired,
        message   : PropTypes.string,
        fallback  : PropTypes.string,
        href      : PropTypes.string,
        path      : PropTypes.string,
        baseUrl   : PropTypes.string,
        children  : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        href    : "/",
        baseUrl : "",
    }
}

export default connect(null, {
    closeMenu,
})(SecondaryNav);
