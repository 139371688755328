import NLS                  from "dashboard/dist/Core/NLS";
import Period               from "dashboard/dist/Utils/Period";

// The Graph Type Uses
const NONE          = 0;
const COURSES       = 1;
const PROGRAMS      = 2;
const STRECHES      = 3;
const TYPES         = 4;
const MODES         = 5;
const DUES          = 6;
const PAYMENTS      = 7;
const CONVERSATIONS = 8;
const FACILITATORS  = 9;
const COACHES       = 10;
const COACHEES      = 11;
const SPECIALITIES  = 12;
const GUIDES        = 13;

// The Graph Types For
const TYPES_FOR = {
    "INSCRIPTIONS" : [
        COURSES, PROGRAMS, STRECHES, TYPES, MODES,
        DUES, PAYMENTS, CONVERSATIONS, FACILITATORS,
    ],
    "CONVERSATIONS" : [
        COURSES, PROGRAMS, STRECHES, TYPES, MODES,
        COACHES, COACHEES, SPECIALITIES,
    ],
    "MEETINGS" : [
        COURSES, PROGRAMS, STRECHES, TYPES, MODES,
        COACHES,
    ],
    "GUIDES" : [
        COURSES, PROGRAMS, STRECHES, TYPES, MODES,
        COACHES, COACHEES, GUIDES,
    ],
    "POLLS" : [
        COURSES, PROGRAMS, STRECHES, TYPES, MODES,
        COACHEES,
    ],
    "INVOICES" : [
        COURSES, PROGRAMS, STRECHES, TYPES, MODES,
        COACHES,
    ],
};



/**
 * Handles the Filter State
 * @param {Object} state
 * @param {String} name
 * @param {String} value
 * @returns {Object}
 */
function handleFilter(state, name, value) {
    const result = { ...state.filters };

    switch (name) {
    case "courseID":
        result.courseID  = Number(value);
        result.programID = 0;
        result.strechID  = 0;
        break;
    case "programID":
        result.programID = Number(value);
        result.strechID  = 0;
        break;
    case "period":
        result.period   = Number(value);
        result.fromDate = Period.getFromDate(value);
        result.toDate   = Period.getToDate(value);
        break;
    case "coachName":
    case "coacheeName":
    case "fromDate":
    case "toDate":
        result[name] = value;
        break;
    default:
        result[name] = Number(value);
    }
    return result;
}

/**
 * Returns true if new filter data is required
 * @param {String} name
 * @returns {Boolean}
 */
function shouldFetchFilters(name) {
    return [ "status", "programType", "strechType", "mode", "paymentMethod", "courseID", "programID", "strechID" ].includes(name);
}

/**
 * Creates a Select of Types
 * @param {String} type
 * @returns {Object[]}
 */
function getSelect(type) {
    const result = [];
    if (TYPES_FOR[type]) {
        for (const key of TYPES_FOR[type]) {
            const value = NLS.get(`REPORTS_GRAPH_TYPE_${key}`);
            result.push({ key, value });
        }
    }
    return result;
}

/**
 * When to hide the Filters
 * @param {Number} graphType
 * @returns {Object}
 */
function getHideFilters(graphType) {
    const type = Number(graphType);
    return {
        courses       : Boolean(type === COURSES),
        programs      : [ COURSES, PROGRAMS, TYPES ].includes(type),
        streches      : [ COURSES, PROGRAMS, STRECHES, TYPES ].includes(type),
        types         : Boolean(type === TYPES),
        modes         : Boolean(type === MODES),
        dues          : Boolean(type === DUES),
        payments      : Boolean(type === PAYMENTS),
        conversations : Boolean(type === CONVERSATIONS),
        facilitators  : Boolean(type === FACILITATORS),
        coaches       : Boolean(type === COACHES),
        students      : Boolean(type === COACHEES),
        specialities  : Boolean(type === SPECIALITIES),
        guides        : [ COURSES, GUIDES ].includes(type),
    }
}

/**
 * Returns the Name for the Report
 * @param {Number} graphType
 * @param {String} name
 * @returns {String}
 */
function getReportName(graphType, name) {
    switch (graphType) {
    case TYPES:
        return NLS.get("TYPES", name);
    case MODES:
        return NLS.get("MODES", name);
    case DUES:
        return NLS.pluralize("REPORTS_DUES", name);
    case CONVERSATIONS:
        return `${name}%`;
    default:
        return name || NLS.get("GENERAL_NONE");
    }
}



// The public API
const API = {
    NONE,
    COURSES,
    PROGRAMS,
    STRECHES,
    TYPES,
    MODES,
    DUES,
    PAYMENTS,
    CONVERSATIONS,
    FACILITATORS,
    COACHES,
    COACHEES,
    SPECIALITIES,
    GUIDES,

    handleFilter,
    shouldFetchFilters,
    getSelect,
    getHideFilters,
    getReportName,
};

export default API;
