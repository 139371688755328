import Access               from "dashboard/dist/Core/Access";
import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import HistoryAction        from "Utils/Entity/HistoryAction";
import Common               from "Utils/Common";
import StatusData           from "Utils/Status";

// Types
import {
    LEAD_LOADING, LEAD_CHARGING,
    LEAD_LIST, LEAD_FILTER,
    LEAD_ELEM, LEAD_CREATE, LEAD_EDIT, LEAD_CLOSE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading      : false,
    charging     : false,
    error        : false,
    edition      : 0,
    canCreate    : false,
    canEdit      : false,
    canImport    : false,
    canExport    : false,
    canFilter    : false,
    list         : [],
    total        : 0,
    elem         : {},
    history      : [],
    groupinterest   : [],
    origins      : [],
    interests    : [],
    admins       : [],
    credentials  : [],
    reasons      : [],
    inscriptions : [],
    filters      : {
        status : StatusData.RECEIVED,
    },
    sort         : {
        orderBy  : "lastReply",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    const mult = Status.is("RECEIVED", elem.status) ? 1 : 5;

    elem.createdDate       = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime   = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.createdDateString = DateTime.formatString(elem.createdTime);
    elem.lastReplyString   = DateTime.formatString(elem.lastReply);
    elem.lastReplyColor    = DateTime.getExpiredColor(elem.lastReply, 24 * mult, 48 * mult);
    elem.statusName        = Status.getName(elem.status);
    elem.statusColor       = Status.getColor(elem.status);
    elem.statusClass       = Status.getTextClass(elem.status);
    elem.historyTitle      = NLS.format("LEADS_HISTORY_TITLE", elem.credentialName, elem.createdDateString);
    elem.title             = NLS.format("LEADS_TITLE", elem.credentialName);
    elem.isClosed          = Status.is("CLOSED", elem.status);
    elem.levelName         = Access.getName(elem.credentialLevel);
    elem.isStudent         = Access.is("STUDENT", elem.credentialLevel);
    elem.phoneString       = Common.combinePhone(elem, true);
    elem.descriptionShort  = elem.description.substr(0, 30);
    return elem;
}

/**
 * Parses the History
 * @param {Object[]} list
 * @returns {Object[]}
 */
 function parseHistory(list) {
    const result = [];
    Utils.parseList(list, (elem) => {
        elem.createdDate = DateTime.formatString(elem.createdTime);
        elem.canClick    = false;

        const creator = elem.createdUser ? elem.createdName : NLS.get("LEADS_SYSTEM");
        let   key    = `LEADS_HISTORY_${elem.action}`;
        let   reason = "";

        switch (elem.action) {
        case HistoryAction.CREATE:
            break;
        case HistoryAction.EDIT:
            elem.icon     = "edit";
            elem.content  = NLS.format(key, creator, elem.originName, elem.interestName, elem.strechName);
            elem.canClick = true;
            break;
        case HistoryAction.ASSIGN:
            key         += elem.createdUser === elem.credentialID ? "2" : "1";
            elem.icon    = "assign";
            elem.content = NLS.format(key, creator, elem.credentialName);
            reason       = elem.message;
            break;
        case HistoryAction.CLOSE:
            elem.icon    = "status";
            elem.content = NLS.format(key, creator);
            reason       = elem.inscriptionID ? NLS.format("LEADS_HISTORY_CLOSE", elem.reasonName, elem.strechName) : elem.reasonName;
            break;
        case HistoryAction.OPEN:
            elem.icon    = "status";
            elem.content = NLS.format(key, creator);
            break;
        case HistoryAction.MESSAGE:
            elem.isMessage = true;
            elem.title     = NLS.format("LEADS_HISTORY_REPLY", creator, elem.createdDate);
            break;
        default:
        }

        elem.detail = "";
        if (reason) {
            elem.detail = NLS.format("LEADS_HISTORY_REASON", reason);
        }

        if (elem.icon || elem.isMessage) {
            result.push(elem);
        }
    });
    return result;
}




/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, LEAD_LIST, LEAD_FILTER, LEAD_ELEM, LEAD_CREATE, LEAD_EDIT, LEAD_CLOSE)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case LEAD_LOADING:
        return {
            ...state,
            loading      : true,
        };
    case LEAD_CHARGING:
        return {
            ...state,
            charging     : true,
        };

    case LEAD_LIST:
        return {
            ...state,
            loading      : false,
            error        : false,
            canCreate    : action.data.canCreate,
            canEdit      : action.data.canEdit,
            canImport    : action.data.canImport,
            canExport    : action.data.canExport,
            canFilter    : action.data.canFilter,
            list         : parseList(action.data.list),
            total        : action.data.total,
            filters      : action.data.filters,
            sort         : action.data.sort,
        };

    case LEAD_FILTER:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            origins      : action.data.origins,
            groupinterest   : action.data.groupinterest,
            interests    : action.data.interests,
            admins       : action.data.admins,
        };

    case LEAD_ELEM:
        return {
            ...state,
            charging     : false,
            error        : false,
            edition      : state.edition + 1,
            canEdit      : action.data.canEdit,
            elem         : parseElem(action.data.elem),
            history      : parseHistory(action.data.history),
        };

    case LEAD_CREATE:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            origins      : action.data.origins,
            interests    : action.data.interests,
            admins       : action.data.admins,
        };

    case LEAD_EDIT:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            canEdit      : action.data.canEdit,
            origins      : action.data.origins,
            interests    : action.data.interests,
            admins       : action.data.admins,
            credentials  : action.data.credentials,
            elem         : parseElem(action.data.elem),
        };

    case LEAD_CLOSE:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            canEdit      : action.data.canEdit,
            reasons      : action.data.reasons,
            inscriptions : action.data.inscriptions,
        };


    default:
        return state;
    }
};

export default reducer;
