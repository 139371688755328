import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Student }          from "Utils/API";
import Action               from "dashboard/dist/Core/Action";
import Status               from "dashboard/dist/Core/Status";
import NLS                  from "dashboard/dist/Core/NLS";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import StudentSelect        from "./StudentSelect";
import EditDialog           from "dashboard/dist/Components/EditDialog";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchGroup, fetchGroupCreate, editGroup,
} from "Actions/Admin/Strech/GroupActions";



/**
 * The Group Edit Dialog
 */
class GroupEdit extends React.Component {
    // The Initial Data
    initialData = {
        groupID      : 0,
        strechID     : 0,
        credentialID : 0,
        userID       : 0,
        userName     : "",
        name         : "",
        status       : Status.getID("DRAFT"),
        observations : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
        members : [],
        action  : Action.get(),
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, strechID, fetchGroup, fetchGroupCreate } = this.props;
        let loading = false;
        let data    = null;
        let members = [];

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchGroup(elemID);
                loading = true;
            // Load edit data
            } else {
                fetchGroupCreate(strechID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
                for (const member of elem.members) {
                    members.push({
                        credentialID   : member.credentialID,
                        credentialName : member.credentialName,
                    });
                }
            } else {
                data = { ...this.initialData, strechID };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {}, members });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Suggest
     * @param {String} idName
     * @param {Number} credentialID
     * @param {String} valName
     * @param {String} credentialName
     * @returns {Void}
     */
    handleSuggest = (idName, credentialID, valName, credentialName) => {
        const members = this.state.members;
        if (!members.find((elem) => elem.credentialID === credentialID)) {
            members.push({ credentialID, credentialName });
        }
        this.setState({
            data : { ...this.state.data, userName : "", userID : 0 },
            members,
        });
    }

    /**
     * Handles the Member Remove
     * @param {Number} credentialID
     * @returns {Void}
     */
    handleRemove = (credentialID) => {
        const members = this.state.members;
        const index   = members.findIndex((elem) => elem.credentialID === credentialID);
        if (index > -1) {
            members.splice(index, 1);
            this.setState({ members });
        }
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, members, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const params = { ...data, members : JSON.stringify(members) };
                await this.props.editGroup(params);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Starts an Action
     * @returns {Void}
     */
    startAction = () => {
        this.setState({ action : Action.get("SELECT") });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.setState({ action : Action.get() });
    }

    /**
     * Handles the Select Submit
     * @param {Object[]} members
     * @returns {Void}
     */
    selectMembers = (members) => {
        this.endAction();
        if (members) {
            this.setState({ members });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { strechID, elemID, open, onClose, coaches } = this.props;
        const { data, members, loading, errors, action   } = this.state;

        return <>
            <EditDialog
                open={open}
                icon="group"
                title={!!elemID ? "GROUPS_EDIT_TITLE" : "GROUPS_CREATE_TITLE"}
                error={errors.form}
                onSubmit={this.handleSubmit}
                onClose={onClose}
                isLoading={loading}
            >
                <InputField
                    name="name"
                    label="GROUPS_ITS_NAME"
                    value={data.name}
                    error={errors.name}
                    onChange={this.handleChange}
                    isRequired
                />
                <InputField
                    type="select"
                    name="credentialID"
                    label="COACHES_SINGULAR"
                    value={data.credentialID}
                    error={errors.credentialID}
                    onChange={this.handleChange}
                    options={coaches}
                    withNone
                    isRequired
                />
                <InputField
                    name="userName"
                    label="GROUPS_SEARCH_MEMBER"
                    suggestID="userID"
                    suggestFetch={Student.search}
                    suggestParams={{ strechID }}
                    suggestNone="STUDENTS_NONE_AVAILABLE"
                    button="GENERAL_SELECT"
                    onClick={this.startAction}
                    value={data.userName}
                    error={errors.userID}
                    onChange={this.handleChange}
                    onSuggest={this.handleSuggest}
                />
                <DialogMessage isHidden={!members.length}>
                    <h3>{NLS.get("GROUPS_MEMBERS")}</h3>
                    {members.map((elem) => <InputField
                        key={elem.credentialID}
                        type="checkbox"
                        name="members[]"
                        label={elem.credentialName}
                        value={elem.credentialID}
                        error={errors[`members${elem.credentialID}`]}
                        onChange={() => this.handleRemove(elem.credentialID)}
                        smallMargin
                    />)}
                </DialogMessage>
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    error={errors.status}
                    onChange={this.handleChange}
                    options={Status.getSelect("GROUP")}
                    withNone
                    isRequired
                    shrinkLabel
                />
                <InputField
                    type="textarea"
                    name="observations"
                    label="GENERAL_OBSERVATIONS"
                    value={data.observations}
                    onChange={this.handleChange}
                />
            </EditDialog>

            <StudentSelect
                open={action.isSelect}
                groupID={data.groupID}
                strechID={data.strechID}
                selected={members}
                onSubmit={this.selectMembers}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchGroup       : PropTypes.func.isRequired,
        fetchGroupCreate : PropTypes.func.isRequired,
        editGroup        : PropTypes.func.isRequired,
        open             : PropTypes.bool.isRequired,
        onClose          : PropTypes.func.isRequired,
        onSubmit         : PropTypes.func.isRequired,
        edition          : PropTypes.number.isRequired,
        coaches          : PropTypes.array.isRequired,
        elem             : PropTypes.object.isRequired,
        elemID           : PropTypes.number,
        strechID         : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.strechGroup.edition,
            coaches : state.strechGroup.coaches,
            elem    : state.strechGroup.elem,
        };
    }
}

export default connect(GroupEdit.mapStateToProps, {
    fetchGroup, fetchGroupCreate, editGroup,
})(GroupEdit);
