import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Common               from "Utils/Common";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";
import Utils                from "dashboard/dist/Utils/Utils";

// Dialogs
import CampaignSend         from "Components/Admin/Campaign/CampaignSend";
import InscriptionEdit      from "Components/Admin/Strech/Inscription/InscriptionEdit";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";

// Actions
import {
    fetchInscriptions, fetchInscriptionEmails, cancelInscription,
} from "Actions/Admin/Strech/InscriptionActions";



/**
 * The Inscription List
 */
class InscriptionList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
        users  : [],
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, withLoader) => {
        const { type, elemID, data } = this.props;
        this.props.fetchInscriptions(type, elemID, params || data.sort, withLoader);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Promise}
     */
    startAction = async (action, elemID) => {
        const { type, data, fetchInscriptionEmails } = this.props;
        const inscription = Utils.getValue(data.list, "inscriptionID", elemID);
        if (action.isView) {
            Href.goto("STRECHES", inscription.strechID, "INSCRIPTIONS", "REQUESTS");
        } else if (action.isStudent) {
            Href.goto(Common.getAccessBaseUrl(inscription), inscription.credentialID);
        } else {
            let users = [];
            if (action.isCampaign) {
                users = await fetchInscriptionEmails(type);
            }
            this.setState({ action, elemID, users });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch(null, false);
    }

    /**
     * Handles the Cancel Submit
     * @param {Number} reasonID
     * @returns {Promise}
     */
    cancelElem = async (reasonID) => {
        const elemID = this.state.elemID;
        this.endAction();
        await this.props.cancelInscription(elemID, reasonID);
        this.fetch(null, false);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID, users               } = this.state;
        const { data, params, route, withDetails    } = this.props;
        const { canEdit, list, total, sort, loading } = data;

        return <Main withDetails={withDetails}>
            <Header message="INSCRIPTIONS_REQUESTS" icon="inscription" route={route}>
                <ActionList data={data} onAction={this.startAction} withCampaign />
            </Header>
            <Content isLoading={loading}>
                <Table fetch={this.fetch} sort={sort} none="INSCRIPTIONS_NONE_REQUESTS">
                    <TableHead>
                        <TableHeader field="strechName"    message="STRECHES_SINGULAR"        grow="1.5" />
                        <TableHeader field="firstName"     message="STUDENTS_SINGULAR"        grow="1.5" />
                        <TableHeader field="pricePrice"    message="GENERAL_PRICE"            />
                        <TableHeader field="paymentID"     message="PAYMENT_METHODS_SINGULAR" align="center" />
                        <TableHeader field="conversations" message="CONVERSATIONS_NAME"       maxWidth="120" align="center" />
                        <TableHeader field="createdTime"   message="GENERAL_CREATED_FEM"      maxWidth="90"  hideMobile />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.inscriptionID} elemID={elem.inscriptionID}>
                            <TableCell message={elem.strechName}        />
                            <TableCell message={elem.credentialName}    />
                            <TableCell message={elem.priceText}         />
                            <TableCell message={elem.paymentName}       />
                            <TableCell message={elem.conversationsText} />
                            <TableCell message={elem.createdDate}       />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="EDIT"     message="INSCRIPTIONS_EDIT_TITLE"     />
                        <TableAction action="ACTIVATE" message="INSCRIPTIONS_ACTIVATE_TITLE" />
                        <TableAction action="VIEW"     message="INSCRIPTIONS_VIEW_TITLE"     />
                        <TableAction action="STUDENT"  message="STUDENTS_VIEW_TITLE"         />
                    </TableActionList>
                </Table>
            </Content>

            <CampaignSend
                open={action.isCampaign}
                users={users}
                onClose={this.endAction}
                onSubmit={this.endAction}
            />
            <InscriptionEdit
                open={Boolean(action.isVCE || action.isActivate)}
                elemID={elemID}
                strechID={params.strechID}
                isActivate={action.isActivate}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchInscriptions      : PropTypes.func.isRequired,
        fetchInscriptionEmails : PropTypes.func.isRequired,
        cancelInscription      : PropTypes.func.isRequired,
        data                   : PropTypes.object.isRequired,
        type                   : PropTypes.string.isRequired,
        params                 : PropTypes.object.isRequired,
        route                  : PropTypes.string.isRequired,
        withDetails            : PropTypes.bool.isRequired,
        elemID                 : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.inscription,
        };
    }
}

export default connect(InscriptionList.mapStateToProps, {
    fetchInscriptions, fetchInscriptionEmails, cancelInscription,
})(InscriptionList);
