import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { ProgramPrice }     from "Utils/API";

// Types
import {
    PROGRAM_PRICE, PROGRAM_PRICE_LOADING,
    PROGRAM_PRICE_LIST, PROGRAM_PRICE_ELEM,
} from "Utils/Types";



/**
 * Fetches the Program Price List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPrices(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : PROGRAM_PRICE_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "PROGRAM":
            params.programID = elemID;
            data = await ProgramPrice.getAllForProgram(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : PROGRAM_PRICE_LIST, data });
        dispatch(setSection(PROGRAM_PRICE));
    };
}

/**
 * Fetches a single Program Price
 * @param {Number} priceID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPrice(priceID) {
    return async (dispatch) => {
        const data = await ProgramPrice.getOne({ priceID });
        dispatch({ type : PROGRAM_PRICE_ELEM, data });
    };
}



/**
 * Edits/Creates a Program Price
 * @param {Object} data
 * @returns {() => Void}
 */
export function editPrice(data) {
    return () => ProgramPrice.edit(data);
}

/**
 * Deletes a Program Price
 * @param {Number} priceID
 * @returns {() => Void}
 */
export function deletePrice(priceID) {
    return () => ProgramPrice.delete({ priceID });
}
