import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchClass }       from "Actions/Admin/Strech/ClassActions";
import NLS                  from "dashboard/dist/Core/NLS";
import Action               from "dashboard/dist/Core/Action";

// Sections
import ClassDetails         from "Components/Admin/Strech/Class/ClassDetails";
import ClassEdit            from "Components/Admin/Strech/Class/ClassEdit";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import InfoList             from "dashboard/dist/Components/InfoList";
import InfoItem             from "dashboard/dist/Components/InfoItem";
import InfoAction           from "dashboard/dist/Components/InfoAction";
import Slider               from "dashboard/dist/Components/Slider";
import MultiLine            from "dashboard/dist/Components/MultiLine";

// Styles
import "Styles/Components/App/Class.css";



/**
 * The Strech Class Content
 */
class ClassContent extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const { elemID, fetchClass } = this.props;
        if (elemID) {
            fetchClass(elemID, true);
        }
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { elemID, fetchClass } = this.props;
        if (elemID !== prevProps.elemID) {
            fetchClass(elemID, true);
        }
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.props.fetchClass(this.props.elemID, true);
        this.endAction();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isAdmin, charging, canEdit, elem, materials, guides, guideID, route } = this.props;
        const { action                                                              } = this.state;

        return <>
            <Main withDetails>
                <Header message={elem.name || "CLASSES_SINGULAR"} icon="class" route={route} />
                <Content isLoading={charging}>
                    <InfoList className="class-info" onAction={this.startAction}>
                        <InfoItem label="CLASSES_COACH" message={elem.credentialName} />
                        <InfoItem label="CLASSES_DATE"  message={isAdmin ? elem.timeReal : elem.timeString}     />
                        <InfoAction action="EDIT" isHidden={!canEdit} />
                    </InfoList>
                    {elem.videosCount > 0 && <div className="class-video">
                        <div className="class-video-content">
                            <Slider
                                variant="video"
                                data={elem.videosSources}
                                withDots
                            />
                        </div>
                    </div>}
                    {elem.description && <div className="class-description">
                        <h3>{NLS.get("CLASSES_DESCRIPTION")}</h3>
                        <MultiLine variant="p">{elem.description}</MultiLine>
                    </div>}
                </Content>
            </Main>

            <ClassDetails
                isLoading={charging}
                elem={elem}
                materials={materials}
                guides={guides}
                guideID={guideID}
            />
            <ClassEdit
                open={action.isVCE}
                elemID={elem.strechClassID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchClass : PropTypes.func.isRequired,
        isAdmin    : PropTypes.bool.isRequired,
        canEdit    : PropTypes.bool.isRequired,
        charging   : PropTypes.bool.isRequired,
        elem       : PropTypes.object.isRequired,
        guides     : PropTypes.array.isRequired,
        materials  : PropTypes.array.isRequired,
        route      : PropTypes.string.isRequired,
        elemID     : PropTypes.number,
        guideID    : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAdmin   : state.auth.isAdmin,
            canEdit   : state.strechClass.canEdit,
            charging  : state.strechClass.charging,
            elem      : state.strechClass.elem,
            guides    : state.strechClass.guides,
            materials : state.strechClass.materials,
        };
    }
}

export default connect(ClassContent.mapStateToProps, {
    fetchClass,
})(ClassContent);
