// The App Actions
const actions = [
    // Ticket Actions
    {
        name    : "ASSIGN",
        icon    : "assign",
        message : "TICKETS_ASSIGN",
    },
    {
        name    : "OPEN",
        icon    : "status",
        message : "TICKETS_OPEN",
    },
    {
        name    : "CLOSE",
        icon    : "status",
        message : "GENERAL_CLOSE",
    },
    {
        name    : "MUTE",
        icon    : "mute",
        message : "TICKETS_MUTE",
    },
    {
        name    : "UNMUTE",
        icon    : "unmute",
        message : "TICKETS_UNMUTE",
    },


    // Strech Actions
    {
        name    : "STRECH",
        icon    : "strech",
    },
    {
        name    : "INSCRIPTION",
        icon    : "inscription",
    },
    {
        name    : "ACTIVATE",
        icon    : "check",
    },
    {
        name    : "MATERIAL",
        icon    : "material",
    },
    {
        name    : "GUIDE",
        icon    : "guide",
    },
    {
        name    : "DATE",
        icon    : "time",
    },
    {
        name    : "FEEDBACK",
        icon    : "add",
    },


    // Conversation Actions
    {
        name    : "REQUEST",
        icon    : "create",
        message : "GENERAL_REQUEST",
    },
    {
        name    : "UNREQUEST",
        icon    : "cancel",
        message : "GENERAL_CANCEL",
    },
    {
        name    : "PROGRAM",
        icon    : "time",
        message : "GENERAL_PROGRAM",
    },
    {
        name    : "REPORT",
        icon    : "complete",
        message : "GENERAL_REPORT",
    },
    {
        name    : "CANCEL",
        icon    : "cancel",
        message : "GENERAL_CANCEL",
    },


    // Invoice Actions
    {
        name    : "DOWNLOAD",
        icon    : "download",
        message : "GENERAL_DOWNLOAD",
    },
    {
        name    : "SEND",
        icon    : "send",
        message : "GENERAL_SEND",
    },
    {
        name    : "ATTACH",
        icon    : "attachment",
        message : "GENERAL_ATTACH",
    },
    {
        name    : "APPROVE",
        icon    : "check",
        message : "GENERAL_APPROVE",
    },
    {
        name    : "REJECT",
        icon    : "cancel",
        message : "GENERAL_REJECT",
    },
    {
        name    : "PAID",
        icon    : "money",
        message : "GENERAL_PAID",
    },


    // Calendar Actions
    {
        name    : "PREV",
        icon    : "prev",
        message : "GENERAL_PREV",
    },
    {
        name    : "NEXT",
        icon    : "next",
        message : "GENERAL_NEXT",
    },
    {
        name    : "TODAY",
        icon    : "today",
        message : "CALENDAR_TODAY",
    },
    {
        name    : "GOTO",
        icon    : "goto",
        message : "CALENDAR_GOTO",
    },


    // Enroll Actions
    {
        name    : "CENROLL",
        icon    : "chat",
        message : "GENERAL_CHAT",
    },
    {
        name    : "SENROLL",
        icon    : "strech",
        message : "GENERAL_STRECH",
    },
    {
        name    : "ENROLLED",
        icon    : "strech",
        message : "GENERAL_STRECH",
    },


    // User Actions
    {
        name    : "USER",
        icon    : "user",
    },
    {
        name    : "STUDENT",
        icon    : "student",
    },
    {
        name    : "COACH",
        icon    : "coach",
    },
    {
        name    : "GROUP",
        icon    : "group",
    },
    {
        name    : "COMPLETED",
        icon    : "check",
    },
    {
        name    : "PRICES",
        icon    : "price",
    },
    {
        name    : "ACCESS",
        icon    : "access",
    },
    {
        name    : "WHATSAPP",
        icon    : "whatsapp",
    },


    // Lead Actions
    {
        name    : "LEAD",
        icon    : "lead",
    },


    // Special Actions
    {
        name    : "URL",
        icon    : "link",
    },
    {
        name    : "RESOLVE",
        icon    : "check",
    },
    {
        name    : "ERROR",
        icon    : "error",
    },
];

export default actions;
