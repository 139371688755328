import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchAnswers }     from "Actions/Admin/Strech/AnswerActions";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import InputField           from "dashboard/dist/Components/InputField";
import NoneAvailable        from "dashboard/dist/Components/NoneAvailable";



/**
 * The Strech Answer View Dialog
 */
class AnswerView extends React.Component {
    // The Current State
    state = {
        loading : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, strechID, elemID, fetchAnswers } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchAnswers(strechID, elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { questions, answers, open, onClose } = this.props;
        const { loading                           } = this.state;

        const hasAnswers = questions.length > 0 && !Utils.isEmpty(answers);

        return <ViewDialog
            open={open}
            icon="question"
            title="ANSWERS_VIEW_TITLE"
            onClose={onClose}
            isLoading={loading}
            withSpacing
        >
            {!hasAnswers ? <NoneAvailable
                variant="dialog"
                message="ANSWERS_NONE_AVAILABLE"
            /> : questions.map((elem) => <div key={elem.id} className="question">
                <h3>{elem.question}</h3>
                <div className={`question-input question-${elem.type}`}>
                    <InputField
                        type={elem.type}
                        name={String(elem.id)}
                        value={answers[elem.id] || ""}
                        options={elem.select}
                        withCustom={elem.withOther}
                        isDisabled
                        withNone
                    />
                </div>
            </div>)}
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchAnswers : PropTypes.func.isRequired,
        open         : PropTypes.bool.isRequired,
        onClose      : PropTypes.func.isRequired,
        edition      : PropTypes.number.isRequired,
        questions    : PropTypes.array.isRequired,
        answers      : PropTypes.object.isRequired,
        elemID       : PropTypes.number,
        strechID     : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.strechAnswer.edition,
            questions : state.strechAnswer.questions,
            answers   : state.strechAnswer.answers,
        };
    }
}

export default connect(AnswerView.mapStateToProps, {
    fetchAnswers,
})(AnswerView);
