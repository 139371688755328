import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { StrechCoach }      from "Utils/API";

// Types
import {
    STRECH_COACH, STRECH_COACH_LOADING,
    STRECH_COACH_LIST, STRECH_COACH_ELEM, STRECH_COACH_CREATE,
} from "Utils/Types";



/**
 * Fetches the Coach List
 * @param {String=}  type
 * @param {Number=}  elemID
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCoaches(type = "", elemID = 0, params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : STRECH_COACH_LOADING });
        }
        Params.unset(params);
        let data = null;

        switch (type) {
        case "STRECH":
            params.strechID = elemID;
            data = await StrechCoach.getAllForStrech(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : STRECH_COACH_LIST, data });
        dispatch(setSection(STRECH_COACH));
    };
}

/**
 * Fetches the Inscriptions Emails
 * @param {String=} type
 * @param {Number=} elemID
 * @returns {(dispatch) => Void}
 */
export function fetchCoachesEmails(type = "", elemID = 0) {
    return async () => {
        const params = {};
        if (type === "STRECH") {
            params.strechID = elemID;
        }
        return await StrechCoach.getEmails(params);
    };
}

/**
 * Fetches a single Coach
 * @param {Number} coachID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCoach(coachID) {
    return async (dispatch) => {
        const data = await StrechCoach.getOne({ coachID });
        dispatch({ type : STRECH_COACH_ELEM, data });
    };
}

/**
 * Fetches the Coach Create data
 * @param {Number} strechID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCoachCreate(strechID) {
    return async (dispatch) => {
        const data = await StrechCoach.getCreateData({ strechID });
        dispatch({ type : STRECH_COACH_CREATE, data });
    };
}



/**
 * Edits/Creates a Coach
 * @param {Object} data
 * @returns {() => Void}
 */
export function editCoach(data) {
    return () => StrechCoach.edit(data);
}

/**
 * Deletes a Coach
 * @param {Number} coachID
 * @returns {() => Void}
 */
export function deleteCoach(coachID) {
    return () => StrechCoach.delete({ coachID });
}
