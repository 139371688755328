// The Status IDs
const ACTIVE     = 101;
const INACTIVE   = 102;

const APPROVED   = 201;
const CANCELLED  = 202;
const PAUSED     = 203;
const PENDING    = 204;
const DRAFT      = 205;
const COMPLETED  = 206;
const REPORTED   = 207;

const OPEN       = 301;
const CLOSED     = 302;
const RECEIVED   = 303;
const RESPONDED  = 304;

const INTERESTED = 401;
const POTENTIAL  = 403;
const SENT       = 404;
const PAID       = 405;
const REJECTED   = 406;
const ASSIGNED   = 407;
const NREPORTED  = 408;
const REQUESTED  = 409;
const SCHEDULED  = 410;
const EXPIRED    = 411;



// The public API
const types = {
    "values" : [
        // General Status
        {
            "id"      : ACTIVE,
            "name"    : "ACTIVE",
            "message" : "STATUS_ACTIVE",
            "color"   : "green",
        },
        {
            "id"      : INACTIVE,
            "name"    : "INACTIVE",
            "message" : "STATUS_INACTIVE",
            "color"   : "red",
        },

        // Specific Status
        {
            "id"      : APPROVED,
            "name"    : "APPROVED",
            "message" : "STATUS_APPROVED",
            "color"   : "green",
        },
        {
            "id"      : CANCELLED,
            "name"    : "CANCELLED",
            "message" : "STATUS_CANCELLED",
            "color"   : "red",
        },
        {
            "id"      : PAUSED,
            "name"    : "PAUSED",
            "message" : "STATUS_PAUSED",
            "color"   : "yellow",
        },
        {
            "id"      : PENDING,
            "name"    : "PENDING",
            "message" : "STATUS_PENDING",
            "color"   : "yellow",
        },
        {
            "id"      : DRAFT,
            "name"    : "DRAFT",
            "message" : "STATUS_DRAFT",
            "color"   : "yellow",
        },
        {
            "id"      : COMPLETED,
            "name"    : "COMPLETED",
            "message" : "STATUS_COMPLETED",
            "color"   : "green",
        },
        {
            "id"      : REPORTED,
            "name"    : "REPORTED",
            "message" : "STATUS_REPORTED",
            "color"   : "green",
        },

        // Ticket/Lead Status
        {
            "id"      : OPEN,
            "name"    : "OPEN",
            "message" : "STATUS_OPEN",
            "color"   : "green",
        },
        {
            "id"      : CLOSED,
            "name"    : "CLOSED",
            "message" : "STATUS_CLOSED",
            "color"   : "red",
        },
        {
            "id"      : RECEIVED,
            "name"    : "RECEIVED",
            "message" : "STATUS_RECEIVED",
            "color"   : "yellow",
        },
        {
            "id"      : RESPONDED,
            "name"    : "RESPONDED",
            "message" : "STATUS_RESPONDED",
            "color"   : "green",
        },

        // Special Status
        {
            "id"      : INTERESTED,
            "name"    : "INTERESTED",
            "message" : "STATUS_INTERESTED",
            "color"   : "yellow",
        },
        {
            "id"      : POTENTIAL,
            "name"    : "POTENTIAL",
            "message" : "STATUS_POTENTIAL",
            "color"   : "yellow",
        },
        {
            "id"      : SENT,
            "name"    : "SENT",
            "message" : "STATUS_SENT",
            "color"   : "yellow",
        },
        {
            "id"      : PAID,
            "name"    : "PAID",
            "message" : "STATUS_PAID",
            "color"   : "green",
        },
        {
            "id"      : REJECTED,
            "name"    : "REJECTED",
            "message" : "STATUS_REJECTED",
            "color"   : "red",
        },
        {
            "id"      : ASSIGNED,
            "name"    : "ASSIGNED",
            "message" : "STATUS_ASSIGNED",
            "color"   : "red",
        },
        {
            "id"      : NREPORTED,
            "name"    : "NREPORTED",
            "message" : "STATUS_NREPORTED",
            "color"   : "red",
        },
        {
            "id"      : REQUESTED,
            "name"    : "REQUESTED",
            "message" : "STATUS_REQUESTED",
            "color"   : "yellow",
        },
        {
            "id"      : SCHEDULED,
            "name"    : "SCHEDULED",
            "message" : "STATUS_SCHEDULED",
            "color"   : "green",
        },
        {
            "id"      : EXPIRED,
            "name"    : "EXPIRED",
            "message" : "STATUS_EXPIRED",
            "color"   : "red",
        },
    ],

    // The Status per Type
    "groups" : {
        "GENERAL"      : [ ACTIVE, INACTIVE ],
        "STUDENT"      : [ ACTIVE, POTENTIAL, INACTIVE ],
        "STRECH"       : [ DRAFT, ACTIVE, INACTIVE ],
        "PRICE"        : [ DRAFT, ACTIVE, INACTIVE ],
        "INSCRIPTION"  : [ ACTIVE, REQUESTED, CANCELLED, INTERESTED ],
        "GROUP"        : [ DRAFT, ACTIVE, INACTIVE ],
        "MEETING"      : [ SCHEDULED, COMPLETED, REPORTED, NREPORTED, CANCELLED ],
        "CONVERSATION" : [ REQUESTED, ASSIGNED, SCHEDULED, COMPLETED, REPORTED, NREPORTED, CANCELLED ],
        "INVOICE"      : [ PENDING, SENT, APPROVED, REJECTED, PAID, EXPIRED ],
        "TICKET"       : [ OPEN, CLOSED ],
        "LEAD"         : [ RECEIVED, RESPONDED, CLOSED ],
    },

    // The Status IDs
    ACTIVE,
    INACTIVE,
    APPROVED,
    CANCELLED,
    PAUSED,
    PENDING,
    DRAFT,
    COMPLETED,
    REPORTED,

    OPEN,
    CLOSED,
    RECEIVED,
    RESPONDED,

    INTERESTED,
    POTENTIAL,
    SENT,
    PAID,
    REJECTED,
    ASSIGNED,
    NREPORTED,
    REQUESTED,
    SCHEDULED,
};

export default types;
