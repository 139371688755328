import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchCategory, editCategory,
} from "Actions/Admin/Coach/CategoryActions";



/**
 * The Coach Category Edit Dialog
 */
class CategoryEdit extends React.Component {
    // The Initial Data
    initialData = {
        coachCategoryID      : 0,
        name                 : "",
        fromConversation     : "",
        pricePerConversation : "",
        priceForSchool       : "",
        quota                : "",
        status               : Status.getID("ACTIVE"),
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, fetchCategory } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchCategory(elemID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition && elemID) {
            data = Utils.extend(this.initialData, elem);
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editCategory(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose } = this.props;
        const { data, loading, errors } = this.state;

        return <EditDialog
            open={open}
            icon="coach"
            title={!!elemID ? "COACH_CATEGORIES_EDIT_TITLE" : "COACH_CATEGORIES_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                name="name"
                label="COACH_CATEGORIES_ITS_NAME"
                value={data.name}
                onChange={this.handleChange}
                error={errors.name}
                isRequired
            />
            <InputField
                type="number"
                name="fromConversation"
                label="COACH_CATEGORIES_FROM"
                value={data.fromConversation}
                onChange={this.handleChange}
                error={errors.fromConversation}
                isRequired
            />
            <InputField
                type="number"
                icon="money"
                name="pricePerConversation"
                label="COACH_CATEGORIES_PRICE"
                value={data.pricePerConversation}
                error={errors.pricePerConversation}
                onChange={this.handleChange}
                shrinkLabel
            />
            <InputField
                type="number"
                icon="money"
                name="priceForSchool"
                label="COACH_CATEGORIES_SCHOOL_PRICE"
                helperText="COACH_CATEGORIES_SCHOOL_TIP"
                value={data.priceForSchool}
                error={errors.priceForSchool}
                onChange={this.handleChange}
                shrinkLabel
            />
            <InputField
                type="number"
                name="quota"
                label="COACH_CATEGORIES_QUOTA"
                value={data.quota}
                error={errors.quota}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                value={data.status}
                error={errors.status}
                onChange={this.handleChange}
                options={Status.getFemSelect()}
                withNone
                isRequired
                shrinkLabel
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCategory : PropTypes.func.isRequired,
        editCategory  : PropTypes.func.isRequired,
        open          : PropTypes.bool.isRequired,
        onClose       : PropTypes.func.isRequired,
        onSubmit      : PropTypes.func.isRequired,
        edition       : PropTypes.number.isRequired,
        elem          : PropTypes.object.isRequired,
        elemID        : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.coachCategory.edition,
            elem    : state.coachCategory.elem,
        };
    }
}

export default connect(CategoryEdit.mapStateToProps, {
    fetchCategory, editCategory,
})(CategoryEdit);
