import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStrech }      from "Actions/Admin/Strech/StrechActions";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";
import StrechStep           from "Utils/Entity/StrechStep";

// Sections
import SpecialityEdit       from "./SpecialityEdit";
import StrechBar            from "Components/Admin/Strech/Strech/StrechBar";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";

// Actions
import {
    fetchSpecialities, deleteSpeciality, importSpecialities,
} from "Actions/Admin/Strech/SpecialityActions";



/**
 * The Strech Speciality List
 */
class SpecialityList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchSpecialities(type, elemID, params || data.sort);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch();
        this.props.fetchStrech(this.props.elemID);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        await this.props.deleteSpeciality(this.state.elemID);
        this.editElem();
    }

    /**
     * Handles the Import Submit
     * @returns {Promise}
     */
    importElem = async () => {
        const strechID = this.props.params.strechID;
        await this.props.importSpecialities(strechID);
        this.editElem();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                           } = this.state;
        const { data, strech, params, route, withDetails } = this.props;
        const { canEdit, list, total, sort, loading      } = data;

        const elemName = Utils.getValue(list, "strechSpecialityID", elemID, "name");

        return <Main withDetails={withDetails}>
            <Header message="SPECIALITIES_NAME" icon="speciality" route={route}>
                <ActionList data={data} onAction={this.startAction} useAssign />
            </Header>
            <Content isLoading={loading}>
                <StrechBar step={StrechStep.SPECIALITIES} />
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="SPECIALITIES_NONE_AVAILABLE"
                    hasAlert={StrechStep.hasHelp(strech, StrechStep.SPECIALITIES)}
                >
                    <TableHead>
                        <TableHeader field="name"        message="GENERAL_NAME"        />
                        <TableHeader field="maxAmount"   message="GENERAL_AMOUNT"      maxWidth="80" align="center" />
                        <TableHeader field="position"    message="GENERAL_POSITION"    maxWidth="80" align="center" hideMobile />
                        <TableHeader field="createdTime" message="GENERAL_CREATED_FEM" maxWidth="90" hideMobile />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.strechSpecialityID} elemID={elem.strechSpecialityID}>
                            <TableCell message={elem.name}        />
                            <TableCell message={elem.maxAmount}   />
                            <TableCell message={elem.position}    />
                            <TableCell message={elem.createdDate} />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="EDIT"   message="SPECIALITIES_EDIT_TITLE"   />
                        <TableAction action="DELETE" message="SPECIALITIES_DELETE_TITLE" />
                    </TableActionList>
                </Table>
            </Content>

            <SpecialityEdit
                open={action.isVCE}
                elemID={elemID}
                strechID={params.strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="SPECIALITIES_DELETE_TITLE"
                message="SPECIALITIES_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isImport}
                icon="import"
                title="SPECIALITIES_IMPORT_TITLE"
                message="SPECIALITIES_IMPORT_TEXT"
                onSubmit={this.importElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrech        : PropTypes.func.isRequired,
        fetchSpecialities  : PropTypes.func.isRequired,
        deleteSpeciality   : PropTypes.func.isRequired,
        importSpecialities : PropTypes.func.isRequired,
        data               : PropTypes.object.isRequired,
        strech             : PropTypes.object.isRequired,
        type               : PropTypes.string.isRequired,
        params             : PropTypes.object.isRequired,
        route              : PropTypes.string.isRequired,
        withDetails        : PropTypes.bool.isRequired,
        elemID             : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data   : state.strechSpeciality,
            strech : state.strech.elem,
        };
    }
}

export default connect(SpecialityList.mapStateToProps, {
    fetchStrech, fetchSpecialities, deleteSpeciality, importSpecialities,
})(SpecialityList);
