import { setSection }       from "Actions/Core/CoreActions";
import { Enroll }           from "Utils/API";

// Types
import {
    ENROLL, ENROLL_LOADING,
    ENROLL_LIST,
} from "Utils/Types";



/**
 * Fetches the Enroll data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchEnroll() {
    return async (dispatch) => {
        dispatch({ type : ENROLL_LOADING });
        const data = await Enroll.getData();
        dispatch({ type : ENROLL_LIST, data });
        dispatch(setSection(ENROLL));
    };
}

/**
 * Fetches the updated Enroll data
 * @returns {(dispatch: Function) => Void}
 */
export function updateEnroll() {
    return async (dispatch) => {
        const data = await Enroll.getData();
        dispatch({ type : ENROLL_LIST, data });
    };
}



/**
 * Enrolls the Student to a Chat
 * @param {Number} strechID
 * @param {Object} options
 * @returns {() => Void}
 */
export function validateOptions(strechID, options) {
    return () => Enroll.validateOptions({ strechID, ...options });
}

/**
 * Enrolls the Student to a Chat
 * @param {Number} strechID
 * @param {Object} chats
 * @returns {() => Void}
 */
export function enrollToChat(strechID, chats) {
    return () => Enroll.enrollToChat({ strechID, chats : JSON.stringify(chats) });
}

/**
 * Makes a Pending Inscription
 * @param {Number} strechID
 * @param {Object} options
 * @returns {() => Void}
 */
export function enrollToStrech(strechID, options) {
    return () => Enroll.enrollToStrech({ strechID, ...options });
}

/**
 * Cancels an Inscription
 * @param {Number} strechID
 * @returns {() => Void}
 */
export function cancelEnroll(strechID) {
    return () => Enroll.cancel({ strechID });
}
