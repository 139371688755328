import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchGuide }       from "Actions/Admin/Guide/GuideActions";
import Action               from "dashboard/dist/Core/Action";

// Components
import GuideView            from "./GuideView";
import GuideEdit            from "./GuideEdit";
import Details              from "dashboard/dist/Components/Details";
import DetailList           from "dashboard/dist/Components/DetailList";
import DetailItem           from "dashboard/dist/Components/DetailItem";
import DetailActionList     from "dashboard/dist/Components/DetailActionList";
import DetailAction         from "dashboard/dist/Components/DetailAction";



/**
 * The Guide Details
 */
class GuideDetails extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
    }

    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.props.fetchGuide(this.props.guideID, true);
        this.endAction();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { charging, error, canEdit, elem, guideID } = this.props;
        const { action                                  } = this.state;

        return <>
            <Details
                isLoading={charging}
                hasError={error}
                error="GUIDES_ERROR_EXISTS"
            >
                <DetailList message="GUIDES_DETAILS_TITLE">
                    <DetailItem icon="name"         message={elem.name}            tooltip="GUIDES_ITS_NAME"      />
                    <DetailItem icon="status"       message={elem.statusName}      tooltip="GENERAL_STATUS"       className={elem.statusClass} />
                    <DetailItem icon="observations" message={elem.observations}    tooltip="GENERAL_OBSERVATIONS" />
                    <DetailItem icon="time"         message={elem.createdDateTime} tooltip="GENERAL_CREATED"      />
                </DetailList>
                <DetailActionList onAction={this.startAction} canEdit={canEdit}>
                    <DetailAction action="VIEW" message="GUIDES_VIEW_TITLE" />
                    <DetailAction action="EDIT" message="GUIDES_EDIT_TITLE" />
                </DetailActionList>
            </Details>

            <GuideView
                open={action.isView}
                elemID={guideID}
                onClose={this.endAction}
            />
            <GuideEdit
                open={action.isEdit}
                elemID={guideID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchGuide : PropTypes.func.isRequired,
        charging   : PropTypes.bool.isRequired,
        error      : PropTypes.bool.isRequired,
        canEdit    : PropTypes.bool.isRequired,
        elem       : PropTypes.object.isRequired,
        guideID    : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            charging : state.guide.charging,
            error    : state.guide.error,
            canEdit  : state.guide.canEdit,
            elem     : state.guide.elem,
        };
    }
}

export default connect(GuideDetails.mapStateToProps, {
    fetchGuide,
})(GuideDetails);
