import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { StrechGroup }      from "Utils/API";

// Types
import {
    GROUP_MEMBER, GROUP_MEMBER_LOADING, GROUP_MEMBER_LIST,
} from "Utils/Types";



/**
 * Fetches the Group Members data
 * @param {String}  type
 * @param {Number}  elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMembers(type, elemID, params = {}) {
    return async (dispatch) => {
        dispatch({ type : GROUP_MEMBER_LOADING });
        Params.unset(params);
        if (type === "GROUP") {
            params.groupID = elemID;
        } else if (type === "STRECH") {
            params.strechID = elemID;
        }

        const data = await StrechGroup.getMembers(params);
        data.sort = params;

        dispatch({ type : GROUP_MEMBER_LIST, data });
        dispatch(setSection(GROUP_MEMBER));
    };
}
