import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Strech }           from "Utils/API";

// Types
import {
    STRECH, STRECH_LOADING, STRECH_CHARGING,
    STRECH_LIST, STRECH_ELEM, STRECH_WELCOME,
    STRECH_EDIT, STRECH_CREATE,
} from "Utils/Types";



/**
 * Fetches the Strech List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchStreches(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : STRECH_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "COURSE":
            params.courseID = elemID;
            data = await Strech.getAllForCourse(params);
            break;
        case "PROGRAM":
            params.programID = elemID;
            data = await Strech.getAllForProgram(params);
            break;
        case "LOCATION":
            params.locationID = elemID;
            data = await Strech.getAllForLocation(params);
            break;
        case "STUDENT":
            params.studentID = elemID;
            data = await Strech.getAllForStudent(params);
            break;
        case "COACH":
            params.coachID = elemID;
            data = await Strech.getAllForCoach(params);
            break;
        case "USER":
            data = await Strech.getAllAsCoach(params);
            break;
        default:
            data = await Strech.getAll(params);
        }
        data.sort = params;
        dispatch({ type : STRECH_LIST, data });
        dispatch(setSection(STRECH));
    };
}

/**
 * Fetches the Strech List for Enrolled
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchStrechesAsStudent(params = {}) {
    return async (dispatch) => {
        dispatch({ type : STRECH_LOADING });
        Params.unset(params);
        let data = await Strech.getAllAsStudent(params);
        data.sort = params;
        dispatch({ type : STRECH_LIST, data });
        dispatch(setSection(STRECH));
    };
}

/**
 * Fetches a single Strech
 * @param {Number}   strechID
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchStrech(strechID, withLoader = false) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : STRECH_CHARGING });
        }
        const data = await Strech.getOne({ strechID });
        dispatch({ type : STRECH_ELEM, data });
    };
}

/**
 * Fetches the Strech Welcome
 * @param {Number}   strechID
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchWelcome(strechID, withLoader = false) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : STRECH_CHARGING });
        }
        const data = await Strech.getWelcome({ strechID });
        dispatch({ type : STRECH_WELCOME, data });
    };
}

/**
 * Fetches the Strech Edit data
 * @param {Number}  strechID
 * @param {Number=} courseID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchStrechEdit(strechID, courseID = 0) {
    return async (dispatch) => {
        const data = await Strech.getEditData({ strechID, courseID });
        dispatch({ type : STRECH_EDIT, data });
    };
}

/**
 * Fetches the Strech Create data
 * @param {Number=} courseID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchStrechCreate(courseID = 0) {
    return async (dispatch) => {
        const data = await Strech.getCreateData({ courseID });
        dispatch({ type : STRECH_CREATE, data });
    };
}



/**
 * Edits/Creates a Strech
 * @param {Object} data
 * @returns {() => Void}
 */
export function editStrech(data) {
    return () => Strech.edit(data);
}

/**
 * Activates a Strech
 * @param {Number} strechID
 * @returns {() => Void}
 */
export function activateStrech(strechID) {
    return () => Strech.activate({ strechID });
}

/**
 * Cancels a Strech
 * @param {Number} strechID
 * @returns {() => Void}
 */
export function cancelStrech(strechID) {
    return () => Strech.cancel({ strechID });
}

/**
 * Deletes a Strech
 * @param {Number} strechID
 * @returns {() => Void}
 */
export function deleteStrech(strechID) {
    return () => Strech.delete({ strechID });
}
