import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchMaterial, fetchMaterialCreate, editMaterial,
} from "Actions/Admin/Program/MaterialActions";



/**
 * The Program Material Edit Dialog
 */
class MaterialEdit extends React.Component {
    // The Initial Data
    initialData = {
        programMaterialID : 0,
        programID         : 0,
        materialID        : 0,
        classID           : 0,
        position          : 0,
        status            : Status.getID("ACTIVE"),
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, programID, position, fetchMaterial, fetchMaterialCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchMaterial(elemID);
                loading = true;
           // Load edit data
            } else if (programID) {
                fetchMaterialCreate(programID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData, programID, position };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editMaterial(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, materials, classes } = this.props;
        const { data, loading, errors                     } = this.state;

        return <EditDialog
            open={open}
            icon="material"
            title={!!elemID ? "MATERIALS_EDIT_TITLE" : "MATERIALS_ASSIGN_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                type="select"
                name="materialID"
                label="MATERIALS_SINGULAR"
                value={data.materialID}
                error={errors.materialID}
                onChange={this.handleChange}
                options={materials}
                withNone
                isRequired
            />
            <InputField
                type="select"
                name="classID"
                label="MATERIALS_CLASS"
                value={data.classID}
                error={errors.classID}
                onChange={this.handleChange}
                options={classes}
                customText="MATERIALS_WELCOME"
                withNone
                withCustom
                customFirst
                isRequired
            />
            <Columns>
                <InputField
                    type="number"
                    name="position"
                    label="GENERAL_POSITION"
                    value={data.position}
                    error={errors.position}
                    onChange={this.handleChange}
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    error={errors.status}
                    onChange={this.handleChange}
                    options={Status.getSelect()}
                    withNone
                    isRequired
                    shrinkLabel
                />
            </Columns>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchMaterial       : PropTypes.func.isRequired,
        fetchMaterialCreate : PropTypes.func.isRequired,
        editMaterial        : PropTypes.func.isRequired,
        open                : PropTypes.bool.isRequired,
        onClose             : PropTypes.func.isRequired,
        onSubmit            : PropTypes.func.isRequired,
        edition             : PropTypes.number.isRequired,
        position            : PropTypes.number.isRequired,
        materials           : PropTypes.array.isRequired,
        classes             : PropTypes.array.isRequired,
        elem                : PropTypes.object.isRequired,
        elemID              : PropTypes.number,
        programID           : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.programMaterial.edition,
            position  : state.programMaterial.position,
            materials : state.programMaterial.materials,
            classes   : state.programMaterial.classes,
            elem      : state.programMaterial.elem,
        };
    }
}

export default connect(MaterialEdit.mapStateToProps, {
    fetchMaterial, fetchMaterialCreate, editMaterial,
})(MaterialEdit);
