import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchQuestion, fetchQuestionCreate, editQuestion,
} from "Actions/Admin/Coach/QuestionActions";



/**
 * The Coach Question Edit Dialog
 */
class QuestionEdit extends React.Component {
    // The Initial Data
    initialData = {
        coachQuestionID : 0,
        section         : 0,
        question        : "",
        type            : "",
        options         : "",
        position        : 0,
        status          : Status.getID("ACTIVE"),
        observations    : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, section, position, fetchQuestion, fetchQuestionCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchQuestion(elemID);
                loading = true;
            // Load edit data
            } else {
                fetchQuestionCreate(section);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData, section, position };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        data.options  = JSON.parse(data.options);
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editQuestion(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose } = this.props;
        const { data, loading, errors } = this.state;

        return <EditDialog
            open={open}
            icon="question"
            title={!!elemID ? "QUESTIONS_EDIT_TITLE" : "QUESTIONS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                name="question"
                label="QUESTIONS_SINGULAR"
                value={data.question}
                error={errors.question}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="select"
                name="type"
                label="QUESTIONS_TYPE"
                value={data.type}
                error={errors.type}
                onChange={this.handleChange}
                options="COACH_QUESTIONS_TYPES"
                withNone
                isRequired
            />
            <InputField
                isHidden={data.type !== "select"}
                type="fields"
                name="options"
                label="QUESTIONS_OPTIONS"
                value={data.options}
                error={errors.options}
                onChange={this.handleChange}
                isRequired
                isSmall
            />
            <Columns>
                <InputField
                    type="number"
                    name="position"
                    label="GENERAL_POSITION"
                    value={data.position}
                    error={errors.position}
                    onChange={this.handleChange}
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    error={errors.status}
                    onChange={this.handleChange}
                    options={Status.getFemSelect()}
                    withNone
                    isRequired
                    shrinkLabel
                />
            </Columns>
            <InputField
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                value={data.observations}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchQuestion       : PropTypes.func.isRequired,
        fetchQuestionCreate : PropTypes.func.isRequired,
        editQuestion        : PropTypes.func.isRequired,
        open                : PropTypes.bool.isRequired,
        onClose             : PropTypes.func.isRequired,
        onSubmit            : PropTypes.func.isRequired,
        edition             : PropTypes.number.isRequired,
        position            : PropTypes.number.isRequired,
        elem                : PropTypes.object.isRequired,
        elemID              : PropTypes.number,
        section             : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition  : state.coachQuestion.edition,
            position : state.coachQuestion.position,
            elem     : state.coachQuestion.elem,
        };
    }
}

export default connect(QuestionEdit.mapStateToProps, {
    fetchQuestion, fetchQuestionCreate, editQuestion,
})(QuestionEdit);
