import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Campaign }         from "Utils/API";

// Types
import {
    CAMPAIGN_USER, CAMPAIGN_USER_LOADING, CAMPAIGN_USER_LIST,
} from "Utils/Types";



/**
 * Fetches the Campaign Users data
 * @param {String}  type
 * @param {Number}  elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchUsers(type, elemID, params = {}) {
    return async (dispatch) => {
        dispatch({ type : CAMPAIGN_USER_LOADING });
        Params.unset(params);
        if (type === "CAMPAIGN") {
            params.campaignID = elemID;
        }

        const data = await Campaign.getUsers(params);
        data.sort = params;

        dispatch({ type : CAMPAIGN_USER_LIST, data });
        dispatch(setSection(CAMPAIGN_USER));
    };
}
