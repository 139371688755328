import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchWelcome }     from "Actions/Student/HomeActions";
import NLS                  from "dashboard/dist/Core/NLS";
import Href                 from "dashboard/dist/Core/Href";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import Icon                 from "dashboard/dist/Components/Icon";

// Styles
import "Styles/Components/App/Welcome.css";



/**
 * The Welcome Page
 */
class WelcomePage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchWelcome();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, advice, route } = this.props;

        const showAdvice = Boolean(advice && advice.imageUrl);

        return <Main>
            <Header message="WELCOME_NAME" icon="home" route={route} />
            <Content className="welcome" isLoading={loading}>
                <section className="welcome-content">
                    <p className="welcome-text">{NLS.get("WELCOME_TEXT")}</p>
                    <div className="welcome-info" onClick={() => Href.goto("ENROLL")}>
                        <Icon icon="inscription" />
                        <p>{NLS.get("WELCOME_ENROLL_INFO")}</p>
                    </div>
                    <div className="welcome-info" onClick={() => Href.goto("TICKETS")}>
                        <Icon icon="ticket" />
                        <p>{NLS.get("WELCOME_TICKET_INFO")}</p>
                    </div>
                </section>

                {showAdvice && <section>
                    <div className="welcome-advice">
                        <img src={advice.imageUrl} alt={advice.name} />
                    </div>
                </section>}
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchWelcome : PropTypes.func.isRequired,
        loading      : PropTypes.bool.isRequired,
        advice       : PropTypes.object,
        route        : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loading : state.home.loading,
            advice  : state.home.advice,
        };
    }
}

export default connect(WelcomePage.mapStateToProps, {
    fetchWelcome,
})(WelcomePage);
