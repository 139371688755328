import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Fields
import NameField            from "Components/Utils/Credential/NameField";
import FiscalField          from "Components/Utils/Credential/FiscalField";
import ContactField         from "Components/Utils/Credential/ContactField";
import AddressField         from "Components/Utils/Credential/AddressField";
import PersonalField        from "Components/Utils/Credential/PersonalField";
import PasswordField        from "Components/Utils/Credential/PasswordField";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchCoach, fetchCoachCreate, editCoach,
} from "Actions/Admin/User/CoachActions";



/**
 * The Coach Edit Dialog
 */
class CoachEdit extends React.Component {
    // The Initial Data
    initialData = {
        credentialID         : 0,
        firstName            : "",
        lastName             : "",
        nationality          : 0,
        document             : 1,
        dni                  : "",
        cuit                 : "",
        taxID                : "",
        countryID            : 0,
        provinceID           : 0,
        address              : "",
        email                : "",
        idd                  : 0,
        cellphone            : "",
        birthDate            : "",
        gender               : 0,
        ocupation            : "",
        profesion            : 0,
        schedule             : "",
        interestID           : 0,
        initialConversations : "",
        afipInscription      : 0,
        trafficlight         : 0,
        camada               : 0,
        schoolStaff          : 0,
        canConverse          : 0,
        showInSite           : 0,
        newPassword          : "",
        confirmPassword      : "",
        status               : Status.getID("ACTIVE"),
        observations         : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
        syncIDD : true,
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, fetchCoach, fetchCoachCreate } = this.props;
        let loading = false;
        let data    = null;
        let syncIDD = true;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchCoach(elemID);
                loading = true;
            } else {
                fetchCoachCreate();
            }

        // Data Updated
        } else if (prevProps.edition !== edition && elemID) {
            data    = Utils.extend(this.initialData, elem);
            syncIDD = !elem.countryID && !elem.idd;
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {}, syncIDD });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const newState = Common.handleUserChange(this.state, name, value);
        this.setState(newState);
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const response = await this.props.editCoach(data);
                this.setState({ loading : false });
                this.props.onSubmit(response);
            } catch (errors) {
                this.setState({
                    errors,
                    loading : false,
                    data    : { ...data, "password" : "" },
                });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, idds, countries, provinces } = this.props;
        const { data, loading, errors                             } = this.state;

        const isActive = Status.is("ACTIVE", Number(data.status));

        return <EditDialog
            open={open}
            icon="coach"
            title={!!elemID ? "COACHES_EDIT_TITLE" : "COACHES_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <NameField
                data={data}
                errors={errors}
                onChange={this.handleChange}
            />
            <FiscalField
                data={data}
                errors={errors}
                countries={countries}
                onChange={this.handleChange}
                isRequired={isActive}
            />
            <AddressField
                data={data}
                errors={errors}
                countries={countries}
                provinces={provinces}
                onChange={this.handleChange}
                isRequired={isActive}
            />
            <ContactField
                data={data}
                errors={errors}
                idds={idds}
                countryID={data.countryID}
                onChange={this.handleChange}
                isRequired={isActive}
            />
            <PasswordField
                data={data}
                errors={errors}
                isNew={!elemID}
                onChange={this.handleChange}
                isRequired={isActive}
            />
            <PersonalField
                data={data}
                errors={errors}
                onChange={this.handleChange}
                isRequired={isActive}
            />

            <Columns>
                <InputField
                    type="select"
                    name="profesion"
                    label="USERS_PROFESION"
                    value={data.profesion}
                    onChange={this.handleChange}
                    options="USERS_PROFESIONS"
                    noneText="GENERAL_NONE"
                    withNone
                    shrinkLabel
                />
                <InputField
                    name="ocupation"
                    label="USERS_OCUPATION"
                    value={data.ocupation}
                    error={errors.ocupation}
                    onChange={this.handleChange}
                />
                <InputField
                    name="schedule"
                    label="USERS_SCHEDULE"
                    value={data.schedule}
                    error={errors.schedule}
                    onChange={this.handleChange}
                />

                <InputField
                    type="select"
                    name="afipInscription"
                    label="USERS_AFIP_INSCRIPTION"
                    value={data.afipInscription}
                    error={errors.afipInscription}
                    onChange={this.handleChange}
                    options="USERS_AFIP_OPTIONS"
                    isRequired={isActive}
                />
                <InputField
                    type="number"
                    name="initialConversations"
                    label="USERS_INITIAL_CONVERSATIONS"
                    value={data.initialConversations}
                    error={errors.initialConversations}
                    onChange={this.handleChange}
                />
                <InputField
                    type="select"
                    name="trafficlight"
                    label="USERS_TRAFFICLIGHT"
                    value={data.trafficlight}
                    onChange={this.handleChange}
                    options="USERS_TRAFFICLIGHTS"
                    noneText="GENERAL_NONE"
                    withNone
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="camada"
                    label="USERS_CAMADANOM"
                    value={data.camada}
                    onChange={this.handleChange}
                    options="USERS_CAMADA"
                    noneText="GENERAL_NONE"
                    withNone
                    shrinkLabel
                />

            </Columns>

            <Columns>
                <InputField
                    type="toggle"
                    name="schoolStaff"
                    label="USERS_SCHOOL_STAFF"
                    value={data.schoolStaff}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="canConverse"
                    label="USERS_CAN_CONVERSE"
                    value={data.canConverse}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="showInSite"
                    label="USERS_SHOW_IN_SITE"
                    value={data.showInSite}
                    onChange={this.handleChange}
                />
            </Columns>

            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                value={data.status}
                error={errors.status}
                onChange={this.handleChange}
                options={Status.getSelect()}
                withNone
                isRequired
                shrinkLabel
            />
            <InputField
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                value={data.observations}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCoach       : PropTypes.func.isRequired,
        fetchCoachCreate : PropTypes.func.isRequired,
        editCoach        : PropTypes.func.isRequired,
        open             : PropTypes.bool.isRequired,
        onClose          : PropTypes.func.isRequired,
        onSubmit         : PropTypes.func.isRequired,
        edition          : PropTypes.number.isRequired,
        idds             : PropTypes.array.isRequired,
        countries        : PropTypes.array.isRequired,
        provinces        : PropTypes.object.isRequired,
        elem             : PropTypes.object.isRequired,
        elemID           : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.coach.edition,
            idds      : state.coach.idds,
            countries : state.coach.countries,
            provinces : state.coach.provinces,
            elem      : state.coach.elem,
        };
    }
}

export default connect(CoachEdit.mapStateToProps, {
    fetchCoach, fetchCoachCreate, editCoach,
})(CoachEdit);
