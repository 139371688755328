import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    COACH_PRICES_ELEM,
} from "Utils/Types";



// The initial State
const initialState = {
    loading      : false,
    error        : false,
    canEdit      : false,
    edition      : 0,
    name         : "",
    specialities : [],
    prices       : [],
};




/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, COACH_PRICES_ELEM)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case COACH_PRICES_ELEM:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            canEdit      : action.data.canEdit,
            name         : action.data.name,
            specialities : action.data.specialities,
            prices       : action.data.prices,
        };

    default:
        return state;
    }
};

export default reducer;
