import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Access               from "dashboard/dist/Core/Access";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Fields
import NameField            from "Components/Utils/Credential/NameField";
import FiscalField          from "Components/Utils/Credential/FiscalField";
import ContactField         from "Components/Utils/Credential/ContactField";
import AddressField         from "Components/Utils/Credential/AddressField";
import PersonalField        from "Components/Utils/Credential/PersonalField";
import PasswordField        from "Components/Utils/Credential/PasswordField";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchAdmin, fetchAdminCreate, editAdmin,
} from "Actions/Admin/User/AdminActions";



/**
 * The Admin Edit Dialog
 */
class AdminEdit extends React.Component {
    // The Initial Data
    initialData = {
        credentialID    : 0,
        level           : 0,
        firstName       : "",
        lastName        : "",
        nationality     : 0,
        document        : 1,
        dni             : "",
        cuit            : "",
        taxID           : "",
        countryID       : 0,
        provinceID      : 0,
        address         : "",
        email           : "",
        idd             : 0,
        cellphone       : "",
        birthDate       : "",
        gender          : 0,
        ocupation       : "",
        schedule        : "",
        newPassword     : "",
        confirmPassword : "",
        status          : Status.getID("ACTIVE"),
        sendEmails      : 1,
        sendTickets     : 1,
        forSupport      : 0,
        forLeads        : 0,
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
        syncIDD : true,
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, fetchAdmin, fetchAdminCreate } = this.props;
        let loading = false;
        let data    = null;
        let syncIDD = true;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchAdmin(elemID);
                loading = true;
            } else {
                fetchAdminCreate();
            }

        // Data Updated
        } else if (prevProps.edition !== edition && elemID) {
            data    = Utils.extend(this.initialData, elem);
            syncIDD = !elem.countryID && !elem.idd;
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {}, syncIDD });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const newState = Common.handleUserChange(this.state, name, value);
        this.setState(newState);
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editAdmin(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({
                    errors,
                    loading : false,
                    data    : { ...data, "password" : "" },
                });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, idds, countries, provinces, isOwner } = this.props;
        const { data, loading, errors                                      } = this.state;

        return <EditDialog
            open={open}
            icon="admin"
            title={!!elemID ? "ADMINS_EDIT_TITLE" : "ADMINS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                type="select"
                name="level"
                label="USERS_LEVEL"
                value={data.level}
                error={errors.level}
                options={Access.getSelect(isOwner ? "ADMIN" : "SUPPORT")}
                onChange={this.handleChange}
                withNone
                isRequired
            />
            <NameField
                data={data}
                errors={errors}
                onChange={this.handleChange}
            />
            <FiscalField
                data={data}
                errors={errors}
                countries={countries}
                onChange={this.handleChange}
            />
            <AddressField
                data={data}
                errors={errors}
                countries={countries}
                provinces={provinces}
                onChange={this.handleChange}
            />
            <ContactField
                data={data}
                errors={errors}
                idds={idds}
                onChange={this.handleChange}
            />
            <PasswordField
                data={data}
                errors={errors}
                isNew={!elemID}
                onChange={this.handleChange}
                isRequired
            />
            <PersonalField
                data={data}
                errors={errors}
                onChange={this.handleChange}
            />

            <Columns>
                <InputField
                    name="ocupation"
                    label="USERS_OCUPATION"
                    value={data.ocupation}
                    error={errors.ocupation}
                    onChange={this.handleChange}
                />
                <InputField
                    name="schedule"
                    label="USERS_SCHEDULE"
                    value={data.schedule}
                    error={errors.schedule}
                    onChange={this.handleChange}
                />
            </Columns>

            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                value={data.status}
                error={errors.status}
                onChange={this.handleChange}
                options={Status.getSelect()}
                withNone
                isRequired
                shrinkLabel
            />
            <Columns>
                <InputField
                    type="toggle"
                    name="sendEmails"
                    label="USERS_SEND_EMAILS"
                    value={data.sendEmails}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="sendTickets"
                    label="USERS_SEND_TICKETS"
                    value={data.sendTickets}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="forSupport"
                    label="USERS_FOR_SUPPORT"
                    value={data.forSupport}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="forLeads"
                    label="USERS_FOR_LEADS"
                    value={data.forLeads}
                    onChange={this.handleChange}
                />
            </Columns>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchAdmin       : PropTypes.func.isRequired,
        fetchAdminCreate : PropTypes.func.isRequired,
        editAdmin        : PropTypes.func.isRequired,
        open             : PropTypes.bool.isRequired,
        onClose          : PropTypes.func.isRequired,
        onSubmit         : PropTypes.func.isRequired,
        isOwner          : PropTypes.bool.isRequired,
        edition          : PropTypes.number.isRequired,
        idds             : PropTypes.array.isRequired,
        countries        : PropTypes.array.isRequired,
        provinces        : PropTypes.object.isRequired,
        elem             : PropTypes.object.isRequired,
        elemID           : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isOwner   : state.auth.isOwner,
            edition   : state.admin.edition,
            idds      : state.admin.idds,
            countries : state.admin.countries,
            provinces : state.admin.provinces,
            elem      : state.admin.elem,
        };
    }
}

export default connect(AdminEdit.mapStateToProps, {
    fetchAdmin, fetchAdminCreate, editAdmin,
})(AdminEdit);
