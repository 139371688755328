import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchSchedule, editSchedule,
} from "Actions/Admin/Location/ScheduleActions";



/**
 * The Schedule Edit Dialog
 */
class ScheduleEdit extends React.Component {
    // The Initial Data
    initialData = {
        scheduleID   : 0,
        locationID   : 0,
        weekDay      : "",
        fromHour     : "",
        toHour       : "",
        status       : Status.getID("ACTIVE"),
        observations : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, locationID, fetchSchedule } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchSchedule(elemID);
                loading = true;
            // Set edit data
            } else if (locationID) {
                data.locationID = locationID;
            }

        // Data Updated
        } else if (prevProps.edition !== edition && elemID) {
            data = Utils.extend(this.initialData, elem);
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editSchedule(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose } = this.props;
        const { data, loading, errors } = this.state;

        return <EditDialog
            open={open}
            icon="schedule"
            title={!!elemID ? "SCHEDULES_EDIT_TITLE" : "SCHEDULES_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                type="select"
                name="weekDay"
                label="SCHEDULES_WEEKDAY"
                value={data.weekDay}
                error={errors.weekDay}
                onChange={this.handleChange}
                options="DATE_DAY_NAMES"
                withNone
                isRequired
            />
            <Columns>
                <InputField
                    type="time"
                    name="fromHour"
                    label="GENERAL_FROM_HOUR"
                    value={data.fromHour}
                    error={errors.fromHour}
                    onChange={this.handleChange}
                />
                <InputField
                    type="time"
                    name="toHour"
                    label="GENERAL_TO_HOUR"
                    value={data.toHour}
                    error={errors.toHour}
                    onChange={this.handleChange}
                />
            </Columns>
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                value={data.status}
                error={errors.status}
                onChange={this.handleChange}
                options={Status.getSelect()}
                withNone
                isRequired
                shrinkLabel
            />
            <InputField
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                value={data.observations}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchSchedule : PropTypes.func.isRequired,
        editSchedule  : PropTypes.func.isRequired,
        open          : PropTypes.bool.isRequired,
        onClose       : PropTypes.func.isRequired,
        onSubmit      : PropTypes.func.isRequired,
        edition       : PropTypes.number.isRequired,
        elem          : PropTypes.object.isRequired,
        elemID        : PropTypes.number,
        locationID    : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.locSchedule.edition,
            elem    : state.locSchedule.elem,
        };
    }
}

export default connect(ScheduleEdit.mapStateToProps, {
    fetchSchedule, editSchedule,
})(ScheduleEdit);
