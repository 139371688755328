import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Lead }             from "Utils/API";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";
import Utils                from "dashboard/dist/Utils/Utils";
import StatusData           from "Utils/Status";

// Dialogs
import LeadFilter           from "./LeadFilter";
import LeadCreate           from "./LeadCreate";
import LeadEdit             from "./LeadEdit";
import LeadAssign           from "./LeadAssign";
import LeadClose            from "./LeadClose";
import CampaignSend         from "Components/Admin/Campaign/CampaignSend";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import TabList              from "dashboard/dist/Components/TabList";
import TabItem              from "dashboard/dist/Components/TabItem";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";
import Downloader           from "dashboard/dist/Components/Downloader";

// Actions
import {
    fetchLeads, fetchLeadsEmails, openLead, deleteLead,
} from "Actions/Admin/Lead/LeadActions";



/**
 * The Lead List
 */
class LeadList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
        users  : [],
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Object=}  filters
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, filters, withLoader) => {
        const { elemID, data, fetchLeads } = this.props;

        fetchLeads(elemID, params || data.sort, filters || data.filters, withLoader);
    }

    /**
     * Filters the content
     * @param {Object=} newFilters
     * @returns {Void}
     */
    filter = (newFilters) => {
        const { filters, sort } = this.props.data;
        this.fetch({ ...sort, page : 0 }, { ...filters, ...newFilters }, false);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Promise}
     */
    startAction = async (action, elemID) => {
        const { list, filters } = this.props.data;
        const credentialID = Utils.getValue(list, "leadID", elemID).credentialID;
        if (action.isTab) {
            this.filter({ status : elemID });
        } else if (action.isStudent) {
            Href.goto("STUDENTS", credentialID);
        } else if (action.isCoach) {
            Href.goto("COACHES", credentialID);
        } else {
            let users = [];
            if (action.isCampaign) {
                users = await this.props.fetchLeadsEmails(filters);
            }
            this.setState({ action, elemID, users });
        }
    }

    /**
     * Ends an Action
     * @param {Boolean=} update
     * @param {Object=}  params
     * @returns {Void}
     */
    endAction = (update, params = null) => {
        this.startAction(Action.get(), 0);
        if (update) {
            this.fetch(params, null, false);
        }
    }

    /**
     * Handles the Filter Submit
     * @param {Object} newFilters
     * @returns {Promise}
     */
    filterElem = async (newFilters) => {
        this.startAction(Action.get(), 0);
        this.filter(newFilters);
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction(true);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID = this.state.elemID;
        await this.props.deleteLead(elemID);
        this.endAction(true);
    }

    /**
     * Handles the Open Submit
     * @returns {Promise}
     */
    openElem = async () => {
        const elemID = this.state.elemID;
        if (!this.canClose(elemID)) {
            const response = await this.props.openLead(elemID);
            this.endAction(response.success);
        }
    }



    /**
     * Returns true if it can close the Lead
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canClose(elemID) {
        const { list } = this.props.data;
        return !Utils.getValue(list, "leadID", elemID, "isClosed");
    }

    /**
     * Returns true if it is a Student
     * @param {Number} elemID
     * @returns {Boolean}
     */
    isStudent(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "leadID", elemID, "isStudent");
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID, users                        } = this.state;
        const { data                                  } = this.props;
        const { canEdit, list, total, sort, filters, loading } = data;

        //const { data, route                                  } = this.props;
        const isClosed = filters.status === StatusData.CLOSED;
        const elemName = Utils.getValue(list, "leadID", elemID, "title");

//            <Header message="LEADS_NAME" icon="lead" route={route}>
        return <Main>
            <Header message="LEADS_NAME" icon="lead" route='/leads'>
                <ActionList data={data} onAction={this.startAction} withCampaign />
            </Header>
            <Content isLoading={loading}>
                <TabList selected={filters.status} onAction={this.startAction}>
                    <TabItem message="LEADS_RECEIVED_TAB"  status="RECEIVED"  />
                    <TabItem message="LEADS_RESPONDED_TAB" status="RESPONDED" />
                    <TabItem message="LEADS_CLOSED_TAB"    status="CLOSED"    />
                </TabList>
                <Table fetch={this.fetch} sort={sort} none="LEADS_NONE_AVAILABLE" hasTabs>
                    <TableHead>
                        <TableHeader field="lastReply"           message="GENERAL_LAST_REPLY"  minWidth="180" />
                        <TableHeader field="credentialFirstName" message="LEADS_CONTACT"       />
                        <TableHeader field="description"         message="LEADS_DESCRIPTION"   isHidden={isClosed} />
                        <TableHeader field="closedReasonName"    message="LEADS_CLOSED_REASON" isHidden={!isClosed} />
                        <TableHeader field="interestName"        message="INTERESTS_SINGULAR"  grow="2" />
                        <TableHeader field="originName"          message="ORIGINS_SINGULAR"    maxWidth="100" />
                        <TableHeader field="assignedFirstName"   message="LEADS_ASSIGNED"      maxWidth="150" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.leadID} elemID={elem.leadID}>
                            <TableCell message={elem.lastReplyString}  circle={elem.lastReplyColor} />
                            <TableCell message={elem.credentialName}   />
                            <TableCell message={elem.descriptionShort} />
                            <TableCell message={elem.closedReasonName} />
                            <TableCell message={elem.interestName}     />
                            <TableCell message={elem.originName}       />
                            <TableCell message={elem.assignedName}     />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"    message="LEADS_VIEW_TITLE"    route='/leads' />
                        <TableAction action="EDIT"    message="LEADS_EDIT_TITLE"    />
                        <TableAction action="ASSIGN"  message="LEADS_ASSIGN_TITLE"  />
                        <TableAction action="CLOSE"   message="LEADS_CLOSE_TITLE"   hide={(elem) => !this.canClose(elem)}  />
                        <TableAction action="OPEN"    message="LEADS_OPEN_TITLE"    hide={(elem) => this.canClose(elem)}   />
                        <TableAction action="DELETE"  message="LEADS_DELETE_TITLE"  />
                        <TableAction action="STUDENT" message="STUDENTS_VIEW_TITLE" hide={(elem) => !this.isStudent(elem)} />
                        <TableAction action="COACH"   message="COACHES_VIEW_TITLE"  hide={(elem) => this.isStudent(elem)}  />
                    </TableActionList>
                </Table>
            </Content>

            <LeadFilter
                open={action.isFilter}
                filters={filters}
                onSubmit={this.filterElem}
                onClose={this.endAction}
            />
            <Downloader
                download={action.isExport}
                source={Lead.export(filters)}
                onLoad={this.endAction}
            />
            <CampaignSend
                open={action.isCampaign}
                users={users}
                onClose={this.endAction}
                onSubmit={this.endAction}
            />

            <LeadCreate
                open={action.isCreate}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <LeadEdit
                open={action.isEdit}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <LeadAssign
                open={action.isAssign}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <LeadClose
                open={action.isClose}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isOpen}
                icon="status"
                title="LEADS_OPEN_TITLE"
                message="LEADS_OPEN_TEXT"
                content={elemName}
                onSubmit={this.openElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="LEADS_DELETE_TITLE"
                message="LEADS_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchLeads       : PropTypes.func.isRequired,
        fetchLeadsEmails : PropTypes.func.isRequired,
        openLead         : PropTypes.func.isRequired,
        deleteLead       : PropTypes.func.isRequired,
        data             : PropTypes.object.isRequired,
        route            : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.lead,
        };
    }
}

export default connect(LeadList.mapStateToProps, {
    fetchLeads, fetchLeadsEmails, openLead, deleteLead,
})(LeadList);
