import React                from "react";
import PropTypes            from "prop-types";
import Graph                from "Utils/Graph";



/**
 * The Report Graph
 */
class ReportGraph extends React.Component {
    // The Refs
    chartRef = React.createRef();

    /**
     * Show the Chart
     * @returns {Void}
     */
    componentDidMount() {
        this.showChart();
    }

    /**
     * Destroy the Chart
     * @returns {Void}
     */
    componentWillUnmount() {
        if (this.chart) {
            this.chart.destroy();
        }
    }

    /**
     * Update the Chart
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.graph !== prevProps.graph) {
            this.showChart();
        }
    }

    /**
     * Shows the Chart
     * @returns {Void}
     */
    showChart() {
        const report = this.props.data.report;
        if (this.chart) {
            this.chart.destroy();
        }
        if (report.length > 0) {
            this.chart = Graph.barChart(this.chartRef, report, this.props.dataType);
        }
    }



    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data } = this.props;

        if (!data.fetched || !data.report.length) {
            return <React.Fragment />;
        }

        return <section className="report-canvas">
            <canvas
                id="report-chart"
                ref={this.chartRef}
            />
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data     : PropTypes.object.isRequired,
        graph    : PropTypes.number.isRequired,
        dataType : PropTypes.number.isRequired,
    }
}

export default ReportGraph;
