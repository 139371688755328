import NLS                  from "dashboard/dist/Core/NLS";



// The Strech Steps
const COACHES      = "coaches";
const PRICES       = "prices";
const SCHEDULES    = "schedules";
const CHATS        = "chats";
const CLASSES      = "classes";
const MATERIALS    = "materials";
const GUIDES       = "guides";
const SPECIALITIES = "specialities";
const QUESTIONS    = "questions";
const STRECH       = "strech";
const ACTIVATE     = "activate";

// The Strech Data
const DATA = {
    [COACHES] : {
        showIf : () => true,
        title  : "STRECHES_STEP_COACHES",
        help   : () => NLS.get("STRECHES_STEP_COACHES_HELP"),
        url    : "COACHES",
    },
    [PRICES] : {
        showIf : (strech) => !strech.isFree,
        title  : "STRECHES_STEP_PRICES",
        help   : () => NLS.get("STRECHES_STEP_PRICES_HELP"),
        url    : "PRICES",
    },
    [SCHEDULES] : {
        showIf : (strech) => strech.hasClasses,
        title  : "STRECHES_STEP_SCHEDULES",
        help   : () => NLS.get("STRECHES_STEP_SCHEDULES_HELP"),
        url    : "SCHEDULES",
    },
    [CHATS] : {
        showIf : (strech) => strech.hasChats,
        title  : "STRECHES_STEP_CHATS",
        help   : () => NLS.get("STRECHES_STEP_CHATS_HELP"),
        url    : "CHATS",
    },
    [CLASSES] : {
        showIf : (strech) => strech.hasClasses,
        title  : "STRECHES_STEP_CLASSES",
        help   : (strech) => NLS.pluralize("STRECHES_STEP_CLASSES", strech.programClasses),
        url    : "CLASSES",
    },
    [MATERIALS] : {
        showIf : (strech) => strech.hasMaterials,
        title  : "STRECHES_STEP_MATERIALS",
        help   : () => NLS.get("STRECHES_STEP_MATERIALS_HELP"),
        url    : "MATERIALS",
    },
    [GUIDES] : {
        showIf : (strech) => strech.hasGuides,
        title  : "STRECHES_STEP_GUIDES",
        help   : () => NLS.get("STRECHES_STEP_GUIDES_HELP"),
        url    : "GUIDES",
    },
    [SPECIALITIES] : {
        showIf : (strech) => strech.hasConversations,
        title  : "STRECHES_STEP_SPECIALITIES",
        help   : (strech) => NLS.format("STRECHES_STEP_SPECIALITIES_HELP", strech.conversations),
        url    : "SPECIALITIES",
    },
    [QUESTIONS] : {
        showIf : (strech) => strech.hasPoll,
        title  : "STRECHES_STEP_QUESTIONS",
        help   : () => NLS.get("STRECHES_STEP_QUESTIONS_HELP"),
        url    : "POLL",
    },
    [STRECH] : {
        showIf : () => true,
        title  : "STRECHES_STEP_STRECH",
        help   : () => NLS.get("STRECHES_STEP_STRECH_HELP"),
        url    : "",
    },
    [ACTIVATE] : {
        showIf : () => true,
        title  : "STRECHES_STEP_ACTIVATE",
        help   : () => "",
        url    : "",
    },
};



/**
 * Creates the List of Steps
 * @param {Object} strech
 * @returns {Object[]}
 */
function getList(strech) {
    const result  = [];
    const steps   = strech.steps || [];
    let   step    = 1;
    let   isFirst = true;

    for (const [ key, value ] of Object.entries(DATA)) {
        if (value.showIf(strech)) {
            const isCompleted = steps.includes(key);
            let   isActive    = false;
            let   className   = "";
            if (!isCompleted && isFirst) {
                isActive = true;
                isFirst  = false;
            }
            if (isCompleted) {
                className = "creating-completed";
            } else if (isActive) {
                className = "creating-active";
            }

            result.push({
                key, step, isActive, isCompleted, className,
                title : value.title,
                help  : value.help(strech),
                url   : value.url,
            });
            step += 1;
        }
    }
    return result;
}

/**
 * Returns the Step Help
 * @param {Object} strech
 * @param {String} step
 * @returns {String}
 */
function getHelp(strech, step) {
    if (hasHelp(strech, step)) {
        return DATA[step].help(strech);
    }
    return "";
}

/**
 * Returns true if the Step has Help
 * @param {Object} strech
 * @param {String} step
 * @returns {Boolean}
 */
function hasHelp(strech, step) {
    return Boolean(strech.strechID && strech.isCreating && DATA[step]);
}

/**
 * Returns true if the Step is Completed
 * @param {Object} strech
 * @param {String} step
 * @returns {Boolean}
 */
function isCompleted(strech, step) {
    if (!strech.strechID || !strech.isCreating || !DATA[step]) {
        return true;
    }
    const steps = strech.steps || [];
    return steps.includes(step);
}

/**
 * Returns true if the Step is Disabled
 * @param {Object} strech
 * @param {String} step
 * @returns {Boolean}
 */
function isDisabled(strech, step) {
    if (!strech.strechID || !strech.isCreating || !DATA[step]) {
        return false;
    }
    const steps = strech.steps || [];
    if (steps.includes(step)) {
        return false;
    }
    for (const [ key, value ] of Object.entries(DATA)) {
        if (value.showIf(strech) && !steps.includes(key)) {
            return key !== step;
        }
    }
    return true;
}




// The public API
const API = {
    COACHES,
    PRICES,
    SCHEDULES,
    CHATS,
    CLASSES,
    MATERIALS,
    GUIDES,
    SPECIALITIES,
    QUESTIONS,
    STRECH,
    ACTIVATE,

    getList,
    getHelp,
    hasHelp,
    isCompleted,
    isDisabled,
};

export default API;
