import { setSection }        from "Actions/Core/CoreActions";
import { InscriptionReport } from "Utils/API";

// Types
import {
    INSCRIPTION_REPORT, INSCRIPTION_REPORT_LOADING,
    INSCRIPTION_REPORT_DATA, INSCRIPTION_REPORT_GRAPH,
} from "Utils/Types";



/**
 * Fetches the Inscription Data
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchInscriptionData(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : INSCRIPTION_REPORT_LOADING });
        }
        const data = await InscriptionReport.getData(params);
        dispatch({ type : INSCRIPTION_REPORT_DATA, data });
        dispatch(setSection(INSCRIPTION_REPORT));
    };
}

/**
 * Fetches the Inscription Graph
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch) => Void}
 */
export function fetchInscriptionGraph(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : INSCRIPTION_REPORT_LOADING });
        }
        const data = await InscriptionReport.getGraph(params);
        dispatch({ type : INSCRIPTION_REPORT_GRAPH, data });
        return data;
    };
}

/**
 * Fetches the Inscription Emails
 * @param {Object=} params
 * @returns {(dispatch) => Void}
 */
export function fetchInscriptionEmails(params = {}) {
    return async () => {
        return await InscriptionReport.getEmails(params);
    };
}
