import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchSpeciality, fetchSpecialityCreate, editSpeciality,
} from "Actions/Admin/Coach/SpecialityActions";



/**
 * The Coach Speciality Edit Dialog
 */
class SpecialityEdit extends React.Component {
    // The Initial Data
    initialData = {
        specialityID : 0,
        name         : "",
        priceType    : 0,
        genericPrice : "",
        position     : 0,
        status       : Status.getID("ACTIVE"),
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, position, fetchSpeciality, fetchSpecialityCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchSpeciality(elemID);
                loading = true;
            // Load edit data
            } else {
                fetchSpecialityCreate();
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData, position };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editSpeciality(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose } = this.props;
        const { data, loading, errors } = this.state;

        return <EditDialog
            open={open}
            icon="speciality"
            title={!!elemID ? "SPECIALITIES_EDIT_TITLE" : "SPECIALITIES_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                name="name"
                label="SPECIALITIES_ITS_NAME"
                value={data.name}
                onChange={this.handleChange}
                error={errors.name}
                isRequired
            />
            <InputField
                type="select"
                name="priceType"
                label="SPECIALITIES_PRICE_TYPE"
                options="SPECIALITIES_PRICE_TYPES"
                value={data.priceType}
                onChange={this.handleChange}
                error={errors.priceType}
                withNone
                isRequired
            />
            <InputField
                isHidden={Number(data.priceType) !== 3}
                type="number"
                icon="money"
                name="genericPrice"
                label="SPECIALITIES_GENERIC_PRICE"
                value={data.genericPrice}
                error={errors.genericPrice}
                onChange={this.handleChange}
                shrinkLabel
            />
            <Columns>
                <InputField
                    type="number"
                    name="position"
                    label="GENERAL_POSITION"
                    value={data.position}
                    error={errors.position}
                    onChange={this.handleChange}
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    error={errors.status}
                    onChange={this.handleChange}
                    options={Status.getFemSelect()}
                    withNone
                    isRequired
                    shrinkLabel
                />
            </Columns>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchSpeciality       : PropTypes.func.isRequired,
        fetchSpecialityCreate : PropTypes.func.isRequired,
        editSpeciality        : PropTypes.func.isRequired,
        open                  : PropTypes.bool.isRequired,
        onClose               : PropTypes.func.isRequired,
        onSubmit              : PropTypes.func.isRequired,
        edition               : PropTypes.number.isRequired,
        position              : PropTypes.number.isRequired,
        elem                  : PropTypes.object.isRequired,
        elemID                : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition  : state.coachSpeciality.edition,
            position : state.coachSpeciality.position,
            elem     : state.coachSpeciality.elem,
        };
    }
}

export default connect(SpecialityEdit.mapStateToProps, {
    fetchSpeciality, fetchSpecialityCreate, editSpeciality,
})(SpecialityEdit);
