import React                from "react";
import PropTypes            from "prop-types";

// Components
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Address Field
 */
class AddressField extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, errors, onChange, countries, provinces, isRequired } = this.props;

        return <Columns amount="3">
            <InputField
                type="select"
                name="countryID"
                label="USERS_COUNTRY"
                value={data.countryID}
                error={errors.countryID}
                onChange={onChange}
                options={countries}
                isRequired={isRequired}
                withNone
            />
            <InputField
                type="select"
                name="provinceID"
                label="PROVINCES_SINGULAR"
                value={data.provinceID}
                error={errors.provinceID}
                onChange={onChange}
                options={data.countryID ? provinces[data.countryID] || [] : []}
                isRequired={isRequired}
                withNone
            />
            <InputField
                name="address"
                label="GENERAL_ADRRESS"
                value={data.address}
                error={errors.address}
                onChange={onChange}
            />
        </Columns>;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        data       : PropTypes.object.isRequired,
        errors     : PropTypes.object.isRequired,
        countries  : PropTypes.array.isRequired,
        provinces  : PropTypes.object.isRequired,
        onChange   : PropTypes.func.isRequired,
        isRequired : PropTypes.bool,
    }
}

export default AddressField;
