import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    STRECH_CHAT_LOADING,
    STRECH_CHAT_LIST, STRECH_CHAT_ELEM,
    STRECH_CHAT_EDIT, STRECH_CHAT_CREATE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    isVirtual : false,
    coaches   : [],
    sort      : {
        orderBy  : "time",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.date            = DateTime.formatDate(elem.timeDate,    "dashes");
    elem.timeDate        = DateTime.formatDate(elem.timeDate,    "dashesReverse");
    elem.timeString      = NLS.format("GENERAL_DATE_TIME", elem.date, elem.fromHour, elem.toHour);
    elem.statusName      = Status.getFemName(elem.status);
    elem.statusColor     = Status.getColor(elem.status);
    elem.statusClass     = Status.getTextClass(elem.status);
    elem.scheduleTime    = [ elem.fromHour, elem.toHour ].join(" - ");
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, STRECH_CHAT_LIST, STRECH_CHAT_ELEM, STRECH_CHAT_EDIT, STRECH_CHAT_CREATE)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case STRECH_CHAT_LOADING:
        return {
            ...state,
            loading   : true,
        };

    case STRECH_CHAT_LIST:
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : parseList(action.data.list),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case STRECH_CHAT_ELEM:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            canEdit   : action.data.canEdit,
            elem      : parseElem(action.data.elem),
        };

    case STRECH_CHAT_EDIT:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            canEdit   : action.data.canEdit,
            elem      : parseElem(action.data.elem),
            isVirtual : action.data.isVirtual,
            coaches   : action.data.coaches,
        };

    case STRECH_CHAT_CREATE:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            isVirtual : action.data.isVirtual,
            coaches   : action.data.coaches,
        };

    default:
        return state;
    }
};

export default reducer;
