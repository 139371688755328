import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchCourse }      from "Actions/Admin/Course/CourseActions";

// Sections
import MaterialList         from "Components/Admin/Material/Material/MaterialList";
import GuideList            from "Components/Admin/Guide/Guide/GuideList";
import ProgramList          from "Components/Admin/Program/Program/ProgramList";
import StrechList           from "Components/Admin/Strech/Strech/StrechList";

// Components
import CourseDetails        from "./CourseDetails";
import SecondaryNav         from "Components/Utils/Navigation/SecondaryNav";
import AdminRoute           from "Components/Utils/Route/AdminRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Course Page
 */
class CoursePage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchCourse(this.props.params.courseID, true);
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.params.courseID !== prevProps.params.courseID) {
            this.props.fetchCourse(this.props.params.courseID, true);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, from, params, match, route } = this.props;
        const { path, url                        } = match;

        return <>
            <SecondaryNav
                message={elem.name}
                fallback="COURSES_SINGULAR"
                href={from}
                path={route}
                baseUrl={url}
            >
                <NavigationItem message="MATERIALS_NAME" url="MATERIALS" icon="material" />
                <NavigationItem message="GUIDES_NAME"    url="GUIDES"    icon="guide"    />
                <NavigationItem message="PROGRAMS_NAME"  url="PROGRAMS"  icon="program"  />
                <NavigationItem message="STRECHES_NAME"  url="STRECHES"  icon="strech"   />
            </SecondaryNav>

            <SwitchRoute type="COURSE" path={path} baseUrl={url} withDetails>
                <AdminRoute url="MATERIALS" component={MaterialList} />
                <AdminRoute url="GUIDES"    component={GuideList}    />
                <AdminRoute url="PROGRAMS"  component={ProgramList}  />
                <AdminRoute url="STRECHES"  component={StrechList}   />
            </SwitchRoute>

            <CourseDetails courseID={params.courseID} />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCourse : PropTypes.func.isRequired,
        elem        : PropTypes.object.isRequired,
        from        : PropTypes.string.isRequired,
        params      : PropTypes.object.isRequired,
        match       : PropTypes.object.isRequired,
        route       : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem : state.course.elem,
        };
    }
}

export default connect(CoursePage.mapStateToProps, {
    fetchCourse,
})(CoursePage);
