import { TicketMessage }    from "Utils/API";



/**
 * Adds a Message to the Ticket
 * @param {Object} params
 * @returns {() => Void}
 */
export function addMessage(params) {
    return () => TicketMessage.add(params);
}

/**
 * Edits a Message in the Ticket
 * @param {Number} ticketID
 * @param {Number} messageID
 * @param {String} message
 * @returns {() => Void}
 */
export function editMessage(ticketID, messageID, message) {
    return () => TicketMessage.edit({ ticketID, messageID, message });
}

/**
 * Deletes a Message from the Ticket
 * @param {Number} ticketID
 * @param {Number} messageID
 * @returns {() => Void}
 */
export function deleteMessage(ticketID, messageID) {
    return () => TicketMessage.delete({ ticketID, messageID });
}
