import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchClass, fetchClassCreate, editClass,
} from "Actions/Admin/Program/ClassActions";



/**
 * The Program Class Edit Dialog
 */
class ClassEdit extends React.Component {
    // The Initial Data
    initialData = {
        classID     : 0,
        programID   : 0,
        name        : "",
        description : "",
        videos      : "[\"\"]",
        position    : 0,
        status      : Status.getID("ACTIVE"),
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, programID, position, fetchClass, fetchClassCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchClass(elemID);
                loading = true;
            // Load edit data
            } else if (programID) {
                fetchClassCreate(programID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData, programID, position };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editClass(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose } = this.props;
        const { data, loading, errors } = this.state;

        return <EditDialog
            open={open}
            icon="class"
            title={!!elemID ? "CLASSES_EDIT_TITLE" : "CLASSES_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                name="name"
                label="CLASSES_ITS_NAME"
                value={data.name}
                error={errors.name}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="textarea"
                name="description"
                label="CLASSES_DESCRIPTION"
                value={data.description}
                error={errors.description}
                onChange={this.handleChange}
            />
            <InputField
                type="fields"
                name="videos"
                label="CLASSES_VIDEOS"
                fieldButton="CLASSES_ADD_VIDEO"
                value={data.videos}
                error={errors.videos}
                onChange={this.handleChange}
                isSmall
            />
            <Columns>
                <InputField
                    type="number"
                    name="position"
                    label="GENERAL_POSITION"
                    value={data.position}
                    error={errors.position}
                    onChange={this.handleChange}
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    error={errors.status}
                    onChange={this.handleChange}
                    options={Status.getFemSelect()}
                    withNone
                    isRequired
                    shrinkLabel
                />
            </Columns>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchClass       : PropTypes.func.isRequired,
        fetchClassCreate : PropTypes.func.isRequired,
        editClass        : PropTypes.func.isRequired,
        open             : PropTypes.bool.isRequired,
        onClose          : PropTypes.func.isRequired,
        onSubmit         : PropTypes.func.isRequired,
        edition          : PropTypes.number.isRequired,
        position         : PropTypes.number.isRequired,
        elem             : PropTypes.object.isRequired,
        elemID           : PropTypes.number,
        programID        : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition  : state.programClass.edition,
            position : state.programClass.position,
            elem     : state.programClass.elem,
        };
    }
}

export default connect(ClassEdit.mapStateToProps, {
    fetchClass, fetchClassCreate, editClass,
})(ClassEdit);
