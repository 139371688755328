import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Student, Coach }   from "Utils/API";
import Access               from "dashboard/dist/Core/Access";
import Status               from "dashboard/dist/Core/Status";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchConversationAssign, assignConversation,
} from "Actions/Admin/Conversation/ConversationActions";



/**
 * The Conversation Assign Dialog
 */
class ConversationAssign extends React.Component {
    // The Initial Data
    initialData = {
        access       : Access.getID("STUDENT"),
        strechID     : 0,
        specialityID : 0,
        userID       : 0,
        userName     : "",
        coachID      : 0,
        coachName    : "",
        invoicePrice : "",
        message      : "",
    }

    // The Current State
    state = {
        data   : { ...this.initialData },
        errors : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, userID, coachID, strechID, fetchConversationAssign } = this.props;

        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            fetchConversationAssign(strechID);
            loading = true;

        // Data Updated
        } else if (prevProps.edition !== edition) {
            data = { ...this.initialData, strechID, coachID };
            if (userID) {
                data.access = Access.getID("STUDENT");
                data.userID = userID;
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { assignConversation, onSubmit } = this.props;
        const { data, loading                } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await assignConversation(data);
                this.setState({ loading : false });
                onSubmit(Status.getID("ASSIGNED"));
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, strechID, studentID, coachID, specialities } = this.props;
        const { data, errors                                              } = this.state;

        return <EditDialog
            open={open}
            icon="conversation"
            title="CONVERSATIONS_ASSIGN_TITLE"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
        >
            <Columns isHidden={!!studentID}>
                <InputField
                    type="select"
                    name="access"
                    label="GENERAL_TYPE"
                    value={data.access}
                    error={errors.access}
                    onChange={this.handleChange}
                    options={Access.getSelect("USER")}
                    isRequired
                />
                <InputField
                    isHidden={!Access.is("STUDENT", data.access)}
                    name="userName"
                    label="STUDENTS_SINGULAR"
                    suggestID="userID"
                    suggestFetch={Student.search}
                    suggestParams={{ strechID }}
                    suggestNone="STUDENTS_NONE_AVAILABLE"
                    value={data.userName}
                    error={errors.userID}
                    onChange={this.handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!Access.is("COACH", data.access)}
                    name="userName"
                    label="CONVERSATIONS_COACHEE"
                    suggestID="userID"
                    suggestFetch={Coach.search}
                    suggestParams={{ strechID, asStudent : 1 }}
                    suggestNone="COACHES_NONE_AVAILABLE"
                    value={data.userName}
                    error={errors.userID}
                    onChange={this.handleChange}
                    isRequired
                />
            </Columns>
            <InputField
                isHidden={!!coachID}
                name="coachName"
                label="COACHES_SINGULAR"
                suggestID="coachID"
                suggestFetch={Coach.search}
                suggestParams={{ strechID }}
                suggestNone="COACHES_NONE_AVAILABLE"
                value={data.coachName}
                error={errors.coachID}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="select"
                name="specialityID"
                label="SPECIALITIES_SHORT"
                value={data.specialityID}
                error={errors.specialityID}
                onChange={this.handleChange}
                options={specialities}
                withNone
                isRequired
            />
            <InputField
                type="number"
                icon="money"
                name="invoicePrice"
                label="CONVERSATIONS_INVOICE_PRICE"
                value={data.invoicePrice}
                error={errors.invoicePrice}
                onChange={this.handleChange}
                shrinkLabel
            />
            <InputField
                type="textarea"
                name="message"
                label="CONVERSATIONS_MESSAGE_ASSIGN"
                value={data.message}
                error={errors.message}
                onChange={this.handleChange}
                isRequired
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchConversationAssign : PropTypes.func.isRequired,
        assignConversation      : PropTypes.func.isRequired,
        open                    : PropTypes.bool.isRequired,
        onClose                 : PropTypes.func.isRequired,
        onSubmit                : PropTypes.func.isRequired,
        edition                 : PropTypes.number.isRequired,
        specialities            : PropTypes.array.isRequired,
        userID                  : PropTypes.number,
        coachID                 : PropTypes.number,
        strechID                : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition      : state.conversation.edition,
            specialities : state.conversation.speciality,
        };
    }
}

export default connect(ConversationAssign.mapStateToProps, {
    fetchConversationAssign, assignConversation,
})(ConversationAssign);
