import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchDashboard }   from "Actions/Admin/DashboardActions";
import NLS                  from "dashboard/dist/Core/NLS";
import Href                 from "dashboard/dist/Core/Href";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import MenuLink             from "dashboard/dist/Components/MenuLink";
import HyperLink            from "dashboard/dist/Components/HyperLink";

// Styles
import "Styles/Components/App/Dashboard.css";



/**
 * The Dashboard Page
 */
class DashboardPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchDashboard();
    }

    /**
     * Handles the Click
     * @param {Object} elem
     * @returns {Void}
     */
    handleClick = (elem) => {
        switch (elem.type) {
        case "student":
            Href.goto("STUDENTS", elem.id);
            break;
        case "inscription":
            Href.goto("STRECHES", elem.id, "INSCRIPTIONS", "REQUESTS");
            break;
        case "ticket":
            Href.goto("TICKETS", elem.id);
            break;
        case "lead":
            Href.goto("LEADS", elem.id);
            break;
        default:
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            loading, route, advice,
            students, inscriptions, tickets, leads,
        } = this.props;

        const lists = [
            {
                type  : "students",
                title : "DASHBOARD_STUDENTS",
                none  : "STUDENTS_NONE_SIGNUPS",
                list  : students,
                url   : "DASHBOARD_SIGNUPS",
            },
            {
                type  : "inscriptions",
                title : "DASHBOARD_INSCRIPTIONS",
                none  : "INSCRIPTIONS_NONE_REQUESTS",
                list  : inscriptions,
                url   : "DASHBOARD_INSCRIPTIONS",
            },
            {
                type  : "tickets",
                title : "DASHBOARD_TICKETS",
                none  : "TICKETS_NONE_AVAILABLE",
                list  : tickets,
                url   : "TICKETS",
            },
            {
                type  : "leads",
                title : "DASHBOARD_LEADS",
                none  : "LEADS_NONE_AVAILABLE",
                list  : leads,
                url   : "LEADS",
            },
        ];

        return <Main>
            <Header message="DASHBOARD_NAME" icon="home" route={route} />
            <Content className="dashboard" isLoading={loading}>
                <section>
                    <div className="dashboard-content">
                        {lists.map((list) => <div key={list.type}>
                            <h3 className="dashboard-title">{NLS.get(list.title)}</h3>
                            {list.list.length > 0 ? <>
                                {list.list.map((elem) => <MenuLink
                                    key={elem.key}
                                    className="dashboard-item"
                                    variant="light"
                                    html={`<i>${elem.date}</i> <span>${elem.text}</span>`}
                                    onClick={() => this.handleClick(elem)}
                                />)}
                                <HyperLink
                                    className="dashboard-link"
                                    variant="primary"
                                    message="DASHBOARD_SHOW_ALL"
                                    url={list.url}
                                />
                            </> : <p>{NLS.get(list.none)}</p>}
                        </div>)}
                    </div>
                </section>

                {!!advice && <section>
                    <div className="dashboard-advice">
                        <img src={advice.imageUrl} alt={advice.name} />
                    </div>
                </section>}
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchDashboard : PropTypes.func.isRequired,
        loading        : PropTypes.bool.isRequired,
        advice         : PropTypes.object,
        students       : PropTypes.array.isRequired,
        inscriptions   : PropTypes.array.isRequired,
        tickets        : PropTypes.array.isRequired,
        leads          : PropTypes.array.isRequired,
        route          : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loading      : state.dashboard.loading,
            advice       : state.dashboard.advice,
            students     : state.dashboard.students,
            inscriptions : state.dashboard.inscriptions,
            tickets      : state.dashboard.tickets,
            leads        : state.dashboard.leads,
        };
    }
}

export default connect(DashboardPage.mapStateToProps, {
    fetchDashboard,
})(DashboardPage);
