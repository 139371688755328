import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Types
import {
    STRECH_GUIDE_LOADING,
    STRECH_GUIDE_LIST, STRECH_GUIDE_ELEM,
    STRECH_GUIDE_EDIT, STRECH_GUIDE_CREATE,
    STRECH_GUIDE_STUDENTS,
} from "Utils/Types";



// The initial State
const initialState = {
    loading      : false,
    error        : false,
    edition      : 0,
    canCreate    : false,
    canEdit      : false,
    canImport    : false,
    canExport    : false,
    canReply     : false,
    canFeedback  : false,
    isOngoing    : false,
    list         : [],
    total        : 0,

    elem         : {},
    questions    : {},
    answers      : {},
    answersSent  : false,
    feedback     : {},
    feedbackSent : false,
    students     : [],

    position     : 0,
    guides       : [],
    classes      : [],

    sort         : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.classString     = Common.getClassName(elem);
    elem.fullName        = `${elem.guideName} (${elem.classString})`;
    elem.statusName      = Status.getName(elem.status);
    elem.statusColor     = Status.getColor(elem.status);
    elem.statusClass     = Status.getTextClass(elem.status);
    return elem;
}

/**
 * Parses the Answers
 * @param {Object[]} list
 * @returns {Object}
 */
function parseAnswers(list) {
    const result = {};
    Utils.parseList(list, (elem) => {
        result[elem.questionID] = elem.answer;
    });
    return result;
}

/**
 * Parses the Feedback
 * @param {Object[]} list
 * @returns {Object}
 */
function parseFeedback(list) {
    const result = {};
    Utils.parseList(list, (elem) => {
        result[elem.sectionID] = elem.feedback;
    });
    return result;
}

/**
 * Parses the Students
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseStudents(list) {
    return Utils.parseList(list, (elem) => {
        elem.sentUser     = elem.feedbackTime ? NLS.format("GUIDES_SENT_USER", elem.credentialName, elem.feedbackName) : elem.credentialName;
        elem.sentDate     = DateTime.formatDate(elem.sentTime,     "dayMonth");
        elem.feedbackDate = DateTime.formatDate(elem.feedbackTime, "dayMonth");
        elem.feedbackText = elem.feedbackTime ? elem.feedbackDate : NLS.get("GUIDES_NO_FEEDBACK");
    });
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, STRECH_GUIDE_LIST, STRECH_GUIDE_ELEM, STRECH_GUIDE_EDIT, STRECH_GUIDE_CREATE)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case STRECH_GUIDE_LOADING:
        return {
            ...state,
            loading      : true,
        };

    case STRECH_GUIDE_LIST:
        return {
            ...state,
            loading      : false,
            error        : false,
            canCreate    : action.data.canCreate,
            canEdit      : action.data.canEdit,
            canImport    : action.data.canImport,
            canExport    : action.data.canExport,
            canReply     : action.data.canReply,
            canFeedback  : action.data.canFeedback,
            list         : parseList(action.data.list),
            total        : action.data.total,
            sort         : action.data.sort,
        };

    case STRECH_GUIDE_ELEM:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            canEdit      : action.data.canEdit,
            canReply     : action.data.canReply,
            canFeedback  : action.data.canFeedback,
            isOngoing    : action.data.isOngoing,
            elem         : parseElem(action.data.elem),
            questions    : action.data.questions,
            answers      : parseAnswers(action.data.answers),
            answersSent  : Boolean(action.data.answers[0] && action.data.answers[0].isSent),
            feedback     : parseFeedback(action.data.feedback),
            feedbackSent : Boolean(action.data.feedback[0] && action.data.feedback[0].isSent),
        };

    case STRECH_GUIDE_EDIT:
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            canEdit      : action.data.canEdit,
            elem         : parseElem(action.data.elem),
            position     : action.data.position,
            guides       : action.data.guides,
            classes      : action.data.classes,
        };

    case STRECH_GUIDE_CREATE:
        return {
            ...state,
            edition      : state.edition + 1,
            position     : action.data.position,
            guides       : action.data.guides,
            classes      : action.data.classes,
        };

    case STRECH_GUIDE_STUDENTS:
        return {
            ...state,
            edition      : state.edition + 1,
            canEdit      : action.data.canEdit,
            elem         : parseElem(action.data.elem),
            students     : parseStudents(action.data.students),
        };

    default:
        return state;
    }
};

export default reducer;
