import NLS                  from "dashboard/dist/Core/NLS";

// Chart
import {
    Chart, registerables,
} from "chart.js";
Chart.register(...registerables);

// Constants
const DATA_AMOUNT   = 1;
const DATA_PRICE    = 2;

const TOTAL_COLUMNS = 20;



/**
 * Creates a Bar Chart
 * @param {React.RefObject} ref
 * @param {Object}          data
 * @param {Number}          dataType
 * @returns {Chart}
 */
function barChart(ref, data, dataType) {
    const context = ref.current.getContext("2d");
    const labels  = getLabels(data);
    const dataset = getDataset(data, dataType);

    return new Chart(context, {
        type : "bar",
        data : {
            labels   : labels,
            datasets : [{
                label           : NLS.get("REPORTS_DATA_OPTIONS", dataType),
                backgroundColor : "#642f70",
                data            : dataset,
            }],
        },
        options : {
            locale              : "es",
            maintainAspectRatio : false,
            scales              : {
                y : { beginAtZero : true },
            },
            plugins             : {
                legend  : {
                    display : false
                },
                tooltip : {
                    callbacks : {
                        title(context) {
                            return data[context[0].dataIndex].name;
                        },
                        label(context) {
                            var label = context.dataset.label + ": ";
                            if (dataType === DATA_PRICE) {
                                label += "$";
                            }
                            label += context.formattedValue;
                            return label;
                        },
                    },
                },
            },
        },
    });
}



/**
 * Returns the Labels for the Chart
 * @param {Object[]} data
 * @return {String[]}
 */
function getLabels(data) {
    const amount = data.length <= 5 ? 30 : (data.length <= 10 ? 15 : 10);
    const result = [];

    for (const elem of data) {
        let   name      = String(elem.name);
        const shortName = name.substr(0, amount);
        if (name !== shortName) {
            result.push(shortName + "...");
        } else {
            result.push(name);
        }
        if (result.length >= TOTAL_COLUMNS) {
            break;
        }
    }
    return result;
}

/**
 * Returns the Dataset for the report
 * @param {Object[]} data
 * @return {Number[]}
 */
function getDataset(data, dataType) {
    const result = [];
    for (const elem of data) {
        if (dataType === DATA_AMOUNT) {
            result.push(elem.totalAmount);
        } else {
            result.push(elem.totalPrice);
        }
        if (result.length >= TOTAL_COLUMNS) {
            break;
        }
    }
    return result;
}



// The public API
const API = {
    DATA_AMOUNT,
    DATA_PRICE,

    barChart,
};

export default API;
