import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Conversation }     from "Utils/API";

// Types
import {
    CONVERSATION, CONVERSATION_LOADING,
    CONVERSATION_LIST, CONVERSATION_ELEM,
    CONVERSATION_ASSIGN, CONVERSATION_REQUEST,
    CONVERSATION_CREATE, CONVERSATION_STUDENTS,
    CONVERSATION_EDIT,
} from "Utils/Types";



/**
 * Fetches the Conversation List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchConversations(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : CONVERSATION_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "STUDENT":
            params.studentID = elemID;
            data = await Conversation.getAllForStudent(params);
            break;
        case "COACH":
            params.coachID = elemID;
            data = await Conversation.getAllForCoach(params);
            break;
        case "STRECH":
            params.strechID = elemID;
            data = await Conversation.getAllForStrech(params);
            break;
        case "LOCATION":
            params.locationID = elemID;
            data = await Conversation.getAllForLocation(params);
            break;
        case "USER":
            data = await Conversation.getAllAsCoach(params);
            break;
        default:
            data = await Conversation.getAll(params);
        }
        data.sort = params;
        dispatch({ type : CONVERSATION_LIST, data });
        dispatch(setSection(CONVERSATION));
    };
}

/**
 * Fetches the Conversation List as Student
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchConvsAsStudent(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : CONVERSATION_LOADING });
        Params.unset(params);
        if (type === "STRECH") {
            params.strechID = elemID;
        }
        let data = await Conversation.getAllAsStudent(params);
        data.sort = params;
        dispatch({ type : CONVERSATION_LIST, data });
        dispatch(setSection(CONVERSATION));
    };
}

/**
 * Fetches a single Conversation
 * @param {Number} conversationID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchConversation(conversationID) {
    return async (dispatch) => {
        const data = await Conversation.getOne({ conversationID });
        dispatch({ type : CONVERSATION_ELEM, data });
    };
}



/**
 * Fetches the Conversation Request data
 * @param {Number=} strechID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchConversationAssign(strechID = 0) {
    return async (dispatch) => {
        const data = await Conversation.getAssignData({ strechID });
        dispatch({ type : CONVERSATION_ASSIGN, data });
    };
}

/**
 * Fetches the Conversation Request data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchConversationRequest() {
    return async (dispatch) => {
        const data = await Conversation.getRequestData();
        dispatch({ type : CONVERSATION_REQUEST, data });
    };
}

/**
 * Fetches the Conversation Create data
 * @param {Number} strechID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchConversationCreate(strechID) {
    return async (dispatch) => {
        const data = await Conversation.getCreateData({ strechID });
        dispatch({ type : CONVERSATION_CREATE, data });
    };
}

/**
 * Fetches the Conversation Students data
 * @param {Number} strechID
 * @param {Number} specialityID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchConversationStudents(strechID, specialityID) {
    return async (dispatch) => {
        const data = await Conversation.getStudentsData({ strechID, specialityID });
        dispatch({ type : CONVERSATION_STUDENTS, data });
    };
}

/**
 * Fetches the Conversation Edit data
 * @param {Number} conversationID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchConversationEdit(conversationID) {
    return async (dispatch) => {
        const data = await Conversation.getEditData({ conversationID });
        dispatch({ type : CONVERSATION_EDIT, data });
    };
}



/**
 * Requests a Conversation
 * @param {Object} data
 * @returns {() => Void}
 */
export function requestConversation(data) {
    return () => Conversation.request(data);
}

/**
 * Unrequests a Conversation
 * @param {Number} conversationID
 * @returns {() => Void}
 */
export function unrequestConversation(conversationID) {
    return () => Conversation.unrequest({ conversationID });
}

/**
 * Assigns a Conversation
 * @param {Object} data
 * @returns {() => Void}
 */
export function assignConversation(data) {
    return () => Conversation.assign(data);
}

/**
 * Creates a Conversation
 * @param {Object} data
 * @returns {() => Void}
 */
export function createConversation(data) {
    return () => Conversation.create(data);
}

/**
 * Programs a Conversation
 * @param {Object} data
 * @returns {() => Void}
 */
export function programConversation(data) {
    return () => Conversation.program(data);
}

/**
 * Edits a Conversation
 * @param {Object} data
 * @returns {() => Void}
 */
export function editConversation(data) {
    return () => Conversation.edit(data);
}

/**
 * Reports a Conversation
 * @param {Number} conversationID
 * @param {String} report
 * @returns {() => Void}
 */
export function reportConversation(conversationID, report) {
    return () => Conversation.report({ conversationID, report });
}

/**
 * Cancels a Conversation
 * @param {Object} data
 * @returns {() => Void}
 */
export function cancelConversation(data) {
    return () => Conversation.cancel(data);
}

/**
 * Deletes a Conversation
 * @param {Number} conversationID
 * @returns {() => Void}
 */
export function deleteConversation(conversationID) {
    return () => Conversation.delete({ conversationID });
}
