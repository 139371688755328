import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";
import NLS                  from "dashboard/dist/Core/NLS";
import HistoryAction        from "Utils/Entity/HistoryAction";

// Dialogs
import LeadEdit             from "./LeadEdit";
import LeadAssign           from "./LeadAssign";
import LeadClose            from "./LeadClose";

import Button               from "dashboard/dist/Components/Button";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import History              from "dashboard/dist/Components/History";
import HistoryItem          from "dashboard/dist/Components/HistoryItem";
import HistoryMessage       from "dashboard/dist/Components/HistoryMessage";
import HistoryInput         from "dashboard/dist/Components/HistoryInput";
import MultiLine            from "dashboard/dist/Components/MultiLine";
import Details              from "dashboard/dist/Components/Details";
import DetailList           from "dashboard/dist/Components/DetailList";
import DetailItem           from "dashboard/dist/Components/DetailItem";
import DetailActionList     from "dashboard/dist/Components/DetailActionList";
import DetailAction         from "dashboard/dist/Components/DetailAction";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import MediaField           from "Components/Utils/Media/MediaField";

// Actions
import {
    fetchLead, openLead, sendMessage,
} from "Actions/Admin/Lead/LeadActions";



/**
 * The Lead Page
 */
class LeadPage extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
        lead_history_file    : "",
        editorState: EditorState.createEmpty(),
        errors  : {},
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const { params, fetchLead } = this.props;
        fetchLead(params.leadID, true);
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { params, fetchLead } = this.props;
        if (params.leadID !== prevProps.params.leadID) {
            fetchLead(params.leadID, true);
        }
    }

    handleChange = (name, value) => {
        this.setState({
            lead_history_file : value,
            errors : { ...this.state.errors, [name] : ""    },
        });
    }



    /**
     * Handles the History Click
     * @param {Object} item
     * @returns {Void}
     */
    handleClick = (item) => {
        switch (item.action) {
        case HistoryAction.CREATE:
        case HistoryAction.EDIT:
            this.startAction(Action.create("EDIT"), item.leadID);
            break;
        case HistoryAction.STRECH:
        case HistoryAction.INSCRIPTION:
            Href.goto("STRECHES", item.strechID, "INSCRIPTIONS");
            break;
        default:
        }
    }

    /**
     * Handles the History Submit
     * @param {String} message
     * @returns {Promise}
     */
    handleSubmit = async (message) => {
        if (message) {
            const { elem, sendMessage, fetchLead } = this.props;
            await sendMessage(elem.leadID, message);
            fetchLead(elem.leadID, true);
        }
    }

    enviamensaje = async () => {
        const rawContentState = convertToRaw(this.state.editorState.getCurrentContent());
        const message = draftToHtml(rawContentState);
        const leadfile = this.state.lead_history_file;

        if (message) {
            const { elem, sendMessage, fetchLead } = this.props;

            await sendMessage(elem.leadID, message, leadfile);
            fetchLead(elem.leadID, true);
        }
    }


    /**
     * Starts an Action
     * @param {Object}  action
     * @param {Number=} elemID
     * @returns {Void}
     */
    startAction = (action, elemID = 0) => {
        if (action.isStudent) {
            Href.goto("STUDENTS", this.props.elem.credentialID);
        } else if (action.isCoach) {
            Href.goto("COACHES", this.props.elem.credentialID);
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @param {Boolean=} update
     * @returns {Void}
     */
    endAction = (update) => {
        this.startAction(Action.get());
        if (update) {
            this.props.fetchLead(this.props.elem.leadID);
        }
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction(true);
    }

    /**
     * Handles the Open Submit
     * @returns {Promise}
     */
    openElem = async () => {
        const { elem, openLead } = this.props;
        if (elem.isClosed) {
            const response = await openLead(elem.leadID);
            this.endAction(response.success);
        }
    }


    
    onEditorStateChange: Function = (editorState) => {
      this.setState({
        editorState,
      });
    };

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { charging, error, canEdit, elem, history, credential, route } = this.props;
        const { action, editorState, errors, lead_history_file             } = this.state;

        return <>
            <Main withDetails>
                <Header message="LEADS_SINGULAR" icon="lead" route={route} />

                <Content isLoading={charging}>
                    <History>
                        <HistoryMessage
                            title={elem.historyTitle}
                            avatar={elem.credentialAvatar}
                            name={elem.credentialName}
                            email={elem.credentialEmail}
                        >
                            <p><b>{NLS.get("INTERESTS_SINGULAR")}:</b> {elem.interestName}</p>
                            <MultiLine variant="p" content={elem.description} noWrapper />
                        </HistoryMessage>
                        {history.map((item) => item.isMessage ? <HistoryMessage
                            key={item.historyID}
                            title={item.title}
                            avatar={item.createdAvatar}
                            name={item.createdName}
                            email={item.createdEmail}
                        >
                         <div dangerouslySetInnerHTML={{ __html: item.message }} />
                        </HistoryMessage> : <HistoryItem
                            key={item.historyID}
                            icon={item.icon}
                            message={item.content}
                            detail={item.detail}
                            date={item.createdDate}
                            canClick={item.canClick}
                            onClick={() => this.handleClick(item)}
                        />)}
                        {/*
                        <HistoryInput
                            avatar={credential.avatar}
                            name={credential.credentialName}
                            email={credential.email}
                            onSubmit={this.handleSubmit}
                        />*/}

                <div  style={{display : 'flex', justifyContent : 'end'}}>
                <Button
                    variant="primary"
                    message="GENERAL_SEND_MESSAGE"
                    onClick={this.enviamensaje}
                />
                </div>

                <MediaField
                    name="lead_history_file"
                    label="GENERAL_ATTACH"
                    value={lead_history_file}
                    error={errors.lead_history_file}
                    onChange={this.handleChange}
                />

                <div style={{ border: "1px solid black", padding: '5px', minHeight: '300px' }}>
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={this.onEditorStateChange}
                />
              </div>



                    </History>
                </Content>
            </Main>

            <Details
                isLoading={charging}
                hasError={error}
                error="LEADS_ERROR_EXISTS"
            >
                <DetailList message="LEADS_DETAILS_TITLE">
                    <DetailItem
                        icon="user"
                        message={elem.credentialName}
                        tooltip="LEADS_CONTACT"
                    />
                    <DetailItem
                        icon="level"
                        message={elem.levelName}
                        tooltip="GENERAL_LEVEL"
                    />
                    <DetailItem
                        icon="email"
                        message={elem.credentialEmail}
                        tooltip="GENERAL_EMAIL"
                        isEmail
                    />
                    <DetailItem
                        icon="whatsapp"
                        message={elem.phoneString}
                        tooltip="USERS_CELLPHONE"
                        isWhatsApp
                    />
                    <DetailItem
                        icon="origin"
                        message={elem.originName}
                        tooltip="ORIGINS_SINGULAR"
                    />
                    <DetailItem
                        icon="interest"
                        message={elem.interestName}
                        tooltip="INTERESTS_SINGULAR"
                    />
                    <DetailItem
                        icon="assign"
                        message={elem.assignedName}
                        tooltip="LEADS_ASSIGNED"
                    />
                    <DetailItem
                        icon="time"
                        className={`text-${elem.lastReplyColor}`}
                        message={elem.lastReplyString}
                        tooltip="GENERAL_CREATED"
                    />
                    <DetailItem
                        icon="status"
                        message={elem.statusName}
                        tooltip="GENERAL_STATUS"
                        className={elem.statusClass}
                    />
                </DetailList>
                <DetailActionList onAction={this.startAction} canEdit={canEdit}>
                    <DetailAction action="EDIT"    message="LEADS_EDIT_TITLE"    />
                    <DetailAction action="ASSIGN"  message="LEADS_ASSIGN_TITLE"  />
                    <DetailAction action="OPEN"    message="LEADS_OPEN_TITLE"    isHidden={!elem.isClosed}  />
                    <DetailAction action="CLOSE"   message="LEADS_CLOSE_TITLE"   isHidden={elem.isClosed}   />
                    <DetailAction action="STUDENT" message="STUDENTS_VIEW_TITLE" isHidden={!elem.isStudent} />
                    <DetailAction action="COACH"   message="COACHES_VIEW_TITLE"  isHidden={elem.isStudent}  />
                </DetailActionList>
            </Details>

            <LeadEdit
                open={action.isEdit}
                elemID={elem.leadID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <LeadAssign
                open={action.isAssign}
                elemID={elem.leadID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <LeadClose
                open={action.isClose}
                elemID={elem.leadID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isOpen}
                icon="status"
                title="LEADS_OPEN_TITLE"
                message="LEADS_OPEN_TEXT"
                content={elem.descriptionShort}
                onSubmit={this.openElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchLead   : PropTypes.func.isRequired,
        openLead    : PropTypes.func.isRequired,
        sendMessage : PropTypes.func.isRequired,
        charging    : PropTypes.bool.isRequired,
        error       : PropTypes.bool.isRequired,
        canEdit     : PropTypes.bool.isRequired,
        elem        : PropTypes.object.isRequired,
        history     : PropTypes.array.isRequired,
        credential  : PropTypes.object.isRequired,
        route       : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            charging   : state.lead.charging,
            error      : state.lead.error,
            canEdit    : state.lead.canEdit,
            elem       : state.lead.elem,
            history    : state.lead.history,
            credential : state.auth.credential,
        };
    }
}

export default connect(LeadPage.mapStateToProps, {
    fetchLead, openLead, sendMessage,
})(LeadPage);
