import React                from "react";
import PropTypes            from "prop-types";

// Components
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableFoot            from "dashboard/dist/Components/TableFoot";
import TableCell            from "dashboard/dist/Components/TableCell";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";



/**
 * The Report Table
 */
class ReportTable extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, withPrice, startAction } = this.props;

        if (!data.fetched) {
            return <React.Fragment />;
        }

        return <section className="report-table">
            <Table none="REPORTS_NONE_AVAILABLE" noSorting noClick notFixed>
                <TableHead>
                    <TableHeader field="name"        message="GENERAL_NAME"         grow="2" />
                    <TableHeader field="totalAmount" message="REPORTS_TOTAL_AMOUNT" align="center" />
                    <TableHeader field="totalPrice"  message="REPORTS_TOTAL_PRICE"  align="right"  isHidden={!withPrice} />
                </TableHead>
                <TableBody>
                    {data.report.map((elem) => <TableRow key={elem.id} elemID={elem.id}>
                        <TableCell message={elem.name}              />
                        <TableCell message={elem.totalAmount}       />
                        <TableCell message={`$ ${elem.totalPrice}`} />
                    </TableRow>)}
                </TableBody>
                <TableFoot>
                    <TableHeader message="GENERAL_TOTAL" />
                    <TableHeader message={data.totalAmount} />
                    <TableHeader message={`$ ${data.totalPrice}`} />
                </TableFoot>
                <TableActionList onAction={startAction} canEdit>
                    <TableAction action="DOWNLOAD" message="REPORTS_DOWNLOAD_LIST" />
                    <TableAction action="CAMPAIGN" message="CAMPAIGNS_SEND_TITLE"  />
                </TableActionList>
            </Table>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data        : PropTypes.object.isRequired,
        startAction : PropTypes.func.isRequired,
        withPrice   : PropTypes.bool,
    }
}

export default ReportTable;
