import React                from "react";
import PropTypes            from "prop-types";
import Action               from "dashboard/dist/Core/Action";

// Dialogs
import MaterialView         from "Components/Admin/Strech/Material/MaterialView";
import GuideView            from "Components/Admin/Strech/Guide/GuideView";

// Components
import Details              from "dashboard/dist/Components/Details";
import DetailList           from "dashboard/dist/Components/DetailList";
import DetailItem           from "dashboard/dist/Components/DetailItem";
import AlertDialog          from "dashboard/dist/Components/AlertDialog";



/**
 * The Strech Class Details
 */
class ClassDetails extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
     componentDidMount() {
        if (this.props.guideID) {
            this.startAction(Action.get("GUIDE"), this.props.guideID);
        }
    }

    /**
     * Starts an Action
     * @param {Object}  action
     * @param {Number=} elemID
     * @returns {Void}
     */
    startAction = (action, elemID = 0) => {
        this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isLoading, elem, materials, guides } = this.props;
        const { action, elemID                     } = this.state;

        if (!elem) {
            return <React.Fragment />;
        }
        const children = [];
        let   key      = 0;

        if (elem.streamLink) {
            children.push(<DetailList key={key} message="GENERAL_STREAM_LINK">
                <DetailItem
                    message="GENERAL_STREAM_ZOOM"
                    href={elem.streamLink}
                    target="_blank"
                />
                {!!elem.streamPassword && <DetailItem
                    message={elem.streamPassword}
                    tooltip="USERS_PASSWORD"
                    withTip
                />}
            </DetailList>);
        }
        if (elem.feedback) {
            children.push(<DetailList key={key} message="CLASSES_FEEDBACK">
                <DetailItem
                    message={elem.feedbackShort}
                    onClick={() => this.startAction(Action.get("FEEDBACK"))}
                />
            </DetailList>);
        }
        if (elem.hasGuides && guides && guides.length > 0) {
            children.push(<DetailList key={key} message="GUIDES_NAME">
                {guides.map((elem, index) => <DetailItem
                    key={elem.strechGuideID}
                    icon="guide"
                    message={`${index + 1}. ${elem.guideName}`}
                    onClick={() => this.startAction(Action.get("GUIDE"), elem.strechGuideID)}
                />)}
            </DetailList>);
        }
        if (elem.hasMaterials && materials && materials.length > 0) {
            for (const elem of materials) {
                children.push(<DetailList key={key} message={elem.name}>
                    {elem.list.map((item, index) => <DetailItem
                        key={item.strechMaterialID}
                        icon="material"
                        message={`${index + 1}. ${item.materialName}`}
                        onClick={() => this.startAction(Action.get("MATERIAL"), item.strechMaterialID)}
                    />)}
                </DetailList>);
                key += 1;
            }
        }

        return <>
            <Details className="class-details" isLoading={isLoading} topSpace={72}>
                {children}
            </Details>

            <AlertDialog
                open={action.isFeedback}
                title="CLASSES_FEEDBACK"
                message={elem ? elem.feedback : ""}
                onClose={this.endAction}
            />
            <MaterialView
                open={action.isMaterial}
                elemID={elemID}
                onClose={this.endAction}
            />
            <GuideView
                open={action.isGuide}
                elemID={elemID}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isLoading : PropTypes.bool,
        elem      : PropTypes.object,
        guides    : PropTypes.array,
        materials : PropTypes.array,
        guideID   : PropTypes.number,
    }
}

export default ClassDetails;
