import { combineReducers }       from "redux";

// Core
import CoreReducer               from "Reducers/Core/CoreReducer";
import AuthReducer               from "Reducers/Core/AuthReducer";
import MediaReducer              from "Reducers/Core/MediaReducer";

// Student
import HomeReducer               from "Reducers/Student/HomeReducer";
import CalendarReducer           from "Reducers/Student/CalendarReducer";
import EnrollReducer             from "Reducers/Student/EnrollReducer";

// Admin
import DashboardReducer          from "Reducers/Admin/DashboardReducer";

// Course
import CourseReducer             from "Reducers/Admin/Course/CourseReducer";
import CourseCompletedReducer    from "Reducers/Admin/Course/CompletedReducer";

// Program
import ProgramReducer            from "Reducers/Admin/Program/ProgramReducer";
import ProgramPriceReducer       from "Reducers/Admin/Program/PriceReducer";
import ProgramChatReducer        from "Reducers/Admin/Program/ChatReducer";
import ProgramClassReducer       from "Reducers/Admin/Program/ClassReducer";
import ProgramMaterialReducer    from "Reducers/Admin/Program/MaterialReducer";
import ProgramGuideReducer       from "Reducers/Admin/Program/GuideReducer";
import ProgramSpecialityReducer  from "Reducers/Admin/Program/SpecialityReducer";
import ProgramQuestionReducer    from "Reducers/Admin/Program/QuestionReducer";

// Strech
import StrechReducer             from "Reducers/Admin/Strech/StrechReducer";
import StrechPriceReducer        from "Reducers/Admin/Strech/PriceReducer";
import StrechScheduleReducer     from "Reducers/Admin/Strech/ScheduleReducer";
import StrechCoachReducer        from "Reducers/Admin/Strech/CoachReducer";
import StrechChatReducer         from "Reducers/Admin/Strech/ChatReducer";
import StrechClassReducer        from "Reducers/Admin/Strech/ClassReducer";
import StrechMaterialReducer     from "Reducers/Admin/Strech/MaterialReducer";
import StrechGuideReducer        from "Reducers/Admin/Strech/GuideReducer";
import StrechSpecialityReducer   from "Reducers/Admin/Strech/SpecialityReducer";
import StrechQuestionReducer     from "Reducers/Admin/Strech/QuestionReducer";
import StrechAnswerReducer       from "Reducers/Admin/Strech/AnswerReducer";
import InscriptionReducer        from "Reducers/Admin/Strech/InscriptionReducer";
import InscriptionChatReducer    from "Reducers/Admin/Strech/InscriptionChatReducer";
import InscriptionReasonReducer  from "Reducers/Admin/Strech/InscriptionReasonReducer";
import InscriptionAssignReducer  from "Reducers/Admin/Strech/InscriptionAssignReducer";
import PresentismReducer         from "Reducers/Admin/Strech/PresentismReducer";
import GroupReducer              from "Reducers/Admin/Strech/GroupReducer";
import MemberReducer             from "Reducers/Admin/Strech/MemberReducer";
import MeetingReducer            from "Reducers/Admin/Strech/MeetingReducer";

// Cnversation
import ConversationReducer       from "Reducers/Admin/Conversation/ConversationReducer";

// Material
import MaterialReducer           from "Reducers/Admin/Material/MaterialReducer";
import MatCategoryReducer        from "Reducers/Admin/Material/CategoryReducer";

// Guide
import GuideReducer              from "Reducers/Admin/Guide/GuideReducer";
import GuideSectionReducer       from "Reducers/Admin/Guide/SectionReducer";
import GuideQuestionReducer      from "Reducers/Admin/Guide/QuestionReducer";

// Location
import LocationReducer           from "Reducers/Admin/Location/LocationReducer";
import LocRoomReducer            from "Reducers/Admin/Location/RoomReducer";
import LocScheduleReducer        from "Reducers/Admin/Location/ScheduleReducer";

// Ticket
import TicketReducer             from "Reducers/Admin/Ticket/TicketReducer";
import TicketTypeReducer         from "Reducers/Admin/Ticket/TicketTypeReducer";
import HelpSectionReducer        from "Reducers/Admin/Ticket/SectionReducer";
import HelpQuestionReducer       from "Reducers/Admin/Ticket/QuestionReducer";

// Users
import StudentReducer            from "Reducers/Admin/User/StudentReducer";
import CoachReducer              from "Reducers/Admin/User/CoachReducer";
import AdminReducer              from "Reducers/Admin/User/AdminReducer";

// Coach
import CoachPricesReducer        from "Reducers/Admin/Coach/PricesReducer";
import CoachSpecialityReducer    from "Reducers/Admin/Coach/SpecialityReducer";
import CoachCategoryReducer      from "Reducers/Admin/Coach/CategoryReducer";
import CoachQuestionReducer      from "Reducers/Admin/Coach/QuestionReducer";
import CoachInvoiceReducer       from "Reducers/Admin/Coach/InvoiceReducer";
import CoachMaterialReducer      from "Reducers/Admin/Coach/MaterialReducer";
import CoachProfesionReducer      from "Reducers/Admin/Coach/ProfesionReducer";

// Report
import InscriptionReportReducer  from "Reducers/Admin/Report/InscriptionReducer";
import MeetingReportReducer      from "Reducers/Admin/Report/MeetingReducer";
import ConversationReportReducer from "Reducers/Admin/Report/ConversationReducer";
import GuideReportReducer        from "Reducers/Admin/Report/GuideReducer";
import PollReportReducer         from "Reducers/Admin/Report/PollReducer";
import InvoiceReportReducer      from "Reducers/Admin/Report/InvoiceReducer";

// Campaign
import CampaignReducer           from "Reducers/Admin/Campaign/CampaignReducer";
import CampaignUserReducer       from "Reducers/Admin/Campaign/UserReducer";

// Lead
import LeadReducer               from "Reducers/Admin/Lead/LeadReducer";
import OriginReducer             from "Reducers/Admin/Lead/OriginReducer";
import AssignmentReducer         from "Reducers/Admin/Lead/AssignmentReducer";
import CloseReasonReducer        from "Reducers/Admin/Lead/CloseReasonReducer";

// Setup
import InterestReducer           from "Reducers/Admin/Setup/InterestReducer";
import GroupInterestReducer      from "Reducers/Admin/Setup/GroupInterestReducer";
import StaffReducer              from "Reducers/Admin/Setup/StaffReducer";
import NewsReducer               from "Reducers/Admin/Setup/NewsReducer";
import AdviceReducer             from "Reducers/Admin/Setup/AdviceReducer";
import PaymentMethodReducer      from "Reducers/Admin/Setup/PaymentMethodReducer";
import CountryReducer            from "Reducers/Admin/Setup/CountryReducer";
import ProvinceReducer           from "Reducers/Admin/Setup/ProvinceReducer";
import LocalityReducer           from "Reducers/Admin/Setup/LocalityReducer";
import EmailTemplateReducer      from "Reducers/Admin/Setup/EmailTemplateReducer";
import EmailQueueReducer         from "Reducers/Admin/Setup/EmailQueueReducer";
import EmailWhiteListReducer     from "Reducers/Admin/Setup/EmailWhiteListReducer";
import ActionLogReducer          from "Reducers/Admin/Setup/ActionLogReducer";
import ErrorLogReducer           from "Reducers/Admin/Setup/ErrorLogReducer";

// Profile
import ProfileReducer            from "Reducers/Profile/ProfileReducer";



// The Combined Reducers
export default combineReducers({
    core                : CoreReducer,
    auth                : AuthReducer,
    media               : MediaReducer,

    // Student
    home                : HomeReducer,
    calendar            : CalendarReducer,
    enroll              : EnrollReducer,

    // Admin
    dashboard           : DashboardReducer,

    // Course
    course              : CourseReducer,
    courseCompleted     : CourseCompletedReducer,

    // Program
    program             : ProgramReducer,
    programPrice        : ProgramPriceReducer,
    programChat         : ProgramChatReducer,
    programClass        : ProgramClassReducer,
    programMaterial     : ProgramMaterialReducer,
    programGuide        : ProgramGuideReducer,
    programSpeciality   : ProgramSpecialityReducer,
    programQuestion     : ProgramQuestionReducer,

    // Strech
    strech              : StrechReducer,
    strechPrice         : StrechPriceReducer,
    strechSchedule      : StrechScheduleReducer,
    strechCoach         : StrechCoachReducer,
    strechChat          : StrechChatReducer,
    strechClass         : StrechClassReducer,
    strechMaterial      : StrechMaterialReducer,
    strechGuide         : StrechGuideReducer,
    strechSpeciality    : StrechSpecialityReducer,
    strechQuestion      : StrechQuestionReducer,
    strechAnswer        : StrechAnswerReducer,
    inscription         : InscriptionReducer,
    inscriptionChat     : InscriptionChatReducer,
    inscriptionReason   : InscriptionReasonReducer,
    inscriptionAssign   : InscriptionAssignReducer,
    presentism          : PresentismReducer,
    strechGroup         : GroupReducer,
    groupMember         : MemberReducer,
    meeting             : MeetingReducer,

    // Conversation
    conversation        : ConversationReducer,

    // Material
    material            : MaterialReducer,
    matCategory         : MatCategoryReducer,

    // Guide
    guide               : GuideReducer,
    guideSection        : GuideSectionReducer,
    guideQuestion       : GuideQuestionReducer,

    // Location
    location            : LocationReducer,
    locRoom             : LocRoomReducer,
    locSchedule         : LocScheduleReducer,

    // Ticket
    ticket              : TicketReducer,
    ticketType          : TicketTypeReducer,
    helpSection         : HelpSectionReducer,
    helpQuestion        : HelpQuestionReducer,

    // Users
    student             : StudentReducer,
    coach               : CoachReducer,
    admin               : AdminReducer,

    // Coach
    coachPrices         : CoachPricesReducer,
    coachSpeciality     : CoachSpecialityReducer,
    coachCategory       : CoachCategoryReducer,
    coachQuestion       : CoachQuestionReducer,
    coachInvoice        : CoachInvoiceReducer,
    coachMaterial       : CoachMaterialReducer,
    coachProfesion      : CoachProfesionReducer,

    // Report
    inscriptionReport   : InscriptionReportReducer,
    conversationReport  : ConversationReportReducer,
    meetingReport       : MeetingReportReducer,
    guideReport         : GuideReportReducer,
    pollReport          : PollReportReducer,
    invoiceReport       : InvoiceReportReducer,

    // Campaign
    campaign            : CampaignReducer,
    campaignUser        : CampaignUserReducer,

    // Lead
    lead                : LeadReducer,
    origin              : OriginReducer,
    assignment          : AssignmentReducer,
    closeReason         : CloseReasonReducer,

    // Setup
    interest            : InterestReducer,
    groupinterest       : GroupInterestReducer,
    staff               : StaffReducer,
    news                : NewsReducer,
    advice              : AdviceReducer,
    paymentMethod       : PaymentMethodReducer,
    country             : CountryReducer,
    province            : ProvinceReducer,
    locality            : LocalityReducer,
    emailTemplate       : EmailTemplateReducer,
    emailQueue          : EmailQueueReducer,
    emailWhiteList      : EmailWhiteListReducer,
    actionLog           : ActionLogReducer,
    errorLog            : ErrorLogReducer,

    // Profile
    profile             : ProfileReducer,
});
