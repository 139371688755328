import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Common               from "Utils/Common";

// Sections
import EnrollFilters        from "./EnrollFilters";
import EnrollList           from "./EnrollList";
import EnrollChat           from "./EnrollChat";
import EnrollStrech         from "./EnrollStrech";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import NoneAvailable        from "dashboard/dist/Components/NoneAvailable";
import AlertDialog          from "dashboard/dist/Components/AlertDialog";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";

// Actions
import {
    fetchEnroll, updateEnroll, cancelEnroll,
} from "Actions/Student/EnrollActions";

// Styles
import "Styles/Components/App/Enroll.css";



/**
 * The Enroll Page
 */
class EnrollPage extends React.Component {
    // The Initial Data
    initialFilters = {
        courseID     : 0,
        strechMode   : 0,
        scheduleType : 0,
        strechFor    : 1,
        countryID    : 0,
        provinceID   : 0,
        localityID   : 0,
    }

    // The Current State
    state = {
        filters : { ...this.initialFilters },
        action  : Action.get(),
        elem    : {},
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchEnroll();
        if (this.props.isCoach) {
            this.setState({ filters : { ...this.state.filters, strechFor : 0 } });
        }
    }



    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({ filters : { ...this.state.filters, [name] : value } });
    }

    /**
     * Starts an Action
     * @param {String} action
     * @param {Object} elem
     * @returns {Void}
     */
    startAction = (action, elem) => {
        this.setState({ action : Action.get(action), elem });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction("", {});
    }



    /**
     * Handles the Chat Enroll
     * @returns {Promise}
     */
    handleChatEnroll = async () => {
        this.endAction();
        this.props.updateEnroll();
    }

    /**
     * Handles the Strech Enroll
     * @returns {Promise}
     */
    handleStrechEnroll = async () => {
        this.startAction("ENROLLED", this.state.elem);
        this.props.updateEnroll();
    }

    /**
     * Handles the Enroll Cancel
     * @returns {Promise}
     */
    handleCancel = async () => {
        const result = await this.props.cancelEnroll(this.state.elem.strechID);
        this.endAction();
        if (result.success) {
            this.props.updateEnroll();
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, route, streches } = this.props;
        const { filters, action, elem    } = this.state;

        const list       = Common.filterStreches(streches, filters);
        const showNone   = !streches.length;
        const showFilter = streches.length > 0;
        const showEmpty  = !showNone && !list.length;
        const showList   = list.length > 0;

        return <Main>
            <Header message="ENROLL_NAME" icon="inscription" route={route} />
            <Content isLoading={loading}>
                {showNone && <NoneAvailable message="ENROLL_NONE_AVAILABLE" />}
                {showFilter && <EnrollFilters
                    filters={filters}
                    onChange={this.handleChange}
                />}
                {showEmpty && <NoneAvailable message="ENROLL_NONE_FILTERED" />}
                {showList && <EnrollList
                    startAction={this.startAction}
                    list={list}
                />}
            </Content>

            <EnrollChat
                open={action.isCenroll}
                elem={elem}
                onSubmit={this.handleChatEnroll}
                onClose={this.endAction}
                handlePost
            />
            <EnrollStrech
                open={action.isSenroll}
                elem={elem}
                onSubmit={this.handleStrechEnroll}
                onClose={this.endAction}
            />

            <AlertDialog
                open={action.isEnrolled}
                icon="inscription"
                title="ENROLL_INFO_TITLE"
                message={elem.isFree ? "ENROLL_INFO_TEXT_FREE" : "ENROLL_INFO_TEXT"}
                content={elem.title}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isCancel}
                icon="inscription"
                title="ENROLL_CANCEL_TITLE"
                message="ENROLL_CANCEL_TEXT"
                content={elem.title}
                onSubmit={this.handleCancel}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchEnroll  : PropTypes.func.isRequired,
        updateEnroll : PropTypes.func.isRequired,
        cancelEnroll : PropTypes.func.isRequired,
        loading      : PropTypes.bool.isRequired,
        streches     : PropTypes.array.isRequired,
        isCoach      : PropTypes.bool.isRequired,
        type         : PropTypes.string.isRequired,
        route        : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loading  : state.enroll.loading,
            streches : state.enroll.streches,
            isCoach  : state.auth.isCoach,
        };
    }
}

export default connect(EnrollPage.mapStateToProps, {
    fetchEnroll, updateEnroll, cancelEnroll,
})(EnrollPage);
