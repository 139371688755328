import React                from "react";
import PropTypes            from "prop-types";

// Components
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Contact Field
 */
class ContactField extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, errors, idds, onChange, isRequired } = this.props;

        return <Columns amount="2">
            <InputField
                type="email"
                name="email"
                label="GENERAL_EMAIL"
                value={data.email}
                error={errors.email}
                onChange={onChange}
                isRequired
            />
            <InputField
                type="tel"
                name="cellphone"
                label="USERS_CELLPHONE"
                value={data.cellphone}
                error={errors.cellphone}
                preType="select"
                preName="idd"
                preValue={data.idd}
                prePlaceholder="USERS_IDD"
                preOptions={idds}
                preWidth="120"
                onChange={onChange}
                isRequired={isRequired}
                shrinkLabel
            />
        </Columns>;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        data       : PropTypes.object.isRequired,
        errors     : PropTypes.object.isRequired,
        idds       : PropTypes.array.isRequired,
        onChange   : PropTypes.func.isRequired,
        isRequired : PropTypes.bool,
    }
}

export default ContactField;
