import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "dashboard/dist/Core/Href";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import AuthForm             from "./AuthForm";
import EmailStep            from "./Steps/EmailStep";
import PasswordStep         from "./Steps/PasswordStep";
import ResetPassStep        from "./Steps/ResetPassStep";
import AssignStep           from "./Assign/AssignStep";

// Actions
import {
    fetchAssign,
} from "Actions/Core/AuthActions";



/**
 * The Assign Page
 */
class AssignPage extends React.Component {
    // The Current State
    state = {
        step    : "email",
        email   : "",
        code    : "",
        error   : "",
        loading : false,
    }

    /**
     * Fetches the Data
     * @returns {Void}
     */
    componentDidMount() {
        const { isAuthenticated, isCoach, match, fetchAssign } = this.props;

        fetchAssign(match.params.strechID);
        if (isAuthenticated && !isCoach) {
            this.setState({ step : "error" });
        } else if (isAuthenticated) {
            this.setState({ step : "assign" });
        } else if (match.params.code) {
            this.setState({ step : "reset", code : match.params.code });
        }
    }

    /**
     * Goes to the error, if required
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { error, strech } = this.props;
        if ((prevProps.error !== error || prevProps.strech.strechID !== strech.strechID) && this.getError()) {
            this.setState({ step : "error" });
        }
    }

    /**
     * Handles the Submit
     * @param {Object} data
     * @returns {Void}
     */
    handleSubmit = (data) => {
        const { step, email } = data;

        switch (step) {
        case "enter":
            this.setState({ step : "assign" });
            break;
        case "error":
            this.setState({ step, error : true });
            break;
        default:
            this.setState({ step, email });
        }
    }

    /**
     * Returns an Error
     * @returns {String}
     */
    getError() {
        const { error, strech, isAuthenticated, isCoach } = this.props;
        if (error || !strech.isActive) {
            return "ASSIGNME_ERROR_EXISTS";
        }
        if (!strech.canConverse) {
            return "ASSIGNME_ERROR_PERMISSION";
        }
        if (!strech.inAssignme) {
            return NLS.format("ASSIGNME_ERROR_PERIOD", strech.name);
        }
        if (this.state.error || (isAuthenticated && !isCoach)) {
            return "ASSIGNME_ERROR_PERMISSION";
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { strech, match     } = this.props;
        const { step, email, code } = this.state;

        const sendData = { forAssign : 1, strechID : strech.strechID };

        switch (step) {
        case "email":
            return <EmailStep
                title="ASSIGNME_TITLE"
                help={NLS.format("ASSIGNME_HELP", strech.name || "")}
                sendData={sendData}
                onSubmit={this.handleSubmit}
            />;
        case "password":
            return <PasswordStep
                title="ASSIGNME_TITLE"
                email={email}
                sendData={sendData}
                onSubmit={this.handleSubmit}
            />;
        case "reset":
            return <ResetPassStep
                title="ASSIGNME_TITLE"
                email={email}
                code={code}
                match={match}
                onSubmit={this.handleSubmit}
            />;

        case "assign":
            return <AssignStep
                onSubmit={this.handleSubmit}
            />;
        case "error":
            return <AuthForm
                title="ASSIGNME_TITLE"
                help={this.getError()}
                onSubmit={() => Href.goto()}
            />;
        case "success":
            return <AuthForm
                title="ASSIGNME_TITLE"
                help={NLS.format("ASSIGNME_SUCCESS", strech.name)}
                onSubmit={() => Href.goto()}
            />;
        default:
        }
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchAssign     : PropTypes.func.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        isCoach         : PropTypes.bool.isRequired,
        strech          : PropTypes.object.isRequired,
        error           : PropTypes.bool.isRequired,
        match           : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAuthenticated : state.auth.isAuthenticated,
            isCoach         : state.auth.isCoach,
            strech          : state.auth.strech,
            error           : state.auth.error,
        };
    }
}

export default connect(AssignPage.mapStateToProps, {
    fetchAssign,
})(AssignPage);
