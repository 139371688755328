import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { loginAs }          from "Actions/Core/AuthActions";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";

// Dialogs
import StudentEdit          from "./StudentEdit";
import CourseCompleted      from "Components/Admin/Course/CourseCompleted";

// Components
import Details              from "dashboard/dist/Components/Details";
import DetailList           from "dashboard/dist/Components/DetailList";
import DetailItem           from "dashboard/dist/Components/DetailItem";
import DetailActionList     from "dashboard/dist/Components/DetailActionList";
import DetailAction         from "dashboard/dist/Components/DetailAction";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchStudent, editStudentLevel, deleteStudent,
} from "Actions/Admin/User/StudentActions";



/**
 * The Student Details
 */
class StudentDetails extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
    }

    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        if (action.isLogin) {
            this.props.loginAs(this.props.credentialID);
        } else {
            this.setState({ action });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        const { credentialID, fetchStudent } = this.props;
        this.endAction();
        fetchStudent(credentialID, true);
    }

    /**
     * Handles the Level Submit
     * @returns {Promise}
     */
    editLevel = async () => {
        const { credentialID, editStudentLevel } = this.props;
        this.endAction();
        const response = await editStudentLevel(credentialID);
        if (response.success) {
            Href.goto("COACHES", credentialID);
        }
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
     deleteElem = async () => {
        const { credentialID, deleteStudent } = this.props;
        this.endAction();
        await deleteStudent(credentialID);
        Href.goto("STUDENTS");
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { charging, error, canEdit, elem, credentialID } = this.props;
        const { action                                       } = this.state;

        return <>
            <Details
                isLoading={charging}
                hasError={error}
                error="STUDENTS_ERROR_EXISTS"
            >
                <DetailList message="STUDENTS_DETAILS_TITLE">
                    <DetailItem icon="name"         message={elem.credentialName}   tooltip="GENERAL_NAME"         />
                    <DetailItem icon="email"        message={elem.email}            tooltip="GENERAL_EMAIL"        isEmail />
                    <DetailItem icon="phone"        message={elem.phoneString}      tooltip="USERS_CELLPHONE"      isWhatsApp />
                    <DetailItem icon="country"      message={elem.nationalityName}  tooltip="USERS_NATIONALITY"    withTip />
                    <DetailItem icon="dni"          message={elem.dni}              tooltip="USERS_DNI"            withTip />
                    <DetailItem icon="cuit"         message={elem.cuit}             tooltip="USERS_CUIT"           withTip />
                    <DetailItem icon="taxID"        message={elem.taxID}            tooltip="USERS_TAX_ID"         withTip />
                    <DetailItem icon="ocupation"    message={elem.ocupation}        tooltip="USERS_OCUPATION"      />
                    <DetailItem icon="address"      message={elem.addressString}    tooltip="GENERAL_ADRRESS"      />
                    <DetailItem icon="gender"       message={elem.genderName}       tooltip="USERS_GENDER"         />
                    <DetailItem icon="subscription" message={elem.subscriptionName} tooltip="GENERAL_SUBSCRIPTION" className={elem.subscriptionClass} />
                    <DetailItem icon="status"       message={elem.statusName}       tooltip="GENERAL_STATUS"       className={elem.statusClass} />
                </DetailList>
                <DetailActionList onAction={this.startAction} canEdit={canEdit}>
                    <DetailAction action="EDIT"      message="STUDENTS_EDIT_TITLE"    />
                    <DetailAction action="COMPLETED" message="COURSES_COMPLETED_NAME" />
                    <DetailAction action="ACCESS"    message="STUDENTS_ACCESS_TITLE"  />
                    <DetailAction action="LOGIN"     message="STUDENTS_LOGIN_AS"      />
                    <DetailAction action="DELETE"    message="STUDENTS_DELETE_TITLE"  />
                </DetailActionList>
            </Details>

            <StudentEdit
                open={action.isEdit}
                elemID={credentialID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <CourseCompleted
                open={action.isCompleted}
                elemID={credentialID}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isAccess}
                icon="access"
                title="STUDENTS_ACCESS_TITLE"
                message="STUDENTS_ACCESS_TEXT"
                content={elem.credentialName}
                onSubmit={this.editLevel}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="STUDENTS_DELETE_TITLE"
                message="STUDENTS_DELETE_TEXT"
                content={elem.credentialName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStudent     : PropTypes.func.isRequired,
        editStudentLevel : PropTypes.func.isRequired,
        deleteStudent    : PropTypes.func.isRequired,
        loginAs          : PropTypes.func.isRequired,
        charging         : PropTypes.bool.isRequired,
        error            : PropTypes.bool.isRequired,
        canEdit          : PropTypes.bool.isRequired,
        elem             : PropTypes.object.isRequired,
        credentialID     : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            charging : state.student.charging,
            error    : state.student.error,
            canEdit  : state.student.canEdit,
            elem     : state.student.elem,
        };
    }
}

export default connect(StudentDetails.mapStateToProps, {
    fetchStudent, editStudentLevel, deleteStudent,
    loginAs,
})(StudentDetails);
