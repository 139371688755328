import NLS                  from "dashboard/dist/Core/NLS";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    ACTION_LOG_LOADING,
    ACTION_LOG_LIST,
} from "Utils/Types";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    filter    : {
        credentialID : 0,
        fromDate     : "",
        toDate       : "",
    },
    sort      : {
        page         : 0,
        amount       : 10,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.timeDate     = DateTime.formatDate(elem.time, "dashes");
    elem.timeDateTime = DateTime.formatDate(elem.time, "dashesTime");

    for (const action of elem.actions) {
        action.timeDate    = DateTime.formatDate(action.time, "dashesTime");
        action.sectionName = action.section ? NLS.getSection(action.section) : "";
        action.actionName  = NLS.getAction(action.action);
        action.dataText    = action.dataID ? action.dataID.join(", ") : "";
    }
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, ACTION_LOG_LIST)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case ACTION_LOG_LOADING:
        return {
            ...state,
            loading   : true,
        };

    case ACTION_LOG_LIST:
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : parseList(action.data.list),
            total     : action.data.total,
            filter    : action.data.filter,
            sort      : action.data.sort,
        };

    default:
        return state;
    }
};

export default reducer;
