import React                from "react";
import PropTypes            from "prop-types";
import Href                 from "dashboard/dist/Core/Href";
import Common               from "Utils/Common";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import ViewField            from "dashboard/dist/Components/ViewField";



/**
 * The Class View Dialog
 */
class ClassView extends React.Component {
    /**
     * Handles the View Link
     * @return {Void}
     */
    handleView = () => {
        const { data } = this.props.elem;
        let url;
        if (data.asCoach) {
            url = [ "STRECHES", data.strechID, "CLASSES", data.strechClassID ];
        } else {
            url = [ "COURSES_MINE", data.strechID ];
            if (data.hasStarted) {
                url.push("CLASSES");
                url.push(data.strechClassID);
            }
        }
        Href.goto(...url);
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose } = this.props;
        const { type, data          } = elem;

        const values    = data || {};
        const showClass = values.hasStarted || values.asCoach;
        const typeName  = Common.getTypeName(values.programType);
        const button    = Common.getTypeButton(values.programType);

        return <ViewDialog
            open={open}
            icon={type}
            title={values.name || "CLASSES_VIEW_TITLE"}
            onClose={onClose}
            secondary={showClass ? "CLASSES_VIEW_TITLE" : button}
            onSecondary={this.handleView}
            withSpacing
        >
            <ViewField label={typeName}            value={values.strechTitle}    />
            <ViewField label="CLASSES_DESCRIPTION" value={values.description}    />
            <ViewField label="CLASSES_COACH"       value={values.credentialName} />
            <ViewField label="CLASSES_DATE"        value={values.timeString}     />
            {values.isVirtual && <>
                <ViewField label="GENERAL_STREAM_LINK"     value={values.streamLink}     />
                <ViewField label="GENERAL_STREAM_PASSWORD" value={values.streamPassword} />
            </>}
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open    : PropTypes.bool.isRequired,
        onClose : PropTypes.func.isRequired,
        elem    : PropTypes.object.isRequired,
    }
}

export default ClassView;
