import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { validateEmail }    from "Actions/Core/AuthActions";

// Components
import AuthForm             from "Components/Auth/AuthForm";
import IconField            from "dashboard/dist/Components/IconField";



/**
 * The Auth Email Step
 */
class EmailStep extends React.Component {
    // The Current State
    state = {
        data    : { email : "" },
        loading : false,
        errors  : {},
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { sendData, validateEmail, onSubmit } = this.props;
        const { data, loading                     } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const result = await validateEmail({ email : data.email, ...sendData });
                onSubmit(result);
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }

    /**
     * Handles the Link
     * @returns {Void}
     */
    handleLink = () => {
        const { prevStep, onSubmit } = this.props;
        onSubmit({ step : prevStep });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { title, help, link     } = this.props;
        const { data, loading, errors } = this.state;

        return <AuthForm
            onSubmit={this.handleSubmit}
            title={title}
            help={help}
            link={link}
            onClick={this.handleLink}
            error={errors.form}
            isLoading={loading}
        >
            <IconField
                className="auth-field"
                type="email"
                name="email"
                icon="email"
                label="GENERAL_EMAIL"
                autoComplete="username"
                value={data.email}
                error={errors.email}
                onChange={this.handleChange}
                autoFocus
                isRequired
                withLabel
                smallMargin
            />
            <input
                className="auth-hidden"
                type="password"
                name="hiddenPassword"
                tabIndex={-1}
                aria-hidden="true"
            />
        </AuthForm>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        validateEmail : PropTypes.func.isRequired,
        onSubmit      : PropTypes.func.isRequired,
        title         : PropTypes.string.isRequired,
        help          : PropTypes.string.isRequired,
        link          : PropTypes.string,
        prevStep      : PropTypes.string,
        sendData      : PropTypes.object,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
     static defaultProps = {
        sendData : {},
    }
}

export default connect(null, {
    validateEmail,
})(EmailStep);
