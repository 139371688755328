import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { StrechSpeciality } from "Utils/API";

// Types
import {
    STRECH_SPECIALITY, STRECH_SPECIALITY_LOADING,
    STRECH_SPECIALITY_LIST, STRECH_SPECIALITY_ELEM, STRECH_SPECIALITY_CREATE,
} from "Utils/Types";



/**
 * Fetches the Speciality List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchSpecialities(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : STRECH_SPECIALITY_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "STRECH":
            params.strechID = elemID;
            data = await StrechSpeciality.getAllForStrech(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : STRECH_SPECIALITY_LIST, data });
        dispatch(setSection(STRECH_SPECIALITY));
    };
}

/**
 * Fetches a single Speciality
 * @param {Number} strechSpecialityID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchSpeciality(strechSpecialityID) {
    return async (dispatch) => {
        const data = await StrechSpeciality.getOne({ strechSpecialityID });
        dispatch({ type : STRECH_SPECIALITY_ELEM, data });
    };
}

/**
 * Fetches the Speciality Create data
 * @param {Number} strechID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchSpecialityCreate(strechID) {
    return async (dispatch) => {
        const data = await StrechSpeciality.getCreateData({ strechID });
        dispatch({ type : STRECH_SPECIALITY_CREATE, data });
    };
}



/**
 * Edits/Creates a Speciality
 * @param {Object} data
 * @returns {() => Void}
 */
export function editSpeciality(data) {
    return () => StrechSpeciality.edit(data);
}

/**
 * Deletes a Speciality
 * @param {Number} strechSpecialityID
 * @returns {() => Void}
 */
export function deleteSpeciality(strechSpecialityID) {
    return () => StrechSpeciality.delete({ strechSpecialityID });
}

/**
 * Imports Specialitys
 * @param {Number} strechID
 * @returns {() => Void}
 */
export function importSpecialities(strechID) {
    return () => StrechSpeciality.import({ strechID });
}
