import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    COURSE_COMPLETED_ELEM,
} from "Utils/Types";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    canEdit   : false,
    edition   : 0,
    name      : "",
    courses   : [],
    completed : [],
};




/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, COURSE_COMPLETED_ELEM)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case COURSE_COMPLETED_ELEM:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            canEdit   : action.data.canEdit,
            name      : action.data.name,
            courses   : action.data.courses,
            completed : action.data.completed,
        };

    default:
        return state;
    }
};

export default reducer;
