import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";
import NLS                  from "dashboard/dist/Core/NLS";
import StatusData           from "Utils/Status";

// Components
import TicketContent        from "./TicketContent";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import TabList              from "dashboard/dist/Components/TabList";
import TabItem              from "dashboard/dist/Components/TabItem";



/**
 * The Ticket List
 */
class TicketList extends React.Component {
    // The Current State
    state = {
        filter     : StatusData.OPEN,
        action     : Action.get(),
        selectedID : 0,
        creating   : false,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const { route, params } = this.props;
        if (route === NLS.baseUrl("TICKETS_CREATE")) {
            this.startAction(Action.get("CREATE"), 0);
            this.setState({ creating : true });
        } else if (params.ticketID) {
            this.startAction(Action.get("VIEW"), params.ticketID);
        }
    }

    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} selectedID
     * @returns {Void}
     */
    startAction = (action, selectedID) => {
        if (action.isTab) {
            this.setState({ filter : selectedID });
        } else {
            this.setState({ action, selectedID });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
        if (this.state.creating) {
            Href.goto("TICKETS");
            this.setState({ creating : false });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, type, elemID, params, route, withDetails } = this.props;
        const { filter, action, selectedID                     } = this.state;

        return <Main withDetails={withDetails}>
            <Header message="TICKETS_NAME" icon="ticket" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content>
                <TabList selected={filter} onAction={this.startAction}>
                    <TabItem message="TICKETS_OPEN_TAB"  status="OPEN"   />
                    <TabItem message="TICKETS_CLOSE_TAB" status="CLOSED" />
                </TabList>
                <TicketContent
                    startAction={this.startAction}
                    endAction={this.endAction}
                    type={type}
                    filter={filter}
                    elemID={elemID}
                    params={params}
                    action={action}
                    selectedID={selectedID}
                />
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data        : PropTypes.object.isRequired,
        type        : PropTypes.string.isRequired,
        params      : PropTypes.object.isRequired,
        route       : PropTypes.string.isRequired,
        withDetails : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.ticket,
        };
    }
}

export default connect(TicketList.mapStateToProps)(TicketList);
