import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    PROFILE_LOADING, PROFILE_ELEM, PROFILE_UPLOAD,
} from "Utils/Types";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    edition   : 0,
    uploads   : 1,
    elem      : {},
    idds      : [],
    countries : [],
    provinces : {},
    profesions : [],
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, PROFILE_ELEM)) {
        return { ...state, loading : false, error : true };
    }
    switch (action.type) {
    case PROFILE_LOADING:
        return {
            ...state,
            loading : true,
        };

    case PROFILE_ELEM:
        return {
            ...state,
            loading   : false,
            error     : false,
            edition   : state.edition + 1,
            elem      : action.data.elem      || {},
            idds      : action.data.idds      || [],
            countries : action.data.countries || [],
            provinces : action.data.provinces || {},
            profesions: action.data.profesions || [],
        };

    case PROFILE_UPLOAD:
        return {
            ...state,
            uploads   : state.uploads + 1,
        };

    default:
        return state;
    }
};

export default reducer;
