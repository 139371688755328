import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import List                 from "dashboard/dist/Components/List";
import ListItem             from "dashboard/dist/Components/ListItem";
import ListContent          from "dashboard/dist/Components/ListContent";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchStudents, assignToGroups,
} from "Actions/Admin/Strech/GroupActions";



/**
 * The Group Assign Dialog
 */
class GroupAssign extends React.Component {
    // The Current State
    state = {
        loading : false,
        members : {},
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, selection, strechID, students, fetchStudents } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchStudents(strechID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.selection !== selection) {
            const members = {};
            for (const elem of students) {
                members[elem.credentialID] = elem.groupID || 0;
            }
            this.setState({ loading : false, members });
        }
    }

    /**
     * Handles the Change
     * @param {Number} credentialID
     * @param {Number} groupID
     * @returns {Void}
     */
    handleChange = (credentialID, groupID) => {
        const members = { ...this.state.members };
        members[credentialID] = groupID;
        this.setState({ members });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { strechID, assignToGroups, onSubmit } = this.props;
        const { members, loading                   } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const params = { strechID, members : JSON.stringify(members) };
                await assignToGroups(params);
                this.setState({ loading : false });
                onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }

    /**
     * Handles the Close
     * @returns {Void}
     */
    handleClose = () => {
        this.props.onClose();
        this.setState({ members : {} });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, students, groups   } = this.props;
        const { loading, members, errors } = this.state;

        return <EditDialog
            open={open}
            icon="group"
            title="GROUPS_ASSIGN_TITLE"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={this.handleClose}
            isLoading={loading}
        >
            <List>
                {students.map((elem) => <ListItem key={elem.credentialID}>
                    <ListContent>{elem.credentialName}</ListContent>
                    <InputField
                        type="select"
                        name="student[]"
                        label="GROUPS_SINGULAR"
                        value={members[elem.credentialID]}
                        error={errors[`members${elem.credentialID}`]}
                        options={groups}
                        onChange={(name, value) => this.handleChange(elem.credentialID, value)}
                        withNone
                    />
                </ListItem>)}
            </List>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStudents  : PropTypes.func.isRequired,
        assignToGroups : PropTypes.func.isRequired,
        open           : PropTypes.bool.isRequired,
        onSubmit       : PropTypes.func.isRequired,
        onClose        : PropTypes.func.isRequired,
        selection      : PropTypes.number.isRequired,
        canEdit        : PropTypes.bool.isRequired,
        students       : PropTypes.array.isRequired,
        groups         : PropTypes.array.isRequired,
        strechID       : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            selection : state.strechGroup.selection,
            canEdit   : state.strechGroup.canEdit,
            students  : state.strechGroup.students,
            groups    : state.strechGroup.groups,
        };
    }
}

export default connect(GroupAssign.mapStateToProps, {
    fetchStudents, assignToGroups,
})(GroupAssign);
