import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import ViewField            from "dashboard/dist/Components/ViewField";
import Columns              from "dashboard/dist/Components/Columns";

// Actions
import {
    fetchStudentAsCoach,
} from "Actions/Admin/User/StudentActions";



/**
 * The Student View Dialog
 */
class StudentView extends React.Component {
    // The Current State
    state = {
        loading : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchStudentAsCoach } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchStudentAsCoach(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, inscription, open, type, onClose } = this.props;
        const { loading                                } = this.state;

        const forStreches = type === "STRECH";

        return <ViewDialog
            open={open}
            icon="student"
            title="STUDENTS_VIEW_TITLE"
            onClose={onClose}
            isLoading={loading}
            withSpacing
        >
            <ViewField
                isHidden={forStreches}
                label="STRECHES_SINGULAR"
                value={inscription.strechName}
                linkIcon="strech"
                linkHref={NLS.baseUrl("STRECHES", inscription.strechID)}
            />

            <Columns>
                <ViewField
                    label="USERS_FIRST_NAME"
                    value={elem.firstName}
                />
                <ViewField
                    label="USERS_LAST_NAME"
                    value={elem.lastName}
                />

                <ViewField
                    label="GENERAL_EMAIL"
                    value={elem.email}
                    linkIcon="email"
                    linkHref={elem.email}
                    isEmail
                />
                <ViewField
                    label="USERS_CELLPHONE"
                    value={elem.phoneString}
                    linkIcon="whatsapp"
                    linkHref={elem.iddRoot ? elem.phoneString : ""}
                    isWhatsApp
                />

                <ViewField
                    label="GENERAL_BIRTHDATE"
                    value={elem.birthDateTime}
                />
                <ViewField
                    className={!elem.birthDateTime ? "columns-double" : ""}
                    label="USERS_GENDER"
                    value={elem.genderName}
                />

                <ViewField
                    className="columns-double"
                    label="GENERAL_ADRRESS"
                    value={elem.addressString}
                />
                <ViewField
                    className="columns-double"
                    label="USERS_OCUPATION"
                    value={elem.ocupation}
                />

                <ViewField
                    label="GENERAL_MODES"
                    value={inscription.modesText}
                />
                <ViewField
                    label="SCHEDULES_NAME"
                    value={inscription.timesText}
                />
            </Columns>
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStudentAsCoach : PropTypes.func.isRequired,
        open                : PropTypes.bool.isRequired,
        type                : PropTypes.string.isRequired,
        onClose             : PropTypes.func.isRequired,
        edition             : PropTypes.number.isRequired,
        elem                : PropTypes.object.isRequired,
        inscription         : PropTypes.object.isRequired,
        elemID              : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition     : state.student.edition,
            elem        : state.student.elem,
            inscription : state.student.inscription,
        };
    }
}

export default connect(StudentView.mapStateToProps, {
    fetchStudentAsCoach,
})(StudentView);
