import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { StrechPresentism } from "Utils/API";

// Types
import {
    STRECH_PRESENTISM, STRECH_PRESENTISM_LOADING,
    STRECH_PRESENTISM_LIST, STRECH_PRESENTISM_ELEM,
} from "Utils/Types";



/**
 * Fetches the Presentism List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPresentisms(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : STRECH_PRESENTISM_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "STRECH":
            params.strechID = elemID;
            data = await StrechPresentism.getAllForStrech(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : STRECH_PRESENTISM_LIST, data });
        dispatch(setSection(STRECH_PRESENTISM));
    };
}

/**
 * Fetches a single Presentism
 * @param {Number} strechID
 * @param {Number} strechClassID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPresentism(strechID, strechClassID) {
    return async (dispatch) => {
        const data = await StrechPresentism.getOne({ strechID, strechClassID });
        dispatch({ type : STRECH_PRESENTISM_ELEM, data });
    };
}



/**
 * Toggles the Presentism
 * @param {Number} strechID
 * @param {Number} strechClassID
 * @param {Number} credentialID
 * @param {Number} attended
 * @returns {() => Void}
 */
export function togglePresentism(strechID, strechClassID, credentialID, attended) {
    return () => StrechPresentism.toggle({ strechID, strechClassID, credentialID, attended });
}
