import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { enrollToChat }     from "Actions/Student/EnrollActions";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Enroll Chat Dialog
 */
class EnrollChat extends React.Component {
    // The Current State
    state = {
        loading : false,
        isEdit  : false,
        data    : {},
        errors  : {},
    }



    /**
     * Resets the State
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, elem } = this.props;
        let data   = null;
        let isEdit = false;

        if (!prevProps.open && open) {
            data = {};
            for (const chat of elem.chatsList) {
                data[chat.chatID] = elem.chatEnrolls.includes(chat.chatID) ? 1 : 0;
            }
            if (elem.chatEnrolls && elem.chatEnrolls.length > 0) {
                isEdit = true;
            }
            this.setState({ data, isEdit, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
            isEdit : true,
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { elem, enrollToChat, handlePost, onSubmit } = this.props;
        const { data, loading                            } = this.state;

        if (!handlePost) {
            onSubmit(data);
            return;
        }
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await enrollToChat(elem.strechID, data);
                this.setState({ loading : false });
                onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, elem, onClose  } = this.props;
        const { data, isEdit, errors } = this.state;

        const chats      = elem.chatsList || [];
        const isDisabled = !isEdit;

        return <EditDialog
            open={open}
            icon="inscription"
            title="ENROLL_CHAT_TITLE"
            primary="ENROLL_NAME"
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isDisabled={isDisabled}
            isNarrow
        >
            <DialogMessage
                variant="h3"
                message={NLS.format("ENROLL_CHAT_TEXT", elem.title)}
            />
            <>
                {chats.map((elem) => <InputField
                    key={elem.chatID}
                    type="toggle"
                    name={String(elem.chatID)}
                    label={elem.label}
                    value={data[elem.chatID] ? 1 : 0}
                    error={errors[elem.chatID]}
                    onChange={this.handleChange}
                />)}
            </>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        enrollToChat : PropTypes.func.isRequired,
        open         : PropTypes.bool.isRequired,
        onClose      : PropTypes.func.isRequired,
        onSubmit     : PropTypes.func.isRequired,
        elem         : PropTypes.object,
        handlePost   : PropTypes.bool,
    }
}

export default connect(null, {
    enrollToChat,
})(EnrollChat);
