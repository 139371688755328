import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchMaterial }    from "Actions/Admin/Strech/MaterialActions";

// Components
import Dialog               from "dashboard/dist/Components/Dialog";
import DialogHeader         from "dashboard/dist/Components/DialogHeader";
import DialogBody           from "dashboard/dist/Components/DialogBody";
import DialogFooter         from "dashboard/dist/Components/DialogFooter";
import PDFViewer            from "dashboard/dist/Components/PDFViewer";
import PDFNavigation        from "dashboard/dist/Components/PDFNavigation";
import Button               from "dashboard/dist/Components/Button";
import HyperLink            from "dashboard/dist/Components/HyperLink";
import Video                from "dashboard/dist/Components/Video";



/**
 * The Strech Material View Dialog
 */
class MaterialView extends React.Component {
    // The Current State
    state = {
        loading     : false,
        currentPage : 1,
        totalPages  : 0,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchMaterial } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchMaterial(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false, currentPage : 1 });
        }
    }



    /**
     * Handles the PDF Load
     * @param {Number} totalPages
     * @returns {Void}
     */
    handleLoad = (totalPages) => {
        this.setState({ totalPages });
    }

    /**
     * Handles the PDF Page Change
     * @param {Number} currentPage
     * @returns {Void}
     */
    handlePage = (currentPage) => {
        this.setState({ currentPage });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose              } = this.props;
        const { loading, currentPage, totalPages } = this.state;

        const withSpacing = !elem.isPDF && !elem.isImage;
        const canDownload = elem.isPDF || elem.isImage || elem.isAudio;

        return <Dialog open={open} onClose={onClose} isLoading={loading} width={800}>
            <DialogHeader message={elem.fullName || "GENERAL_LOADING"} icon="material" />
            <DialogBody withSpacing={withSpacing}>
                {elem.isPDF && <PDFViewer
                    source={elem.fileUrl}
                    currentPage={currentPage}
                    onLoad={this.handleLoad}
                />}
                {elem.isImage && <img
                    src={elem.fileUrl}
                    alt={elem.name}
                    style={{ maxWidth : "100%" }}
                />}
                {elem.isAudio && <audio
                    src={elem.fileUrl}
                    controls
                />}
                {elem.isVideo && <Video
                    source={elem.fileUrl}
                    title={elem.name}
                />}
                {elem.isLink && <HyperLink
                    className="big-text"
                    variant="primary"
                    href={elem.fileUrl}
                    target="_blank"
                    message={elem.name}
                />}
            </DialogBody>
            <DialogFooter cancel="GENERAL_CLOSE">
                {elem.isPDF && <PDFNavigation
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onChange={this.handlePage}
                />}
                {canDownload && <Button
                    variant="primary"
                    href={elem.fileUrl}
                    target="_blank"
                    message="GENERAL_DOWNLOAD"
                />}
            </DialogFooter>
        </Dialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchMaterial : PropTypes.func.isRequired,
        open          : PropTypes.bool.isRequired,
        onClose       : PropTypes.func.isRequired,
        edition       : PropTypes.number.isRequired,
        elem          : PropTypes.object.isRequired,
        elemID        : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.strechMaterial.edition,
            elem    : state.strechMaterial.elem,
        };
    }
}

export default connect(MaterialView.mapStateToProps, {
    fetchMaterial,
})(MaterialView);
