import { CoachPrices }      from "Utils/API";

// Types
import {
    COACH_PRICES_ELEM,
} from "Utils/Types";



/**
 * Fetches a single Coach Prices
 * @param {Number} credentialID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPrices(credentialID) {
    return async (dispatch) => {
        const data = await CoachPrices.getAllForCredential({ credentialID });
        dispatch({ type : COACH_PRICES_ELEM, data });
    };
}



/**
 * Edits a Coach Prices
 * @param {Object} data
 * @returns {() => Void}
 */
export function editPrices(data) {
    return () => CoachPrices.edit(data);
}
