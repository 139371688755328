import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import StatusData           from "Utils/Status";
import Common               from "Utils/Common";

// Types
import {
    MEETING_LOADING,
    MEETING_LIST, MEETING_ELEM,
    MEETING_CREATE, MEETING_EDIT,
} from "Utils/Types";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canDelete : false,
    canImport : false,
    canExport : false,

    list      : [],
    total     : 0,

    elem      : {},
    locations : [],
    groups    : [],
    rooms     : {},
    members   : [],
    questions : [],

    sort      : {
        filter   : StatusData.SCHEDULED,
        orderBy  : "time",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.createdString   = DateTime.formatString(elem.createdTime);
    elem.cancelledDate   = DateTime.formatString(elem.cancelledTime);
    elem.reportDate      = DateTime.formatString(elem.reportTime);
    elem.timeDateTime    = DateTime.formatDate(elem.time, "dashesTime");
    elem.timeString      = DateTime.formatString(elem.time);
    elem.timeDate        = elem.time ? DateTime.formatDate(elem.time, "dashesReverse") : "";
    elem.timeHour        = elem.time ? DateTime.formatDate(elem.time, "time")          : "";
    elem.coachString     = elem.coachName || NLS.get("GENERAL_NOBODY");
    elem.modeName        = Common.getModeName(elem.mode);
    elem.statusName      = Status.getFemName(elem.status);
    elem.statusColor     = Status.getColor(elem.status);
    elem.statusClass     = Status.getTextClass(elem.status);
    elem.description     = NLS.format("MEETINGS_DESCRIPTION", elem.groupName, elem.coachName, elem.timeDateTime);
    elem.cancelledString = NLS.format("MEETINGS_CANCELLED_TEXT", elem.cancelledDate, elem.cancelledReason);
    elem.reportString    = NLS.format("MEETINGS_REPORT_TEXT", elem.reportDate);

    if (elem.isPresential) {
        elem.locationString = Common.combineLocation(elem);
    }
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, MEETING_LIST, MEETING_ELEM, MEETING_EDIT, MEETING_CREATE)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case MEETING_LOADING:
        return {
            ...state,
            loading   : true,
        };

    case MEETING_LIST:
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canDelete : action.data.canDelete,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : parseList(action.data.list),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case MEETING_ELEM:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            canEdit   : action.data.canEdit,
            canDelete : action.data.canDelete,
            elem      : parseElem(action.data.elem),
            members   : action.data.members,
            questions : Common.parseQuestions(action.data.questions, action.data.elem),
        };

    case MEETING_CREATE:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            groups    : action.data.groups,
            locations : action.data.locations,
            rooms     : action.data.rooms,
        };

    case MEETING_EDIT:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            canEdit   : action.data.canEdit,
            elem      : parseElem(action.data.elem),
            locations : action.data.locations,
            rooms     : action.data.rooms,
        };

    default:
        return state;
    }
};

export default reducer;
