import { setSection }       from "Actions/Core/CoreActions";
import { Dashboard }        from "Utils/API";

// Types
import {
    DASHBOARD, DASHBOARD_LOADING,
    DASHBOARD_LIST,
} from "Utils/Types";



/**
 * Fetches the Dashboard
 * @returns {(dispatch: Function) => Void}
 */
export function fetchDashboard() {
    return async (dispatch) => {
        dispatch({ type : DASHBOARD_LOADING });
        const data = await Dashboard.getAll();
        dispatch({ type : DASHBOARD_LIST, data });
        dispatch(setSection(DASHBOARD));
    };
}
