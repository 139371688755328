import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchTicketType, fetchTicketTypeCreate, editTicketType,
} from "Actions/Admin/Ticket/TicketTypeActions";



/**
 * The Ticket Type Edit Dialog
 */
class TicketTypeEdit extends React.Component {
    // The Initial Data
    initialData = {
        ticketTypeID : 0,
        credentialID : "",
        name         : "",
        message      : "",
        position     : 0,
        status       : Status.getID("ACTIVE"),
        coachesOnly  : 0,
        forSupport   : false,
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, forSupport, elemID, elem, position, fetchTicketType, fetchTicketTypeCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchTicketType(elemID, forSupport);
                loading = true;
            // Load edit data
            } else {
                fetchTicketTypeCreate(forSupport);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData, position };
            }
            data.forSupport = forSupport;
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editTicketType(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, forSupport, open, onClose, credentials } = this.props;
        const { data, loading, errors                          } = this.state;

        const prefix = forSupport ? "SUPPORT_" : "TICKETS_";

        return <EditDialog
            open={open}
            icon="type"
            title={!!elemID ? `${prefix}TYPES_EDIT_TITLE` : `${prefix}TYPES_CREATE_TITLE`}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                name="name"
                label={`${prefix}TYPES_ITS_NAME`}
                value={data.name}
                error={errors.name}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="select"
                name="credentialID"
                label="TICKETS_TYPES_CREDENTIAL"
                value={data.credentialID}
                error={errors.credentialID}
                onChange={this.handleChange}
                options={credentials}
                noneText="GENERAL_NOBODY"
                withNone
                shrinkLabel
            />
            <InputField
                isHidden={forSupport}
                name="message"
                label="TICKETS_TYPES_MESSAGE"
                value={data.message}
                error={errors.message}
                onChange={this.handleChange}
            />
            <Columns>
                <InputField
                    type="number"
                    name="position"
                    label="GENERAL_POSITION"
                    value={data.position}
                    error={errors.position}
                    onChange={this.handleChange}
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    error={errors.status}
                    onChange={this.handleChange}
                    options={Status.getSelect()}
                    withNone
                    isRequired
                    shrinkLabel
                />
            </Columns>
            <InputField
                isHidden={forSupport}
                type="toggle"
                name="coachesOnly"
                label="TICKETS_TYPES_COACHES_ONLY"
                value={data.coachesOnly}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchTicketType       : PropTypes.func.isRequired,
        fetchTicketTypeCreate : PropTypes.func.isRequired,
        editTicketType        : PropTypes.func.isRequired,
        open                  : PropTypes.bool.isRequired,
        forSupport            : PropTypes.bool.isRequired,
        onClose               : PropTypes.func.isRequired,
        onSubmit              : PropTypes.func.isRequired,
        edition               : PropTypes.number.isRequired,
        position              : PropTypes.number.isRequired,
        credentials           : PropTypes.array.isRequired,
        elem                  : PropTypes.object.isRequired,
        elemID                : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition     : state.ticketType.edition,
            position    : state.ticketType.position,
            credentials : state.ticketType.credentials,
            elem        : state.ticketType.elem,
        };
    }
}

export default connect(TicketTypeEdit.mapStateToProps, {
    fetchTicketType, fetchTicketTypeCreate, editTicketType,
})(TicketTypeEdit);
