import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";

// Components
import MeetingView          from "./MeetingView";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import TabList              from "dashboard/dist/Components/TabList";
import TabItem              from "dashboard/dist/Components/TabItem";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";

// Actions
import {
    fetchMeetsAsStudent,
} from "Actions/Admin/Strech/MeetingActions";



/**
 * The Meeting List
 */
class MeetingList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { elemID, data } = this.props;
        this.props.fetchMeetsAsStudent(elemID, params || data.sort);
    }

    /**
     * Fetch the tab content
     * @param {Number=} filter
     * @returns {Void}
     */
    fetchTab = (filter) => {
        this.fetch({ ...this.props.data.sort, filter, page : 0 });
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isTab) {
            this.fetchTab(elemID);
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                      } = this.state;
        const { data, route, withDetails            } = this.props;
        const { canEdit, list, total, sort, loading } = data;

        return <Main withDetails={withDetails}>
            <Header message="MEETINGS_MINE" icon="meeting" route={route} />
            <Content>
                <TabList selected={sort.filter} onAction={this.startAction}>
                    <TabItem message="MEETINGS_PROGRAM"   status="SCHEDULED" />
                    <TabItem message="MEETINGS_COMPLETED" status="COMPLETED" />
                    <TabItem message="MEETINGS_CANCELLED" status="CANCELLED" />
                </TabList>
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="MEETINGS_NONE_AVAILABLE"
                    isLoading={loading}
                >
                    <TableHead>
                        <TableHeader field="time"           message="GENERAL_DATE"     />
                        <TableHeader field="coachFirstName" message="COACHES_SINGULAR" />
                        <TableHeader field="mode"           message="GENERAL_MODE"     maxWidth="80" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.meetingID} elemID={elem.meetingID}>
                            <TableCell message={elem.timeString}  />
                            <TableCell message={elem.coachString} />
                            <TableCell message={elem.modeName}    />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW" message="MEETINGS_VIEW_TITLE" />
                    </TableActionList>
                </Table>
            </Content>

            <MeetingView
                open={action.isView}
                elemID={elemID}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchMeetsAsStudent : PropTypes.func.isRequired,
        data                : PropTypes.object.isRequired,
        type                : PropTypes.string.isRequired,
        params              : PropTypes.object.isRequired,
        route               : PropTypes.string.isRequired,
        withDetails         : PropTypes.bool.isRequired,
        elemID              : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.meeting,
        };
    }
}

export default connect(MeetingList.mapStateToProps, {
    fetchMeetsAsStudent,
})(MeetingList);
