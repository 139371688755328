import React                from "react";
import PropTypes            from "prop-types";
import Period               from "dashboard/dist/Utils/Period";
import Utils                from "dashboard/dist/Utils/Utils";
import GraphType            from "Utils/Entity/GraphType";

// Components
import InputField           from "dashboard/dist/Components/InputField";
import Button               from "dashboard/dist/Components/Button";



/**
 * The Report Filters
 */
class ReportFilters extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { type, data, filters, onChange, onFilter, children } = this.props;

        const hideFilter = GraphType.getHideFilters(filters.graphType);

        const preChildren  = [];
        const postChildren = [];
        for (const child of Utils.toArray(children)) {
            if (child && child.props && child.props.name) {
                if ([ "dues", "conversations", "guideID", "credentialID", "coachName", "coacheeName" ].includes(child.props.name)) {
                    postChildren.push(child);
                } else {
                    preChildren.push(child);
                }
            }
        }

        return <section className="report-filter">
            <InputField
                type="select"
                name="graphType"
                label="REPORTS_GRAPH"
                value={filters.graphType}
                onChange={onChange}
                options={GraphType.getSelect(type)}
                noMargin
                shrinkLabel
                labelInside
            />
            {preChildren}

            <InputField
                isHidden={hideFilter.types}
                type="select"
                name="programType"
                label="PROGRAMS_TYPE"
                value={filters.programType}
                onChange={onChange}
                options="TYPES"
                noneText="GENERAL_ALL"
                withNone
                noMargin
                shrinkLabel
                labelInside
            />
            <InputField
                isHidden={hideFilter.modes}
                type="select"
                name="mode"
                label="REPORTS_MODE"
                value={filters.mode}
                onChange={onChange}
                options="MODES"
                noneText="GENERAL_ALL_FEM"
                withNone
                noMargin
                shrinkLabel
                labelInside
            />

            <InputField
                isHidden={hideFilter.courses}
                type="select"
                name="courseID"
                label="COURSES_SINGULAR"
                value={filters.courseID}
                onChange={onChange}
                options={data.courses}
                noneText="GENERAL_ALL"
                withNone
                noMargin
                shrinkLabel
                labelInside
            />
            <InputField
                isHidden={hideFilter.programs}
                type="select"
                name="programID"
                label="PROGRAMS_SINGULAR"
                value={filters.programID}
                onChange={onChange}
                options={data.programs}
                noneText="GENERAL_ALL"
                withNone
                noMargin
                shrinkLabel
                labelInside
            />
            <InputField
                isHidden={hideFilter.streches}
                type="select"
                name="strechID"
                label="STRECHES_SINGULAR"
                value={filters.strechID}
                onChange={onChange}
                options={data.streches}
                noneText="GENERAL_ALL"
                withNone
                noMargin
                shrinkLabel
                labelInside
            />
            {postChildren}

            <InputField
                type="select"
                name="period"
                label="GENERAL_PERIOD"
                value={filters.period}
                onChange={onChange}
                options={Period.getSelect()}
                noMargin
                shrinkLabel
                labelInside
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={filters.fromDate}
                onChange={onChange}
                noMargin
                shrinkLabel
                labelInside
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={filters.toDate}
                onChange={onChange}
                noMargin
                shrinkLabel
                labelInside
            />

            <div className="report-button">
                <Button
                    variant="primary"
                    message="GENERAL_FILTER"
                    onClick={onFilter}
                />
            </div>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        type     : PropTypes.string.isRequired,
        data     : PropTypes.object.isRequired,
        filters  : PropTypes.object.isRequired,
        onChange : PropTypes.func.isRequired,
        onFilter : PropTypes.func.isRequired,
        children : PropTypes.any,
    }
}

export default ReportFilters;
