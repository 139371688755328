import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "dashboard/dist/Core/NLS";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import Dialog               from "dashboard/dist/Components/Dialog";
import DialogHeader         from "dashboard/dist/Components/DialogHeader";
import DialogBody           from "dashboard/dist/Components/DialogBody";
import DialogFooter         from "dashboard/dist/Components/DialogFooter";
import NoneAvailable        from "dashboard/dist/Components/NoneAvailable";
import AccordionList        from "dashboard/dist/Components/AccordionList";
import AccordionItem        from "dashboard/dist/Components/AccordionItem";
import IconLink             from "dashboard/dist/Components/IconLink";
import InputField           from "dashboard/dist/Components/InputField";
import ViewField            from "dashboard/dist/Components/ViewField";
import Button               from "dashboard/dist/Components/Button";

// Actions
import {
    fetchGuide, saveGuideFeedback, sendGuideFeedback,
} from "Actions/Admin/Strech/GuideActions";

// Styles
import "Styles/Components/App/Guide.css";



/**
 * The Strech Guide Answers Dialog
 */
class GuideAnswers extends React.Component {
    // The Initial Data
    initialData = {
        feedback : {},
    }

    // The Current State
    state = {
        isMounted   : false,
        data        : { ...this.initialData },
        error       : false,
        loading     : false,
        sending     : false,
        update      : false,
        errors      : {},
        currentPage : 1,
        totalPages  : 0,
    }

    /**
     * Sets the Mounted state
     * @returns {Void}
     */
    componentDidMount() {
        this.setState({ isMounted : true });
    }

    /**
     * Unsets the Mounted state
     * @returns {Void}
     */
    componentWillUnmount() {
        this.setState({ isMounted : true });
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, error, edition, elemID, credentialID, questions, feedback, fetchGuide } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchGuide(elemID, credentialID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            data = { feedback : Utils.clone(feedback) };

            // There is an Error
        } else if (!prevProps.error && error) {
            this.setState({ loading : false, error : true });
        }

        // Set the State
        if (data) {
            this.setState({
                data, loading,
                errors      : {},
                currentPage : 1,
                totalPages  : Object.values(questions).length,
            });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        let questionID = name.split("-")[1];
        let feedback   = this.state.data.feedback;
        feedback[questionID] = value;

        this.setState({
            data : { ...this.state.data, feedback },
        });
    }

    /**
     * Handles the Submit
     * @param {Number} sectionID
     * @returns {Promise}
     */
    handleSave = async (sectionID) => {
        const { elem, credentialID, saveGuideFeedback, fetchGuide } = this.props;
        const { data, sending, currentPage                        } = this.state;

        if (!sending) {
            this.setState({ sending : true, errors : {} });
            try {
                await saveGuideFeedback({
                    strechGuideID : elem.strechGuideID,
                    sectionID     : sectionID,
                    feedback      : data.feedback[sectionID],
                    credentialID  : credentialID,
                });
                await fetchGuide(elem.strechGuideID, credentialID);
                this.setState({ sending : false, currentPage });
            } catch (errors) {
                this.setState({ sending : false, errors });
            }
        }
    }

    /**
     * Handles the Send
     * @returns {Promise}
     */
    handleSend = async () => {
        const { elem, credentialID, sendGuideFeedback, fetchGuide } = this.props;
        const { sending, currentPage                              } = this.state;

        if (!sending) {
            this.setState({ sending : true, errors : {} });
            try {
                await sendGuideFeedback({
                    strechGuideID : elem.strechGuideID,
                    credentialID  : credentialID,
                });
                await fetchGuide(elem.strechGuideID, credentialID);
                this.setState({ sending : false, update : true, currentPage });
            } catch (errors) {
                this.setState({ sending : false, errors });
            }
        }
    }

    /**
     * Handles the Close
     * @returns {Void}
     */
    handleClose = () => {
        this.props.onClose(this.state.update);
    }



    /**
     * Handles the Prev Page button
     * @returns {Void}
     */
    handlePrevPage = () => {
        const { isMounted, currentPage } = this.state;
        if (isMounted && currentPage > 1) {
            this.setState({ currentPage : currentPage - 1 });
        }
    }

    /**
     * Handles the Next Page button
     * @returns {Void}
     */
    handleNextPage = () => {
        const { isMounted, currentPage, totalPages } = this.state;
        if (isMounted && currentPage < totalPages) {
            this.setState({ currentPage : currentPage + 1 });
        }
    }

    /**
     * Returns the Section
     * @param {Object[]} questions
     * @param {Number}   currentPage
     * @returns {Object}
     */
    getSection(questions, currentPage) {
        let index = 1;
        for (const section of Object.values(questions)) {
            if (index === currentPage) {
                return section;
            }
            index += 1;
        }
        return { id : 0, name : "", questions : [] };
    }

    /**
     * Returns true if all the Questions are answered
     * @param {Object[]} questions
     * @param {Object}   feedback
     * @returns {Boolean}
     */
    allFeedback(questions, feedback) {
        if (Utils.isEmpty(questions) || this.state.loading) {
            return false;
        }
        for (const section of Object.values(questions)) {
            if (!feedback[section.id]) {
                return false;
            }
        }
        return true;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, questions, answers, feedback, feedbackSent, isOngoing, canFeedback, open } = this.props;
        const { data, error, loading, sending, currentPage, totalPages                         } = this.state;

        const section      = this.getSection(questions, currentPage);
        const canSave      = isOngoing && canFeedback && !feedbackSent;
        const canSend      = isOngoing && canFeedback && !feedbackSent && this.allFeedback(questions, feedback);
        const prevDisabled = currentPage === 1;
        const nextDisabled = currentPage >= totalPages;

        return <Dialog open={open} onClose={this.handleClose} isLoading={loading} isWide>
            <DialogHeader message={elem.fullName || "GUIDES_VIEW_TITLE"} icon="guide" />
            <DialogBody className="guide-dialog" withSpacing>
                {error ? <NoneAvailable message="GUIDES_ERROR_EXISTS" /> : <>
                    <div className="guide-answers">
                        <h3>{NLS.format("ANSWERS_SECTION", section.name)}</h3>
                        <AccordionList>
                            {section.questions.map((elem, index) => <AccordionItem
                                key={index}
                                number={elem.number}
                                message={elem.value}
                                icon={answers[elem.key] ? "checkedbox" : "checkbox"}
                            >
                                <ViewField
                                    label="ANSWERS_SINGULAR"
                                    value={answers[elem.key] || "-"}
                                    showEmpty
                                />
                            </AccordionItem>)}
                        </AccordionList>
                    </div>
                    <div className="guide-feedback">
                        <h3>{NLS.get("GENERAL_FEEDBACK")}</h3>
                        {canSave ? <div className="guide-answer">
                            <InputField
                                type="textarea"
                                name={`feedback-${section.id}`}
                                label="ANSWERS_MINE"
                                value={data.feedback[section.id] || ""}
                                onChange={this.handleChange}
                            />
                            <Button
                                variant="primary"
                                message="GENERAL_SAVE"
                                onClick={() => this.handleSave(section.id)}
                                isDisabled={sending}
                            />
                        </div> : <ViewField
                            label="ANSWERS_SINGULAR"
                            value={data.feedback[section.id] || NLS.get("GUIDES_NO_FEEDBACK")}
                            showEmpty
                        />}
                    </div>
                </>}
            </DialogBody>
            <DialogFooter
                primary={canSend ? "GENERAL_SEND" : ""}
                onSubmit={this.handleSend}
                cancel="GENERAL_CLOSE"
            >
                {!error && <>
                    <p>{Utils.getPageText(currentPage, totalPages)}</p>
                    <IconLink
                        className="left-space"
                        variant="light"
                        icon="prev"
                        onClick={this.handlePrevPage}
                        isDisabled={prevDisabled}
                    />
                    <IconLink
                        className="right-space"
                        variant="light"
                        icon="next"
                        onClick={this.handleNextPage}
                        isDisabled={nextDisabled}
                    />
                </>}
            </DialogFooter>
        </Dialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchGuide        : PropTypes.func.isRequired,
        saveGuideFeedback : PropTypes.func.isRequired,
        sendGuideFeedback : PropTypes.func.isRequired,
        open              : PropTypes.bool.isRequired,
        onClose           : PropTypes.func.isRequired,
        error             : PropTypes.bool.isRequired,
        edition           : PropTypes.number.isRequired,
        isOngoing         : PropTypes.bool.isRequired,
        canFeedback       : PropTypes.bool.isRequired,
        elem              : PropTypes.object.isRequired,
        questions         : PropTypes.object.isRequired,
        answers           : PropTypes.object.isRequired,
        feedback          : PropTypes.object.isRequired,
        feedbackSent      : PropTypes.bool.isRequired,
        elemID            : PropTypes.number,
        credentialID      : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            error        : state.strechGuide.error,
            edition      : state.strechGuide.edition,
            isOngoing    : state.strechGuide.isOngoing,
            canFeedback  : state.strechGuide.canFeedback,
            elem         : state.strechGuide.elem,
            questions    : state.strechGuide.questions,
            answers      : state.strechGuide.answers,
            feedback     : state.strechGuide.feedback,
            feedbackSent : state.strechGuide.feedbackSent,
        };
    }
}

export default connect(GuideAnswers.mapStateToProps, {
    fetchGuide, saveGuideFeedback, sendGuideFeedback,
})(GuideAnswers);
