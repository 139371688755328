import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStudents }    from "Actions/Admin/Strech/GroupActions";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import List                 from "dashboard/dist/Components/List";
import ListItem             from "dashboard/dist/Components/ListItem";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Student Select Dialog
 */
class StudentSelect extends React.Component {
    // The Current State
    state = {
        loading  : false,
        selected : [],
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, selection, strechID, selected, fetchStudents } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchStudents(strechID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.selection !== selection) {
            this.setState({ loading : false, selected : selected || [] });
        }
    }

    /**
     * Handles the Check
     * @param {Object} elem
     * @returns {Void}
     */
    handleCheck = (elem) => {
        const { credentialID, credentialName } = elem;
        const selected = [ ...this.state.selected ];
        const index    = selected.findIndex((elem) => elem.credentialID === credentialID);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push({ credentialID, credentialName  });
        }
        this.setState({ selected });
    }

    /**
     * Handles the Submit
     * @returns {Void}
     */
    handleSubmit = () => {
        this.props.onSubmit(this.state.selected);
        this.setState({ selected : [] });
    }

    /**
     * Handles the Close
     * @returns {Void}
     */
    handleClose = () => {
        this.props.onClose();
        this.setState({ selected : [] });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, groupID, students } = this.props;
        const { loading, selected       } = this.state;

        return <EditDialog
            open={open}
            icon="presentism"
            title="GROUPS_SELECT_STUDENTS"
            primary="GENERAL_SELECT"
            onSubmit={this.handleSubmit}
            onClose={this.handleClose}
            isLoading={loading}
        >
            <List>
                {students.map((elem) => <ListItem key={elem.credentialID}>
                    <InputField
                        type="checkbox"
                        name="student[]"
                        label={elem.credentialName}
                        value={selected.findIndex((sel) => sel.credentialID === elem.credentialID) > -1 ? 1 : 0}
                        isDisabled={Boolean(elem.groupID && elem.groupID !== groupID)}
                        onChange={() => this.handleCheck(elem)}
                    />
                    {elem.groupName}
                </ListItem>)}
            </List>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStudents : PropTypes.func.isRequired,
        open          : PropTypes.bool.isRequired,
        onSubmit      : PropTypes.func.isRequired,
        onClose       : PropTypes.func.isRequired,
        selection     : PropTypes.number.isRequired,
        canEdit       : PropTypes.bool.isRequired,
        students      : PropTypes.array.isRequired,
        selected      : PropTypes.array,
        groupID       : PropTypes.number,
        strechID      : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            selection : state.strechGroup.selection,
            canEdit   : state.strechGroup.canEdit,
            students  : state.strechGroup.students,
        };
    }
}

export default connect(StudentSelect.mapStateToProps, {
    fetchStudents,
})(StudentSelect);
