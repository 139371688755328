import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import AuthForm             from "Components/Auth/AuthForm";



/**
 * The Enroll Success Step
 */
class SuccessStep extends React.Component {
    /**
     * Handles the Submit
     * @returns {Void}
     */
    handleSubmit = () => {
        this.props.onSubmit({ step : "confirm" });
    }

    /**
     * Handles the Link
     * @returns {Void}
     */
    handleLink = () => {
        this.props.onSubmit({ step : "enroll" });
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { strechName, isFree, chats } = this.props;

        let addChat = false;
        let delChat = false;
        if (chats) {
            let amount = 0;
            for (const value of Object.values(chats)) {
                amount += value;
            }
            addChat = amount > 0;
            delChat = amount === 0;
        }

        let text = "ENROLL_INFO_TEXT";
        if (addChat) {
            text = "ENROLL_INFO_TEXT_ADD_CHAT";
        } else if (delChat) {
            text = "ENROLL_INFO_TEXT_DEL_CHAT";
        } else if (isFree) {
            text = "ENROLL_INFO_TEXT_FREE";
        }

        return <AuthForm
            title="ENROLL_NAME"
            help={NLS.format(text, strechName)}
            link="ENROLL_BACK"
            onSubmit={this.handleSubmit}
            onClick={this.handleLink}
        />;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        onSubmit   : PropTypes.func.isRequired,
        strechName : PropTypes.string.isRequired,
        isFree     : PropTypes.bool.isRequired,
        chats      : PropTypes.object,
    }
}

export default SuccessStep;
