import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import List                 from "dashboard/dist/Components/List";
import ListItem             from "dashboard/dist/Components/ListItem";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Coach Select Dialog
 */
class CoachSelect extends React.Component {
    // The Current State
    state = {
        selected : 0,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, selected } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            this.setState({ selected });
        }
    }

    /**
     * Handles the Check
     * @param {Number} selected
     * @returns {Void}
     */
    handleCheck = (selected) => {
        this.setState({ selected });
    }

    /**
     * Handles the Submit
     * @returns {Void}
     */
    handleSubmit = () => {
        this.props.onSubmit(this.state.selected);
        this.setState({ selected : 0 });
    }

    /**
     * Handles the Close
     * @returns {Void}
     */
    handleClose = () => {
        this.props.onClose();
        this.setState({ selected : 0 });
    }


    getCoachTrafficLightColor(elem) {
        let   trafficlightColor   = "Sin Semaforo";
        if ( elem.trafficlight === 1 ){
            trafficlightColor = 'Verde';
        }else if ( elem.trafficlight === 2 ) {
            trafficlightColor = 'Amarillo';
        }else if ( elem.trafficlight === 3 ) {
            trafficlightColor = 'Rojo';
        }
        return trafficlightColor;
    }


    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, coaches } = this.props;
        const { selected      } = this.state;

        return <EditDialog
            open={open}
            icon="presentism"
            title="INSC_ASSIGNS_SELECT"
            primary="GENERAL_SELECT"
            onSubmit={this.handleSubmit}
            onClose={this.handleClose}
        >
            <List columns="4">
                {coaches.map((elem) => <ListItem key={elem.credentialID}>
                    <InputField
                        type="checkbox"
                        name="coachID"
                        label={elem.credentialName}
                        value={selected === elem.credentialID ? 1 : 0}
                        onChange={() => this.handleCheck(elem.credentialID)}
                    />
                    <div>{this.getCoachTrafficLightColor(elem)}</div>
                    <div>{NLS.pluralize("INSC_ASSIGNS_ASSIGNS", elem.assignments)}</div>
                    <div>{NLS.pluralize("INSC_ASSIGNS_CONVS", elem.conversations)}</div>
                </ListItem>)}
            </List>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open     : PropTypes.bool.isRequired,
        onSubmit : PropTypes.func.isRequired,
        onClose  : PropTypes.func.isRequired,
        coaches  : PropTypes.array.isRequired,
        selected : PropTypes.number.isRequired,
    }
}

export default CoachSelect;
