import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";

// Dialogs
import GuideView            from "Components/Admin/Guide/Guide/GuideView";
import GuideEdit            from "Components/Admin/Strech/Guide/GuideEdit";
import GuideAnswers         from "Components/Admin/Strech/Guide/GuideAnswers";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import InfoList             from "dashboard/dist/Components/InfoList";
import InfoItem             from "dashboard/dist/Components/InfoItem";
import InfoAction           from "dashboard/dist/Components/InfoAction";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableCell            from "dashboard/dist/Components/TableCell";
import Html                 from "dashboard/dist/Components/Html";
import Button               from "dashboard/dist/Components/Button";

// Actions
import {
    fetchGuideStudents,
} from "Actions/Admin/Strech/GuideActions";



/**
 * The Strech Guide Students Dialog
 */
class GuideStudents extends React.Component {
    // The Current State
    state = {
        loading      : false,
        update       : false,
        action       : Action.get(),
        credentialID : null,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchGuideStudents } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchGuideStudents(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }

    /**
     * Handles the Close
     * @returns {Void}
     */
    handleClose = () => {
        this.props.onClose(this.state.update);
        this.setState({ update : false });
    }



    /**
     * Starts an Action
     * @param {Object}  action
     * @param {Object=} credentialID
     * @returns {Void}
     */
    startAction = (action, credentialID) => {
        this.setState({ action, credentialID });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = (update) => {
        const { elemID, fetchGuideStudents } = this.props;
        this.setState({ action : Action.get(), credentialID : null });
        if (update) {
            fetchGuideStudents(elemID);
        }
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        const { elemID, fetchGuideStudents } = this.props;
        this.endAction();
        this.setState({ update : true });
        fetchGuideStudents(elemID);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, canEdit, elem, students } = this.props;
        const { loading, action, credentialID } = this.state;

        return <>
            <ViewDialog
                open={open}
                icon="guide"
                title={elem.name || "GUIDES_VIEW_TITLE"}
                secondary="GUIDES_VIEW_TITLE"
                onSecondary={() => this.startAction(Action.get("GUIDE"))}
                onClose={this.handleClose}
                isLoading={loading}
            >
                <InfoList onAction={this.startAction}>
                    <InfoItem label="CLASSES_SINGULAR"    message={elem.className}   />
                    <InfoItem label="GENERAL_CREATED_FEM" message={elem.createdDate} />
                    <InfoAction action="EDIT" isHidden={!canEdit} />
                </InfoList>

                <div className="spacing">
                    <Table none="GUIDES_NONE_ANSWERS" noSorting noClick notFixed>
                        <TableHead>
                            <TableHeader field="name"     message="GENERAL_NAME"     grow="2" />
                            <TableHeader field="date"     message="ANSWERS_DATE"     />
                            <TableHeader field="feedback" message="GENERAL_FEEDBACK" />
                            <TableHeader field="button"                              align="center" />
                        </TableHead>
                        <TableBody>
                            {students.map((elem) => <TableRow key={elem.credentialID}>
                                <TableCell><Html>{elem.sentUser}</Html></TableCell>
                                <TableCell message={elem.sentDate} />
                                <TableCell message={elem.feedbackText} />
                                <TableCell>
                                    <Button
                                        variant="primary"
                                        message="GENERAL_VIEW"
                                        onClick={() => this.startAction(Action.get("VIEW"), elem.credentialID)}
                                    />
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </div>
            </ViewDialog>

            <GuideView
                open={action.isGuide}
                elemID={elem.guideID}
                onClose={this.endAction}
            />
            <GuideAnswers
                open={action.isView}
                elemID={elem.strechGuideID}
                credentialID={credentialID}
                onClose={this.endAction}
            />
            <GuideEdit
                open={action.isEdit}
                elemID={elem.strechGuideID}
                strechID={elem.strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchGuideStudents : PropTypes.func.isRequired,
        open               : PropTypes.bool.isRequired,
        onClose            : PropTypes.func.isRequired,
        edition            : PropTypes.number.isRequired,
        canEdit            : PropTypes.bool.isRequired,
        elem               : PropTypes.object.isRequired,
        students           : PropTypes.array.isRequired,
        elemID             : PropTypes.number,
        strechID           : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition  : state.strechGuide.edition,
            canEdit  : state.strechGuide.canEdit,
            elem     : state.strechGuide.elem,
            students : state.strechGuide.students,
        };
    }
}

export default connect(GuideStudents.mapStateToProps, {
    fetchGuideStudents,
})(GuideStudents);
