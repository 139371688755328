import React                from "react";
import PropTypes            from "prop-types";

// Sections
import DashboardPage        from "Components/Admin/Dashboard/DashboardPage";
import InscriptionList      from "Components/Admin/Dashboard/InscriptionList";
import CourseList           from "Components/Admin/Course/Course/CourseList";
import ProgramList          from "Components/Admin/Program/Program/ProgramList";
import StrechList           from "Components/Admin/Strech/Strech/StrechList";
import ConversationList     from "Components/Admin/Conversation/ConversationList";
import InvoiceList          from "Components/Admin/Coach/Invoice/InvoiceList";
import CampaignList         from "Components/Admin/Campaign/CampaignList";
import TicketList           from "Components/Admin/Ticket/Ticket/TicketList";
import LeadList             from "Components/Admin/Lead/Lead/LeadList";
import LeadPage             from "Components/Admin/Lead/Lead/LeadPage";
import SupportPage          from "Components/Admin/Ticket/Support/SupportPage";
import StudentList          from "Components/Admin/User/Student/StudentList";
import CoachList            from "Components/Admin/User/Coach/CoachList";

// Components
import PrimaryNav           from "Components/Utils/Navigation/PrimaryNav";
import AdminRoute           from "Components/Utils/Route/AdminRoute";
import NavigationList       from "dashboard/dist/Components/NavigationList";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";



/**
 * The Admin Container
 */
class AdminContainer extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { route } = this.props;

        return <>
            <PrimaryNav path={route}>
                <NavigationList>
                    <NavigationItem message="DASHBOARD_NAME"      url="DASHBOARD"     icon="home"         usePrefix />
                    <NavigationItem message="COURSES_NAME"        url="COURSES"       icon="course"       />
                    <NavigationItem message="PROGRAMS_NAME"       url="PROGRAMS"      icon="program"      />
                    <NavigationItem message="STRECHES_NAME"       url="STRECHES"      icon="strech"       />
                    <NavigationItem message="CONVERSATIONS_NAME"  url="CONVERSATIONS" icon="conversation" />
                    <NavigationItem message="COACH_INVOICES_NAME" url="INVOICES"      icon="invoice"      usePrefix />
                    <NavigationItem message="CAMPAIGNS_NAME"      url="CAMPAIGNS"     icon="campaign"     />
                    <NavigationItem message="REPORTS_NAME"        url="REPORTS"       icon="report"       />
                </NavigationList>
                <NavigationList>
                    <NavigationItem message="TICKETS_NAME"        url="TICKETS"       icon="ticket"       usePrefix />
                    <NavigationItem message="LEADS_NAME"          url="LEADS"         icon="lead"         usePrefix />
                </NavigationList>
                <NavigationList>
                    <NavigationItem message="STUDENTS_NAME"       url="STUDENTS"      icon="student"      />
                    <NavigationItem message="COACHES_NAME"        url="COACHES"       icon="coach"        />
                </NavigationList>
                <NavigationList>
                    <NavigationItem message="SETTINGS_NAME"       url="SETTINGS"      icon="settings"     />
                </NavigationList>
            </PrimaryNav>

            <SwitchRoute type="ADMIN">
                <AdminRoute url="DASHBOARD"              component={DashboardPage}    exact />
                <AdminRoute url="DASHBOARD_INSCRIPTIONS" component={InscriptionList}  exact />
                <AdminRoute url="DASHBOARD_SIGNUPS"      component={StudentList}      exact />
                <AdminRoute url="COURSES"                component={CourseList}       exact />
                <AdminRoute url="PROGRAMS"               component={ProgramList}      exact />
                <AdminRoute url="STRECHES"               component={StrechList}       exact />
                <AdminRoute url="CONVERSATIONS"          component={ConversationList} exact />
                <AdminRoute url="INVOICES"               component={InvoiceList}      exact />
                <AdminRoute url="INVOICES_INVOICE"       component={InvoiceList}      exact />
                <AdminRoute url="CAMPAIGNS"              component={CampaignList}     exact />
                <AdminRoute url="TICKETS"                component={TicketList}       exact />
                <AdminRoute url="TICKETS_TICKET"         component={TicketList}       exact />
                <AdminRoute url="LEADS"                  component={LeadList}         exact />
                <AdminRoute url="LEADS_LEAD"             component={LeadPage}         exact />
                <AdminRoute url="STUDENTS"               component={StudentList}      exact />
                <AdminRoute url="COACHES"                component={CoachList}        exact />
                <AdminRoute url="SUPPORT"                component={SupportPage}      exact />
            </SwitchRoute>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        route : PropTypes.string.isRequired,
    }
}

export default AdminContainer;
