import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { sendInvoice }      from "Actions/Admin/Coach/InvoiceActions";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Coach Invoice Send Dialog
 */
class InvoiceSend extends React.Component {
    // The Initial Data
    initialData = {
        invoiceID : 0,
        file      : "",
        message   : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, elemID } = this.props;
        let data = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData, invoiceID : elemID };
        }

        // Set the State
        if (data) {
            this.setState({ data, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.sendInvoice(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose         } = this.props;
        const { data, loading, errors } = this.state;

        return <EditDialog
            open={open}
            icon="invoice"
            title="COACH_INVOICES_SEND_TITLE"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
            isNarrow
        >
            <InputField
                type="file"
                name="file"
                label="COACH_INVOICES_FILE"
                error={errors.file}
                onChange={this.handleChange}
                shrinkLabel
            />
            <InputField
                type="textarea"
                name="message"
                label="COACH_INVOICES_MESSAGE"
                value={data.message}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        sendInvoice : PropTypes.func.isRequired,
        open        : PropTypes.bool.isRequired,
        onClose     : PropTypes.func.isRequired,
        onSubmit    : PropTypes.func.isRequired,
        elemID      : PropTypes.number,
    }
}

export default connect(null, {
    sendInvoice,
})(InvoiceSend);
