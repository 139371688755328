import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Student }          from "Utils/API";
import Action               from "dashboard/dist/Core/Action";

// Components
import StudentEdit          from "Components/Admin/User/Student/StudentEdit";
import EditDialog           from "dashboard/dist/Components/EditDialog";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    createInscription,
} from "Actions/Admin/Strech/InscriptionChatActions";



/**
 * The Chat Enroll Dialog
 */
class ChatEnroll extends React.Component {
    // The Initial Data
    initialData = {
        strechID       : 0,
        strechChatID   : 0,
        credentialID   : 0,
        credentialName : "",
        observations   : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
        action  : Action.get(),
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, elemID, strechID } = this.props;
        let data = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            if (elemID) {
                data.strechID     = strechID;
                data.strechChatID = elemID;
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.createInscription(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Starts an Action
     * @returns {Void}
     */
    startAction = () => {
        this.setState({ action : Action.get("CREATE") });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.setState({ action : Action.get() });
    }

    /**
     * Handles the Create Submit
     * @param {Object} data
     * @returns {Void}
     */
    createElem = (data) => {
        this.endAction();
        if (data) {
            this.handleChange("credentialID",   data.credentialID);
            this.handleChange("credentialName", data.credentialName);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose                 } = this.props;
        const { data, loading, errors, action } = this.state;

        return <>
            <EditDialog
                open={open}
                icon="inscription"
                title="INSCRIPTIONS_CREATE_TITLE"
                error={errors.form}
                onSubmit={this.handleSubmit}
                onClose={onClose}
                isLoading={loading}
            >
                <InputField
                    name="credentialName"
                    label="STUDENTS_SINGULAR"
                    suggestID="credentialID"
                    suggestFetch={Student.search}
                    suggestNone="STUDENTS_NONE_AVAILABLE"
                    value={data.credentialName}
                    error={errors.credentialID}
                    onChange={this.handleChange}
                    button="GENERAL_CREATE"
                    onClick={this.startAction}
                    isRequired
                />
                <InputField
                    type="textarea"
                    name="observations"
                    label="GENERAL_OBSERVATIONS"
                    value={data.observations}
                    onChange={this.handleChange}
                />
            </EditDialog>

            <StudentEdit
                open={action.isCreate}
                onSubmit={this.createElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        createInscription : PropTypes.func.isRequired,
        open              : PropTypes.bool.isRequired,
        onClose           : PropTypes.func.isRequired,
        onSubmit          : PropTypes.func.isRequired,
        elemID            : PropTypes.number,
        strechID          : PropTypes.number,
    }
}

export default connect(null, {
    createInscription,
})(ChatEnroll);
