import React                from "react";
import PropTypes            from "prop-types";

// Sections
import InscriptionReport    from "Components/Admin/Report/Report/InscriptionReport";
import ConversationReport   from "Components/Admin/Report/Report/ConversationReport";
import MeetingReport        from "Components/Admin/Report/Report/MeetingReport";
import GuideReport          from "Components/Admin/Report/Report/GuideReport";
import PollReport           from "Components/Admin/Report/Report/PollReport";
import InvoiceReport        from "Components/Admin/Report/Report/InvoiceReport";

// Components
import SecondaryNav         from "Components/Utils/Navigation/SecondaryNav";
import AdminRoute           from "Components/Utils/Route/AdminRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationList       from "dashboard/dist/Components/NavigationList";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Report Container
 */
class ReportContainer extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { match, route } = this.props;
        const { path, url    } = match;

        return <>
            <SecondaryNav message="REPORTS_NAME" path={route} baseUrl={url}>
                <NavigationList>
                    <NavigationItem message="REPORTS_INSCRIPTIONS"  url="INSCRIPTIONS"  icon="inscription"  />
                    <NavigationItem message="REPORTS_CONVERSATIONS" url="CONVERSATIONS" icon="conversation" />
                    <NavigationItem message="REPORTS_MEETINGS"      url="MEETINGS"      icon="meeting"      />
                    <NavigationItem message="REPORTS_GUIDES"        url="GUIDES"        icon="guide"        />
                    <NavigationItem message="REPORTS_POLLS"         url="POLLS"         icon="question"     />
                    <NavigationItem message="REPORTS_INVOICES"      url="INVOICES"      icon="invoice"      />
                </NavigationList>
            </SecondaryNav>

            <SwitchRoute type="SETUP" path={path} baseUrl={url}>
                <AdminRoute url="INSCRIPTIONS"  component={InscriptionReport}  />
                <AdminRoute url="CONVERSATIONS" component={ConversationReport} />
                <AdminRoute url="MEETINGS"      component={MeetingReport}      />
                <AdminRoute url="GUIDES"        component={GuideReport}        />
                <AdminRoute url="POLLS"         component={PollReport}         />
                <AdminRoute url="INVOICES"      component={InvoiceReport}      />
            </SwitchRoute>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        match : PropTypes.object.isRequired,
        route : PropTypes.string.isRequired,
    }
}

export default ReportContainer;
