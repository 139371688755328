import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    PROGRAM_PRICE_LOADING,
    PROGRAM_PRICE_LIST, PROGRAM_PRICE_ELEM,
} from "Utils/Types";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    sort      : {
        orderBy  : "dues",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.name            = NLS.pluralize("PRICES_NAME", elem.dues, elem.price);
    elem.duesName        = NLS.pluralize("PRICES_DUES", elem.dues);
    elem.showPriceString = Utils.toYesNo(elem.showPrice);
    elem.promotionString = Utils.toYesNo(elem.isPromotion);
    elem.statusName      = Status.getName(elem.status);
    elem.statusColor     = Status.getColor(elem.status);
    elem.statusClass     = Status.getTextClass(elem.status);
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, PROGRAM_PRICE_LIST, PROGRAM_PRICE_ELEM)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case PROGRAM_PRICE_LOADING:
        return {
            ...state,
            loading   : true,
        };

    case PROGRAM_PRICE_LIST:
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : parseList(action.data.list),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case PROGRAM_PRICE_ELEM:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            canEdit   : action.data.canEdit,
            elem      : parseElem(action.data.elem),
        };

    default:
        return state;
    }
};

export default reducer;
