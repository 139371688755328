import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import Avatar               from "dashboard/dist/Components/Avatar";
import Button               from "dashboard/dist/Components/Button";

// Actions
import {
    fetchProfile, uploadProfile,
} from "Actions/Profile/ProfileActions";



/**
 * The Settings Avatar
 */
class SettingsAvatar extends React.Component {
    // The Current State
    state = {
        loading : false,
    }

    /**
     * Handles the Submit
     * @param {React.ChangeEvent<HTMLInputElement>} e
     * @returns {Promise}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { uploadProfile, fetchProfile } = this.props;
        const { loading                     } = this.state;
        const files = e.target.files;

        if (files.length === 1 && !loading) {
            this.setState({ loading : true });
            await uploadProfile(files[0]);
            await fetchProfile(false);
            this.setState({ loading : false });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem    } = this.props;
        const { loading } = this.state;

        return <div className="settings-avatar">
            <Avatar
                name={elem.credentialName}
                email={elem.email}
                avatar={elem.avatar}
                size={120}
                withReload
            />
            <input
                type="file"
                accept="image/*"
                id="settings-file"
                className="settings-input"
                onChange={this.handleSubmit}
                ref={(elem) => (this.inputElement = elem)}
            />
            <label htmlFor="settings-file">
                <Button
                    variant="outlined"
                    className="settings-change"
                    isDisabled={loading}
                    onClick={() => this.inputElement.click()}
                    message="ACCOUNT_CHANGE_AVATAR"
                />
            </label>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchProfile  : PropTypes.func.isRequired,
        uploadProfile : PropTypes.func.isRequired,
        elem          : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem : state.profile.elem,
        };
    }
}

export default connect(SettingsAvatar.mapStateToProps, {
    fetchProfile, uploadProfile,
})(SettingsAvatar);
