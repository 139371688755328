import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    STRECH_GROUP_LOADING, STRECH_GROUP_CHARGING,
    STRECH_GROUP_LIST, STRECH_GROUP_ELEM, STRECH_GROUP_CREATE,
    STRECH_GROUP_CHANGE, STRECH_GROUP_STUDENTS,
} from "Utils/Types";



// The initial State
const initialState = {
    loading     : false,
    charging    : false,
    error       : false,
    edition     : 0,
    selection   : 0,
    canCreate   : false,
    canEdit     : false,
    canImport   : false,
    canExport   : false,
    list        : [],
    total       : 0,
    elem        : {},
    coaches     : [],
    students    : [],
    groups      : [],
    groupID     : 0,
    strechName  : "",
    studentName : "",
    sort        : {
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.meetingsText    = NLS.format("GENERAL_X_OF_Y", elem.totalMeetings, elem.meetingAmount);
    elem.statusName      = Status.getName(elem.status);
    elem.statusColor     = Status.getColor(elem.status);
    elem.statusClass     = Status.getTextClass(elem.status);
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, STRECH_GROUP_LIST, STRECH_GROUP_ELEM, STRECH_GROUP_CREATE, STRECH_GROUP_CHANGE, STRECH_GROUP_STUDENTS)) {
        return { ...state, loading : false, charging : false, error : true };
    }

    switch (action.type) {
    case STRECH_GROUP_LOADING:
        return {
            ...state,
            loading     : true,
        };
    case STRECH_GROUP_CHARGING:
        return {
            ...state,
            charging    : true,
        };

    case STRECH_GROUP_LIST:
        return {
            ...state,
            loading     : false,
            error       : false,
            canCreate   : action.data.canCreate,
            canEdit     : action.data.canEdit,
            canImport   : action.data.canImport,
            canExport   : action.data.canExport,
            list        : parseList(action.data.list),
            total       : action.data.total,
            sort        : action.data.sort,
        };

    case STRECH_GROUP_ELEM:
        return {
            ...state,
            charging    : false,
            error       : false,
            edition     : state.edition + 1,
            canEdit     : action.data.canEdit,
            coaches     : action.data.coaches,
            elem        : parseElem(action.data.elem),
        };

    case STRECH_GROUP_CREATE:
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            coaches     : action.data.coaches,
        };

    case STRECH_GROUP_CHANGE:
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            groupID     : action.data.groupID,
            strechName  : action.data.strechName,
            studentName : action.data.studentName,
            groups      : action.data.groups,
        };

    case STRECH_GROUP_STUDENTS:
        return {
            ...state,
            error       : false,
            selection   : state.selection + 1,
            students    : action.data.students,
            groups      : action.data.groups,
        };

    default:
        return state;
    }
};

export default reducer;
