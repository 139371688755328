import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import MediaField           from "Components/Utils/Media/MediaField";
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchProgram, fetchProgramCreate, editProgram,
} from "Actions/Admin/Program/ProgramActions";



/**
 * The Program Edit Dialog
 */
class ProgramEdit extends React.Component {
    // The Initial Data
    initialData = {
        programID        : 0,
        courseID         : 0,
        name             : "",
        title            : "",
        type             : 0,
        guideFile        : "",
        isFree           : 0,
        onlyCoaches      : 0,
        hasClasses       : 1,
        hasChats         : 1,
        hasMaterials     : 1,
        hasGuides        : 1,
        hasGroups        : 1,
        hasConversations : 1,
        hasPoll          : 1,
        hasCertificate   : 1,
        position         : 0,
        status           : Status.getID("ACTIVE"),
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, courseID, position, fetchProgram, fetchProgramCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchProgram(elemID);
                loading = true;
            // Load edit data
            } else {
                fetchProgramCreate();
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData, courseID, position };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editProgram(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { courseID, elemID, open, onClose, courses } = this.props;
        const { data, loading, errors                    } = this.state;

        return <EditDialog
            open={open}
            icon="program"
            title={!!elemID ? "PROGRAMS_EDIT_TITLE" : "PROGRAMS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                isHidden={!!courseID}
                type="select"
                name="courseID"
                label="PROGRAMS_COURSE"
                value={data.courseID}
                error={errors.courseID}
                onChange={this.handleChange}
                options={courses}
                withNone
            />
            <InputField
                type="select"
                name="type"
                label="PROGRAMS_TYPE"
                value={data.type}
                error={errors.type}
                onChange={this.handleChange}
                options="TYPES"
                isRequired
                withNone
            />
            <InputField
                name="name"
                label="PROGRAMS_ITS_NAME"
                value={data.name}
                error={errors.name}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                name="title"
                label="GENERAL_STUDENT_TITLE"
                helperText="GENERAL_STUDENT_TITLE_HELP"
                value={data.title}
                error={errors.title}
                onChange={this.handleChange}
                isRequired
            />
            <MediaField
                name="guideFile"
                label="PROGRAMS_FILE"
                value={data.guideFile}
                error={errors.guideFile}
                onChange={this.handleChange}
            />
            <Columns>
                <InputField
                    type="toggle"
                    name="isFree"
                    label="PROGRAMS_IS_FREE"
                    value={data.isFree}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="onlyCoaches"
                    label="PROGRAMS_ONLY_COACHES"
                    value={data.onlyCoaches}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="hasChats"
                    label="PROGRAMS_HAS_CHATS"
                    value={data.hasChats}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="hasClasses"
                    label="PROGRAMS_HAS_CLASSES"
                    value={data.hasClasses}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="hasMaterials"
                    label="PROGRAMS_HAS_MATERIALS"
                    value={data.hasMaterials}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="hasGuides"
                    label="PROGRAMS_HAS_GUIDES"
                    value={data.hasGuides}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="hasGroups"
                    label="PROGRAMS_HAS_GROUPS"
                    value={data.hasGroups}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="hasConversations"
                    label="PROGRAMS_HAS_CONVERSATIONS"
                    value={data.hasConversations}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="hasPoll"
                    label="PROGRAMS_HAS_POLL"
                    value={data.hasPoll}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="hasCertificate"
                    label="PROGRAMS_HAS_CERTIFICATE"
                    value={data.hasCertificate}
                    onChange={this.handleChange}
                />
            </Columns>
            <Columns>
                <InputField
                    type="number"
                    name="position"
                    label="GENERAL_POSITION"
                    value={data.position}
                    error={errors.position}
                    onChange={this.handleChange}
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    error={errors.status}
                    onChange={this.handleChange}
                    options={Status.getSelect()}
                    withNone
                    isRequired
                    shrinkLabel
                />
            </Columns>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchProgram       : PropTypes.func.isRequired,
        fetchProgramCreate : PropTypes.func.isRequired,
        editProgram        : PropTypes.func.isRequired,
        open               : PropTypes.bool.isRequired,
        onClose            : PropTypes.func.isRequired,
        onSubmit           : PropTypes.func.isRequired,
        edition            : PropTypes.number.isRequired,
        position           : PropTypes.number.isRequired,
        courses            : PropTypes.array.isRequired,
        elem               : PropTypes.object.isRequired,
        elemID             : PropTypes.number,
        courseID           : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition  : state.program.edition,
            position : state.program.position,
            courses  : state.program.courses,
            elem     : state.program.elem,
        };
    }
}

export default connect(ProgramEdit.mapStateToProps, {
    fetchProgram, fetchProgramCreate, editProgram,
})(ProgramEdit);
