import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { validateOptions }  from "Actions/Student/EnrollActions";
import NLS                  from "dashboard/dist/Core/NLS";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import AuthForm             from "Components/Auth/AuthForm";
import InputField           from "dashboard/dist/Components/InputField";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";



/**
 * The Enroll Options Step
 */
class OptionsStep extends React.Component {
    // The Initial Data
    initialData = {
        modes : [],
        times : [],
    }

    // The Current State
    state = {
        data    : Utils.clone(this.initialData),
        loading : false,
        errors  : {},
    }



    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { strech, validateOptions, onSubmit } = this.props;
        const { data, loading                     } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await validateOptions(strech.strechID, data);
                onSubmit({ step : "options", options : data });
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { strech, onSubmit      } = this.props;
        const { data, loading, errors } = this.state;

        const textpagos = "ENROLL_TEXT_PAGOS";

        return <AuthForm
            onSubmit={this.handleSubmit}
            title="ENROLL_NAME"
            help={NLS.format("ENROLL_OPTIONS_HELP", strech.title)}
            error={errors.form}
            isLoading={loading}
            link="ENROLL_BACK"
            onClick={() => onSubmit({ step : "enroll" })}
        >
            <DialogMessage
                variant="p"
                message={textpagos}
                centered
            />

            {/* 

            <a style={{textAlign:"center", padding: '8px', backgroundColor: "rgba(255, 255, 255, 0.7)", display: "block"}} target="_blank" href={NLS.get("ENROLL_LINK_PAGOS1")}>{NLS.get("ENROLL_LINK_PAGOS_TEXT")}</a>            
     */}


            {/* 

            <InputField
                className="auth-multiple"
                type="multiple"
                name="modes"
                label="GENERAL_MODES"
                value={data.modes}
                error={errors.modes}
                onChange={this.handleChange}
                options="MODES"
                labelInside
                smallMargin
                errorBackground
                isRequired
            />
            <InputField
                className="auth-multiple"
                type="multiple"
                name="times"
                label="SCHEDULES_NAME"
                value={data.times}
                error={errors.times}
                onChange={this.handleChange}
                options="TIMES"
                labelInside
                smallMargin
                errorBackground
                isRequired
            />
     */}

        </AuthForm>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        validateOptions : PropTypes.func.isRequired,
        strech          : PropTypes.object.isRequired,
        onSubmit        : PropTypes.func.isRequired,
    }
}

export default connect(null, {
    validateOptions,
})(OptionsStep);
