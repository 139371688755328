// Core Types
export const CORE_LOADING               = "CORE_LOADING";
export const CORE_REDIRECT              = "CORE_REDIRECT";
export const CORE_SECTION               = "CORE_SECTION";
export const CORE_RESULT                = "CORE_RESULT";
export const CORE_MENU_OPEN             = "CORE_MENU_OPEN";
export const CORE_MENU_CLOSE            = "CORE_MENU_CLOSE";
export const CORE_DETAILS_SET           = "CORE_DETAILS_SET";
export const CORE_DETAILS_OPEN          = "CORE_DETAILS_OPEN";
export const CORE_DETAILS_CLOSE         = "CORE_DETAILS_CLOSE";

export const AUTH_DATA                  = "AUTH_DATA";
export const AUTH_COURSE                = "AUTH_COURSE";
export const AUTH_ASSIGN                = "AUTH_ASSIGN";
export const AUTH_CURRENT_USER          = "AUTH_CURRENT_USER";

export const MEDIA_LOADING              = "MEDIA_LOADING";
export const MEDIA_LIST                 = "MEDIA_LIST";



// Student Types
export const HOME                       = "HOME";
export const HOME_LOADING               = "HOME_LOADING";
export const HOME_LIST                  = "HOME_LIST";
export const HOME_WELCOME               = "HOME_WELCOME";

export const CALENDAR                   = "CALENDAR";
export const CALENDAR_LOADING           = "CALENDAR_LOADING";
export const CALENDAR_LIST              = "CALENDAR_LIST";
export const CALENDAR_DAY               = "CALENDAR_DAY";

export const ENROLL                     = "ENROLL";
export const ENROLL_LOADING             = "ENROLL_LOADING";
export const ENROLL_LIST                = "ENROLL_LIST";
export const ENROLL_DAY                 = "ENROLL_DAY";



// Admin Types
export const DASHBOARD                  = "DASHBOARD";
export const DASHBOARD_LOADING          = "DASHBOARD_LOADING";
export const DASHBOARD_LIST             = "DASHBOARD_LIST";



// Course Types
export const COURSE                     = "COURSE";
export const COURSE_LOADING             = "COURSE_LOADING";
export const COURSE_CHARGING            = "COURSE_CHARGING";
export const COURSE_LIST                = "COURSE_LIST";
export const COURSE_ELEM                = "COURSE_ELEM";
export const COURSE_CREATE              = "COURSE_CREATE";

export const COURSE_COMPLETED_ELEM      = "COURSE_COMPLETED_ELEM";



// Program Types
export const PROGRAM                    = "PROGRAM";
export const PROGRAM_LOADING            = "PROGRAM_LOADING";
export const PROGRAM_CHARGING           = "PROGRAM_CHARGING";
export const PROGRAM_LIST               = "PROGRAM_LIST";
export const PROGRAM_ELEM               = "PROGRAM_ELEM";
export const PROGRAM_CREATE             = "PROGRAM_CREATE";

export const PROGRAM_PRICE              = "PROGRAM_PRICE";
export const PROGRAM_PRICE_LOADING      = "PROGRAM_PRICE_LOADING";
export const PROGRAM_PRICE_LIST         = "PROGRAM_PRICE_LIST";
export const PROGRAM_PRICE_ELEM         = "PROGRAM_PRICE_ELEM";

export const PROGRAM_CHAT               = "PROGRAM_CHAT";
export const PROGRAM_CHAT_LOADING       = "PROGRAM_CHAT_LOADING";
export const PROGRAM_CHAT_LIST          = "PROGRAM_CHAT_LIST";
export const PROGRAM_CHAT_ELEM          = "PROGRAM_CHAT_ELEM";
export const PROGRAM_CHAT_CREATE        = "PROGRAM_CHAT_CREATE";

export const PROGRAM_CLASS              = "PROGRAM_CLASS";
export const PROGRAM_CLASS_LOADING      = "PROGRAM_CLASS_LOADING";
export const PROGRAM_CLASS_LIST         = "PROGRAM_CLASS_LIST";
export const PROGRAM_CLASS_ELEM         = "PROGRAM_CLASS_ELEM";
export const PROGRAM_CLASS_CREATE       = "PROGRAM_CLASS_CREATE";

export const PROGRAM_MATERIAL           = "PROGRAM_MATERIAL";
export const PROGRAM_MATERIAL_LOADING   = "PROGRAM_MATERIAL_LOADING";
export const PROGRAM_MATERIAL_LIST      = "PROGRAM_MATERIAL_LIST";
export const PROGRAM_MATERIAL_ELEM      = "PROGRAM_MATERIAL_ELEM";
export const PROGRAM_MATERIAL_CREATE    = "PROGRAM_MATERIAL_CREATE";

export const PROGRAM_GUIDE              = "PROGRAM_GUIDE";
export const PROGRAM_GUIDE_LOADING      = "PROGRAM_GUIDE_LOADING";
export const PROGRAM_GUIDE_LIST         = "PROGRAM_GUIDE_LIST";
export const PROGRAM_GUIDE_ELEM         = "PROGRAM_GUIDE_ELEM";
export const PROGRAM_GUIDE_CREATE       = "PROGRAM_GUIDE_CREATE";

export const PROGRAM_SPECIALITY         = "PROGRAM_SPECIALITY";
export const PROGRAM_SPECIALITY_LOADING = "PROGRAM_SPECIALITY_LOADING";
export const PROGRAM_SPECIALITY_LIST    = "PROGRAM_SPECIALITY_LIST";
export const PROGRAM_SPECIALITY_ELEM    = "PROGRAM_SPECIALITY_ELEM";
export const PROGRAM_SPECIALITY_CREATE  = "PROGRAM_SPECIALITY_CREATE";

export const PROGRAM_QUESTION           = "PROGRAM_QUESTION";
export const PROGRAM_QUESTION_LOADING   = "PROGRAM_QUESTION_LOADING";
export const PROGRAM_QUESTION_LIST      = "PROGRAM_QUESTION_LIST";
export const PROGRAM_QUESTION_ELEM      = "PROGRAM_QUESTION_ELEM";
export const PROGRAM_QUESTION_CREATE    = "PROGRAM_QUESTION_CREATE";



// Strech Types
export const STRECH                     = "STRECH";
export const STRECH_LOADING             = "STRECH_LOADING";
export const STRECH_CHARGING            = "STRECH_CHARGING";
export const STRECH_LIST                = "STRECH_LIST";
export const STRECH_ELEM                = "STRECH_ELEM";
export const STRECH_WELCOME             = "STRECH_WELCOME";
export const STRECH_EDIT                = "STRECH_EDIT";
export const STRECH_CREATE              = "STRECH_CREATE";

export const STRECH_PRICE               = "STRECH_PRICE";
export const STRECH_PRICE_LOADING       = "STRECH_PRICE_LOADING";
export const STRECH_PRICE_LIST          = "STRECH_PRICE_LIST";
export const STRECH_PRICE_ELEM          = "STRECH_PRICE_ELEM";
export const STRECH_PRICE_CREATE        = "STRECH_PRICE_CREATE";

export const STRECH_SCHEDULE            = "STRECH_SCHEDULE";
export const STRECH_SCHEDULE_LOADING    = "STRECH_SCHEDULE_LOADING";
export const STRECH_SCHEDULE_LIST       = "STRECH_SCHEDULE_LIST";
export const STRECH_SCHEDULE_ELEM       = "STRECH_SCHEDULE_ELEM";

export const STRECH_COACH               = "STRECH_COACH";
export const STRECH_COACH_LOADING       = "STRECH_COACH_LOADING";
export const STRECH_COACH_LIST          = "STRECH_COACH_LIST";
export const STRECH_COACH_ELEM          = "STRECH_COACH_ELEM";
export const STRECH_COACH_CREATE        = "STRECH_COACH_CREATE";

export const STRECH_CHAT                = "STRECH_CHAT";
export const STRECH_CHAT_LOADING        = "STRECH_CHAT_LOADING";
export const STRECH_CHAT_LIST           = "STRECH_CHAT_LIST";
export const STRECH_CHAT_ELEM           = "STRECH_CHAT_ELEM";
export const STRECH_CHAT_EDIT           = "STRECH_CHAT_EDIT";
export const STRECH_CHAT_CREATE         = "STRECH_CHAT_CREATE";

export const STRECH_CLASS               = "STRECH_CLASS";
export const STRECH_CLASS_LOADING       = "STRECH_CLASS_LOADING";
export const STRECH_CLASS_CHARGING      = "STRECH_CLASS_CHARGING";
export const STRECH_CLASS_LIST          = "STRECH_CLASS_LIST";
export const STRECH_CLASS_ELEM          = "STRECH_CLASS_ELEM";
export const STRECH_CLASS_EDIT          = "STRECH_CLASS_EDIT";
export const STRECH_CLASS_CREATE        = "STRECH_CLASS_CREATE";

export const STRECH_MATERIAL            = "STRECH_MATERIAL";
export const STRECH_MATERIAL_LOADING    = "STRECH_MATERIAL_LOADING";
export const STRECH_MATERIAL_LIST       = "STRECH_MATERIAL_LIST";
export const STRECH_MATERIAL_ELEM       = "STRECH_MATERIAL_ELEM";
export const STRECH_MATERIAL_EDIT       = "STRECH_MATERIAL_EDIT";
export const STRECH_MATERIAL_CREATE     = "STRECH_MATERIAL_CREATE";

export const STRECH_GUIDE               = "STRECH_GUIDE";
export const STRECH_GUIDE_LOADING       = "STRECH_GUIDE_LOADING";
export const STRECH_GUIDE_LIST          = "STRECH_GUIDE_LIST";
export const STRECH_GUIDE_ELEM          = "STRECH_GUIDE_ELEM";
export const STRECH_GUIDE_EDIT          = "STRECH_GUIDE_EDIT";
export const STRECH_GUIDE_CREATE        = "STRECH_GUIDE_CREATE";
export const STRECH_GUIDE_STUDENTS      = "STRECH_GUIDE_STUDENTS";

export const STRECH_SPECIALITY          = "STRECH_SPECIALITY";
export const STRECH_SPECIALITY_LOADING  = "STRECH_SPECIALITY_LOADING";
export const STRECH_SPECIALITY_LIST     = "STRECH_SPECIALITY_LIST";
export const STRECH_SPECIALITY_ELEM     = "STRECH_SPECIALITY_ELEM";
export const STRECH_SPECIALITY_CREATE   = "STRECH_SPECIALITY_CREATE";

export const STRECH_QUESTION            = "STRECH_QUESTION";
export const STRECH_QUESTION_LOADING    = "STRECH_QUESTION_LOADING";
export const STRECH_QUESTION_LIST       = "STRECH_QUESTION_LIST";
export const STRECH_QUESTION_ELEM       = "STRECH_QUESTION_ELEM";
export const STRECH_QUESTION_CREATE     = "STRECH_QUESTION_CREATE";

export const STRECH_ANSWER              = "STRECH_ANSWER";
export const STRECH_ANSWER_LOADING      = "STRECH_ANSWER_LOADING";
export const STRECH_ANSWER_STUDENTS     = "STRECH_ANSWER_STUDENTS";
export const STRECH_ANSWER_ANSWERS      = "STRECH_ANSWER_ANSWERS";
export const STRECH_ANSWER_QUESTIONS    = "STRECH_ANSWER_QUESTIONS";

export const INSCRIPTION                = "INSCRIPTION";
export const INSCRIPTION_LOADING        = "INSCRIPTION_LOADING";
export const INSCRIPTION_LIST           = "INSCRIPTION_LIST";
export const INSCRIPTION_ELEM           = "INSCRIPTION_ELEM";
export const INSCRIPTION_CREATE         = "INSCRIPTION_CREATE";

export const INSCRIPTION_CHAT_LOADING   = "INSCRIPTION_CHAT_LOADING";
export const INSCRIPTION_CHAT_LIST      = "INSCRIPTION_CHAT_LIST";

export const INSCRIPTION_REASON         = "INSCRIPTION_REASON";
export const INSCRIPTION_REASON_LOADING = "INSCRIPTION_REASON_LOADING";
export const INSCRIPTION_REASON_LIST    = "INSCRIPTION_REASON_LIST";
export const INSCRIPTION_REASON_ELEM    = "INSCRIPTION_REASON_ELEM";
export const INSCRIPTION_REASON_CREATE  = "INSCRIPTION_REASON_CREATE";

export const STRECH_ASSIGNMENT          = "STRECH_ASSIGNMENT";
export const STRECH_ASSIGNMENT_LOADING  = "STRECH_ASSIGNMENT_LOADING";
export const STRECH_ASSIGNMENT_LIST     = "STRECH_ASSIGNMENT_LIST";
export const STRECH_ASSIGNMENT_ELEM     = "STRECH_ASSIGNMENT_ELEM";

export const STRECH_PRESENTISM          = "STRECH_PRESENTISM";
export const STRECH_PRESENTISM_LOADING  = "STRECH_PRESENTISM_LOADING";
export const STRECH_PRESENTISM_LIST     = "STRECH_PRESENTISM_LIST";
export const STRECH_PRESENTISM_ELEM     = "STRECH_PRESENTISM_ELEM";

export const STRECH_GROUP               = "STRECH_GROUP";
export const STRECH_GROUP_LOADING       = "STRECH_GROUP_LOADING";
export const STRECH_GROUP_CHARGING      = "STRECH_GROUP_CHARGING";
export const STRECH_GROUP_LIST          = "STRECH_GROUP_LIST";
export const STRECH_GROUP_ELEM          = "STRECH_GROUP_ELEM";
export const STRECH_GROUP_CREATE        = "STRECH_GROUP_CREATE";
export const STRECH_GROUP_CHANGE        = "STRECH_GROUP_CHANGE";
export const STRECH_GROUP_STUDENTS      = "STRECH_GROUP_STUDENTS";

export const GROUP_MEMBER               = "GROUP_MEMBER";
export const GROUP_MEMBER_LOADING       = "GROUP_MEMBER_LOADING";
export const GROUP_MEMBER_LIST          = "GROUP_MEMBER_LIST";

export const MEETING                    = "MEETING";
export const MEETING_LOADING            = "MEETING_LOADING";
export const MEETING_LIST               = "MEETING_LIST";
export const MEETING_ELEM               = "MEETING_ELEM";
export const MEETING_EDIT               = "MEETING_EDIT";
export const MEETING_CREATE             = "MEETING_CREATE";



// Conversations Types
export const CONVERSATION               = "CONVERSATION";
export const CONVERSATION_LOADING       = "CONVERSATION_LOADING";
export const CONVERSATION_LIST          = "CONVERSATION_LIST";
export const CONVERSATION_ELEM          = "CONVERSATION_ELEM";
export const CONVERSATION_EDIT          = "CONVERSATION_EDIT";
export const CONVERSATION_CREATE        = "CONVERSATION_CREATE";
export const CONVERSATION_STUDENTS      = "CONVERSATION_STUDENTS";
export const CONVERSATION_REQUEST       = "CONVERSATION_REQUEST";
export const CONVERSATION_ASSIGN        = "CONVERSATION_ASSIGN";



// Campaign Types
export const CAMPAIGN                   = "CAMPAIGN";
export const CAMPAIGN_LOADING           = "CAMPAIGN_LOADING";
export const CAMPAIGN_CHARGING          = "CAMPAIGN_CHARGING";
export const CAMPAIGN_LIST              = "CAMPAIGN_LIST";
export const CAMPAIGN_ELEM              = "CAMPAIGN_ELEM";
export const CAMPAIGN_SEND              = "CAMPAIGN_SEND";
export const CAMPAIGN_CONTENT           = "CAMPAIGN_CONTENT";

export const CAMPAIGN_USER              = "CAMPAIGN_USER";
export const CAMPAIGN_USER_LOADING      = "CAMPAIGN_USER_LOADING";
export const CAMPAIGN_USER_LIST         = "CAMPAIGN_USER_LIST";



// Materials Types
export const MATERIAL                   = "MATERIAL";
export const MATERIAL_LOADING           = "MATERIAL_LOADING";
export const MATERIAL_CHARGING          = "MATERIAL_CHARGING";
export const MATERIAL_LIST              = "MATERIAL_LIST";
export const MATERIAL_ELEM              = "MATERIAL_ELEM";
export const MATERIAL_CREATE            = "MATERIAL_CREATE";

export const MAT_CATEGORY               = "MAT_CATEGORY";
export const MAT_CATEGORY_LOADING       = "MAT_CATEGORY_LOADING";
export const MAT_CATEGORY_LIST          = "MAT_CATEGORY_LIST";
export const MAT_CATEGORY_ELEM          = "MAT_CATEGORY_ELEM";
export const MAT_CATEGORY_CREATE        = "MAT_CATEGORY_CREATE";



// Guides Types
export const GUIDE                      = "GUIDE";
export const GUIDE_LOADING              = "GUIDE_LOADING";
export const GUIDE_CHARGING             = "GUIDE_CHARGING";
export const GUIDE_LIST                 = "GUIDE_LIST";
export const GUIDE_ELEM                 = "GUIDE_ELEM";
export const GUIDE_CREATE               = "GUIDE_CREATE";

export const GUIDE_SECTION              = "GUIDE_SECTION";
export const GUIDE_SECTION_LOADING      = "GUIDE_SECTION_LOADING";
export const GUIDE_SECTION_LIST         = "GUIDE_SECTION_LIST";
export const GUIDE_SECTION_ELEM         = "GUIDE_SECTION_ELEM";
export const GUIDE_SECTION_CREATE       = "GUIDE_SECTION_CREATE";

export const GUIDE_QUESTION             = "GUIDE_QUESTION";
export const GUIDE_QUESTION_LOADING     = "GUIDE_QUESTION_LOADING";
export const GUIDE_QUESTION_LIST        = "GUIDE_QUESTION_LIST";
export const GUIDE_QUESTION_ELEM        = "GUIDE_QUESTION_ELEM";
export const GUIDE_QUESTION_CREATE      = "GUIDE_QUESTION_CREATE";



// Locations Types
export const LOCATION                   = "LOCATION";
export const LOCATION_LOADING           = "LOCATION_LOADING";
export const LOCATION_CHARGING          = "LOCATION_CHARGING";
export const LOCATION_LIST              = "LOCATION_LIST";
export const LOCATION_ELEM              = "LOCATION_ELEM";
export const LOCATION_CREATE            = "LOCATION_CREATE";

export const LOC_ROOM                   = "LOC_ROOM";
export const LOC_ROOM_LOADING           = "LOC_ROOM_LOADING";
export const LOC_ROOM_LIST              = "LOC_ROOM_LIST";
export const LOC_ROOM_ELEM              = "LOC_ROOM_ELEM";

export const LOC_SCHEDULE               = "LOC_SCHEDULE";
export const LOC_SCHEDULE_LOADING       = "LOC_SCHEDULE_LOADING";
export const LOC_SCHEDULE_LIST          = "LOC_SCHEDULE_LIST";
export const LOC_SCHEDULE_ELEM          = "LOC_SCHEDULE_ELEM";



// Ticket Types
export const TICKET                     = "TICKET";
export const TICKET_LOADING             = "TICKET_LOADING";
export const TICKET_LIST                = "TICKET_LIST";
export const TICKET_ELEM                = "TICKET_ELEM";
export const TICKET_CREATE              = "TICKET_CREATE";
export const TICKET_ASSIGN              = "TICKET_ASSIGN";

export const TICKET_TYPE                = "TICKET_TYPE";
export const TICKET_TYPE_LOADING        = "TICKET_TYPE_LOADING";
export const TICKET_TYPE_LIST           = "TICKET_TYPE_LIST";
export const TICKET_TYPE_ELEM           = "TICKET_TYPE_ELEM";
export const TICKET_TYPE_CREATE         = "TICKET_TYPE_CREATE";

export const HELP_SECTION               = "HELP_SECTION";
export const HELP_SECTION_LOADING       = "HELP_SECTION_LOADING";
export const HELP_SECTION_LIST          = "HELP_SECTION_LIST";
export const HELP_SECTION_ELEM          = "HELP_SECTION_ELEM";
export const HELP_SECTION_CREATE        = "HELP_SECTION_CREATE";

export const HELP_QUESTION              = "HELP_QUESTION";
export const HELP_QUESTION_LOADING      = "HELP_QUESTION_LOADING";
export const HELP_QUESTION_LIST         = "HELP_QUESTION_LIST";
export const HELP_QUESTION_ELEM         = "HELP_QUESTION_ELEM";
export const HELP_QUESTION_CREATE       = "HELP_QUESTION_CREATE";
export const HELP_QUESTION_DATA         = "HELP_QUESTION_DATA";



// User Types
export const STUDENT                    = "STUDENT";
export const STUDENT_LOADING            = "STUDENT_LOADING";
export const STUDENT_CHARGING           = "STUDENT_CHARGING";
export const STUDENT_LIST               = "STUDENT_LIST";
export const STUDENT_FILTER             = "STUDENT_FILTER";
export const STUDENT_ELEM               = "STUDENT_ELEM";
export const STUDENT_COACH              = "STUDENT_COACH";
export const STUDENT_CREATE             = "STUDENT_CREATE";

export const COACH                      = "COACH";
export const COACH_LOADING              = "COACH_LOADING";
export const COACH_CHARGING             = "COACH_CHARGING";
export const COACH_LIST                 = "COACH_LIST";
export const COACH_FILTER               = "COACH_FILTER";
export const COACH_ELEM                 = "COACH_ELEM";
export const COACH_CREATE               = "COACH_CREATE";

export const ADMIN                      = "ADMIN";
export const ADMIN_LOADING              = "ADMIN_LOADING";
export const ADMIN_LIST                 = "ADMIN_LIST";
export const ADMIN_ELEM                 = "ADMIN_ELEM";
export const ADMIN_CREATE               = "ADMIN_CREATE";



// Coach Types
export const COACH_PRICES_ELEM          = "COACH_PRICES_ELEM";

export const COACH_SPECIALITY           = "COACH_SPECIALITY";
export const COACH_SPECIALITY_LOADING   = "COACH_SPECIALITY_LOADING";
export const COACH_SPECIALITY_LIST      = "COACH_SPECIALITY_LIST";
export const COACH_SPECIALITY_ELEM      = "COACH_SPECIALITY_ELEM";
export const COACH_SPECIALITY_CREATE    = "COACH_SPECIALITY_CREATE";

export const COACH_CATEGORY             = "COACH_CATEGORY";
export const COACH_CATEGORY_LOADING     = "COACH_CATEGORY_LOADING";
export const COACH_CATEGORY_LIST        = "COACH_CATEGORY_LIST";
export const COACH_CATEGORY_ELEM        = "COACH_CATEGORY_ELEM";

export const COACH_QUESTION             = "COACH_QUESTION";
export const COACH_QUESTION_LOADING     = "COACH_QUESTION_LOADING";
export const COACH_QUESTION_LIST        = "COACH_QUESTION_LIST";
export const COACH_QUESTION_ELEM        = "COACH_QUESTION_ELEM";
export const COACH_QUESTION_CREATE      = "COACH_QUESTION_CREATE";

export const COACH_INVOICE              = "COACH_INVOICE";
export const COACH_INVOICE_LOADING      = "COACH_INVOICE_LOADING";
export const COACH_INVOICE_LIST         = "COACH_INVOICE_LIST";
export const COACH_INVOICE_ELEM         = "COACH_INVOICE_ELEM";
export const COACH_INVOICE_CREATE       = "COACH_INVOICE_CREATE";

export const COACH_MATERIAL             = "COACH_MATERIAL";
export const COACH_MATERIAL_LOADING     = "COACH_MATERIAL_LOADING";
export const COACH_MATERIAL_CHARGING    = "COACH_MATERIAL_CHARGING";
export const COACH_MATERIAL_LIST        = "COACH_MATERIAL_LIST";
export const COACH_MATERIAL_ELEM        = "COACH_MATERIAL_ELEM";
export const COACH_MATERIAL_CREATE      = "COACH_MATERIAL_CREATE";


export const COACH_PROFESION             = "COACH_PROFESION";
export const COACH_PROFESION_LOADING     = "COACH_PROFESION_LOADING";
export const COACH_PROFESION_LIST        = "COACH_PROFESION_LIST";
export const COACH_PROFESION_ELEM        = "COACH_PROFESION_ELEM";


// Report Types
export const INSCRIPTION_REPORT         = "INSCRIPTION_REPORT";
export const INSCRIPTION_REPORT_LOADING = "INSCRIPTION_REPORT_LOADING";
export const INSCRIPTION_REPORT_DATA    = "INSCRIPTION_REPORT_DATA";
export const INSCRIPTION_REPORT_GRAPH   = "INSCRIPTION_REPORT_GRAPH";

export const CONVERSATION_REPORT        = "CONVERSATION_REPORT";
export const CONVERSATION_REPORT_LOADING= "CONVERSATION_REPORT_LOADING";
export const CONVERSATION_REPORT_DATA   = "CONVERSATION_REPORT_DATA";
export const CONVERSATION_REPORT_GRAPH  = "CONVERSATION_REPORT_GRAPH";

export const MEETING_REPORT             = "MEETING_REPORT";
export const MEETING_REPORT_LOADING     = "MEETING_REPORT_LOADING";
export const MEETING_REPORT_DATA        = "MEETING_REPORT_DATA";
export const MEETING_REPORT_GRAPH       = "MEETING_REPORT_GRAPH";

export const GUIDE_REPORT               = "GUIDE_REPORT";
export const GUIDE_REPORT_LOADING       = "GUIDE_REPORT_LOADING";
export const GUIDE_REPORT_DATA          = "GUIDE_REPORT_DATA";
export const GUIDE_REPORT_GRAPH         = "GUIDE_REPORT_GRAPH";

export const POLL_REPORT                = "POLL_REPORT";
export const POLL_REPORT_LOADING        = "POLL_REPORT_LOADING";
export const POLL_REPORT_DATA           = "POLL_REPORT_DATA";
export const POLL_REPORT_GRAPH          = "POLL_REPORT_GRAPH";

export const INVOICE_REPORT             = "INVOICE_REPORT";
export const INVOICE_REPORT_LOADING     = "INVOICE_REPORT_LOADING";
export const INVOICE_REPORT_DATA        = "INVOICE_REPORT_DATA";
export const INVOICE_REPORT_GRAPH       = "INVOICE_REPORT_GRAPH";



// Lead Types
export const LEAD                       = "LEAD";
export const LEAD_LOADING               = "LEAD_LOADING";
export const LEAD_CHARGING              = "LEAD_CHARGING";
export const LEAD_LIST                  = "LEAD_LIST";
export const LEAD_FILTER                = "LEAD_FILTER";
export const LEAD_ELEM                  = "LEAD_ELEM";
export const LEAD_CREATE                = "LEAD_CREATE";
export const LEAD_EDIT                  = "LEAD_EDIT";
export const LEAD_CLOSE                 = "LEAD_CLOSE";

export const ORIGIN                     = "ORIGIN";
export const ORIGIN_LOADING             = "ORIGIN_LOADING";
export const ORIGIN_LIST                = "ORIGIN_LIST";
export const ORIGIN_ELEM                = "ORIGIN_ELEM";
export const ORIGIN_CREATE              = "ORIGIN_CREATE";

export const ASSIGNMENT                 = "ASSIGNMENT";
export const ASSIGNMENT_LOADING         = "ASSIGNMENT_LOADING";
export const ASSIGNMENT_LIST            = "ASSIGNMENT_LIST";
export const ASSIGNMENT_ELEM            = "ASSIGNMENT_ELEM";
export const ASSIGNMENT_CREATE          = "ASSIGNMENT_CREATE";

export const CLOSE_REASON               = "CLOSE_REASON";
export const CLOSE_REASON_LOADING       = "CLOSE_REASON_LOADING";
export const CLOSE_REASON_LIST          = "CLOSE_REASON_LIST";
export const CLOSE_REASON_ELEM          = "CLOSE_REASON_ELEM";
export const CLOSE_REASON_CREATE        = "CLOSE_REASON_CREATE";


// Setup Types
export const GROUPINTEREST                   = "GROUPINTEREST";
export const GROUPINTEREST_LOADING           = "GROUPINTEREST_LOADING";
export const GROUPINTEREST_LIST              = "GROUPINTEREST_LIST";
export const GROUPINTEREST_ELEM              = "GROUPINTEREST_ELEM";
export const GROUPINTEREST_CREATE            = "GROUPINTEREST_CREATE";

export const INTEREST                   = "INTEREST";
export const INTEREST_LOADING           = "INTEREST_LOADING";
export const INTEREST_LIST              = "INTEREST_LIST";
export const INTEREST_ELEM              = "INTEREST_ELEM";
export const INTEREST_CREATE            = "INTEREST_CREATE";

export const STAFF                      = "STAFF";
export const STAFF_LOADING              = "STAFF_LOADING";
export const STAFF_LIST                 = "STAFF_LIST";
export const STAFF_ELEM                 = "STAFF_ELEM";
export const STAFF_CREATE               = "STAFF_CREATE";

export const NEWS                       = "NEWS";
export const NEWS_LOADING               = "NEWS_LOADING";
export const NEWS_LIST                  = "NEWS_LIST";
export const NEWS_ELEM                  = "NEWS_ELEM";

export const ADVICE                     = "ADVICE";
export const ADVICE_LOADING             = "ADVICE_LOADING";
export const ADVICE_LIST                = "ADVICE_LIST";
export const ADVICE_ELEM                = "ADVICE_ELEM";

export const PAYMENT_METHOD             = "PAYMENT_METHOD";
export const PAYMENT_METHOD_LOADING     = "PAYMENT_METHOD_LOADING";
export const PAYMENT_METHOD_LIST        = "PAYMENT_METHOD_LIST";
export const PAYMENT_METHOD_ELEM        = "PAYMENT_METHOD_ELEM";
export const PAYMENT_METHOD_CREATE      = "PAYMENT_METHOD_CREATE";

export const COUNTRY                    = "COUNTRY";
export const COUNTRY_LOADING            = "COUNTRY_LOADING";
export const COUNTRY_LIST               = "COUNTRY_LIST";
export const COUNTRY_ELEM               = "COUNTRY_ELEM";

export const PROVINCE                   = "PROVINCE";
export const PROVINCE_LOADING           = "PROVINCE_LOADING";
export const PROVINCE_LIST              = "PROVINCE_LIST";
export const PROVINCE_ELEM              = "PROVINCE_ELEM";
export const PROVINCE_CREATE            = "PROVINCE_CREATE";

export const LOCALITY                   = "LOCALITY";
export const LOCALITY_LOADING           = "LOCALITY_LOADING";
export const LOCALITY_LIST              = "LOCALITY_LIST";
export const LOCALITY_ELEM              = "LOCALITY_ELEM";
export const LOCALITY_CREATE            = "LOCALITY_CREATE";

export const EMAIL_TEMPLATE             = "EMAIL_TEMPLATE";
export const EMAIL_TEMPLATE_LOADING     = "EMAIL_TEMPLATE_LOADING";
export const EMAIL_TEMPLATE_LIST        = "EMAIL_TEMPLATE_LIST";
export const EMAIL_TEMPLATE_ELEM        = "EMAIL_TEMPLATE_ELEM";

export const EMAIL_QUEUE                = "EMAIL_QUEUE";
export const EMAIL_QUEUE_LOADING        = "EMAIL_QUEUE_LOADING";
export const EMAIL_QUEUE_LIST           = "EMAIL_QUEUE_LIST";
export const EMAIL_QUEUE_ELEM           = "EMAIL_QUEUE_ELEM";

export const EMAIL_WHITE                = "EMAIL_WHITE";
export const EMAIL_WHITE_LOADING        = "EMAIL_WHITE_LOADING";
export const EMAIL_WHITE_LIST           = "EMAIL_WHITE_LIST";
export const EMAIL_WHITE_ELEM           = "EMAIL_WHITE_ELEM";

export const ERROR_LOG                  = "ERROR_LOG";
export const ERROR_LOG_LOADING          = "ERROR_LOG_LOADING";
export const ERROR_LOG_LIST             = "ERROR_LOG_LIST";
export const ERROR_LOG_ELEM             = "ERROR_LOG_ELEM";

export const ACTION_LOG                 = "ACTION_LOG";
export const ACTION_LOG_LOADING         = "ACTION_LOG_LOADING";
export const ACTION_LOG_LIST            = "ACTION_LOG_LIST";



// Profile Types
export const PROFILE                    = "PROFILE";
export const PROFILE_LOADING            = "PROFILE_LOADING";
export const PROFILE_ELEM               = "PROFILE_ELEM";
export const PROFILE_UPLOAD             = "PROFILE_UPLOAD";
