import { InscriptionChat }  from "Utils/API";

// Types
import {
    INSCRIPTION_CHAT_LIST,
} from "Utils/Types";



/**
 * Fetches the Chat Presentism data
 * @param {Number} strechChatID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchChatPresentism(strechChatID) {
    return async (dispatch) => {
        const data = await InscriptionChat.getAllForChat({ strechChatID });
        dispatch({ type : INSCRIPTION_CHAT_LIST, data });
    };
}



/**
 * Creates an Inscription
 * @param {Object} data
 * @returns {() => Void}
 */
export function createInscription(data) {
    return () => InscriptionChat.create(data);
}

/**
 * Toggles the Presentism
 * @param {Number} strechChatID
 * @param {Number} credentialID
 * @param {Number} attended
 * @returns {() => Void}
 */
export function togglePresentism(strechChatID, credentialID, attended) {
    return () => InscriptionChat.togglePresentism({ strechChatID, credentialID, attended });
}
