import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Href                 from "dashboard/dist/Core/Href";

// Components
import EmailStep            from "./Steps/EmailStep";
import PasswordStep         from "./Steps/PasswordStep";
import ResetPassStep        from "./Steps/ResetPassStep";
import DataStep             from "./Steps/DataStep";
import CourseStep           from "./Steps/CourseStep";

// Actions
import {
    setData, setCourse,
} from "Actions/Core/AuthActions";



/**
 * The Auth Page
 */
class AuthPage extends React.Component {
    // The Current State
    state = {
        step     : "email",
        email    : "",
        password : "",
        code     : "",
        loading  : false,
    }

    /**
     * Goes the Reset Step if required
     * @returns {Void}
     */
    componentDidMount() {
        if (this.props.match.params.code) {
            this.setState({ step : "reset", code : this.props.match.params.code });
        }
    }

    /**
     * Handles the Submit
     * @param {Object} data
     * @returns {Void}
     */
    handleSubmit = (data) => {
        const { redirect, setData, setCourse } = this.props;
        const { step, email, password        } = data;

        if (step === "enter") {
            Href.goto(redirect);
        } else {
            if (step === "data") {
                setData(data);
            } else if (step === "course") {
                console.log(data);
                setCourse(data);
            }
            this.setState({ step, email, password });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { match                       } = this.props;
        const { step, email, password, code } = this.state;

        switch (step) {
        case "email":
            return <EmailStep
                title="AUTH_TITLE"
                help="AUTH_EMAIL_HELP"
                onSubmit={this.handleSubmit}
            />;
        case "password":
            return <PasswordStep
                title="AUTH_TITLE"
                email={email}
                onSubmit={this.handleSubmit}
            />;
        case "reset":
            return <ResetPassStep
                title="AUTH_TITLE"
                email={email}
                code={code}
                match={match}
                onSubmit={this.handleSubmit}
            />;
        case "data":
            return <DataStep
                title="AUTH_TITLE"
                email={email}
                password={password}
                onSubmit={this.handleSubmit}
            />;
        case "course":
            return <CourseStep
                title="AUTH_TITLE"
                onSubmit={this.handleSubmit}
            />;
        default:
        }
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setData   : PropTypes.func.isRequired,
        setCourse : PropTypes.func.isRequired,
        redirect  : PropTypes.string.isRequired,
        match     : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            redirect : state.core.redirect,
        };
    }
}

export default connect(AuthPage.mapStateToProps, {
    setData, setCourse,
})(AuthPage);
