import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Meeting }          from "Utils/API";

// Types
import {
    MEETING, MEETING_LOADING,
    MEETING_LIST, MEETING_ELEM,
    MEETING_CREATE, MEETING_EDIT,
} from "Utils/Types";



/**
 * Fetches the Meeting List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMeetings(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : MEETING_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "GROUP":
            params.groupID = elemID;
            data = await Meeting.getAllForGroup(params);
            break;
        case "COACH":
            params.coachID = elemID;
            data = await Meeting.getAllForCoach(params);
            break;
        case "LOCATION":
            params.locationID = elemID;
            data = await Meeting.getAllForLocation(params);
            break;
        case "USER":
            data = await Meeting.getAllAsCoach(params);
            break;
        default:
            data = await Meeting.getAll(params);
        }
        data.sort = params;
        dispatch({ type : MEETING_LIST, data });
        dispatch(setSection(MEETING));
    };
}

/**
 * Fetches the Meeting List as Student
 * @param {Number}  elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMeetsAsStudent(elemID, params = {}) {
    return async (dispatch) => {
        dispatch({ type : MEETING_LOADING });
        Params.unset(params);
        params.strechID = elemID;

        let data = await Meeting.getAllAsStudent(params);
        data.sort = params;
        dispatch({ type : MEETING_LIST, data });
        dispatch(setSection(MEETING));
    };
}

/**
 * Fetches a single Meeting
 * @param {Number} meetingID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMeeting(meetingID) {
    return async (dispatch) => {
        const data = await Meeting.getOne({ meetingID });
        dispatch({ type : MEETING_ELEM, data });
    };
}



/**
 * Fetches the Meeting Create data
 * @param {Number} groupID
 * @param {Number} coachID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMeetingCreate(groupID, coachID) {
    return async (dispatch) => {
        const data = await Meeting.getCreateData({ groupID, coachID });
        dispatch({ type : MEETING_CREATE, data });
    };
}

/**
 * Fetches the Meeting Edit data
 * @param {Number} meetingID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMeetingEdit(meetingID) {
    return async (dispatch) => {
        const data = await Meeting.getEditData({ meetingID });
        dispatch({ type : MEETING_EDIT, data });
    };
}



/**
 * Creates a Meeting
 * @param {Object} data
 * @returns {() => Void}
 */
export function createMeeting(data) {
    return () => Meeting.create(data);
}

/**
 * Edits a Meeting
 * @param {Object} data
 * @returns {() => Void}
 */
export function editMeeting(data) {
    return () => Meeting.edit(data);
}

/**
 * Reports a Meeting
 * @param {Object} data
 * @returns {() => Void}
 */
export function reportMeeting(data) {
    return () => Meeting.report(data);
}

/**
 * Cancels a Meeting
 * @param {Object} data
 * @returns {() => Void}
 */
export function cancelMeeting(data) {
    return () => Meeting.cancel(data);
}

/**
 * Deletes a Meeting
 * @param {Number} meetingID
 * @returns {() => Void}
 */
export function deleteMeeting(meetingID) {
    return () => Meeting.delete({ meetingID });
}
