import { setSection }       from "Actions/Core/CoreActions";
import { StrechQuestion }   from "Utils/API";

// Types
import {
    STRECH_QUESTION, STRECH_QUESTION_LOADING,
    STRECH_QUESTION_LIST, STRECH_QUESTION_ELEM, STRECH_QUESTION_CREATE,
} from "Utils/Types";



/**
 * Fetches the Question List
 * @param {String} strechID
 * @param {Object} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchQuestions(strechID, params = {}) {
    return async (dispatch) => {
        dispatch({ type : STRECH_QUESTION_LOADING });
        const data = await StrechQuestion.getAllForStrech({ strechID, ...params });
        data.sort = params;
        dispatch({ type : STRECH_QUESTION_LIST, data });
        dispatch(setSection(STRECH_QUESTION));
    };
}

/**
 * Fetches a single Question
 * @param {Number} strechQuestionID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchQuestion(strechQuestionID) {
    return async (dispatch) => {
        const data = await StrechQuestion.getOne({ strechQuestionID });
        dispatch({ type : STRECH_QUESTION_ELEM, data });
    };
}

/**
 * Fetches the Question Create data
 * @param {Number} strechID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchQuestionCreate(strechID) {
    return async (dispatch) => {
        const data = await StrechQuestion.getCreateData({ strechID });
        dispatch({ type : STRECH_QUESTION_CREATE, data });
    };
}



/**
 * Edits a Question
 * @param {Object} data
 * @returns {() => Void}
 */
export function editQuestion(data) {
    return () => StrechQuestion.edit(data);
}

/**
 * Deletes a Question
 * @param {Number} strechQuestionID
 * @returns {() => Void}
 */
export function deleteQuestion(strechQuestionID) {
    return () => StrechQuestion.delete({ strechQuestionID });
}

/**
 * Imports the Questions
 * @param {Number} strechID
 * @returns {() => Void}
 */
export function importQuestions(strechID) {
    return () => StrechQuestion.import({ strechID });
}
