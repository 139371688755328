import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { StrechPrice }      from "Utils/API";

// Types
import {
    STRECH_PRICE, STRECH_PRICE_LOADING,
    STRECH_PRICE_LIST, STRECH_PRICE_ELEM, STRECH_PRICE_CREATE,
} from "Utils/Types";



/**
 * Fetches the Price List
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPrices(type = "", elemID = 0, params = {}) {
    return async (dispatch) => {
        dispatch({ type : STRECH_PRICE_LOADING });
        Params.unset(params);
        let data = {};

        switch (type) {
        case "STRECH":
            params.strechID = elemID;
            data = await StrechPrice.getAllForStrech(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : STRECH_PRICE_LIST, data });
        dispatch(setSection(STRECH_PRICE));
    };
}

/**
 * Fetches a single Price
 * @param {Number} strechPriceID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPrice(strechPriceID) {
    return async (dispatch) => {
        const data = await StrechPrice.getOne({ strechPriceID });
        dispatch({ type : STRECH_PRICE_ELEM, data });
    };
}

/**
 * Fetches the Price Create data
 * @param {Number} strechID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPriceCreate(strechID) {
    return async (dispatch) => {
        const data = await StrechPrice.getCreateData({ strechID });
        dispatch({ type : STRECH_PRICE_CREATE, data });
    };
}



/**
 * Edits/Creates a Price
 * @param {Object} data
 * @returns {() => Void}
 */
export function editPrice(data) {
    return () => StrechPrice.edit(data);
}

/**
 * Deletes a Price
 * @param {Number} strechPriceID
 * @returns {() => Void}
 */
export function deletePrice(strechPriceID) {
    return () => StrechPrice.delete({ strechPriceID });
}

/**
 * Imports Prices
 * @param {Number} strechID
 * @returns {() => Void}
 */
export function importPrices(strechID) {
    return () => StrechPrice.import({ strechID });
}
