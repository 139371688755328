import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";
import TicketUse            from "Utils/Entity/TicketUse";

// Dialogs
import ConversationView     from "./ConversationView";
import ConversationRequest  from "./ConversationRequest";
import TicketEdit           from "Components/Admin/Ticket/Ticket/TicketEdit";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import ActionItem           from "dashboard/dist/Components/ActionItem";
import ActionOption         from "dashboard/dist/Components/ActionOption";
import Content              from "dashboard/dist/Components/Content";
import TabList              from "dashboard/dist/Components/TabList";
import TabItem              from "dashboard/dist/Components/TabItem";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";

// Actions
import {
    fetchConvsAsStudent, unrequestConversation,
} from "Actions/Admin/Conversation/ConversationActions";



/**
 * The Conversation List
 */
class ConversationList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchConvsAsStudent(type, elemID, params || data.sort);
    }

    /**
     * Fetch the tab content
     * @param {Number=} filter
     * @returns {Void}
     */
    fetchTab = (filter) => {
        const orderBy  = Status.is("REQUESTED", filter) ? "createdTime" : "time";
        const orderAsc = Status.is("SCHEDULED", filter) ? 1 : 0;
        this.fetch({ ...this.props.data.sort, filter, page : 0, orderBy, orderAsc });
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isTab) {
            this.fetchTab(elemID);
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @param {Boolean} update
     * @param {Number=} filter
     * @returns {Void}
     */
    endAction = (update, filter) => {
        this.startAction(Action.get(), 0);
        if (filter) {
            this.fetchTab(filter);
        } else if (update) {
            this.fetch();
        }
    }



    /**
     * Handles the Request Submit
     * @param {Number=} filter
     * @returns {Void}
     */
    requestElem = (filter) => {
        this.endAction(true, filter);
    }

    /**
     * Handles the Unrequest Submit
     * @returns {Promise}
     */
    unrequestElem = async () => {
        const elemID = this.state.elemID;
        await this.props.unrequestConversation(elemID);
        this.endAction(true);
    }

    /**
     * Returns true if it can Unrequest the Conversation
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canUnrequest(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "conversationID", elemID, "isRequested");
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                         } = this.state;
        const { data, type, params, route, withDetails } = this.props;
        const { canEdit, list, total, sort, loading    } = data;

        const isRequested = Status.is("REQUESTED", sort.filter);
        const forStreches = type === "STRECH";
        const showMode    = !isRequested;

        return <Main withDetails={withDetails}>
            <Header message="CONVERSATIONS_MINE" icon="conversation" route={route}>
                <ActionList onAction={this.startAction}>
                    <ActionItem action="REQUEST">
                        <ActionOption action="REQUEST" message="CONVERSATIONS_REQUEST_TITLE"  />
                        <ActionOption action="COACH"   message="CONVERSATIONS_REQUEST_CHANGE" />
                    </ActionItem>
                </ActionList>
            </Header>
            <Content>
                <TabList selected={sort.filter} onAction={this.startAction}>
                    <TabItem message="CONVERSATIONS_PROGRAM"   status="SCHEDULED" />
                    <TabItem message="CONVERSATIONS_REQUEST"   status="REQUESTED" />
                    <TabItem message="CONVERSATIONS_COMPLETED" status="COMPLETED" />
                    <TabItem message="CONVERSATIONS_CANCELLED" status="CANCELLED" />
                </TabList>
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="CONVERSATIONS_NONE_AVAILABLE"
                    isLoading={loading}
                >
                    <TableHead>
                        <TableHeader field="time"           message="GENERAL_DATE"       isHidden={isRequested}  />
                        <TableHeader field="createdTime"    message="GENERAL_DATE"       isHidden={!isRequested} />
                        <TableHeader field="coachFirstName" message="COACHES_SINGULAR"   />
                        <TableHeader field="strechTitle"    message="COURSES_SINGULAR"   isHidden={forStreches} />
                        <TableHeader field="specialityName" message="SPECIALITIES_SHORT" />
                        <TableHeader field="mode"           message="GENERAL_MODE"       isHidden={!showMode} maxWidth="80" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.conversationID} elemID={elem.conversationID}>
                            <TableCell message={elem.theTime}        />
                            <TableCell message={elem.createdString}  />
                            <TableCell message={elem.coachString}    />
                            <TableCell message={elem.strechTitle}    />
                            <TableCell message={elem.specialityName} />
                            <TableCell message={elem.modeName}       />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"      message="CONVERSATIONS_VIEW_TITLE"      />
                        <TableAction action="UNREQUEST" message="CONVERSATIONS_UNREQUEST_TITLE" hide={(elemID) => !this.canUnrequest(elemID)} />
                    </TableActionList>
                </Table>
            </Content>

            <ConversationView
                open={action.isView}
                elemID={elemID}
                strechID={params.strechID}
                onClose={this.endAction}
            />
            <ConversationRequest
                open={action.isRequest}
                elemID={elemID}
                studentID={params.studentID}
                strechID={params.strechID}
                onSubmit={this.requestElem}
                onClose={this.endAction}
            />
            <TicketEdit
                open={action.isCoach}
                title="CONVERSATIONS_REQUEST_CHANGE"
                use={TicketUse.CHANGE_COACH}
                studentID={params.studentID}
                onSubmit={this.requestElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isUnrequest}
                icon="cancel"
                title="CONVERSATIONS_UNREQUEST_TITLE"
                message="CONVERSATIONS_UNREQUEST_TEXT"
                onSubmit={this.unrequestElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchConvsAsStudent   : PropTypes.func.isRequired,
        unrequestConversation : PropTypes.func.isRequired,
        data                  : PropTypes.object.isRequired,
        type                  : PropTypes.string.isRequired,
        params                : PropTypes.object.isRequired,
        route                 : PropTypes.string.isRequired,
        withDetails           : PropTypes.bool.isRequired,
        elemID                : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.conversation,
        };
    }
}

export default connect(ConversationList.mapStateToProps, {
    fetchConvsAsStudent, unrequestConversation,
})(ConversationList);
