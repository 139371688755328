import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchHelp }        from "Actions/Admin/Ticket/QuestionActions";
import NLS                  from "dashboard/dist/Core/NLS";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import HyperLink            from "dashboard/dist/Components/HyperLink";
import AccordionList        from "dashboard/dist/Components/AccordionList";
import AccordionItem        from "dashboard/dist/Components/AccordionItem";
import NoneAvailable        from "dashboard/dist/Components/NoneAvailable";
import Video                from "dashboard/dist/Components/Video";
import Html                 from "dashboard/dist/Components/Html";
import Button               from "dashboard/dist/Components/Button";

// Styles
import "Styles/Components/App/Help.css";



/**
 * The Help Content
 */
class HelpContent extends React.Component {
    // The Current State
    state = {
        sectionID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const { forSupport, fetchHelp } = this.props;
        fetchHelp(forSupport);
    }

    /**
     * Handles the Section Switching
     * @param {Number} sectionID
     * @returns {Void}
     */
    handleClick = (sectionID) => {
        this.setState({ sectionID });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { help, withButton } = this.props;
        const { sectionID        } = this.state;

        const hasData   = Boolean(help.length);
        const section   = sectionID || (hasData ? help[0].sectionID : 0);
        const questions = Utils.getValue(help, "sectionID", section, "questions", []);

        if (!hasData) {
            return <NoneAvailable message="HELP_NONE_QUESTIONS" />;
        }
        return <section className="help-container">
            <ul className="help-sections">
                {help.map((elem) => <li key={elem.sectionID}>
                    <HyperLink
                        className={section === elem.sectionID ? "help-selected" : ""}
                        variant="opacity"
                        message={elem.name}
                        onClick={() => this.handleClick(elem.sectionID)}
                    />
                </li>)}
            </ul>
            <div className="help-content">
                <AccordionList>
                    {questions.map((elem, index) => <AccordionItem
                        key={index}
                        value={index + 1}
                        number={index + 1}
                        message={elem.question}
                    >
                        {!!elem.videoLink && <div className="help-video">
                            <Video
                                title={elem.question}
                                source={elem.videoLink}
                            />
                        </div>}
                        <Html content={elem.answer} linkify />
                    </AccordionItem>)}
                </AccordionList>
                {withButton && <div className="help-ticket">
                    <h3>{NLS.get("HELP_NOT_AVAILABLE")}</h3>
                    <Button
                        variant="primary"
                        message="TICKETS_CREATE_TITLE"
                        url="TICKETS_CREATE"
                    />
                </div>}
            </div>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchHelp  : PropTypes.func.isRequired,
        help       : PropTypes.array.isRequired,
        forSupport : PropTypes.bool,
        withButton : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        forSupport : false,
        withButton : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            help : state.helpQuestion.help,
        };
    }
}

export default connect(HelpContent.mapStateToProps, {
    fetchHelp,
})(HelpContent);
