import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStrech }      from "Actions/Admin/Strech/StrechActions";
import NLS                  from "dashboard/dist/Core/NLS";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";

// Components
import StrechEdit           from "./StrechEdit";
import Details              from "dashboard/dist/Components/Details";
import DetailList           from "dashboard/dist/Components/DetailList";
import DetailItem           from "dashboard/dist/Components/DetailItem";
import DetailActionList     from "dashboard/dist/Components/DetailActionList";
import DetailAction         from "dashboard/dist/Components/DetailAction";



/**
 * The Strech Details
 */
class StrechDetails extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
    }

    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }

    /**
     * Handles the Edit Submit
     * @param {Number} newStrechID
     * @returns {Void}
     */
    editElem = (newStrechID) => {
        const { strechID, fetchStrech } = this.props;
        if (strechID === newStrechID) {
            fetchStrech(strechID, true);
        } else {
            Href.goto("STRECHES", newStrechID);
        }
        this.endAction();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { charging, error, canEdit, elem, strechID } = this.props;
        const { action                                   } = this.state;

        const hasConversations = elem.hasConversations && canEdit;
        const hasMeetings      = elem.hasGroups && canEdit;

        return <>
            <Details
                isLoading={charging}
                hasError={error}
                error="STRECHES_ERROR_EXISTS"
            >
                <DetailList message="STRECHES_DETAILS_TITLE">
                    <DetailItem
                        icon="name"
                        message={elem.name}
                        tooltip="STRECHES_ITS_NAME"
                    />
                    <DetailItem
                        icon="name"
                        message={elem.title}
                        tooltip="GENERAL_STUDENT_TITLE"
                    />
                    <DetailItem
                        icon="link"
                        message={elem.link}
                        url={elem.link}
                        tooltip="STRECHES_ITS_URL"
                    />
                    <DetailItem
                        icon="program"
                        message={elem.programName}
                        tooltip="PROGRAMS_SINGULAR"
                        href={NLS.baseUrl("PROGRAMS", elem.programID)}
                    />
                    <DetailItem
                        icon="mode"
                        message={elem.modeName}
                        tooltip="STRECHES_MODE"
                    />
                    <DetailItem
                        isHidden={!elem.isPresential}
                        icon="location"
                        message={elem.locationName}
                        tooltip="LOCATIONS_SINGULAR"
                        href={NLS.baseUrl("LOCATION_SINGLE", elem.locationID)}
                    />
                    <DetailItem
                        isHidden={!elem.isPresential}
                        icon="room"
                        message={elem.roomName}
                        tooltip="ROOMS_SINGULAR"
                    />
                    <DetailItem
                        icon="time"
                        message={elem.fromTimeDateHour}
                        tooltip="STRECHES_FROM_DATE"
                        prefix="GENERAL_START"
                    />
                    <DetailItem
                        icon="time"
                        message={elem.toTimeDateHour}
                        tooltip="STRECHES_TO_DATE"
                        prefix="GENERAL_END"
                    />
                    <DetailItem
                        icon="time"
                        message={elem.inscFromDate}
                        tooltip="STRECHES_INSC_FROM_DATE"
                        prefix="INSCRIPTIONS_SINGULAR"
                        isHidden={!canEdit}
                    />
                    <DetailItem
                        icon="time"
                        message={elem.inscToDate}
                        tooltip="STRECHES_INSC_TO_DATE"
                        prefix="INSCRIPTIONS_SINGULAR"
                        isHidden={!canEdit}
                    />
                    <DetailItem
                        icon="amount"
                        message={elem.quota}
                        tooltip="STRECHES_QUOTA"
                        prefix="GENERAL_QUOTA"
                    />
                    <DetailItem
                        icon="conversation"
                        message={elem.conversations}
                        tooltip="STRECHES_CONVERSATIONS"
                        prefix="CONVERSATIONS_NAME"
                        isHidden={!hasConversations}
                        showAlways
                    />
                    <DetailItem
                        icon="meeting"
                        message={elem.meetingAmount}
                        tooltip="STRECHES_MEETING_AMOUNT"
                        prefix="STRECHES_MEETING_BY_GROUP"
                        isHidden={!hasMeetings}
                        showAlways
                    />
                    <DetailItem
                        icon="status"
                        message={elem.statusName}
                        tooltip="GENERAL_STATUS"
                        className={elem.statusClass}
                        isHidden={!canEdit}
                    />
                    <DetailItem
                        icon="time"
                        message={elem.createdDateTime}
                        tooltip="GENERAL_CREATED"
                        isHidden={!canEdit}
                    />
                </DetailList>
                <DetailActionList onAction={this.startAction} canEdit={canEdit}>
                    <DetailAction action="EDIT" message="STRECHES_EDIT_TITLE" />
                </DetailActionList>
            </Details>

            <StrechEdit
                open={action.isEdit}
                elemID={strechID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrech : PropTypes.func.isRequired,
        charging    : PropTypes.bool.isRequired,
        error       : PropTypes.bool.isRequired,
        canEdit     : PropTypes.bool.isRequired,
        elem        : PropTypes.object.isRequired,
        strechID    : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            charging : state.strech.charging,
            error    : state.strech.error,
            canEdit  : state.strech.canEdit,
            elem     : state.strech.elem,
        };
    }
}

export default connect(StrechDetails.mapStateToProps, {
    fetchStrech,
})(StrechDetails);
