import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { loginAs }          from "Actions/Core/AuthActions";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Dialogs
import CoachFilter          from "./CoachFilter";
import CoachEdit            from "./CoachEdit";
import CourseCompleted      from "Components/Admin/Course/CourseCompleted";
import CoachPrices          from "Components/Admin/Coach/CoachPrices";
import CampaignSend         from "Components/Admin/Campaign/CampaignSend";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";
import Downloader           from "dashboard/dist/Components/Downloader";

// Actions
import {
    fetchCoaches, fetchCoachesEmails, exportCoaches, editCoachLevel, deleteCoach,
} from "Actions/Admin/User/CoachActions";



/**
 * The Coach List
 */
class CoachList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
        users  : [],
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Object=}  filters
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, filters, withLoader) => {
        const { data, fetchCoaches } = this.props;
        fetchCoaches(params || data.sort, filters || data.filters, withLoader);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Promise}
     */
    startAction = async (action, elemID) => {
        const { data, fetchCoachesEmails, loginAs } = this.props;
        if (action.isLogin) {
            loginAs(elemID);
        } else {
            let users = [];
            if (action.isCampaign) {
                users = await fetchCoachesEmails(data.filters);
            }
            this.setState({ action, elemID, users });
        }
    }

    /**
     * Ends an Action
     * @param {Boolean=} update
     * @returns {Void}
     */
    endAction = (update) => {
        this.startAction(Action.get(), 0);
        if (update) {
            this.fetch(null, null, false);
        }
    }



    /**
     * Handles the Filter Submit
     * @param {Object} newFilters
     * @returns {Promise}
     */
    filterElem = async (newFilters) => {
        const { filters, sort } = this.props.data;
        this.endAction();
        this.fetch({ ...sort, page : 0 }, { ...filters, ...newFilters }, false);
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction(true);
    }

    /**
     * Handles the Level Submit
     * @returns {Promise}
     */
    editLevel = async () => {
        const elemID = this.state.elemID;
        await this.props.editCoachLevel(elemID);
        this.endAction(true);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID = this.state.elemID;
        await this.props.deleteCoach(elemID);
        this.endAction(true);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID, users                        } = this.state;
        const { data, route                                  } = this.props;
        const { canEdit, list, total, sort, filters, loading } = data;

        const elemName = Utils.getValue(list, "credentialID", elemID, "credentialName");

        return <Main>
            <Header message="COACHES_NAME" icon="coach" route={route}>
                <ActionList data={data} onAction={this.startAction} withCampaign />
            </Header>
            <Content isLoading={loading}>
                <Table fetch={this.fetch} sort={sort} none="COACHES_NONE_AVAILABLE">
                    <TableHead>
                        <TableHeader field="firstName"          message="GENERAL_NAME"         />
                        <TableHeader field="email"              message="GENERAL_EMAIL"        />
                        <TableHeader field="cellphone"          message="USERS_CELLPHONE"      />
                        <TableHeader field="trafficlight"       message="USERS_TRAFFICLIGHT"   maxWidth="50"  align="center" />
                        <TableHeader field="afipInscription"    message="USERS_AFIP"           maxWidth="60"  align="center" />
                        <TableHeader field="totalConversations" message="CONVERSATIONS_NAME"   maxWidth="100" align="center" />
                        <TableHeader field="subscription"       message="GENERAL_SUBSCRIPTION" maxWidth="110" align="center" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.credentialID} elemID={elem.credentialID}>
                            <TableCell message={elem.credentialName}     circle={elem.statusColor} />
                            <TableCell message={elem.email}              />
                            <TableCell message={elem.phoneString}        />
                            <TableCell circle={elem.trafficlightColor}   />
                            <TableCell message={elem.afipShortName}      />
                            <TableCell message={elem.totalConversations} />
                            <TableCell message={elem.subscriptionName}   className={elem.subscriptionClass} />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"      message="COACHES_VIEW_TITLE"     route={route} />
                        <TableAction action="EDIT"      message="COACHES_EDIT_TITLE"     />
                        <TableAction action="DELETE"    message="COACHES_DELETE_TITLE"   />
                        <TableAction action="COMPLETED" message="COURSES_COMPLETED_NAME" />
                        <TableAction action="PRICES"    message="COACH_PRICES_NAME"      />
                        <TableAction action="ACCESS"    message="COACHES_ACCESS_TITLE"   />
                        <TableAction action="LOGIN"     message="COACHES_LOGIN_AS"       />
                    </TableActionList>
                </Table>
            </Content>

            <CoachFilter
                open={action.isFilter}
                filters={filters}
                onSubmit={this.filterElem}
                onClose={this.endAction}
            />
            <CoachEdit
                open={action.isVCE}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <CourseCompleted
                open={action.isCompleted}
                elemID={elemID}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <CoachPrices
                open={action.isPrices}
                elemID={elemID}
                onSubmit={this.endAction}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isAccess}
                icon="access"
                title="COACHES_ACCESS_TITLE"
                message="COACHES_ACCESS_TEXT"
                content={elemName}
                onSubmit={this.editLevel}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="COACHES_DELETE_TITLE"
                message="COACHES_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
            <CampaignSend
                open={action.isCampaign}
                users={users}
                onClose={this.endAction}
                onSubmit={this.endAction}
            />

            <Downloader
                download={action.isExport}
                source={exportCoaches(filters)}
                onLoad={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCoaches       : PropTypes.func.isRequired,
        fetchCoachesEmails : PropTypes.func.isRequired,
        editCoachLevel     : PropTypes.func.isRequired,
        deleteCoach        : PropTypes.func.isRequired,
        loginAs            : PropTypes.func.isRequired,
        data               : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.coach,
        };
    }
}

export default connect(CoachList.mapStateToProps, {
    fetchCoaches, fetchCoachesEmails, editCoachLevel, deleteCoach,
    loginAs,
})(CoachList);
