import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchStudents }    from "Actions/Admin/User/StudentActions";
import Action               from "dashboard/dist/Core/Action";
import Href                 from "dashboard/dist/Core/Href";
import Utils                from "dashboard/dist/Utils/Utils";

// Dialogs
import StudentView          from "./StudentView";
import ConversationCreate   from "Components/Admin/Conversation/ConversationCreate";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";



/**
 * The Student List
 */
class StudentList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchStudents(type, elemID, params || data.sort);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isStrech) {
            const strechID = Utils.getValue(this.props.data.list, "inscriptionID", elemID, "strechID");
            Href.goto("STRECHES", strechID);
        } else if (action.isEmail || action.isWhatsapp) {
            const elem = Utils.getValue(this.props.data.list, "inscriptionID", elemID);
            const url  = action.isEmail ? Href.getEmail(elem.email) : Href.getWhatsApp(elem.phoneString);
            Href.gotoUrl(url, true);
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch();
    }



    /**
     * Returns true if it can Email the Student
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canEmail(elemID) {
        const { list } = this.props.data;
        return !!Utils.getValue(list, "inscriptionID", elemID, "email");
    }

    /**
     * Returns true if it can Email the Student
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canWhatsApp(elemID) {
        const { list } = this.props.data;
        const elem = Utils.getValue(list, "inscriptionID", elemID);
        return Boolean(elem.iddRoot && elem.cellphone);
    }

    /**
     * Returns true if it can program a Conversation
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canProgram(elemID) {
        const { list } = this.props.data;
        const elem = Utils.getValue(list, "inscriptionID", elemID);
        return elem.usedConversations < elem.totalConversations;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                 } = this.state;
        const { data, type, route, withDetails } = this.props;
        const { list, total, sort, loading     } = data;

        const forStreches = type === "STRECH";
        const elem        = Utils.getValue(list, "inscriptionID", elemID);

        return <Main withDetails={withDetails}>
            <Header message="STUDENTS_NAME" icon="student" route={route} />
            <Content isLoading={loading}>
                <Table fetch={this.fetch} sort={sort} none="STUDENTS_NONE_AVAILABLE">
                    <TableHead>
                        <TableHeader field="firstName"     message="GENERAL_NAME"       />
                        <TableHeader field="strechID"      message="STRECHES_SINGULAR"  grow="2" isHidden={forStreches} />
                        <TableHeader field="specialityID"  message="SPECIALITIES_SHORT" />
                        <TableHeader field="email"         message="GENERAL_EMAIL"      />
                        <TableHeader field="cellphone"     message="USERS_CELLPHONE"    />
                        <TableHeader field="conversations" message="CONVERSATIONS_NAME" align="center" noSorting />
                    </TableHead>
                    <TableBody>
                        {list.map((elem, index) => <TableRow key={index} elemID={elem.inscriptionID}>
                            <TableCell message={elem.credentialName}    />
                            <TableCell message={elem.strechName}        />
                            <TableCell message={elem.specialityName}    />
                            <TableCell message={elem.email}             />
                            <TableCell message={elem.phoneString}       />
                            <TableCell message={elem.conversationsText} />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction}>
                        <TableAction action="VIEW"     message="STUDENTS_VIEW_TITLE"         />
                        <TableAction action="STRECH"   message="STRECHES_VIEW_TITLE"         isHidden={forStreches} />
                        <TableAction action="EMAIL"    message="STUDENTS_EMAIL_TITLE"        hide={(elemID) => !this.canEmail(elemID)} />
                        <TableAction action="WHATSAPP" message="STUDENTS_WHATSAPP_TITLE"     hide={(elemID) => !this.canWhatsApp(elemID)} />
                        <TableAction action="PROGRAM"  message="CONVERSATIONS_PROGRAM_TITLE" hide={(elemID) => !this.canProgram(elemID)} />
                    </TableActionList>
                </Table>
            </Content>

            <StudentView
                open={action.isView}
                type={type}
                elemID={elemID}
                onClose={this.endAction}
            />
            <ConversationCreate
                open={action.isProgram}
                userID={elem.credentialID}
                strechID={elem.strechID}
                specialityID={elem.specialityID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStudents : PropTypes.func.isRequired,
        data          : PropTypes.object.isRequired,
        route         : PropTypes.string.isRequired,
        withDetails   : PropTypes.bool.isRequired,
        type          : PropTypes.string.isRequired,
        elemID        : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.student,
        };
    }
}

export default connect(StudentList.mapStateToProps, {
    fetchStudents,
})(StudentList);
