import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import InfoList             from "dashboard/dist/Components/InfoList";
import InfoItem             from "dashboard/dist/Components/InfoItem";
import AccordionList        from "dashboard/dist/Components/AccordionList";
import AccordionItem        from "dashboard/dist/Components/AccordionItem";
import List                 from "dashboard/dist/Components/List";
import ListItem             from "dashboard/dist/Components/ListItem";
import ListContent          from "dashboard/dist/Components/ListContent";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchMeeting, reportMeeting,
} from "Actions/Admin/Strech/MeetingActions";



/**
 * The Meeting Report Dialog
 */
class MeetingReport extends React.Component {
    // The Initial Data
    initialData = {
        attended   : 0,
        presentism : {},
        answers    : {},
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchMeeting } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchMeeting(elemID);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            data = { ...this.initialData };
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        let answers   = this.state.data.answers;
        answers[name] = value

        this.setState({
            data : { ...this.state.data, answers },
        });
    }

    /**
     * Handles the Toggle
     * @param {Number} credentialID
     * @returns {Void}
     */
    handleToggle = (credentialID) => {
        let { attended, presentism } = this.state.data;
        if (presentism[credentialID]) {
            attended -= 1;
            delete presentism[credentialID];
        } else {
            attended += 1;
            presentism[credentialID] = 1;
        }

        this.setState({
            data : { ...this.state.data, attended, presentism },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { elem, reportMeeting, onSubmit } = this.props;
        const { data, loading                 } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await reportMeeting({
                    meetingID  : elem.meetingID,
                    attended   : data.attended,
                    presentism : JSON.stringify(data.presentism),
                    answers    : JSON.stringify(data.answers),
                });
                this.setState({ loading : false });
                onSubmit(Status.getID("REPORTED"));
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Item Render
     * @returns {Object[]}
     */
    renderItems() {
        const { members, questions            } = this.props;
        const { attended, presentism, answers } = this.state.data;

        const result = [];
        let   index  = 1;

        result.push(<AccordionItem
            key={index}
            number={index}
            message="MEETINGS_PRESENTISM"
            icon={attended > 0 ? "checkedbox" : "checkbox"}
        >
            <List>
                {members.map((elem) => <ListItem key={elem.credentialID}>
                    <ListContent>
                        {elem.credentialName}
                        {!elem.isActive && <span className="text-red left-space">
                            {NLS.get("GENERAL_INACTIVE")}
                        </span>}
                    </ListContent>
                    <InputField
                        type="toggle"
                        name="attended"
                        label={presentism[elem.credentialID] ? "PRESENTISM_ASSISTED" : "PRESENTISM_DIDNT_ASSISTED"}
                        value={!!presentism[elem.credentialID]}
                        onChange={() => this.handleToggle(elem.credentialID)}
                    />
                </ListItem>)}
            </List>
        </AccordionItem>);
        index += 1;

        for (const elem of questions) {
            result.push(<AccordionItem
                key={index}
                number={index}
                message={elem.question}
                icon={answers[elem.id] ? "checkedbox" : "checkbox"}
            >
                <InputField
                    type={elem.type}
                    name={String(elem.id)}
                    label="ANSWERS_MINE"
                    value={answers[elem.id]}
                    onChange={this.handleChange}
                    options={elem.select}
                    withNone
                />
            </AccordionItem>);
            index += 1;
        }
        return result;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose } = this.props;
        const { loading, errors     } = this.state;

        return <EditDialog
            open={open}
            icon="complete"
            title="MEETINGS_REPORT_TITLE"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
            withSpacing={false}
        >
            <InfoList>
                <InfoItem label="GROUPS_SINGULAR"  message={elem.groupName}   />
                <InfoItem label="COACHES_SINGULAR" message={elem.coachString} />
                <InfoItem label="GENERAL_DATE"     message={elem.timeString}  />
            </InfoList>

            <AccordionList className="spacing">
                {this.renderItems()}
            </AccordionList>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchMeeting  : PropTypes.func.isRequired,
        reportMeeting : PropTypes.func.isRequired,
        open          : PropTypes.bool.isRequired,
        onSubmit      : PropTypes.func.isRequired,
        onClose       : PropTypes.func.isRequired,
        edition       : PropTypes.number.isRequired,
        canEdit       : PropTypes.bool.isRequired,
        elem          : PropTypes.object.isRequired,
        members       : PropTypes.array.isRequired,
        questions     : PropTypes.array.isRequired,
        elemID        : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.meeting.edition,
            canEdit   : state.meeting.canEdit,
            elem      : state.meeting.elem,
            members   : state.meeting.members,
            questions : state.meeting.questions,
        };
    }
}

export default connect(MeetingReport.mapStateToProps, {
    fetchMeeting, reportMeeting,
})(MeetingReport);
