import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import TicketTypeEdit       from "./TicketTypeEdit";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchTicketTypes, deleteTicketType,
} from "Actions/Admin/Ticket/TicketTypeActions";



/**
 * The Ticket Type Content
 */
class TicketTypeContent extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { data, forSupport, fetchTicketTypes } = this.props;
        fetchTicketTypes(params || data.sort, forSupport);
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.props.endAction();
        this.fetch();
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const { elemID, forSupport, endAction, deleteTicketType } = this.props;
        endAction();
        await deleteTicketType(elemID, forSupport);
        this.fetch();
    }

    /**
     * Returns true if it can be Deleted
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canDelete(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "ticketTypeID", elemID, "use") === 0;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, action, elemID, startAction, endAction, forSupport } = this.props;
        const { canEdit, list, total, sort, loading                      } = data;

        const prefix   = forSupport ? "SUPPORT_" : "TICKETS_";
        const elemName = Utils.getValue(list, "ticketTypeID", elemID, "name");

        return <>
            <Table
                fetch={this.fetch}
                sort={sort}
                none={`${prefix}TYPES_NONE_AVAILABLE`}
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"                message="GENERAL_NAME"           />
                    <TableHeader field="use"                 message="TICKETS_TYPES_USE"      isHidden={forSupport} />
                    <TableHeader field="credentialFirstName" message="TICKETS_TYPES_ASSIGNED" />
                    <TableHeader field="position"            message="GENERAL_POSITION"       maxWidth="80" align="center" hideMobile />
                    <TableHeader field="createdTime"         message="GENERAL_CREATED"        maxWidth="90" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.ticketTypeID} elemID={elem.ticketTypeID}>
                        <TableCell message={elem.name}           circle={elem.statusColor} />
                        <TableCell message={elem.useName}        />
                        <TableCell message={elem.credentialName} />
                        <TableCell message={elem.position}       />
                        <TableCell message={elem.createdDate}    />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message={`${prefix}TYPES_EDIT_TITLE`}   />
                    <TableAction action="DELETE" message={`${prefix}TYPES_DELETE_TITLE`} hide={(elemID) => !this.canDelete(elemID)} />
                </TableActionList>
            </Table>

            <TicketTypeEdit
                open={action.isVCE}
                forSupport={forSupport}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title={`${prefix}TYPES_DELETE_TITLE`}
                message={`${prefix}TYPES_DELETE_TEXT`}
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchTicketTypes : PropTypes.func.isRequired,
        deleteTicketType : PropTypes.func.isRequired,
        startAction      : PropTypes.func.isRequired,
        endAction        : PropTypes.func.isRequired,
        data             : PropTypes.object.isRequired,
        action           : PropTypes.object,
        elemID           : PropTypes.number,
        forSupport       : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        forSupport : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.ticketType,
        };
    }
}

export default connect(TicketTypeContent.mapStateToProps, {
    fetchTicketTypes, deleteTicketType,
})(TicketTypeContent);
