import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { MatCategory }      from "Utils/API";

// Types
import {
    MAT_CATEGORY, MAT_CATEGORY_LOADING,
    MAT_CATEGORY_LIST, MAT_CATEGORY_ELEM, MAT_CATEGORY_CREATE,
} from "Utils/Types";



/**
 * Fetches the Material Category List
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCategories(params = {}) {
    return async (dispatch) => {
        dispatch({ type : MAT_CATEGORY_LOADING });
        Params.unset(params);
        const data = await MatCategory.getAll(params);
        data.sort = params;
        dispatch({ type : MAT_CATEGORY_LIST, data });
        dispatch(setSection(MAT_CATEGORY));
    };
}

/**
 * Fetches a single Material Category
 * @param {Number} matCategoryID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCategory(matCategoryID) {
    return async (dispatch) => {
        const data = await MatCategory.getOne({ matCategoryID });
        dispatch({ type : MAT_CATEGORY_ELEM, data });
    };
}

/**
 * Fetches the Material Category Create data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCategoryCreate() {
    return async (dispatch) => {
        const data = await MatCategory.getCreateData();
        dispatch({ type : MAT_CATEGORY_CREATE, data });
    };
}



/**
 * Edits a Material Category
 * @param {Object} data
 * @returns {() => Void}
 */
export function editCategory(data) {
    return () => MatCategory.edit(data);
}

/**
 * Deletes a Material Category
 * @param {Number} matCategoryID
 * @returns {() => Void}
 */
export function deleteCategory(matCategoryID) {
    return () => MatCategory.delete({ matCategoryID });
}
