import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import StaffEdit            from "./StaffEdit";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchStaffs, deleteStaff,
} from "Actions/Admin/Setup/StaffActions";



/**
 * The Staff List
 */
class StaffList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch(null, true);
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, withLoader) => {
        this.props.fetchStaffs(params || this.props.data.sort, withLoader);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch(null, false);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID = this.state.elemID;
        this.endAction();
        await this.props.deleteStaff(elemID);
        this.fetch(null, false);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                      } = this.state;
        const { data, route                         } = this.props;
        const { canEdit, list, total, sort, loading } = data;

        const elemName = Utils.getValue(list, "staffID", elemID, "name");

        return <Main>
            <Header message="STAFF_NAME" icon="staff" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content isLoading={loading}>
                <Table fetch={this.fetch} sort={sort} none="STAFF_NONE_AVAILABLE">
                    <TableHead>
                        <TableHeader field="name"        message="GENERAL_NAME"     />
                        <TableHeader field="summary"     message="STAFF_BIOGRAPHY"  grow="2" />
                        <TableHeader field="position"    message="GENERAL_POSITION" maxWidth="80" align="center" hideMobile />
                        <TableHeader field="createdTime" message="GENERAL_CREATED"  maxWidth="90" hideMobile />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.staffID} elemID={elem.staffID}>
                            <TableCell message={elem.name}        circle={elem.statusColor} />
                            <TableCell message={elem.summary}     />
                            <TableCell message={elem.position}    />
                            <TableCell message={elem.createdDate} />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="EDIT"   message="STAFF_EDIT_TITLE"   />
                        <TableAction action="DELETE" message="STAFF_DELETE_TITLE" />
                    </TableActionList>
                </Table>
            </Content>

            <StaffEdit
                open={action.isVCE}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="STAFF_DELETE_TITLE"
                message="STAFF_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStaffs : PropTypes.func.isRequired,
        deleteStaff : PropTypes.func.isRequired,
        data        : PropTypes.object.isRequired,
        route       : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.staff,
        };
    }
}

export default connect(StaffList.mapStateToProps, {
    fetchStaffs, deleteStaff,
})(StaffList);
