import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Locality }         from "Utils/API";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchLocation, fetchLocationCreate, editLocation,
} from "Actions/Admin/Location/LocationActions";



/**
 * The Location Edit Dialog
 */
class LocationEdit extends React.Component {
    // The Initial Data
    initialData = {
        locationID   : 0,
        name         : "",
        countryID    : 0,
        provinceID   : 0,
        localityID   : "",
        localityName : "",
        address      : "",
        contact      : "",
        phone        : "",
        status       : Status.getID("ACTIVE"),
        observations : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, fetchLocation, fetchLocationCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchLocation(elemID);
                loading = true;
            } else {
                fetchLocationCreate();
            }

        // Data Updated
        } else if (prevProps.edition !== edition && elemID) {
            data = Utils.extend(this.initialData, elem);
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editLocation(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, countries, provinces } = this.props;
        const { data, loading, errors                       } = this.state;

        return <EditDialog
            open={open}
            icon="location"
            title={!!elemID ? "LOCATIONS_EDIT_TITLE" : "LOCATIONS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                name="name"
                label="LOCATIONS_ITS_NAME"
                value={data.name}
                error={errors.name}
                onChange={this.handleChange}
                isRequired
            />
            <Columns>
                <InputField
                    type="select"
                    name="countryID"
                    label="LOCALITIES_COUNTRY"
                    value={data.countryID}
                    error={errors.countryID}
                    onChange={this.handleChange}
                    options={countries}
                    withNone
                    isRequired
                />
                <InputField
                    type="select"
                    name="provinceID"
                    label="LOCATIONS_PROVINCE"
                    value={data.provinceID}
                    error={errors.provinceID}
                    onChange={this.handleChange}
                    options={data.countryID ? provinces[data.countryID] || [] : []}
                    withNone
                    isRequired
                />
                <InputField
                    name="localityName"
                    label="LOCATIONS_LOCALITY"
                    suggestID="localityID"
                    suggestFetch={Locality.search}
                    suggestParams={{ provinceID : data.provinceID }}
                    suggestNone="LOCATIONS_NONE_AVAILABLE"
                    value={data.localityName}
                    error={errors.localityID}
                    onChange={this.handleChange}
                    autoComplete="new-password"
                    isRequired
                />
                <InputField
                    name="address"
                    label="LOCATIONS_ADDRESS"
                    value={data.address}
                    error={errors.address}
                    onChange={this.handleChange}
                    isRequired
                />
                <InputField
                    name="contact"
                    label="LOCATIONS_CONTACT"
                    value={data.contact}
                    error={errors.contact}
                    onChange={this.handleChange}
                />
                <InputField
                    name="phone"
                    label="LOCATIONS_PHONE"
                    value={data.phone}
                    error={errors.phone}
                    onChange={this.handleChange}
                />
            </Columns>
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                value={data.status}
                error={errors.status}
                onChange={this.handleChange}
                options={Status.getSelect()}
                withNone
                isRequired
                shrinkLabel
            />
            <InputField
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                value={data.observations}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchLocation       : PropTypes.func.isRequired,
        fetchLocationCreate : PropTypes.func.isRequired,
        editLocation        : PropTypes.func.isRequired,
        open                : PropTypes.bool.isRequired,
        onClose             : PropTypes.func.isRequired,
        onSubmit            : PropTypes.func.isRequired,
        edition             : PropTypes.number.isRequired,
        countries           : PropTypes.array.isRequired,
        provinces           : PropTypes.object.isRequired,
        elem                : PropTypes.object.isRequired,
        elemID              : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.location.edition,
            countries : state.location.countries,
            provinces : state.location.provinces,
            elem      : state.location.elem,
        };
    }
}

export default connect(LocationEdit.mapStateToProps, {
    fetchLocation, fetchLocationCreate, editLocation,
})(LocationEdit);
