import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Lead }             from "Utils/API";
import { fetchLeadsFilter } from "Actions/Admin/Lead/LeadActions";
import Period               from "dashboard/dist/Utils/Period";

// Components
import FilterDialog         from "dashboard/dist/Components/FilterDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";
import MultipleInput           from "dashboard/dist/Components/MultipleInput";



/**
 * The Lead Filter Dialog
 */
class LeadFilter extends React.Component {
    // The Initial Data
    initialData = {
        period         : 0,
        fromDate       : "",
        toDate         : "",
        credentialID   : 0,
        credentialName : "",
        originID       : 0,
        interestID     : [],
        groupinterestID    : 0,
        assignedUser   : 0,
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, filters, edition, fetchLeadsFilter } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = {};
            // Load new data
            fetchLeadsFilter();
            loading = true;

        // Data Updated
        } else if (prevProps.edition !== edition) {
            data = { ...this.initialData, ...filters };
        }

        // Set the State
        if (data) {
            this.setState({ data, loading });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const data = { ...this.state.data, [name] : value };
        if (name === "period") {
            data.fromDate = Period.getFromDate(value);
            data.toDate   = Period.getToDate(value);
        }
        this.setState({ data });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onSubmit, onClose, interests, origins, admins, groupinterest } = this.props;
        const { loading, data                                       } = this.state;

        return <FilterDialog
            open={open}
            title="LEADS_FILTER_TITLE"
            initialData={this.initialData}
            data={data}
            onSubmit={onSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <Columns amount="3">
                <InputField
                    type="select"
                    name="period"
                    label="GENERAL_PERIOD"
                    value={data.period}
                    onChange={this.handleChange}
                    options={Period.getSelect()}
                    noMargin
                    shrinkLabel
                />
                <InputField
                    type="date"
                    name="fromDate"
                    label="GENERAL_FROM"
                    value={data.fromDate}
                    onChange={this.handleChange}
                    noMargin
                    shrinkLabel
                />
                <InputField
                    type="date"
                    name="toDate"
                    label="GENERAL_TO"
                    value={data.toDate}
                    onChange={this.handleChange}
                    noMargin
                    shrinkLabel
                />
            </Columns>
            <InputField
                name="credentialName"
                label="LEADS_CONTACT"
                suggestID="credentialID"
                suggestFetch={Lead.searchContact}
                suggestNone="LEADS_CONTACT_NONE"
                value={data.credentialName}
                onChange={this.handleChange}
            />
            <InputField
                type="select"
                name="originID"
                label="ORIGINS_SINGULAR"
                value={data.originID}
                onChange={this.handleChange}
                options={origins}
                noneText="GENERAL_ALL"
                withNone
                shrinkLabel
            />
            <InputField
                type="select"
                name="groupinterestID"
                label="Grupo al que pertenece"
                value={data.groupinterestID}
                onChange={this.handleChange}
                options={groupinterest}
                withNone
            />
            <MultipleInput
                type="select"
                name="interestID"
                label="INTERESTS_SINGULAR"
                value={data.interestID}
                onChange={this.handleChange}
                options={interests}
                noneText="GENERAL_ALL"
                withNone
                shrinkLabel
            />
            <InputField
                type="select"
                name="assignedUser"
                label="LEADS_ASSIGNED_USER"
                value={data.assignedUser}
                onChange={this.handleChange}
                options={admins}
                noneText="GENERAL_ALL"
                withNone
                shrinkLabel
            />
        </FilterDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchLeadsFilter : PropTypes.func.isRequired,
        open             : PropTypes.bool.isRequired,
        onSubmit         : PropTypes.func.isRequired,
        onClose          : PropTypes.func.isRequired,
        edition          : PropTypes.number.isRequired,
        groupinterest    : PropTypes.array.isRequired,
        interests        : PropTypes.array.isRequired,
        origins          : PropTypes.array.isRequired,
        admins           : PropTypes.array.isRequired,
        filters          : PropTypes.object,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
     static mapStateToProps(state) {
        return {
            edition   : state.lead.edition,
            interests : state.lead.interests,
            origins   : state.lead.origins,
            groupinterest  : state.lead.groupinterest,
            admins    : state.lead.admins,
        };
    }
}

export default connect(LeadFilter.mapStateToProps, {
    fetchLeadsFilter,
})(LeadFilter);
