import React                from "react";
import PropTypes            from "prop-types";

// Components
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Personal Field
 */
class PersonalField extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { data, errors, onChange, isRequired } = this.props;

        return <Columns amount="2">
            <InputField
                type="select"
                name="gender"
                label="USERS_GENDER"
                value={data.gender}
                error={errors.gender}
                onChange={onChange}
                options="USERS_GENDERS"
                isRequired={isRequired}
                withNone
            />
            <InputField
                type="date"
                name="birthDate"
                label="GENERAL_BIRTHDATE"
                value={data.birthDate}
                error={errors.birthDate}
                onChange={onChange}
            />
        </Columns>;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        data       : PropTypes.object.isRequired,
        errors     : PropTypes.object.isRequired,
        onChange   : PropTypes.func.isRequired,
        isRequired : PropTypes.bool,
    }
}

export default PersonalField;
