import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { CloseReason }      from "Utils/API";

// Types
import {
    CLOSE_REASON, CLOSE_REASON_LOADING,
    CLOSE_REASON_LIST, CLOSE_REASON_ELEM, CLOSE_REASON_CREATE,
} from "Utils/Types";



/**
 * Fetches the Close Reasons List
 * @param {Object=} params
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCloseReasons(params = {}) {
    return async (dispatch) => {
        dispatch({ type : CLOSE_REASON_LOADING });
        Params.unset(params);
        const data = await CloseReason.getAll(params);
        data.sort = params;
        dispatch({ type : CLOSE_REASON_LIST, data });
        dispatch(setSection(CLOSE_REASON));
    };
}

/**
 * Fetches a single Close Reason
 * @param {Number} reasonID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCloseReason(reasonID) {
    return async (dispatch) => {
        const data = await CloseReason.getOne({ reasonID });
        dispatch({ type : CLOSE_REASON_ELEM, data });
    };
}

/**
 * Fetches the Close Reason Create data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCloseReasonCreate() {
    return async (dispatch) => {
        const data = await CloseReason.getCreateData();
        dispatch({ type : CLOSE_REASON_CREATE, data });
    };
}



/**
 * Edits a Close Reason
 * @param {Object} data
 * @returns {() => Void}
 */
export function editCloseReason(data) {
    return () => CloseReason.edit(data);
}

/**
 * Deletes a Close Reason
 * @param {Number} reasonID
 * @returns {() => Void}
 */
export function deleteCloseReason(reasonID) {
    return () => CloseReason.delete({ reasonID });
}
