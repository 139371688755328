import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Utils                from "dashboard/dist/Utils/Utils";
import Href                 from "dashboard/dist/Core/Href";
import Common               from "Utils/Common";

// Components
import AnswerView           from "./AnswerView";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import Downloader           from "dashboard/dist/Components/Downloader";

// Actions
import {
    fetchStudents, exportAnswers,
} from "Actions/Admin/Strech/AnswerActions";



/**
 * The Strech Answer List
 */
class AnswerList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @returns {Void}
     */
    fetch = () => {
        const { strechID } = this.props;
        this.props.fetchStudents(strechID);
    }

    /**
     * Starts an Action
     * @param {Object}          action
     * @param {(Number|String)} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isStudent) {
            const student = Utils.getValue(this.props.students, "credentialID", elemID);
            Href.goto(Common.getAccessBaseUrl(student), student.credentialID);
        } else {
            this.props.startAction(action, elemID);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { students, loading, params, action, elemID, strechID, endAction } = this.props;

        return <>
            <Table
                fetch={this.fetch}
                none="ANSWERS_NONE_AVAILABLE"
                isLoading={loading}
                noSorting
                hasTabs
            >
                <TableHead>
                    <TableHeader field="firstName"   message="STUDENTS_SINGULAR" />
                    <TableHeader field="createdTime" message="ANSWERS_DATE"      maxWidth="90" />
                </TableHead>
                <TableBody>
                    {students.map((elem) => <TableRow key={elem.credentialID} elemID={elem.credentialID}>
                        <TableCell message={elem.credentialName} />
                        <TableCell message={elem.answeredDate}   />
                    </TableRow>)}
                </TableBody>
                <TableActionList onAction={this.startAction}>
                    <TableAction action="VIEW"    message="ANSWERS_VIEW_TITLE"  />
                    <TableAction action="STUDENT" message="STUDENTS_VIEW_TITLE" />
                </TableActionList>
            </Table>

            <AnswerView
                open={action.isView}
                elemID={elemID}
                strechID={params.strechID}
                onClose={endAction}
            />
            <Downloader
                download={action.isExport}
                source={exportAnswers(strechID)}
                onLoad={endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStudents : PropTypes.func.isRequired,
        exportAnswers : PropTypes.func.isRequired,
        startAction   : PropTypes.func.isRequired,
        endAction     : PropTypes.func.isRequired,
        loading       : PropTypes.bool.isRequired,
        students      : PropTypes.array.isRequired,
        params        : PropTypes.object.isRequired,
        action        : PropTypes.object,
        strechID      : PropTypes.number,
        elemID        : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loading  : state.strechAnswer.loading,
            students : state.strechAnswer.students,
        };
    }
}

export default connect(AnswerList.mapStateToProps, {
    fetchStudents, exportAnswers,
})(AnswerList);
