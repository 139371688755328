import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchPaymentMethod, fetchPaymentMethodCreate, editPaymentMethod,
} from "Actions/Admin/Setup/PaymentMethodActions";



/**
 * The Payment Method Edit Dialog
 */
class PaymentMethodEdit extends React.Component {
    // The Initial Data
    initialData = {
        paymentMethodID : 0,
        name            : "",
        position        : "",
        status          : Status.getID("ACTIVE"),
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, position, fetchPaymentMethod, fetchPaymentMethodCreate } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchPaymentMethod(elemID);
                loading = true;
            // Load edit data
            } else {
                fetchPaymentMethodCreate();
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData, position };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editPaymentMethod(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose } = this.props;
        const { data, loading, errors } = this.state;

        return <EditDialog
            open={open}
            icon="billing"
            title={!!elemID ? "PAYMENT_METHODS_EDIT_TITLE" : "PAYMENT_METHODS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                name="name"
                label="PAYMENT_METHODS_ITS_NAME"
                value={data.name}
                error={errors.name}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="number"
                name="position"
                label="GENERAL_POSITION"
                value={data.position}
                error={errors.position}
                onChange={this.handleChange}
                shrinkLabel
            />
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                value={data.status}
                error={errors.status}
                onChange={this.handleChange}
                options={Status.getSelect()}
                withNone
                isRequired
                shrinkLabel
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchPaymentMethod       : PropTypes.func.isRequired,
        fetchPaymentMethodCreate : PropTypes.func.isRequired,
        editPaymentMethod        : PropTypes.func.isRequired,
        open                     : PropTypes.bool.isRequired,
        onClose                  : PropTypes.func.isRequired,
        onSubmit                 : PropTypes.func.isRequired,
        edition                  : PropTypes.number.isRequired,
        position                 : PropTypes.number.isRequired,
        elem                     : PropTypes.object.isRequired,
        elemID                   : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition  : state.paymentMethod.edition,
            position : state.paymentMethod.position,
            elem     : state.paymentMethod.elem,
        };
    }
}

export default connect(PaymentMethodEdit.mapStateToProps, {
    fetchPaymentMethod, fetchPaymentMethodCreate, editPaymentMethod,
})(PaymentMethodEdit);
