import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchGroupChange, changeGroups,
} from "Actions/Admin/Strech/GroupActions";



/**
 * The Group Change Dialog
 */
class GroupChange extends React.Component {
    // The Current State
    state = {
        loading : false,
        data    : {
            credentialID : 0,
            groupID      : 0,
        },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, strechID, credentialID, groupID, fetchGroupChange } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchGroupChange(strechID, credentialID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false, data : { strechID, credentialID, groupID } });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { changeGroups, onSubmit } = this.props;
        const { data, loading          } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await changeGroups(data);
                this.setState({ loading : false });
                onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }

    /**
     * Handles the Close
     * @returns {Void}
     */
    handleClose = () => {
        this.props.onClose();
        this.setState({ members : {} });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, strechName, studentName, groups } = this.props;
        const { loading, data, errors                 } = this.state;

        return <EditDialog
            open={open}
            icon="group"
            title="GROUPS_CHANGE_TITLE"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={this.handleClose}
            isLoading={loading}
        >
            <DialogMessage html={NLS.format("GROUPS_CHANGE_HELP", studentName, strechName)} />
            <InputField
                type="select"
                name="groupID"
                label="GROUPS_SINGULAR"
                value={data.groupID}
                error={errors.groupID}
                options={groups}
                onChange={this.handleChange}
                withNone
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchGroupChange : PropTypes.func.isRequired,
        changeGroups     : PropTypes.func.isRequired,
        open             : PropTypes.bool.isRequired,
        onSubmit         : PropTypes.func.isRequired,
        onClose          : PropTypes.func.isRequired,
        edition          : PropTypes.number.isRequired,
        canEdit          : PropTypes.bool.isRequired,
        groupID          : PropTypes.number.isRequired,
        strechName       : PropTypes.string.isRequired,
        studentName      : PropTypes.string.isRequired,
        groups           : PropTypes.array.isRequired,
        strechID         : PropTypes.number,
        credentialID     : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition     : state.strechGroup.edition,
            canEdit     : state.strechGroup.canEdit,
            groupID     : state.strechGroup.groupID,
            strechName  : state.strechGroup.strechName,
            studentName : state.strechGroup.studentName,
            groups      : state.strechGroup.groups,
        };
    }
}

export default connect(GroupChange.mapStateToProps, {
    fetchGroupChange, changeGroups,
})(GroupChange);
