import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Strech, Coach }    from "Utils/API";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchCoach, fetchCoachCreate, editCoach,
} from "Actions/Admin/Strech/CoachActions";



/**
 * The Strech Coach Edit Dialog
 */
class CoachEdit extends React.Component {
    // The Initial Data
    initialData = {
        coachID        : 0,
        strechID       : 0,
        strechName     : "",
        credentialID   : 0,
        credentialName : "",
        type           : 2,
        price          : "",
        canAssign      : 1,
        modes          : [],
        times          : [],
        position       : 0,
        status         : Status.getID("ACTIVE"),
    }

    // The Current State
    state = {
        loading : false,
        data    : Utils.clone(this.initialData),
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const {
            open, edition, elemID, elem, strechID, credentialID, position,
            fetchCoach, fetchCoachCreate,
        } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = Utils.clone(this.initialData);
            // Load new data
            if (elemID) {
                fetchCoach(elemID);
                loading = true;
            // Load edit data
            } else if (strechID) {
                fetchCoachCreate(strechID);
                loading = true;
            // Set the data
            } else {
                data.credentialID = credentialID;
            }

        // Data Updated
        } else if (prevProps.edition !== edition) {
            const initial = Utils.clone(this.initialData);
            if (elemID) {
                data = Utils.extend(initial, elem);
            } else if (strechID) {
                data = { ...initial, strechID, position };
            } else {
                data = { ...initial, position };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
        if (name === "strechID") {
            this.props.fetchCoachEdit(value);
        }
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editCoach(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, strechID, credentialID, hasConversations } = this.props;
        const { data, loading, errors                                           } = this.state;

        return <EditDialog
            open={open}
            icon="coach"
            title={!!elemID ? "COACHES_EDIT_TITLE" : "COACHES_ASSIGN_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                isHidden={!!strechID}
                name="strechName"
                label="STRECHES_SINGULAR"
                suggestID="strechID"
                suggestFetch={Strech.search}
                suggestNone="STRECHES_NONE_AVAILABLE"
                value={data.strechName}
                error={errors.strechID}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                isHidden={!!credentialID}
                name="credentialName"
                label="COACHES_SINGULAR"
                suggestID="credentialID"
                suggestFetch={Coach.search}
                suggestNone="COACHES_NONE_AVAILABLE"
                value={data.credentialName}
                error={errors.credentialID}
                onChange={this.handleChange}
                isRequired
            />
            <Columns>
                <InputField
                    type="select"
                    name="type"
                    label="COACHES_TYPE"
                    value={data.type}
                    error={errors.type}
                    onChange={this.handleChange}
                    options="COACHES_TYPES"
                    withNone
                    isRequired
                />
                <InputField
                    type="number"
                    icon="money"
                    name="price"
                    label="COACHES_PRICE"
                    value={data.price}
                    error={errors.price}
                    onChange={this.handleChange}
                />

                <InputField
                    isHidden={!hasConversations}
                    className="columns-double"
                    type="toggle"
                    name="canAssign"
                    label="COACHES_CAN_ASSIGN"
                    value={data.canAssign}
                    onChange={this.handleChange}
                />
                <InputField
                    isHidden={!hasConversations || !data.canAssign}
                    type="multiple"
                    name="modes"
                    label="GENERAL_MODES"
                    value={data.modes}
//                    error={errors.modes}
                    onChange={this.handleChange}
                    options="MODES"
  //                  isRequired
                />
                <InputField
                    isHidden={!hasConversations || !data.canAssign}
                    type="multiple"
                    name="times"
                    label="SCHEDULES_NAME"
                    value={data.times}
    //                error={errors.times}
                    onChange={this.handleChange}
                    options="TIMES"
      //              isRequired
                />

                <InputField
                    type="number"
                    name="position"
                    label="GENERAL_POSITION"
                    value={data.position}
                    error={errors.position}
                    onChange={this.handleChange}
                    shrinkLabel
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    error={errors.status}
                    onChange={this.handleChange}
                    options={Status.getSelect()}
                    withNone
                    isRequired
                    shrinkLabel
                />
            </Columns>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCoach       : PropTypes.func.isRequired,
        fetchCoachCreate : PropTypes.func.isRequired,
        editCoach        : PropTypes.func.isRequired,
        open             : PropTypes.bool.isRequired,
        onClose          : PropTypes.func.isRequired,
        onSubmit         : PropTypes.func.isRequired,
        edition          : PropTypes.number.isRequired,
        position         : PropTypes.number.isRequired,
        hasConversations : PropTypes.bool.isRequired,
        elem             : PropTypes.object.isRequired,
        elemID           : PropTypes.number,
        strechID         : PropTypes.number,
        credentialID     : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition          : state.strechCoach.edition,
            position         : state.strechCoach.position,
            hasConversations : state.strechCoach.hasConversations,
            elem             : state.strechCoach.elem,
        };
    }
}

export default connect(CoachEdit.mapStateToProps, {
    fetchCoach, fetchCoachCreate, editCoach,
})(CoachEdit);
