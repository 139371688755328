// Access IDs
const GENERAL = 0;
const STUDENT = 1;
const COACH   = 2;

const SUPPORT = 11;
const ADMIN   = 12;
const OWNER   = 13;



// The App Access
const access = {
    "values" : [
        {
            "id"      : GENERAL,
            "name"    : "GENERAL",
            "message" : "ACCESS_GENERAL",
        },
        {
            "id"      : STUDENT,
            "name"    : "STUDENT",
            "message" : "ACCESS_STUDENT",
        },
        {
            "id"      : COACH,
            "name"    : "COACH",
            "message" : "ACCESS_COACH",
        },

        {
            "id"      : SUPPORT,
            "name"    : "SUPPORT",
            "message" : "ACCESS_SUPPORT",
        },
        {
            "id"      : ADMIN,
            "name"    : "ADMIN",
            "message" : "ACCESS_ADMIN",
        },
        {
            "id"      : OWNER,
            "name"    : "OWNER",
            "message" : "ACCESS_OWNER",
        },
    ],

    // The Access per Type
    "groups" : {
        "USER"    : [ STUDENT, COACH ],
        "SUPPORT" : [ SUPPORT, ADMIN ],
        "ADMIN"   : [ SUPPORT, ADMIN, OWNER ],
        "OWNER"   : [ ADMIN, OWNER ],
    },

    // The Access IDs
    STUDENT,
    COACH,
    SUPPORT,
    ADMIN,
    OWNER,
};

export default access;
