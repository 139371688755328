import { setSection }       from "Actions/Core/CoreActions";
import { EmailQueue }       from "Utils/API";

// Types
import {
    EMAIL_QUEUE, EMAIL_QUEUE_LOADING,
    EMAIL_QUEUE_LIST, EMAIL_QUEUE_ELEM,
} from "Utils/Types";



/**
 * Fetches the Email Queue List
 * @param {Object=} filter
 * @param {Object=} sort
 * @returns {(dispatch: Function) => Void}
 */
export function fetchQueue(filter = {}, sort = {}) {
    return async (dispatch) => {
        try {
            dispatch({ type : EMAIL_QUEUE_LOADING });
            const data  = await EmailQueue.getAll({ ...filter, ...sort });
            data.filter = filter;
            data.sort   = sort;
            dispatch({ type : EMAIL_QUEUE_LIST, data });
            dispatch(setSection(EMAIL_QUEUE));
        } catch (errors) {
            throw errors;
        }
    };
}

/**
 * Fetches a single Email Queue Element
 * @param {Number} emailID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchEmail(emailID) {
    return async (dispatch) => {
        const data = await EmailQueue.getOne({ emailID });
        dispatch({ type : EMAIL_QUEUE_ELEM, data });
    };
}
