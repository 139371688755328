import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "dashboard/dist/Core/NLS";

// Sections
import AuthPage             from "Components/Auth/AuthPage";
import EnrollPage           from "Components/Auth/EnrollPage";
import AssignPage           from "Components/Auth/AssignPage";

// Components
import GuestRoute           from "Components/Utils/Route/GuestRoute";
import HyperLink            from "dashboard/dist/Components/HyperLink";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import AnyRoute             from "dashboard/dist/Components/AnyRoute";

// Images
import Logo                 from "Styles/Images/LogoWhite.png";

// Styles
import "Styles/Images/AuthBG.jpg";
import "Styles/Components/Core/Auth.css";



/**
 * The Auth Page
 */
class Auth extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { location } = this.props;

        return <div className="auth">
            <header className="auth-header">
                <h1>
                    <HyperLink variant="none" url="AUTH" useBase>
                        <img src={Logo} alt={NLS.get("TITLE")} />
                    </HyperLink>
                </h1>
            </header>
            <SwitchRoute location={location} saveRoute>
                <GuestRoute url="AUTH"               component={AuthPage}   exact />
                <GuestRoute url="AUTH_CODE"          component={AuthPage}   exact />

                <AnyRoute   url="INSCRIPTION"        component={EnrollPage} exact />
                <AnyRoute   url="INSC_NONE_STRECH"   component={EnrollPage} exact />
                <AnyRoute   url="INSC_NONE_CHAT"     component={EnrollPage} exact />
                <AnyRoute   url="INSC_COURSE"        component={EnrollPage} exact />
                <AnyRoute   url="INSC_COURSE_STRECH" component={EnrollPage} exact />
                <AnyRoute   url="INSC_COURSE_CHAT"   component={EnrollPage} exact />
                <AnyRoute   url="INSC_STRECH"        component={EnrollPage} exact />
                <AnyRoute   url="INSC_STRECH_ACCESS" component={EnrollPage} exact />
                <AnyRoute   url="INSC_STRECH_STRECH" component={EnrollPage} exact />
                <AnyRoute   url="INSC_STRECH_CHAT"   component={EnrollPage} exact />

                <AnyRoute   url="ASSIGNME_STRECH"    component={AssignPage} exact />
                <AnyRoute   url="ASSIGNME_CODE"      component={AssignPage} exact />
            </SwitchRoute>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        location : PropTypes.object.isRequired,
    }
}

export default Auth;
