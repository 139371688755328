import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Containers
import StudentContainer     from "Components/Student/StudentContainer";
import ProfileContainer     from "Components/Profile/ProfileContainer";

// Sections
import StrechPage           from "Components/Student/Strech/StrechPage";

// Components
import StudentRoute         from "Components/Utils/Route/StudentRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";



/**
 * The Student Router
 */
class StudentRouter extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        if (!this.props.isStudent) {
            return <React.Fragment />;
        }
        return <SwitchRoute>
            <StudentRoute url="PROFILE"     component={ProfileContainer} />
            <StudentRoute url="COURSE_MINE" component={StrechPage}       />
            <StudentRoute url="NONE"        component={StudentContainer} />
        </SwitchRoute>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isStudent : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isStudent : state.auth.isStudent,
        };
    }
}

export default connect(StudentRouter.mapStateToProps)(StudentRouter);
