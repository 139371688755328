import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";
import TabList              from "dashboard/dist/Components/TabList";
import TabItem              from "dashboard/dist/Components/TabItem";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";

// Actions
import {
    fetchStrechesAsStudent,
} from "Actions/Admin/Strech/StrechActions";



/**
 * The Strech List
 */
class StrechList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        this.props.fetchStrechesAsStudent(params || this.props.data.sort);
    }

    /**
     * Starts an Action
     * @param {Object}          action
     * @param {(Number|String)} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        this.fetch({
            ...this.props.data.sort,
            filter   : elemID,
            page     : 0,
            orderAsc : elemID === "ended" ? 0 : 1,
        });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, route                         } = this.props;
        const { canEdit, list, total, sort, loading } = data;

        return <Main>
            <Header message="COURSES_MINE" icon="strech" route={route} />
            <Content>
                <TabList selected={sort.filter} onAction={this.startAction}>
                    <TabItem message="STRECHES_ACTIVE"   value="active"   />
                    <TabItem message="STRECHES_UPCOMING" value="upcoming" />
                    <TabItem message="STRECHES_ENDED"    value="ended"    />
                </TabList>
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="COURSES_NONE_AVAILABLE"
                    isLoading={loading}
                >
                    <TableHead>
                        <TableHeader field="title"        message="GENERAL_NAME"       grow="1.5" />
                        <TableHeader field="locationName" message="LOCATIONS_SINGULAR" />
                        <TableHeader field="fromTime"     message="GENERAL_START"      maxWidth="90" />
                        <TableHeader field="toTime"       message="GENERAL_END"        maxWidth="90" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.strechID} elemID={elem.strechID}>
                            <TableCell message={elem.title}        />
                            <TableCell message={elem.locationText} />
                            <TableCell message={elem.fromDate}     />
                            <TableCell message={elem.toDate}       />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW" message="STRECHES_VIEW_TITLE" route={route} />
                    </TableActionList>
                </Table>
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchStrechesAsStudent : PropTypes.func.isRequired,
        data                   : PropTypes.object.isRequired,
        type                   : PropTypes.string.isRequired,
        route                  : PropTypes.string.isRequired,
        elemID                 : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.strech,
        };
    }
}

export default connect(StrechList.mapStateToProps, {
    fetchStrechesAsStudent,
})(StrechList);
