import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import LocalityEdit         from "./LocalityEdit";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Content              from "dashboard/dist/Components/Content";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchLocalities, deleteLocality,
} from "Actions/Admin/Setup/LocalityActions";



/**
 * The Locality List
 */
class LocalityList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        this.props.fetchLocalities(params || this.props.data.sort);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch();
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID = this.state.elemID;
        this.endAction();
        await this.props.deleteLocality(elemID);
        this.fetch();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                      } = this.state;
        const { data, route                         } = this.props;
        const { canEdit, list, total, sort, loading } = data;

        const elemName = Utils.getValue(list, "localityID", elemID, "name");

        return <Main>
            <Header message="LOCALITIES_NAME" icon="locality" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content isLoading={loading}>
                <Table fetch={this.fetch} sort={sort} none="LOCALITIES_NONE_AVAILABLE">
                    <TableHead>
                        <TableHeader field="countryName"  message="COUNTRIES_SINGULAR"    />
                        <TableHeader field="provinceName" message="PROVINCES_SINGULAR"    />
                        <TableHeader field="department"   message="LOCALITIES_DEPARTMENT" />
                        <TableHeader field="name"         message="LOCALITIES_SINGULAR"   />
                        <TableHeader field="createdTime"  message="GENERAL_CREATED_FEM"   maxWidth="90" hideMobile />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.localityID} elemID={elem.localityID}>
                            <TableCell message={elem.countryName}  circle={elem.statusColor} />
                            <TableCell message={elem.provinceName} />
                            <TableCell message={elem.department}   />
                            <TableCell message={elem.name}         />
                            <TableCell message={elem.createdDate}  />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="EDIT"   message="LOCALITIES_EDIT_TITLE"   />
                        <TableAction action="DELETE" message="LOCALITIES_DELETE_TITLE" />
                    </TableActionList>
                </Table>
            </Content>

            <LocalityEdit
                open={action.isVCE}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="LOCALITIES_DELETE_TITLE"
                message="LOCALITIES_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchLocalities : PropTypes.func.isRequired,
        deleteLocality  : PropTypes.func.isRequired,
        data            : PropTypes.object.isRequired,
        route           : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.locality,
        };
    }
}

export default connect(LocalityList.mapStateToProps, {
    fetchLocalities, deleteLocality,
})(LocalityList);
